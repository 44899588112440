import React, { useEffect, useState } from 'react';
import { Card, Dialog, DialogContent, DialogTitle, Fab, Grid, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddCircleOutline, AirlineSeatReclineNormal, ChangeCircleOutlined, CheckCircleOutline, Close, CurrencyExchange } from '@mui/icons-material';
import { ReactComponent as BaggageImage } from '../../../assets/images/baggage/baggage.svg';
import { ReactComponent as SmallBaggage } from '../../../assets/images/baggage/smallBaggage.svg';
import { ReactComponent as MediumBaggage } from '../../../assets/images/baggage/mediumBaggage.svg';
import { ReactComponent as LargeBaggage } from '../../../assets/images/baggage/largeBaggage.svg';
import Carousel from 'react-multi-carousel';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useClientData } from '../../../context/ClientContext';
import { getClientIp } from '../../../store/services/IpServices';
import { LoadingButton } from '@mui/lab';
import { startCheckoutAction } from '../../../store/actions';
import Preloader from '../../common/Preloader';
import { formatNumber, getCurrency } from '../../../utils/price';
import { isMobile } from 'react-device-detect';
import { FEATURES, PENALTY } from '../../../utils/flightsData';

function AvailabilityModal({
  recommendationID,
  flight,
  client,
  selectedFlights,
  open,
  availability,
  apiCheckout,
  errorApiCheckout,
  handleClose,
  ...props
}) {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { clientData, currencies, currentProduct, setClientData } = useClientData();
  const [loadingCheckout, setLoadingCheckout] = useState(false);
  const [selectedFare, setSelectedFare] = useState();

  const carouselResponsive = {
    breakpoint3: {
      breakpoint: { max: 4000, min: 768 },
      items: 3,
    },
    breakpoint2: {
      breakpoint: { max: 765, min: 585 },
      items: 2,
    },
    breakpoint1: {
      breakpoint: { max: 584, min: 0 },
      items: 1,
    }
  };

  useEffect(() => {
    if (clientData?.client?.isB2C) {
      const bookingFare = availability?.Booking?.BookingFare;
      const alternativeFare = availability?.AlternativeFares?.find(elem => (
        elem.ExtendedFareInfo.NetTotalAmountWithFee === bookingFare?.ExtendedFareInfo.NetTotalAmountWithFee
      ));
      setSelectedFare(alternativeFare || availability?.Booking?.BookingFare || availability?.AlternativeFares?.[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availability])

  useEffect(() => {
    if (loadingCheckout && apiCheckout && Object.keys(apiCheckout).length !== 0) {
      if (!errorApiCheckout) {
        const iframePath = params.tokenIframe ? `${params.tokenIframe}/` : '';
        if (apiCheckout.baseDomain) {
          if (window.location.hostname === 'localhost' || (`${window.location.origin}/` === apiCheckout.baseDomain)) {
            navigate(apiCheckout.urlRedirect.replace(apiCheckout.baseDomain, '/' + iframePath));
          } else {
            window.location.href = apiCheckout.urlRedirect.replace(apiCheckout.baseDomain, apiCheckout.baseDomain + iframePath)
          }
        } else {
          navigate(`/${iframePath}${apiCheckout.urlRedirect}`);
        }
      } else {
        setLoadingCheckout(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorApiCheckout, apiCheckout]);

  const getCabinClass = (fare) => {
    const classes = {
      Y: clientData?.client?.isB2C ? t('results.flights.economyB2C') : t('results.flights.economy'),
      B: t('results.flights.business'),
      F: t('results.flights.firstClass'),
      P: t('results.flights.premium'),
      W: t('results.flights.premium')
    };

    const cabinClass = fare?.FareFeatures?.find(elem => elem.Code === 'CABIN_CLASS')?.Value;
    if (cabinClass && classes[cabinClass]) {
      return <Typography className='fare-subtitle'>
        {`${t('results.flights.class')} ${classes[cabinClass]}`}
      </Typography>;
    }
  }

  const hasFeatureIncluded = (features, carrier, feature) => {
    return features?.filter(elem1 => {
      const featureAux = FEATURES[elem1.Code]?.[feature]?.[carrier] || FEATURES[elem1.Code]?.[feature]?.all;
      if (featureAux) {
        let included = featureAux?.included?.[0] === 'all'
          || featureAux?.included?.every(elem2 => elem1.Description.includes(elem2));

        if (included) {
          return !featureAux.not_included
            || featureAux.not_included.length === 0
            || featureAux.not_included?.every(elem2 => !elem1.Description.includes(elem2));
        }
      }

      return false;
    }) || [];
  }

  const getBaggages = (fare) => {
    const baggages = {
      small: {
        icon: <SmallBaggage />,
        title: t('results.flights.smallBaggageTitle'),
        description: t('results.flights.smallBaggageDescription')
      },
      medium: {
        icon: <MediumBaggage className='not-included' />,
        title: t('results.flights.mediumBaggageNotIncluded')
      },
      large: {
        icon: <LargeBaggage className='not-included' />,
        title: t('results.flights.largeBaggageNotIncluded')
      }
    };

    const mediumBaggageIncluded = hasFeatureIncluded(fare?.FareFeatures, fare.ValidatingCarrier, 'CABIN_BAG');
    if (mediumBaggageIncluded?.length > 0) {
      baggages['medium'] = {
        icon: <MediumBaggage />,
        title: t('results.flights.mediumBaggageTitle'),
        description: t('results.flights.mediumBaggageDescription')
      };
    }

    const largeBaggageIncluded = hasFeatureIncluded(fare?.FareFeatures, fare.ValidatingCarrier, 'CHECKED_BAG');
    if (largeBaggageIncluded?.length > 0) {
      const description = [];

      const baggage = fare?.FareFeatures?.find(elem => elem.Code === 'BAGGAGE');
      if (baggage?.Value?.includes('K')) {
        const splitPC = baggage?.Value?.split('K');
        const kg = splitPC.length > 0 ? splitPC[0] : 0;
        fare?.PaxFares?.forEach(elem => {
          const passenger = elem.PaxType === 'ADT'
            ? t('results.flights.adult')
            : elem.PaxType === 'CHD'
              ? t('results.flights.kid')
              : t('results.flights.baby');
          description.push(t('results.flights.pieceKgPerPassenger', { kg, passenger: passenger.toLowerCase() }));
        })
      } else {
        let pieces = largeBaggageIncluded.filter(elem => elem.Code === 'CHECKED_BAG')?.length || 1;
        if (baggage?.Value?.includes('PC')) {
          const splitPC = baggage?.Value?.split('PC');
          pieces = splitPC.length > 0 ? splitPC[0] : 0;
        }
        fare?.PaxFares?.forEach(elem => {
          const passenger = elem.PaxType === 'ADT'
            ? t('results.flights.adult')
            : elem.PaxType === 'CHD'
              ? t('results.flights.kid')
              : t('results.flights.baby');
          description.push(t(
            `results.flights.${pieces > 1 ? 'piecesPerPassenger' : 'piecePerPassenger'}`,
            { pieces, passenger: passenger.toLowerCase() }
          ));
        })
      }

      description.push(t('results.flights.largeBaggageDescription'));

      baggages['large'] = {
        icon: <LargeBaggage />,
        title: t('results.flights.largeBaggageIncluded'),
        description: description.join(' ')
      };
    }

    return <Grid container className='baggage-container' spacing={1}>
      {Object.values(baggages).map((elem, i) => (
        <Grid item xs={12} key={i}>
          <Grid container className='row' spacing={1} columns={24}>
            <Grid item xs={3} className='icon-container'>{elem.icon}</Grid>
            <Grid item xs={21} className='column baggage-description'>
              <b>{elem.title}</b>
              {elem.description && <span>{elem.description}</span>}
            </Grid>
          </Grid>
        </ Grid>
      ))}
    </Grid>
  }

  const getBaggageIcons = (fare) => {
    const baggage = {};

    const mediumBaggageIncluded = hasFeatureIncluded(fare?.FareFeatures, fare.ValidatingCarrier, 'CABIN_BAG');
    if (mediumBaggageIncluded.length === 0) {
      baggage['medium'] = 'not-included';
    }

    const largeBaggageIncluded = hasFeatureIncluded(fare?.FareFeatures, fare.ValidatingCarrier, 'CHECKED_BAG');
    if (largeBaggageIncluded.length === 0) {
      baggage['large'] = 'not-included';
    }

    return <Tooltip title={getBaggages(fare)}>
      <SmallBaggage className='small' />
      <MediumBaggage className={`medium ${baggage['medium']}`} />
      <LargeBaggage className={`large ${baggage['large']}`} />
    </Tooltip>
  }

  const getFareFeatures = (fare) => {
    const allFeatures = {
      SEAT_SELECTION: { value: t('results.flights.withExtraCost') },
      CHANGEABLE: { value: t('results.flights.notAllowed') },
      REFUNDABLE: { value: t('results.flights.notRefundable') },
    };

    const seatSelection = hasFeatureIncluded(fare?.FareFeatures, fare.ValidatingCarrier, 'SEAT_SELECTION');
    if (seatSelection.length > 0) {
      allFeatures['SEAT_SELECTION'].value = t('results.flights.included');
      allFeatures['SEAT_SELECTION'].className = 'included';
    }

    const changeable = hasFeatureIncluded(fare?.FareFeatures, fare.ValidatingCarrier, 'CHANGEABLE');
    if (changeable.length > 0) {
      if (changeable.some(elem => PENALTY.some(penalty => elem?.Description?.includes(penalty)))) {
        allFeatures['CHANGEABLE'].value = t('results.flights.withExtraCost');
      } else {
        allFeatures['CHANGEABLE'].value = t('results.flights.withoutExtraCost');
        allFeatures['CHANGEABLE'].className = 'included';
      }
    }

    const refundable = hasFeatureIncluded(fare?.FareFeatures, fare.ValidatingCarrier, 'REFUNDABLE');
    if (refundable.length > 0) {
      if (refundable.some(elem => PENALTY.some(penalty => elem?.Description?.includes(penalty)))) {
        allFeatures['REFUNDABLE'].value = t('results.flights.allowedWithPenalty');
      } else {
        allFeatures['REFUNDABLE'].value = t('results.flights.allowedWithoutPenalty');
        allFeatures['REFUNDABLE'].className = 'included';
      }
    }

    return <>
      <div className='row'>
        <AirlineSeatReclineNormal className={allFeatures['SEAT_SELECTION'].className || ''} />
        <Typography><b>{t('results.flights.seatSelection')}:</b> {allFeatures['SEAT_SELECTION'].value}</Typography>
      </div>
      <div className='row'>
        <ChangeCircleOutlined className={allFeatures['CHANGEABLE'].className || ''} />
        <Typography><b>{t('results.flights.changeable')}:</b> {allFeatures['CHANGEABLE'].value}</Typography>
      </div>
      <div className='row'>
        <CurrencyExchange className={allFeatures['REFUNDABLE'].className || ''} />
        <Typography><b>{t('results.flights.refundable')}:</b> {allFeatures['REFUNDABLE'].value}</Typography>
      </div>
    </>
  }

  const handleClickReserve = async (fare, index) => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const accessToken = localStorage.getItem('jwt');
    const tokenCheckout = currentProduct?.config?.api_checkout_token || currentProduct?.config_work_unit?.api_checkout_token;
    const { currency } = params;
    const token = currentProduct?.config?.['API-Token']?.[currency];

    if (user && accessToken && tokenCheckout && token) {
      setLoadingCheckout(fare.FareID);

      const clientIp = await getClientIp();
      const urlRedirect = location.pathname;
      const userProducts = JSON.parse(localStorage.getItem('userProducts') || '{}');
      const userProduct = {
        work_unit_relation: {
          token: userProducts?.work_unit_relation?.[0]?.token || null,
          long_name: userProducts?.work_unit_relation?.[0]?.long_name || null
        },
        username: userProducts?.username,
        hash_client: userProducts?.hash_client,
        products: [
          {
            product_type_id: currentProduct?.product_type_id || null,
            ref_table_detail: currentProduct?.ref_table_detail || null,
            parameters: currentProduct?.parameters || null,
            work_unit_id: currentProduct?.work_unit_id || null,
            token_id: currentProduct?.token_id || null,
            work_unit_token: currentProduct?.work_unit_token || null,
            business_unit_token: currentProduct?.business_unit_token || null
          }
        ]
      }

      const requestData = {
        module: clientData?.client?.isB2C ? 'flights' : 'airNarrative',
        dataProduct: {
          recommendationID,
          fareId: flight.FareID,
          optionId: selectedFlights,
          brandedFare: fare.FareID,
          brandedFareIndex: index,
          adults: params.adults,
          children: params.kids,
          infants: params.babys,
          dateFrom: params.datesDeparture,
          dateTo: params.datesReturn,
          urlRedirect,
          supplier: null,
          ip: clientIp,
          utm: '',
          loginData: null,
          cabin: params.cabinClasses,
          urlMeta: window.location.href,
          token,
          currency
        },
        username: user?.username,
        user_product: userProduct,
        deviceType: isMobile ? 'mobile' : 'desktop'
      }

      props.startCheckout(accessToken, tokenCheckout, requestData);
    } else {
      const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
      if (iframePath) {
        localStorage.clear();
        setClientData(null);
        navigate(iframePath);
      } else {
        navigate('/login', { state: { expiredSession: true } });
      }
    }
  }

  const handleClickFare = (fare, index) => {
    if (clientData?.client?.isB2C) {
      setSelectedFare(fare);
    } else {
      handleClickReserve(fare, index);
    }
  }

  const handleClickContinue = () => {
    const index = availability?.AlternativeFares?.findIndex(elem => elem.FareID === selectedFare.FareID);
    if (index > -1 || availability.Booking?.BookingFare?.FareID === selectedFare.FareID) {
      handleClickReserve(selectedFare, index);
    }
  }

  const getFareButton = (fare, index) => {
    let text = t('results.flights.select');
    let icon = null;
    let variant = 'outlined';
    if (!clientData?.client?.isB2C) {
      text = t('common.reserve');
      icon = <AddCircleOutline />;
    } else if (selectedFare?.FareID === fare.FareID) {
      text = t('results.flights.selected');
      icon = <CheckCircleOutline />;
      variant='contained';
    }

    return <LoadingButton
      variant={variant}
      color='secondary'
      disabled={Boolean(loadingCheckout)}
      loading={loadingCheckout === fare.FareID}
      onClick={() => handleClickFare(fare, index)}
    >
      {icon}<span>{text}</span>
    </LoadingButton>
  }

  const isSelectedFare = (fare) => {
    return clientData?.client?.isB2C && selectedFare?.FareID === fare.FareID;
  }

  const toCamelCase = (str) => {
    if (str) {
      let words = str.split(' ');
      words = words.filter(elem => elem).map(elem => elem[0].toUpperCase() + elem.slice(1).toLowerCase());
      return words.join(' ');
    }
  }

  const getBrandedFares = () => {
    const bookingFare = availability?.Booking?.BookingFare;
    const brandedFares = availability?.AlternativeFares
      ?.filter(elem => elem.ExtendedFareInfo.NetTotalAmountWithFee >= bookingFare.ExtendedFareInfo.NetTotalAmountWithFee)
      || [];

    if (
      bookingFare?.FareCode !== brandedFares?.[0]?.FareCode ||
      bookingFare?.ExtendedFareInfo?.NetTotalAmountWithFee !== brandedFares?.[0]?.ExtendedFareInfo?.NetTotalAmountWithFee
    ) {
      bookingFare.FareCode = bookingFare?.FareCode || t('results.flights.simple');

      if (brandedFares.length > 0) {
        const cabinClassFeature = brandedFares[0]?.FareFeatures?.find(elem => elem.Code === 'CABIN_CLASS');

        if (cabinClassFeature) {
          const existingFeatures = bookingFare?.FareFeatures || [];

          if (!existingFeatures.find(elem => elem.Code === 'CABIN_CLASS')) {
            bookingFare.FareFeatures = [...existingFeatures, cabinClassFeature];
          }
        }
      }

      brandedFares.unshift(bookingFare);
    }

    return brandedFares;
  }

  return (
    <Dialog
      className='closable-modal flights-modal'
      open={open}
      maxWidth='md'
      fullWidth
      onClose={handleClose}
    >
      <Fab size='small' onClick={handleClose} className='close-button'>
        <Close fontSize='small' />
      </Fab>

      <DialogTitle className='row'>
        <BaggageImage className='baggage-image' />
        <Typography className='text'>{t('results.flights.improveFlight')}</Typography>
      </DialogTitle>

      <DialogContent>
        <Carousel responsive={carouselResponsive}>
          {getBrandedFares()?.map((fare, i) => {
            const countPassengers = fare?.PaxFares?.reduce((acc, current) => acc + current.Count, 0);
            const minPrice = availability?.Booking?.BookingFare?.ExtendedFareInfo?.NetTotalAmountWithFee || availability?.Booking?.BookingFare?.TotalAmount || 0;
            const differencePrice = (fare?.ExtendedFareInfo?.NetTotalAmountWithFee || fare?.TotalAmount || 0) - minPrice;
            const differencePricePassenger = differencePrice / countPassengers;
            const totalAmount = fare?.ExtendedFareInfo?.NetTotalAmountWithFee || fare?.TotalAmount || 0;
            const currency = getCurrency(clientData?.client, currencies, fare.Currency);

            return <Card key={i} className={isSelectedFare(fare) ? 'selected-fare' : ''}>
              <div className='top text-center'>
                <Typography className='fare-title'>
                  {t('results.flights.fare')} {toCamelCase(fare.FareCode)}
                </Typography>
                {getCabinClass(fare)}
              </div>

              <div className='column middle'>
                <div className='baggage-container-icons'>
                  {getBaggageIcons(fare)}
                </div>
                {getFareFeatures(fare)}
              </div>

              <div className='column bottom'>
                <Typography>
                  +&nbsp;
                  <b>
                    {currency}&nbsp;
                    {formatNumber(clientData?.client?.isB2C ? differencePrice : differencePricePassenger)}
                  </b>
                </Typography>
                <Typography>{t('results.flights.totalPrice')} <b>{currency} {formatNumber(totalAmount)}</b></Typography>
                {getFareButton(fare, i)}
              </div>
            </Card>
          })}
        </Carousel>

        {clientData?.client?.isB2C && (
          <Grid className='row total-price-container'>
            <Typography>
              {t('results.flights.totalPrice')}&nbsp;
              <span className='total-price'>
                {selectedFare && getCurrency(clientData?.client, currencies, selectedFare.Currency)}&nbsp;
                {formatNumber(selectedFare?.ExtendedFareInfo?.NetTotalAmountWithFee || 0)}
              </span>
            </Typography>
            <LoadingButton
              variant='contained'
              color='secondary'
              loading={Boolean(loadingCheckout)}
              disabled={!Boolean(selectedFare)}
              onClick={handleClickContinue}
            >
              <span>{t('common.continue')}</span>
            </LoadingButton>
          </Grid>
        )}
      </DialogContent>

      {loadingCheckout && (
        <Preloader
          addDots
          image={`sites/${client.client.name}/preloadAereos.gif`}
          text={t('common.processingRequest')}
        />
      )}
    </Dialog>
  );
}

const mapStateToProps = reducers => {
  return reducers.flightsReducer;
};

const mapDispatchToProps = dispatch => {
  return {
    startCheckout: (access, tokenCheckout, data) => dispatch(startCheckoutAction(access, tokenCheckout, data))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityModal);
