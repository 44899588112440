import React, { Fragment, useCallback, useMemo } from 'react';
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Tooltip, Typography } from '@mui/material';
import DetailItem from './cardDetails/DetailItem';
import { useTranslation } from 'react-i18next';
import { Add, CheckCircleOutline, MedicalServicesOutlined } from '@mui/icons-material';
import DialogModal from '../DialogModal';
import { GetMessageConditions } from '../CardDetailMessages';
import { useClientData } from '../../../../context/ClientContext';
import { formatNumber, getCurrency } from '../../../../utils/price';

const BRANDED_CLASSES = {
  Y: { value: 'economy', valueB2C: 'economyB2C' },
  P: { value: 'premium' },
  W: { value: 'premium' },
  B: { value: 'business' },
  F: { value: 'firstClass' }
};

const GetHeaderContent = ({ providerImage, provider, name, secondLine, promoLabel }) => (
  <Fragment>
    {promoLabel &&
      <div className='promo-label'>
        <div className='content-label'>
          <div className='text'>{promoLabel}</div>
          <div className='triangle' />
        </div>
      </div>
    }

    <div className='text-center'>
      {providerImage && <div className='img-provider'><img src={providerImage} alt={provider} /></div>}
      {name && (
        <Tooltip title={name}>
          <Typography noWrap variant='h3' className='product-card-title'>
            {name}
          </Typography>
        </Tooltip>
      )}
      {secondLine && (
        <Tooltip title={secondLine}>
          <Typography noWrap component='p' variant='body2' className='product-card-subtitle'>
            {secondLine}
          </Typography>
        </Tooltip>
      )}
    </div>
  </Fragment>
);

const CardProduct = ({ cardProduct, crossellData, isModal = false, onSelectCrossell, onRemoveCrossell }) => {
  const { t } = useTranslation();
  const { clientData, currencies } = useClientData();
  const {
    module,
    idOffer,
    cardDetail: {
      template,
      providerImage,
      provider,
      name,
      secondLine,
      detailItems,
      buttons,
      cabinClass,
      title,
      messages
    },
    priceDetail: {
      currencyCode,
      amount,
      priceOffer,
      promoLabel,
      priceDiffPerPerson
    }
  } = cardProduct;

  const selected = useMemo(() => (
    crossellData?.idOffer
      ? crossellData.idOffer === idOffer
      : module === 'brandedFares' && parseInt(idOffer) === 1
  ), [crossellData, module, idOffer]);

  const formatedSecondLine = useMemo(() => {
    if (module === 'brandedFares') {
      const brandedClass = BRANDED_CLASSES[cabinClass]
      const value = clientData?.client?.isB2C && brandedClass.valueB2C ? brandedClass.valueB2C : brandedClass.value;
      return value ? `${t('results.flights.class')} ${t(`results.flights.${value}`)}` : '';
    }

    return secondLine;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData, module, cabinClass, secondLine]);

  const handleClickCrossell = useCallback(() => {
    if (selected) {
      onRemoveCrossell && onRemoveCrossell();
    } else {
      onSelectCrossell(cardProduct);
    }
  }, [cardProduct, selected, onSelectCrossell, onRemoveCrossell]);

  const getCrosselIcon = useCallback((module) => {
    switch (module) {
      case 'assistmed':
        return <MedicalServicesOutlined />;
      default:
        return null;
    }
  }, []);

  const getPriceDetail = () => {
    const isBrandedFare = module === 'brandedFares';
    const currency = getCurrency(clientData?.client, currencies, currencyCode);

    return <div className={`detail-price-product ${priceOffer ? 'has-price-offer' : ''}`}>
      {(!isBrandedFare && priceOffer) && (
        <span className='price-offer'>{currency} {priceOffer}</span>
      )}
      <span className='product-price row'>
        {isBrandedFare && <span>+</span>}
        <span className='currency'>&nbsp;{currency}</span>&nbsp;
        <span className='amount'>{isBrandedFare ? formatNumber(priceDiffPerPerson) : amount}</span>
      </span>

      {isBrandedFare && (
        <>
          <p className='total-price'>
            {t('checkout.crossell.totalPrice')}&nbsp;
            <span className='currency'>{currency}</span>&nbsp;
            <span className='amount'>{amount}</span>
          </p>
        </>
      )}
    </div>
  }

  return (
    <Card className={`card-product-crossell card-product-${module} ${selected ? 'selected-product' : ''}`} id={`${module}-${idOffer}`}>
      <CardHeader
        className='card-product-header'
        disableTypography
        title={
          <GetHeaderContent
            providerImage={providerImage}
            provider={provider}
            name={name}
            secondLine={formatedSecondLine}
            promoLabel={promoLabel}
        />}
      />

      <CardContent className='card-product-content'>
        {detailItems.map((item, i) => (
          <DetailItem key={i} item={item} template={template} isModal={isModal} />
        ))}

        {buttons?.[0]?.detail &&
          <div className='container-button-modal'>
            <DialogModal
              fullWidth
              maxWidth='md'
              id='cardProductDialog'
              className='card-product'
              endIcon={<Add />}
              button={<span>{buttons[0]?.detail?.text}</span> }
              header={<Typography variant='h4' className='dialog-card-product-title'>
                {getCrosselIcon(module)}
                {title}
              </Typography>}
              content={
                <div className='dialog-card-product-content'>
                  {providerImage && module !== 'brandedFares' && (
                    <div className='img-provider'><img src={providerImage} alt={provider}/></div>
                  )}
                  {name && <Typography variant='h3' className='product-card-title'>{name}</Typography>}
                  {secondLine && (
                    <Typography component='p' variant='body2' className='product-card-subtitle'>{secondLine}</Typography>
                  )}
                  {detailItems.map((item, i) => (
                    <DetailItem key={i}
                      item={item}
                      template={template}
                      showDetailSearch
                      showDetailProductFull
                    />
                  ))}
                  <GetMessageConditions messages={messages} />
                </div>
              }
            />
          </div>
        }
      </CardContent>

      <CardActions className='card-product-footer'>
        <div className='text-center card-product-footer-content'>
          <Grid container>
            <Grid item xs={12}>
              {getPriceDetail()}
            </Grid>

            <Grid item xs={12}>
              <Button
                className='button-add-product'
                color='secondary'
                variant={selected ? 'contained' : 'outlined'}
                startIcon={selected && <CheckCircleOutline />}
                onClick={handleClickCrossell}
              >
                {selected ? t('checkout.crossell.selectedFare') : buttons?.[0]?.add?.text}
              </Button>
            </Grid>
          </Grid>
        </div>
      </CardActions>
    </Card>
  );
}

export default CardProduct;
