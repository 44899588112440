import React from 'react'
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

export default function MainTitle({ module }) {
  const { t } = useTranslation();

  return (
    <Typography className="main-title" variant="h1">
      {t(`checkout.${module}.mainTitle`)}
    </Typography>
  )
}
