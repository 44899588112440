import React from 'react';
import {  Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, Hidden, useMediaQuery, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import IconCustom from '../common/IconCustom'
import { useLayoutContext } from '../../context/LayoutContext';
import { useClientData } from '../../context/ClientContext';
import Multibuscador from '../common/Multibuscador/Multibuscador';
import { useBannersLandingsData } from '../../context/BannersLandingsContext';

function Navbar({ defaultModule, ...props }) {
  const { clientData } = useClientData();
  const location = useLocation();
  const { showModules } = useLayoutContext();
  const { bannersData } = useBannersLandingsData();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const isSelected = (item) => {
    return (defaultModule && item.path.includes(defaultModule))
      || location.pathname.includes(item.path);
  }

  function normalizeText(text) {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();
  }

  const getStyleContainer = () => {
    const selectedModule = clientData.modules.find(isSelected);
    if (selectedModule) {
      const module = normalizeText(selectedModule?.moduleName);
      const image = isMobile
        ? bannersData?.data?.modules?.[module]?.flows?.home?.banners?.SearchBackground?.slice(-1)?.[0]?.image_url_mobile
        : bannersData?.data?.modules?.[module]?.flows?.home?.banners?.SearchBackground?.slice(-1)?.[0]?.image_url;

      if (image) {
        return  {
          backgroundImage: `url('${image}')`,
          backgroundSize: 'cover',
          minHeight: 400,
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          alignItems: 'center'
        };
      }
    }

    return {};
  }

  const getMultibuscador = () => {
    const selectedModule = clientData.modules.find(isSelected);
    if (selectedModule) {
      return (
        <>
          <Hidden smDown>
            <Grid className='search-container' style={getStyleContainer()}>
              <Container>
                <Multibuscador moduleName={selectedModule.module} {...props} />
              </Container>
            </Grid>
          </Hidden>

          <Hidden smUp>
            <Grid className='search-container'>
              <Multibuscador moduleName={selectedModule.module} {...props} />
            </Grid>
          </Hidden>
        </>
      )
    }
  }

  return (

    <>
      {clientData?.modules && Array.isArray(clientData.modules) && clientData.modules.length > 1 && (
        <Accordion
          className='nav-bar-accordion'
          expanded={showModules}
        >
          <AccordionSummary />
          <AccordionDetails>
            <Box className='nav-bar'>
              <Container>
                {clientData.modules.map((item, index) => (
                  <Box
                    key={index}
                    component={'a'}
                    href={item.path}
                    className={isSelected(item) ? 'activeTab tab-item' : 'tab-item'}
                    target={item.target}
                  >
                    <IconCustom icon={item.icon} alt={item.moduleName} className="custom-icon" />
                    <span>{item.moduleName}</span>
                  </Box>
                ))}
              </Container>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}

      {getMultibuscador()}
    </>
  );
}

export default Navbar;
