import React from 'react';
import BannerSingleImage from './BannerSingleImage';
import BannerSlider from './BannerSlider';
import BannerPromotions from './BannerPromotions';
import BannerOffers from './BannerOffers';
import BannerFeatured from './BannerFeatured';

const AllBanners = ({
  banners,
  titlePromotions = '',
  titleOffers = '',
  titleFeatured = ''
}) => {

  const groupByGroup = (banners, title = 'empty') => {
    const groups = banners?.sort((a, b) => (a.order || Infinity) - (b.order || Infinity))?.reduce?.((acc, current) => {
      if (acc?.[current.group || title]) {
        acc[current.group || title].push(current);
      } else {
        acc[current.group || title] = [current];
      }

      return acc;
    }, {}) || {};

    return groups;
  }

  return <>
    {Object.entries(groupByGroup(banners.Slider)).map(([key, value], i) => (
      <BannerSlider key={i} title={key !== 'empty' ? key : ''} banners={value} />
    ))}
    {Object.entries(groupByGroup(banners.ImageCards, titlePromotions)).map(([key, value], i) => (
      <BannerPromotions key={i} title={key !== 'empty' ? key : ''} banners={value} />
    ))}
    {Object.entries(groupByGroup(banners.TextImageCards, titlePromotions)).map(([key, value], i) => (
      <BannerPromotions key={i} title={key !== 'empty' ? key : ''} banners={value} />
    ))}
    {Object.entries(groupByGroup(banners.ProductCards, titleOffers)).map(([key, value], i) => (
      <BannerOffers key={i} title={key !== 'empty' ? key : ''} banners={value} />
    ))}
    {Object.entries(groupByGroup(banners.HorizontalCardSlider, titleFeatured)).map(([key, value], i) => (
      <BannerFeatured key={i} title={key !== 'empty' ? key : ''} banners={value} />
    ))}
    {Object.entries(groupByGroup(banners.FullWidth)).map(([key, value], i) => (
      <BannerSingleImage key={i} title={key !== 'empty' ? key : ''} banners={value} />
    ))}
  </>
};

export default AllBanners;
