import React, { memo, useMemo } from 'react'
import { Typography, Icon, Chip } from '@mui/material';
// import IconC from '../_IconC'

const GetDetailSearchOneCol = memo(({ detailSearch }) => {
  return (
    <ul className='detail-item-list mb-1'>
      {detailSearch.map((item, i) => (
        <li key={i} className='list-item'>
          <div className={item.className}>
            <Typography component='p' className={item.icon ? 'has-icon' : ''}>
              {item.icon && <Icon>{item.icon}</Icon>}
              <strong>{item.title}</strong> {item.description}
            </Typography>
          </div>
        </li>
      ))}
    </ul>
  );
});

const GetDetailProductSelectionOneCol = memo(({ detailProduct }) => {
  return (
    <ul className='detail-item-list product'>
      {detailProduct.map((item, i) => (
        <li key={i} className='list-item detail-product-item'>
          <div className={`detail-product-item-container ${item.icon ? 'has-icon' : ''} ${item.className}`}>
            {/* {item.icon && <IconC icon={item.icon} className='custom-icon' />}  */}
            {item.title && (
              <Typography component='p'>
                <strong>{item.title}</strong>
              </Typography>
            )}
            {item.description && (
              <Typography component='p'>
                {item.description}
              </Typography>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
});

export default function CardOneCol({ template, item }) {
  const detailSearch = useMemo(() => item.details?.detailSearch || [], [item.details]);
  const detailProduct = useMemo(() => item.details?.detailProduct || [], [item.details]);

  return (
    <div className={template}>
      <div className='detail-item-header'>
        {item.title && <Typography className='detail-item-title' variant='h4'>
          {item.tagLabel && (
            <span className='detail-item-tags'>
              <Chip icon={item.icon && <Icon>{item.icon}</Icon> } size='small' label={item.tagLabel} />
            </span>
          )}
          {item.title}
          {item.image && <img className='detail-item-image' src={item.image} alt={item.title}/>}
        </Typography>}
      </div>

      {detailSearch.length > 0 && <GetDetailSearchOneCol detailSearch={detailSearch} />}
      {detailProduct.length > 0 && <GetDetailProductSelectionOneCol detailProduct={detailProduct} />}
    </div>
  )
}
