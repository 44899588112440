import React, { Fragment } from 'react';
import { Card, CardContent, CardHeader, Grid, Icon, Typography } from '@mui/material';
import * as TrainsDetail from './utils/TrainsDetail';
import * as FlightsDetail from './utils/FlightsDetail';
import { useTranslation } from 'react-i18next';
import { GetMessageConditions } from './CardDetailMessages';
import { useClientData } from '../../../context/ClientContext';
import DetailItem from './crossell/cardDetails/DetailItem';

const BRANDED_CLASSES = {
  Y: { value: 'economy', valueB2C: 'economyB2C' },
  P: { value: 'premium' },
  W: { value: 'premium' },
  B: { value: 'business' },
  F: { value: 'firstClass' }
};

export default function CardDetail({ product, module, isDetailDialog = false, isFinalView = false }) {
  const { t } = useTranslation();
  const { clientData } = useClientData();

  const { template, providerImage, category, name, secondLine, icon, title, detailItems, image, provider, cabinClass, messages } = product.cardDetail;

  const getIconCardDetail = (icon) => {
    if (icon === 'flight') {
      return <Icon>flight</Icon>
    } else if (icon === 'assist') {
      return <Icon>local_hospital</Icon>
    } else if (icon === 'hotel') {
      return <Icon>hotel</Icon>
    } else if (icon === 'cars') {
      return <Icon>directions_car</Icon>
    } else {
      return <Icon>info</Icon>
    }
  }

  const formatedSecondLine = () => {
    if (module === 'brandedFares') {
      const brandedClass = BRANDED_CLASSES[cabinClass]
      const value = clientData?.client?.isB2C && brandedClass.valueB2C ? brandedClass.valueB2C : brandedClass.value;
      return value ? `${t('results.flights.class')} ${t(`results.flights.${value}`)}` : '';
    }

    return secondLine;
  };

  const getDefaultDetails = () => {
    return <Card>
      <CardHeader
        avatar={getIconCardDetail(icon)}
        title={title}
      />
      <CardContent className='card-detail-product-content'>
        {template !== "CardTwoColsV2" && (
          <Fragment>
            {template === "CardOneColV2" ? (
              <Grid container spacing={1} className="CardOneColV2 autos">
                <Grid item sm={4}>
                  {image && (<div className="img-product"><img src={image} alt={name}/></div>)}
                </Grid>
                <Grid item sm={8}>
                  {providerImage && (<div className="img-provider"><img src={providerImage} alt={provider}/></div>)}
                  {category && (<Typography className="product-card-category" variant="body2" component="p">{category}</Typography>)}
                  {name && (<Typography noWrap variant="h3" className="product-card-title">{name}</Typography>)}
                  {secondLine && (<Typography noWrap component="p" variant="body2" className="product-card-subtitle">{secondLine}</Typography>)}
                </Grid>
              </Grid>
            )
            : template === "CardOneColV2brandedFares" ? (
              <Fragment>
                {providerImage && (<div className="img-provider"><img src={providerImage} alt={provider}/></div>)}
                {(name || cabinClass) && (
                  <Typography variant="h3" className="product-card-title" color='primary'>
                    {name}&nbsp;
                    {cabinClass && <span style={{ fontSize: 16 }}>({formatedSecondLine()})</span>}
                  </Typography>
                )}
              </Fragment>
            )
            : (
              <Fragment>
                {providerImage && (<div className="img-provider"><img src={providerImage} alt={provider}/></div>)}
                {name && (<Typography noWrap variant="h3" className="product-card-title">{name}</Typography>)}
                {secondLine && (<Typography noWrap component="p" variant="body2" className="product-card-subtitle">{secondLine}</Typography>)}
              </Fragment>
            )}
          </Fragment>
        )}

        {detailItems.map((item, i) => (
          <DetailItem
            key={i}
            item={item}
            template={template}
            isDetailDialog={isDetailDialog}
            showDetailSearch
            showDetailProductFull
          />
        ))}

        <GetMessageConditions messages={messages} />
      </CardContent>
    </Card>
  }

  const getDetail = () => {
    switch (module) {
      case 'trains':
        return TrainsDetail.getCardDetail(product.cardDetail.bookingItems);
      case 'flights':
        return FlightsDetail.getCardDetail(product, clientData?.client, t, isFinalView);
      default:
        return getDefaultDetails();
    }
  }

  if (product?.cardDetail) {
    return (
      <div className={`card-detail ${module}-details`}>
        {getDetail()}
      </div>
    )
  }
}
