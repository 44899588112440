const initialState = {
  responseReservations: null,
  responseReservation: null,
  responseCurrencies: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_RESERVATIONS':
      return {
        ...state,
        responseReservations: null
      };
    case 'GET_RESERVATIONS_SUCCESS':
      return {
        ...state,
        responseReservations: action.payload
      };
    case 'GET_RESERVATIONS_ERROR':
      return {
        ...state,
        responseReservations: []
      };
    case 'RESET_RESERVATION': {
      return {
        ...state,
        responseReservation: null
      };
    }
    case 'GET_RESERVATION_SUCCESS':
      return {
        ...state,
        responseReservation: action.payload
      };
    case 'GET_RESERVATION_ERROR':
      return {
        ...state,
        responseReservation: action.payload === 'canceled' ? null : {}
      };
    case 'GET_CURRENCIES_BY_PRODUCT_SUCCESS':
      return {
        ...state,
        responseCurrencies: action.payload
      };
    case 'GET_CURRENCIES_BY_PRODUCT_ERROR':
      return {
        ...state,
        responseCurrencies: null
      };
    default:
      return state;
  }
};

export default reducer;
