const formatNumber = (number, decimals = 0) => {
  return Number(number || 0).toFixed(decimals).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

const getCurrency = (client, currencies, currency) => {
  if (client?.isB2C) {
    return currencies?.find(elem => elem.short_name === currency?.toUpperCase())?.symbol || currency;
  }

  return currency;
}

export {
  formatNumber,
  getCurrency
}
