import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getCredentials } from './util';

function* getReservationsAction(actions) {
  const headers = {
    'Content-Type': "application/json",
    'authentication-token': actions.access
  };

  const requestOptions = {
    headers: headers
  };

  try {
    const response = yield call(axios.get, `${getCredentials().urlB2}reservation/${actions.query}`, requestOptions);
    yield put({ type: 'GET_RESERVATIONS_SUCCESS', payload: response.data });
  } catch (error) {
    yield put({ type: 'GET_RESERVATIONS_ERROR', payload: error });
  }
}

function* getReservationAction(actions) {
  const headers = {
    'Content-Type': "application/json",
    'authentication-token': actions.access
  };

  const requestOptions = {
    headers: headers
  };

  if (actions.signalAbort) {
    requestOptions.signal = actions.signalAbort
  }

  try {
    const response = yield call(axios.get, `${getCredentials().urlB2}reservation/${actions.reservationId}`, requestOptions);
    yield put({ type: 'GET_RESERVATION_SUCCESS', payload: response.data });
  } catch (error) {
    yield put({ type: 'GET_RESERVATION_ERROR', payload: error.message });
  }
}

function* getCurrenciesByProductAction(actions) {
  const headers = {
    'Content-Type': "application/json",
    'authentication-token': actions.access
  };

  const requestOptions = {
    headers: headers
  };

  try {
    const response = yield call(axios.get, `${getCredentials().urlB2}product-by-work-unit/currency/?token_product=${actions.productToken}&format_=1`, requestOptions);
    yield put({ type: 'GET_CURRENCIES_BY_PRODUCT_SUCCESS', payload: response.data });
  } catch (error) {
    yield put({ type: 'GET_CURRENCIES_BY_PRODUCT_ERROR', payload: error.message });
  }
}

export function* reservationSaga() {
  yield takeEvery('GET_RESERVATIONS', getReservationsAction);
  yield takeEvery('GET_RESERVATION', getReservationAction);
  yield takeEvery('GET_CURRENCIES_BY_PRODUCT', getCurrenciesByProductAction);
}
