import React, { Fragment, useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Fab, Icon, Paper, Typography } from '@mui/material';
import RawMarkup from '../../common/RawMarkup';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const getMessages = (items, remarks = false) => {
  return items.filter(elem => {
    if (remarks) {
      return elem.type === 'remarks';
    } else {
      return elem.type !== 'remarks'
    }
  })
}

const parseHTML = (remarks) => {
  if (remarks) {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = remarks.map(elem => elem.description).join('\n\n');
    return <div dangerouslySetInnerHTML={{ __html: textArea.value }} />
  }
};

const ItemsList = ({ items, category }) => {
  return getMessages(items).map((item, i) => {
    return <Typography key={i} variant='body2' className={`row ${item.className || ''}`}>
      {category === 'restrictions' ? (
        <Fragment>
          {item.title}
          {item.description && <RawMarkup content={item.description} containerType='span' />}
        </Fragment>
      ) : (
        <Fragment>
          {item.icon && <Icon className='message-icon'>{item.icon}</Icon>}
          {item.title}
          {item.description && <RawMarkup content={item.description} containerType='span' />}
        </Fragment>
      )}
    </Typography>
  });
}

const Message = ({ message }) => {
  const { t } = useTranslation();
  const [remarks, setRemarks] = useState('');

  return <>
    <Paper className='message column'>
      <div className='message-body row'>
        {message.icon ? (
          <Fragment>
            {message.category === 'restrictions' ? (
              <div>
                <ItemsList items={message.items} category={message.category} />
              </div>
            ) : (
              <Fragment>
                <div style={{ display: 'flex' }}>
                  <Icon className='message-icon'>{message.icon}</Icon>
                </div>
                <div>
                  <ItemsList items={message.items} category={message.category} />
                </div>
              </Fragment>
            )}
          </Fragment>
        ) : (
          <div>
            <ItemsList items={message.items} category={message.category} />
          </div>
        )}
      </div>

      {getMessages(message.items, true).length > 0 && (
        <div className='remarks-container'>
          <Button color='primary' onClick={() => setRemarks(getMessages(message.items, true))}>Ver comentarios</Button>
        </div>
      )}
    </Paper>

    <Dialog open={Boolean(remarks)} onClose={() => setRemarks(null)} className='closable-modal'>
      <Fab size="small" onClick={() => setRemarks(null)} className='close-button'>
        <Close fontSize='small' />
      </Fab>

      <DialogTitle>
        {t('checkout.common.comments')}
      </DialogTitle>
      <DialogContent>
        {parseHTML(remarks)}
      </DialogContent>
    </Dialog>
  </>
}

export const MessagesDetailBottom = ({ messages }) => (
  messages && messages.map((message, i) => (
    <Fragment key={i}>
      {message.template === 'messageDetailBottom' && <Message message={message} />}
    </Fragment>
  ))
)

export const MessagesDetailTop = ({ messages }) => (
  messages && messages.map((message, i) => (
    <Fragment key={i}>
      {message.template === 'messageDetailTop' && <Message message={message} />}
    </Fragment>
  ))
)

export const MessagesDetailTopAlert = ({ messages }) => (
  messages && messages.map((message, i) => (
    <Fragment key={i}>
      {message.template === 'messageDetailTopAlert' && <Message message={message} />}
    </Fragment>
  ))
)

export const GetMessageConditions = ({ messages }) => (
  messages && messages.map((advice, i) => (
    <Fragment key={i}>
      {advice.template === 'messageConditions' && advice.items?.length > 0 && (
        <ul className="messageConditions">
          {advice.items.map((item, j) =>
            <li key={j}>
              <Typography variant="body2" className={item.className}>
                <Icon>{item.icon}</Icon> {item.title} <RawMarkup content={item.description} />
              </Typography>
            </li>
          )}
        </ul>
      )}
    </Fragment>
  ))
)

export const MessagesTopSidebar = ({ messages }) => (
  messages && messages.map((message, i) => (
    <Fragment key={i}>
      {message.template === 'messageTopSidebar' && <Message message={message} />}
    </Fragment>
  ))
)

export const MessagesInfo = ({ messages }) => (
  messages && messages.map((message, i) => (
    <Fragment key={i}>
      {message.template === 'messageInfo' && <Message message={message} />}
    </Fragment>
  ))
)

export const MessagesRestrictions = ({ messages }) => (
  messages && messages.map((message, i) => (
    <Fragment key={i}>
      {message.template === 'restrictions' && <Message message={message} />}
    </Fragment>
  ))
)

export const MessagesInfoPassengers = ({ messages }) => (
  messages && messages.map((message, i) => (
    <Fragment key={i}>
      {message.template === 'messageInfoPassengers' && <Message message={message} />}
    </Fragment>
  ))
)
