import React, { forwardRef, Suspense, useImperativeHandle, useRef } from 'react';
import { Element, scroller } from 'react-scroll';

const DefaultForm = (props, ref) => {
  const componentRef = useRef();

  useImperativeHandle(ref, () => ({
    handleSubmit: handleSubmit,
  }));

  const handleSubmit = async () => {
    const componentData = await componentRef?.current?.handleSubmit();
    if (!componentData?.status) {
      scroller.scrollTo('componentTo', { smooth: 'easeOutQuad' });
      return { status: false, data: null }
    } else {
      return { status: true, data: componentData?.data };
    }
  }

  if (props.form) {
    const formName = props.form.charAt(0).toUpperCase() + props.form.slice(1);
    const Component = React.lazy(() => import(`./${formName}`));

    return (
      <Suspense>
        <Element name='componentTo'>
          <Component
            ref={componentRef}
          />
        </Element>
      </Suspense>
    )
  }
}

export default forwardRef(DefaultForm);
