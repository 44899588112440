import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Grid, Hidden, Pagination, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FlightCard from './FlightCard';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { useClientData } from '../../../context/ClientContext';
import { useBannersLandingsData } from '../../../context/BannersLandingsContext';
import BannerResults from '../../common/Banners/BannerResults';
import { DatalayerResults } from '../../../store/services/DatalayerGeneral';

const SORTING_OPTIONS = {
  b2b: [
    { name: 'price', text: 'price' },
    { name: 'duration', text: 'duration' },
    { name: 'stopover', text: 'stopover' }
  ],
  b2c: [
    { name: 'price', text: 'lowerPrice' },
    { name: 'stopover', text: 'directFlights' },
    { name: 'duration', text: 'faster' }
  ]
};

const ResultsComponent = ({
  client,
  results,
  loading,
  module,
  activeSort,
  resultsToCompare,
  handleClickCompareFlight,
  handleChangeSort
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { clientData } = useClientData();
  const { bannersData } = useBannersLandingsData();
  const [currentPage, setCurrentPage] = useState(1);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    setCurrentPage(search.get('pag') ? parseInt(search.get('pag')) : 1);
  }, [location])

  useEffect(() => {
    if (results?.Fares && Math.ceil(results.Fares.length / 10) < currentPage) {
      handleChangePage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results])

  const handleChangePage = (page) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    const scrollInterval = setInterval(() => {
      if (window.scrollY === 0) {
        clearInterval(scrollInterval);
        setCurrentPage(page);
      }
    }, 50);
  }

  // Ref para guardar los resultados anteriores
  const previousResultsRef = useRef([]);
  const { searchedProduct } = useClientData();

  const getResults = () => {
    const startIndex = (currentPage - 1) * 10;
    const endIndex = currentPage * 10;

    // start / datalayer - send pagination results
    const newResults = results?.Fares?.slice(startIndex, endIndex) || [];
    if (JSON.stringify(newResults) !== JSON.stringify(previousResultsRef.current)) {
      DatalayerResults(
        "searchResults",
        module,
        searchedProduct,
        newResults,
        null);
      previousResultsRef.current = newResults;
    }
    // end / datalayer - send pagination results

    return results?.Fares?.slice(startIndex, endIndex) || [];
  }

  return (
    <Grid className='results-component'>
      <Hidden lgDown>
        <Grid style={{ marginBottom: 16 }}>
          {t('results.flights.sortBy')}:
          {SORTING_OPTIONS[clientData?.client?.isB2C ? 'b2c' : 'b2b'].map((option, i) => (
            <Button
              key={i}
              className={`sort-button ${activeSort?.name === option.name ? 'active' : ''}`}
              disabled={loading}
              onClick={() => handleChangeSort(option.name)}
            >
              <span className='title'>{t(`results.flights.${option.text}`)}</span>
              {!clientData?.client?.isB2C && activeSort?.name === option.name && (
                activeSort.sort === 'asc' ? <ArrowUpward /> : <ArrowDownward />
              )}
            </Button>
          ))}
        </Grid>
      </Hidden>

      <Grid>
        {loading ? (
          Array.from(Array(3).keys()).map(elem => (
            <Grid className='flight-card skeleton' container key={elem} columnSpacing={3}>
              <Grid item xs={4} lg={3} xl={4}>
                <Skeleton height='100%' width='100%' />
              </Grid>
              <Grid container item className='row info-container' xs={8} lg={9} xl={8}>
                <Grid className='left-side' item sm={12} md={8} lg={8}>
                  <Skeleton width={300} height={35} variant='rounded' />
                  <Skeleton width={150} height={25} variant='rounded' />
                  <Skeleton width={150} height={25} variant='rounded' />
                  <Skeleton width={150} height={25} variant='rounded' />
                  <Skeleton width={150} height={25} variant='rounded' />
                  <Skeleton width={150} height={25} variant='rounded' />
                </Grid>
                <Hidden mdDown>
                  <Grid className='right-side' item md={4} lg={4}>
                    <Skeleton width={150} height={25} variant='rounded' />
                    <Skeleton width={150} height={25} variant='rounded' />
                    <Skeleton width={150} height={25} variant='rounded' />
                    <Skeleton width={150} height={25} variant='rounded' />
                    <Skeleton width={150} height={25} variant='rounded' />
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          ))
        ) : (
          getResults().map(flight => {
            const bannerIndex = flight.index === 0
              ? 0
              : flight.index % 2 === 0
                ? flight.index / 2
                : -1;

            return <>
              <FlightCard
                key={flight.FareID}
                client={client}
                recommendationID={results?.RecommendationID}
                flight={flight}
                disabledCompare={resultsToCompare.length >= 4}
                selectedToCompare={Boolean(resultsToCompare.find(elem => elem.FareID === flight.FareID))}
                handleClickCompare={(flightToCompare, checked) => handleClickCompareFlight(flightToCompare, checked)}
              />

              {clientData?.client?.isB2C
                && bannerIndex > -1
                && bannersData?.data?.modules?.AEREOS?.flows?.results?.banners?.ContentResults[bannerIndex]
                && (
                  <BannerResults banners={bannersData?.data?.modules?.AEREOS?.flows?.results?.banners?.ContentResults} />
                )}
            </>
          })
        )}
      </Grid>

      {!loading && results && results?.Fares?.length > 0 && (
        <Grid className='pagination-container'>
          <Pagination
            size={isMobile ? 'small' : ''}
            page={currentPage}
            onChange={(e, value) => handleChangePage(value)}
            count={Math.ceil(results?.Fares?.length / 10)}
            color="primary"
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ResultsComponent;
