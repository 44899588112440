import React from 'react'
import CardOneCol from './CardOneCol'
import CardOneColV2 from './CardOneColV2'
import CardTwoCols from './CardTwoCols'
import CardTwoColsV2 from './CardTwoColsV2'
import CardOneColV3 from './CardOneColV3'
import CardBrandedFares from './CardBrandedFares'

export default function DetailItem(props) {
  const templateComponents = {
    CardOneCol: <CardOneCol {...props} />,
    CardOneColV2: <CardOneColV2 {...props} />,
    CardOneColV2brandedFares: <CardBrandedFares {...props} />,
    CardTwoCols: <CardTwoCols {...props} />,
    CardTwoColsV2: <CardTwoColsV2 {...props} />,
    CardOneColV3: <CardOneColV3 {...props} />
  };

  return (
    <div className='detail-item'>
      {templateComponents[props.template] || null}
    </div>
  );
}
