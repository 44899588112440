import React, { useEffect } from 'react';
import Navbar from '../../layout/Navbar';
import { useNavigate, useParams } from 'react-router';
import { checkToken } from '../../../store/services/Login';
import { useBannersLandingsData } from '../../../context/BannersLandingsContext';
import AllBanners from '../../common/Banners/AllBanners';
import { useClientData } from '../../../context/ClientContext';

function HomePage() {
  const navigate = useNavigate();
  const params = useParams();
  const { setClientData, updateProductToken } = useClientData();
  const { bannersData } = useBannersLandingsData();

  useEffect(() => {
    ;(async () => {
      try {
        const token = localStorage.getItem('jwt');
        await checkToken(token);
        updateProductToken(['train_detail_segment', 'train_detail_passes']);
      } catch (error) {
        const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
        if (iframePath) {
          localStorage.clear();
          setClientData(null);
          navigate(iframePath);
        } else {
          navigate('/login', { state: { expiredSession: true } });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="trains-home homepage">
      <Navbar />

      <AllBanners
        banners={bannersData?.data?.modules?.['TRENES']?.flows?.home?.banners || {}}
        titlePromotions='Las últimas novedades de trenes'
        titleOffers='Compañías de trenes y recorridos'
        titleFeatured='Compañías de trenes y recorridos'
      />
    </div>
  );
}

export default HomePage;
