import { mdiCreditCardMultipleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { AccountBalance, Info, PaymentOutlined, QrCode } from '@mui/icons-material';
import Payment from 'payment';

export const IconPaymentMethod = ({ icon }) => {
    if (icon === 'creditCard') {
        return <PaymentOutlined />;
    } else if (icon === 'creditCard_2') {
        return <Icon path={mdiCreditCardMultipleOutline} size={1} />;
    } else if (icon === 'pago_offline' || icon === 'transfer' || icon === 'deposit') {
        return <AccountBalance />;
    } else if (icon === 'qr') {
        return <QrCode />;
    } else {
        return <Info />;
    }
}

export const clearNumber = (value = '') => {
    return value.replace(/\D+/g, "");
}

export const formatCreditCardNumber = (value) => {
    if (!value) {
        return value;
    }

    const issuer = Payment.fns.cardType(value);
    const clearValue = clearNumber(value);
    let nextValue;

    switch (issuer) {
        case 'amex':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10
            )} ${clearValue.slice(10, 15)}`;
            break;
        case 'dinersclub':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10
            )} ${clearValue.slice(10, 14)}`;
            break;
        default:
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                8
            )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
            break;
    }

    return nextValue.trim();
}

export const formatCVC = (value, prevValue, allValues = {}) => {
    const clearValue = clearNumber(value);
    let maxLength = 4;

    if (allValues.number) {
        const issuer = Payment.fns.cardType(allValues.number);
        maxLength = issuer === "amex" ? 4 : 3;
    }

    return clearValue.slice(0, maxLength);
}

export const formatExpirationDate = (value) => {
    const clearValue = clearNumber(value);

    if (clearValue.length >= 3) {
        return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }

    return clearValue;
}

const validateCPF = (cpf) => {
	cpf = cpf.replace(/[^\d]+/g,'');
	if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
		return false;
	}

	let sum = 0;
	for (let i = 0; i < 9; i++) {
		sum += parseInt(cpf.charAt(i)) * (10 - i);
	}

	let rest = sum % 11;
	let digit1 = (rest < 2) ? 0 : (11 - rest);
	sum = 0;

	for (let i = 0; i < 10; i++) {
		sum += parseInt(cpf.charAt(i)) * (11 - i);
	}

	rest = sum % 11;
	let digit2 = (rest < 2) ? 0 : (11 - rest);

	return (digit1 === parseInt(cpf.charAt(9)) && digit2 === parseInt(cpf.charAt(10)));
}

const validateCNPJ = (cnpj) => {
    cnpj = cnpj.replace(/[^\d]+/g,'');
    if (cnpj.length !== 14) {
        return false;
    }

    if (/^(\d)\1+$/.test(cnpj)) {
        return false;
    }

    let sum = 0;
    let pos = 5;
    for (let i = 12; i >= 1; i--) {
      sum += cnpj.charAt(12 - i) * pos--;
      if (pos < 2) pos = 9;
    }

    const firstDigit = (sum % 11) < 2 ? 0 : 11 - (sum % 11);
    if (parseInt(cnpj.charAt(12)) !== firstDigit) {
      return false;
    }

    sum = 0;
    pos = 6;
    for (let i = 13; i >= 1; i--) {
      sum += cnpj.charAt(13 - i) * pos--;
      if (pos < 2) pos = 9;
    }

    const secondDigit = (sum % 11) < 2 ? 0 : 11 - (sum % 11);
    if (parseInt(cnpj.charAt(13)) !== secondDigit) {
      return false;
    }

    return true;
}

export const validateDocumentNumber = (value, documentType) => {
    switch (documentType) {
        case 'CPF':
            return validateCPF(value);
        case 'CNPJ':
            return validateCNPJ(value);
        default:
            return true;
    }
}
