import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Hidden,
  Link,
  Box,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material';
import Timer, { TimerProvider } from './Timer';
import PreloadStep from './PreloadStep';
import PurchaseDetail from './PurchaseDetail';
import SecureSite from './SecureSite';
import ContainerFixedMobile from 'react-headroom';
import { connect } from 'react-redux';
import { getCheckoutInfoAction, getProcessesAction, resetCheckoutInfoAction } from '../../../store/actions';
import Preloader from '../../common/Preloader';
import { useTranslation } from 'react-i18next';
import { ArrowBack, ArrowForward, ErrorOutline } from '@mui/icons-material';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useClientData } from '../../../context/ClientContext';
import * as TrainsDetail from './utils/TrainsDetail';
import * as FlightsDetail from './utils/FlightsDetail';
import Preload from './Preload';
import { usePaymentContext } from '../../../context/PaymentContext';
import { LoadingButton } from '@mui/lab';
import { MessagesTopSidebar } from './CardDetailMessages';
import PreloadBooking from './PreloadBooking';
import TotalAPagar from './TotalAPagar';
import { usePurchaseDetailContext } from '../../../context/PurchaseDetailContext';
import Crossell from './scenes/Crossell';
import Passenger from './scenes/Passenger';
import Payment from './scenes/Payment';

const PRELOAD = {
  hotels: 'preloadHotel.gif',
  trains: 'preloadTrenes.gif',
  flights: 'preloadAereos.gif'
};

const PENDING_METHODS = [
  'transfer',
  'deposit'
];

const ANTIFRAUD_METHODS = [
  'creditCard'
];

const componentMap = {
  Crossell,
  Passenger,
  Payment,
};

const ChangePriceDialog = () => {
  const { t } = useTranslation();
  const { purchaseDetail } = usePurchaseDetailContext();
  const [openChangePriceModal, setOpenChangePriceModal] = useState(false);

  useEffect(() => {
    const changePrice = purchaseDetail?.priceDetail?.find((priceDetail) => (
      priceDetail?.charge?.find((elem) => elem?.fare_class?.change_price)
    )) ? true : false;
    setOpenChangePriceModal(changePrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseDetail]);

  return (
    <Dialog
      open={openChangePriceModal}
      className='change-price-modal'
      maxWidth='sm'
    >
      <ErrorOutline className='error-icon' />

      <DialogTitle>
        {t('checkout.common.attention')}
      </DialogTitle>

      <DialogContent>
        {t('checkout.common.changePriceMessage')}
      </DialogContent>

      <DialogActions>
        <Button
          variant='contained'
          onClick={() => setOpenChangePriceModal(false)}
        >
          {t('checkout.common.accept')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const Steps = ({
  selectedReservation,
  checkoutInfo,
  errorCheckoutInfo,
  processData,
  errorProcessData,
  ...props
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const componentRef = useRef();
  const { clientData, payment, currentProduct } = useClientData();
  const { paymentSelected, numberRetry, setPaymentError, setNumberRetry } = usePaymentContext();
  const {
    products,
    setProducts,
    purchaseDetail,
    setPurchaseDetail,
    setIncludeComission,
    setInterest,
    refreshTotalAmount,
    purchaseDetailOpen,
    setPurchaseDetailOpen,
    handleAddCrosselToCart,
    setFromAvailabilityError
  } = usePurchaseDetailContext();
  const [loading, setLoading] = useState(true);
  const [loadingBeforeProcess, setLoadingBeforeProcess] = useState(false);
  const [loadingFinalStep, setLoadingFinalStep] = useState(false);
  const [error, setError] = useState(false);
  const [steps, setSteps] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [messages, setMessages] = useState();
  const [timer, setTimer] = useState();
  const [currentStepData, setCurrentStepData] = useState();
  const [generalData, setGeneralData] = useState();
  const [previewsStepsData, setPreviewsStepsData] = useState({});
  const [externalId, setExternalId] = useState();
  const [openAvailabilityModal, setOpenAvailabilityModal] = useState(false);
  const [brandedFares, setBrandedFares] = useState(null);
  const [selectedBrandedFare, setSelectedBrandedFare] = useState(null);

  useEffect(() => {
    if (Object.keys(currentProduct || {}).length > 0) {
      (async () => {
        const accessToken = localStorage.getItem('jwt');
        const tokenCheckout = currentProduct?.config?.api_checkout_token || currentProduct?.config_work_unit?.api_checkout_token;

        if (params.hash && tokenCheckout && accessToken) {
          const dataToSend = {
            hash: params.hash,
            product: params.module
          }
          props.getCheckoutInfo(accessToken, tokenCheckout, dataToSend);
        }
      })();
    }

    return () => {
      props.resetCheckoutInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProduct]);

  useEffect(() => {
    if (checkoutInfo) {
      setPurchaseDetail(checkoutInfo.prebooking?.purchaseDetail);
      setProducts(checkoutInfo.prebooking?.products);
      setSteps(checkoutInfo.steps?.map((elem, i) => ({ ...elem, index: i })));
      setMessages(checkoutInfo.messages);
      setGeneralData(checkoutInfo.prebooking?.data);
      setExternalId(checkoutInfo?.externalId);
      setIncludeComission(false);
      setInterest(null);
      refreshTotalAmount();

      const timerAux = {
        ...checkoutInfo.timer,
        ...(checkoutInfo.timer?.timer || {})
      };
      delete timerAux.timer;
      setTimer(timerAux);

      if (checkoutInfo.steps?.[0]?.processes_before?.length === 0) {
        setLoading(false);
      } else if (!errorCheckoutInfo && !checkoutInfo?.error) {
        getProcessesBefore(checkoutInfo.steps?.[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutInfo]);

  useEffect(() => {
    if (errorCheckoutInfo) {
      setError(true);
      setLoading(false);
    }
  }, [errorCheckoutInfo]);

  useEffect(() => {
    if (loading && processData) {
      const crossellData = processData?.data?.find(elem => elem.name === "search_crossell")?.tasks?.get_branded_fares?.data;
      if (crossellData?.cardProducts?.length > 0 && !brandedFares) {
        setBrandedFares(crossellData.cardProducts);
      }
      if (!loadingBeforeProcess && steps?.[activeStep + 1]?.step_name === 'FINAL_VIEW') {
        evaluateLastStepData(processData);
      } else {
        const crossellStep = processData?.data?.find(({ name }) => name === 'search_crossell')?.tasks;
        const skipStep = crossellStep
          ? Object.values(crossellStep).every(({ data }) => Object.keys(data || {}).length === 0)
          : false;

        if (!processData?.status || processData.data?.some(elem => elem.status === false)) {
          const isDuplicatedBooking = processData.data?.find(
            elem =>
              elem.status === false &&
              elem.name === 'booking' &&
              elem.error?.code === 'DUPLICATED'
          );
          const isAvailabilityError = processData.data?.find(
            elem =>
              elem.status === false &&
              elem.name === 'booking' &&
              elem.error?.code === 'NOT_BOOKING_AVAILABILITY'
          );
          if (isAvailabilityError) {
            setOpenAvailabilityModal(true);
            setLoading(false);
            return;
          }

          const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
          const state = {
            purchaseDetail,
            products,
            previewsStepsData,
            status: 'REJECTED',
            bookingDuplicated: Boolean(isDuplicatedBooking),
            bookingDetail: isDuplicatedBooking?.tasks?.booking?.data?.pivot?.message,
          };
          const url = `${iframePath}/checkout/${params.module}/booking/${externalId}`;
          navigate(url, {
            state,
            replace: true
          });
        } else if (loadingBeforeProcess && !skipStep) {
          setCurrentStepData(processData.data);
          setLoading(false);
          setLoadingBeforeProcess(false);
        } else {
          const newActiveStep = activeStep + 1;
          setActiveStep(newActiveStep);

          if (steps?.[newActiveStep]?.processes_before?.length === 0) {
            setLoading(false);
            setCurrentStepData({});
          } else {
            getProcessesBefore(steps?.[newActiveStep]);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processData]);

  useEffect(() => {
    if (errorProcessData) {
      const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
      const state = {
        purchaseDetail,
        products,
        previewsStepsData,
        status: 'REJECTED'
      };
      const url = `${iframePath}/checkout/${params.module}/booking/${externalId}`;
      navigate(url, {
        state,
        replace: true
      });
    }
  }, [errorProcessData]);

  const StepperCustom = () => {
    if (steps && steps.length > 1 && steps?.[activeStep]?.stepper?.show) {
      const stepsAux = steps.filter(elem => elem?.stepper?.show);
      const maxWidth = stepsAux.length > 3
        ? 1170
        : 300 * stepsAux.length;

      return (
        <section className='stepper-container'>
          <Stepper className='stepper-component' style={{ maxWidth: maxWidth }}>
            {stepsAux.map(step => (
              <Step key={step.index} active={activeStep === step.index} completed={activeStep > step.index}>
                <StepLabel>{step.name}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </section>
      )
    }
  }

  const StepContent = useCallback(() => {
    if (steps && currentStepData) {
      const Component = componentMap[steps[activeStep]?.name_container];
      if (Component) {
        return (
          <Component
            ref={componentRef}
            activeStep={steps[activeStep]}
            data={currentStepData}
            generalData={generalData}
            externalId={externalId}
            messages={concatMessagess(messages, steps?.[activeStep]?.messages)}
            selectedReservation={selectedReservation}
            previewsStepsData={previewsStepsData}
          />
        );
      }
    }

    return getPreload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps, activeStep, currentStepData, selectedReservation, previewsStepsData]);

  const ContinueButton = () => {
    const { paymentSelected } = usePaymentContext();
    const [acceptedConditions, setAcceptedConditions] = useState(activeStep === 0 ? false : true);
    const [loading, setLoading] = useState(false);

    const isPaymentStep = steps?.[activeStep]?.stepName === 'initStepPaymentV2';
    const isPaymentMethodSelected = isPaymentStep && paymentSelected;

    const availableCredit = payment?.config?.credit?.remaining_balance || 0;
    const totalPrice = Number(getTotalPrice());

    const showButton = !isPaymentStep || isPaymentMethodSelected?.name !== 'pix';

    const textButton = !isPaymentStep || !isPaymentMethodSelected
      ? t('checkout.common.continue')
      : isPaymentMethodSelected && availableCredit < totalPrice
        ? t('checkout.common.finish')
        : t('checkout.common.pay');

    const onClickButton = async () => {
      setLoading(true);
      await getProcessesAfter();
      setLoading(false);
    }

    return <>
      <div className='text-total-sumary'>
        <TotalAPagar />
        <FormControlLabel
          control={<Checkbox
            checked={acceptedConditions}
            disabled={activeStep !== 0}
            onChange={(e, checked) => setAcceptedConditions(checked)}
          />}
          label={
            <Typography>
              {t('checkout.common.readAndAccept')}&nbsp;
              <Link href={clientData?.client.conditions} target='_blank' color='#155dff'>
                {t('checkout.common.generalConditions')}
              </Link>
              {getExpediaConditions(products?.pivot?.[0]?.cardDetail?.provider)}
            </Typography>
          }
        />
      </div>

      {showButton && (
        <LoadingButton
          className='btn-next-step'
          color='secondary'
          variant='contained'
          onClick={onClickButton}
          endIcon={<ArrowForward />}
          disabled={!acceptedConditions || (isPaymentStep && !isPaymentMethodSelected)}
          loading={loading}
        >
          <span>{textButton}</span>
        </LoadingButton>
      )}
    </>
  }

  const evaluateLastStepData = (processData) => {
    const paymentResult = processData?.data?.find(elem => elem.name === 'payment');
    const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';

    const hasExecutePaymentError = (
      paymentResult?.tasks?.execute_payment &&
      (!paymentResult.tasks.execute_payment.status || paymentResult.tasks.execute_payment.code !== 'OK') &&
      paymentResult.tasks.execute_payment.data?.hasRetry
    );

    const hasFraudError = (
      !paymentResult?.tasks?.fraud_validation?.status ||
      paymentResult.tasks.fraud_validation.code !== 'OK'
    );

    const state = {
      purchaseDetail,
      products,
      previewsStepsData,
      status: 'CONFIRMED',
      isFraud: hasFraudError,
      pendingPayment: PENDING_METHODS.includes(paymentSelected?.payment?.code)
    };

    let url = '';

    if (paymentResult?.status) {
      url = `${iframePath}/checkout/${params.module}/booking/${externalId}`;
    } else if (hasExecutePaymentError) {
      setPaymentError(true);
      setNumberRetry(numberRetry + 1);

      const existingScript = document.getElementById('deviceId_fp');
      if (existingScript) {
        existingScript.remove();
      }
    } else {
      url = `${iframePath}/checkout/${params.module}/error`;
    }

    if (url) {
      navigate(url, {
        state,
        replace: true
      });
    } else {
      setLoading(false);
    }
  };

  const concatMessagess = (generalMessages, stepMessages) => {
    const result = [...generalMessages];

    stepMessages.forEach(item2 => {
      const existing = result.find(item1 =>
        item1.icon === item2.icon &&
        item1.template === item2.template &&
        item1.category === item2.category
      );

      if (existing) {
        item2.items.forEach(newItem => {
          const isDuplicate = existing.items.some(existingItem =>
            JSON.stringify(existingItem) === JSON.stringify(newItem)
          );

          if (!isDuplicate) {
            existing.items.push(newItem);
          }
        });
      } else {
        item2.items = item2.items.filter((newItem, index, self) =>
          index === self.findIndex(item => JSON.stringify(item) === JSON.stringify(newItem))
        );
        result.push(item2);
      }
    });

    return result;
  }

  const getTotalPrice = () => {
    if (params.module === 'hotels') {
      return purchaseDetail.totalAmount;
    } else if (params.module === 'trains') {
      return TrainsDetail.getTotalPrice(purchaseDetail);
    } else if (params.module === 'flights') {
      return FlightsDetail.getTotalPrice(purchaseDetail);
    }
  }

  const getExpediaConditions = (provider) => {
    if (provider) {
      const providerName = provider.replaceAll(' ', '').toLowerCase();
      if (providerName.includes('expedia')) {
        const url = 'https://developer.expediapartnersolutions.com/terms/es';
        return <>
          &nbsp;y de&nbsp;
          <Link href={url} target='_blank' color='#155dff'>Expedia</Link>
        </>
      }
    }

    return null;
  }

  const getPreload = () => {
    return <>
      <Preloader
        addDots
        image={`sites/${clientData.client.name}/${PRELOAD[params.module]}`}
        text={t('common.processingRequest')}
      />
      <PreloadStep />
    </>
  }

  const getBackUrl = () => (
    timer?.redirect || clientData?.modules?.find((elem) => elem.module === params.module)?.path
  )

  const getProcessesBeforeData = (processes) => {
    return processes.map(elem => {
      if (elem.name === 'get_installments') {
        elem.data = {
          paymentMethods: null,
          purchaseDetail
        }
      }

      return elem;
    })
  }

  const getProcessesBefore = (step) => {
    const accessToken = localStorage.getItem('jwt');
    const tokenCheckout = currentProduct?.config?.api_checkout_token || currentProduct?.config_work_unit?.api_checkout_token;
    const processes = getProcessesBeforeData(step?.processes_before || []);

    if (params.hash && tokenCheckout && accessToken && processes.length > 0) {
      const dataToSend = {
        hash: params.hash,
        product: params.module,
        processes
      };
      props.getProcesses(accessToken, tokenCheckout, dataToSend);
      setLoading(true);
      setLoadingBeforeProcess(true);
    }
  }

  const insertAntifraudScript = () => {
    const existingScript = document.getElementById('deviceId_fp');
    if (existingScript) {
      existingScript.remove();
    }

    if (clientData?.checkout?.antifraud?.orgId) {
      const script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('src', 'https://securegtm.despegar.com/risk/fingerprint/statics/track-min.jst');
      script.setAttribute('id', 'deviceId_fp');
      script.setAttribute('org_id', clientData.checkout.antifraud.orgId);
      script.setAttribute('session_id', params.hash);
      document.body.appendChild(script);
    }
  }

  const getProcessesAfter = async () => {
    const responseChildComponent = await componentRef?.current?.handleSubmit();

    if (responseChildComponent?.status) {
      const dataChild = JSON.parse(JSON.stringify(responseChildComponent.data));
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      const accessToken = localStorage.getItem('jwt');
      const tokenCheckout = currentProduct?.config?.api_checkout_token || currentProduct?.config_work_unit?.api_checkout_token;
      const processes = steps[activeStep]?.processes_after?.map(elem => {
        if (elem.name === 'save_process') {
          elem.data = dataChild;
        } else if (elem.name === 'payment') {
          elem.data = {
            retry: responseChildComponent.numberRetry,
            payment_method: responseChildComponent.paymentSelected?.payment?.code
          };

          if (ANTIFRAUD_METHODS.includes(responseChildComponent.paymentSelected?.payment?.form)) {
            insertAntifraudScript();
          }
        }
        return elem;
      });

      const dataToSend = {
        hash: params.hash,
        product: params.module,
        processes
      };

      props.getProcesses(accessToken, tokenCheckout, dataToSend);
      setLoading(true);
      setLoadingFinalStep(steps[activeStep + 1]?.step_name === 'FINAL_VIEW');
      setLoadingBeforeProcess(false);
      if (steps[activeStep]?.step_name) {
        setPreviewsStepsData(current => ({
          ...current,
          [steps[activeStep].step_name]: dataChild
        }))
      }
    }

    return true;
  }


  // Función para continuar el flujo forzando una nueva request con booking y form_branded_fares
  const continueWithCurrentFare = useCallback(async () => {
    const accessToken = localStorage.getItem('jwt');
    const tokenCheckout = currentProduct?.config?.api_checkout_token || currentProduct?.config_work_unit?.api_checkout_token;
    const bookingProcess = steps[activeStep]?.processes_after?.find(elem => elem.name === 'booking');
    const processes = [
      {
        name: "save_process",
        data: [
          {
            event_name: "form_branded_fares",
            event_data: {
              fare_id: selectedBrandedFare?.rateId,
              source: "checkout"
            }
          }
        ]
      }
    ];
    if (bookingProcess) {
      processes.push({
        name: "booking"
      });
    }

    const dataToSend = {
      hash: params.hash,
      product: params.module,
      processes
    };
 
 
    props.getProcesses(accessToken, tokenCheckout, dataToSend);
    setLoading(true);
    setLoadingFinalStep(steps[activeStep + 1]?.step_name === 'FINAL_VIEW');
    setLoadingBeforeProcess(false);
  }, [activeStep, steps, currentProduct, params.hash, params.module, props, selectedBrandedFare]);

  // Exponemos la función en el objeto window para poder llamarla desde DetailDialog.js
  useEffect(() => {
    window.continueWithCurrentFare = continueWithCurrentFare;

    return () => {
      delete window.continueWithCurrentFare;
    };
  }, [continueWithCurrentFare]);

  // Lógica botones
  const checkIfLastBrandedFareSelected = () => {
    const currentBranded = products?.pivot?.[0]?.brandedFare;
    const currentIndex = currentBranded
      ? brandedFares?.findIndex(fare => fare.rateId === currentBranded.rateId)
      : -1;
    return currentIndex === (brandedFares?.length || 0) - 1;
  };

  useEffect(() => {
    if (openAvailabilityModal && checkIfLastBrandedFareSelected()) {
      const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
      navigate(iframePath + `/checkout/${params.module}/error`, {
        replace: true,
        state: {
          errorTimeout: false,
          redirectUrl: timer?.redirect
        }
      });
    }
  }, [openAvailabilityModal, brandedFares, products, params, navigate]);


  // Función para manejar el click y avanzar a la siguiente tarifa
  const handleClickMoreRates = () => {
    setFromAvailabilityError(true);
    const currentBranded = products?.pivot?.[0]?.brandedFare;
    const currentIndex = currentBranded
      ? brandedFares.findIndex(fare => fare.rateId === currentBranded.rateId)
      : -1;
    const nextIndex =
      currentIndex === -1 || currentIndex >= brandedFares.length - 1
        ? 1
        : currentIndex + 1;
    const nextFare = brandedFares[nextIndex];

    handleAddCrosselToCart(nextFare);
    setPurchaseDetailOpen(true);
    setSelectedBrandedFare(nextFare);
    setOpenAvailabilityModal(false);
  };

  return (
    <TimerProvider initialMinutes={timer?.value}>
      <>
        {loading ? (
          <>
            <Preloader
              addDots
              image={`sites/${clientData.client?.name}/${PRELOAD[params.module]}`}
              text={loadingFinalStep ? t('common.processingBooking') : t('common.processingRequest')}
            />
            {loadingFinalStep ? <PreloadBooking /> : <Preload />}
          </>
        ) : error ? (
          <Navigate to={`/checkout/${params.module}/error404`} />
        ) : (
          <div className='steps'>
            <StepperCustom />

            <Container>
              <Hidden mdUp>
                <Timer
                  timer={timer}
                  expired={timer?.expired || false}
                />
                <ContainerFixedMobile pinStart={210}>
                  <PurchaseDetail />
                </ContainerFixedMobile>
              </Hidden>

              <Grid container spacing={2}>
                <Grid item xs={12} md={8} className='col-content'>
                  <Button href={getBackUrl()} size='small' style={{ marginBottom: 10 }}>
                    <ArrowBack style={{ marginRight: 5 }} /> {t('checkout.timer.returnResults')}
                  </Button>

                  <StepContent />

                  <ChangePriceDialog />
                </Grid>

                <Hidden mdDown>
                  <Grid item md={4} className='col-sidebar'>
                    <Grid>
                      <Timer
                        timer={timer}
                        expired={timer?.expired || false}
                      />
                      <PurchaseDetail />
                    </Grid>
                    <MessagesTopSidebar messages={messages?.concat(steps?.meesages || [])} />
                    <SecureSite client={clientData?.client} />
                  </Grid>
                </Hidden>
              </Grid>

              <div className='steps-buttons'>
                <Container>
                  <div className='row help-container'>
                    {/* <Hidden lgDown>
                      <div className='icon-container'>
                        <QuestionAnswer />
                      </div>
                      <div className='message-container'>
                        <Typography>Hola,</Typography>
                        <Typography>¿Cómo podemos ayudarle?</Typography>
                      </div>
                    </Hidden> */}
                  </div>
                  <div className='steps-buttons-inner'>
                    <ContinueButton />
                  </div>
                </Container>
              </div>
            </Container>
            <Dialog
              PaperProps={{
                sx: {
                  borderRadius: 3,
                }
              }}
              borderRadius
              open={openAvailabilityModal}
              maxWidth="sm"
              fullWidth>
              <DialogTitle sx={{ 
                textAlign: 'left', 
                fontWeight: 'semibold', 
                fontSize: '1.25rem' 
              }} 
                color='primary'
                >
                  {t('checkout.common.unavailabilityBrandedModalTitle')}
                </DialogTitle>
              <DialogContent>
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontWeight: 'semibold',
                    fontSize: '1.15rem',
                    mb: 1
                  }}
                  color='primary'>
                  {t('checkout.common.unavailabilityBrandedModalSubTitle')}
                </Typography>
                <Typography sx={{
                  textAlign: 'center',
                  fontSize: '0.7rem',
                  mb: 3
                }}>
                  {t('checkout.common.unavailabilityBrandedModalText')}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    '& button': {
                      borderRadius: 12,
                    },
                    gap: 2
                  }}
                >
                  <Button
                    color='secondary'
                    onClick={() => navigate(timer?.redirect)}
                    variant="contained"
                    style={{ 
                      marginRight: 'auto', 
                      fontSize: '0.8rem' 
                    }}
                  >
                    {t('checkout.common.unavailabilityBrandedModalLeftButton')}
                  </Button>
                  {brandedFares?.length > 1 && !checkIfLastBrandedFareSelected() && (
                    <Button
                      onClick={handleClickMoreRates}
                      variant="contained"
                      color="secondary"
                      style={{ 
                        marginLeft: 'auto', 
                        fontSize: '0.8rem' 
                      }}
                    >
                      {t('checkout.common.unavailabilityBrandedModalRightButton')}
                    </Button>
                  )}
                </Box>
              </DialogContent>
            </Dialog>
          </div>
        )}
      </>
    </TimerProvider>
  );
}

const mapStateToProps = reducers => {
  return reducers.checkoutReducer;
};

const mapDispatchToProps = dispatch => {
  return {
    getCheckoutInfo: (access, tokenCheckout, data) => dispatch(getCheckoutInfoAction(access, tokenCheckout, data)),
    getProcesses: (access, tokenCheckout, data) => dispatch(getProcessesAction(access, tokenCheckout, data)),
    resetCheckoutInfo: () => dispatch(resetCheckoutInfoAction())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Steps);