import React from 'react';
import { Box, Container, Grid, Skeleton } from '@mui/material';

const MultibuscadorSkeleton = () => {
    return (
        <div className='homepage'>
            <Grid className='search-container'>
                <Container>
                    <Box className='search-box-container'>
                        <Grid container spacing={2} columns={24} className='search-skeleton'>
                            <Grid item className='row' xs={12} md={6}>
                                <Skeleton variant='rectangle' width='100%' height={40} />
                            </Grid>

                            <Grid item className='row' xs={12} md={4}>
                                <Skeleton variant='rectangle' width='100%' height={40} />
                            </Grid>

                            <Grid item className='row' md={12} />

                            <Grid item className='row' xs={12} md={2}>
                                <Skeleton variant='rectangle' width='100%' height={25} />
                            </Grid>

                            <Grid item className='row' xs={12} md={5}>
                                <Skeleton variant='rectangle' width='100%' height={40} />
                            </Grid>

                            <Grid item className='row' xs={12} md={5}>
                                <Skeleton variant='rectangle' width='100%' height={40} />
                            </Grid>

                            <Grid item className='row' xs={12} md={2}>
                                <Skeleton variant='rectangle' width='100%' height={40} />
                            </Grid>

                            <Grid item className='row' xs={12} md={2}>
                                <Skeleton variant='rectangle' width='100%' height={40} />
                            </Grid>

                            <Grid item className='row' xs={12} md={5}>
                                <Skeleton variant='rectangle' width='100%' height={40} />
                            </Grid>

                            <Grid item className='row' xs={12} md={3}>
                                <Skeleton variant='rectangle' width='100%' height={40} />
                            </Grid>

                            <Grid item className='row' xs={12} md={2}>
                                <Skeleton variant='rectangle' width='100%' height={40} />
                            </Grid>

                            <Grid item className='row' xs={12} md={3}>
                                <Skeleton variant='rectangle' width='100%' height={25} />
                            </Grid>

                            <Grid item className='row' md={18} />

                            <Grid item className='row' xs={12} md={3}>
                                <Skeleton variant='rectangle' width='100%' height={25} />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Grid>
        </div>
    );
};

export default MultibuscadorSkeleton;
