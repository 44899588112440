import React, { useEffect, useState } from 'react';
import DialogTimer from './DialogTimer';
import { AccessTime, InfoOutlined } from '@mui/icons-material';
import { ClickAwayListener, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { createContext } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useClientData } from '../../../context/ClientContext';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

const TimerContext = createContext();

export function TimerProvider({ children, initialMinutes }) {
  const [timeLeft, setTimeLeft] = useState();

  useEffect(() => {
    if (initialMinutes > 0) {
      const finishTime = dayjs().add(initialMinutes, 'minutes');

      const interval = setInterval(() => {
        const timeToFinish = finishTime.diff(dayjs(), 'seconds');

        if (timeToFinish <= 0) {
          clearInterval(interval);
          setTimeLeft(0);
        } else {
          setTimeLeft(timeToFinish);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [initialMinutes]);

  const minutes = timeLeft === undefined ? undefined : Math.floor(timeLeft / 60);
  const seconds = timeLeft === undefined ? undefined : timeLeft % 60;

  return (
    <TimerContext.Provider value={{ minutes, seconds }}>
      {children}
    </TimerContext.Provider>
  )
}

function Timer(props) {
  const { t } = useTranslation();
  const params = useParams();
  const { minutes, seconds } = useContext(TimerContext);
  const { clientData } = useClientData();
  const [openToltip, setOpenTooltip] = useState(false);

  const getModule = () => {
    return clientData?.modules?.find((elem) => elem.module === params.module);
  };

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  if (minutes !== undefined && seconds !== undefined) {
    return (
      <Paper className='paper timer-checkout'>
        {((minutes === 0 && seconds === 0) || props.expired) && (
          <DialogTimer redirect={props.timer?.redirect} module={getModule()} />
        )}
        <Grid className='row'>
          <AccessTime />
          <Grid container className="row timer-content">
            <Typography>{t('checkout.timer.reservationExpireIn')}:</Typography>
            <Grid className='row right-side'>
              <Grid />
              <Grid className="time">
                {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
              </Grid>
              {props.timer?.messages?.[0]?.items?.[0] && (
                <Grid className='tooltip-container'>
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                    <Tooltip
                      open={openToltip}
                      title={t('checkout.timer.timeToReservation')}
                    >
                      <InfoOutlined onClick={handleTooltipOpen} />
                    </Tooltip>
                  </ClickAwayListener>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

export default Timer;
