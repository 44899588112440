import React from 'react'
import DetailDialog from './DetailDialog'
import * as TrainsDetail from './utils/TrainsDetail';
import * as FlightsDetail from './utils/FlightsDetail';
import { useTranslation } from 'react-i18next';
import { useClientData } from '../../../context/ClientContext';
import { usePurchaseDetailContext } from '../../../context/PurchaseDetailContext';
import { useParams } from 'react-router-dom';
import { formatNumber, getCurrency } from '../../../utils/price';

const TotalAPagar = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { clientData, currencies } = useClientData();
  const { purchaseDetail, products, includeComission, interest } = usePurchaseDetailContext();

  const getTotalPriceB2B = () => {
    if (params.module === 'hotels') {
      return purchaseDetail?.totalAmount;
    } else if (params.module === 'trains') {
      return TrainsDetail.getTotalPrice(purchaseDetail, includeComission);
    } else if (params.module === 'flights') {
      return FlightsDetail.getTotalPrice(purchaseDetail);
    }
  }

  const getTotalPriceB2C = () => {
    let totalAmount = 0;
    if (purchaseDetail?.totalAmountRealFinal) {
      totalAmount = purchaseDetail.totalAmountRealFinal;
    } else {
      const cart = products.pivot?.map(elem => elem.priceDetail).filter(elem => elem.addToCart);

      if (interest) {
        cart.push({ amountReal: interest.interestAmount || interest.chargeAmount });
      }

      totalAmount = cart.reduce((acc, current) =>(
        acc + current.amountReal
      ), 0);
    }

    return formatNumber(totalAmount, 2);
  }

  const getTotalPrice = () => {
    if (clientData.client.isB2C) {
      return getTotalPriceB2C();
    } else {
      return getTotalPriceB2B();
    }
  }

  return (
    <div className='price-detail-total row'>
      <span className="text">{t('checkout.common.totalToPay')}</span>
      <span className="price">
        {getCurrency(clientData?.client, currencies, purchaseDetail?.currency)}&nbsp;
        <span className="amount">{getTotalPrice()}</span>
      </span>
      <DetailDialog icon='info_outlined' />
    </div>
  )
}

export default TotalAPagar