
function getItemsParams(module, search, items, crossell) {
    let arrayItems = [];

    // console.log('%c /// getItemsParams / search: ', 'color: #f0fae7; background: #203c05', search);
    // console.log('%c /// getItemsParams / items: ', 'color: #f0fae7; background: #203c05', items);

    // array ITEMS
    if (search !== undefined && search !== null && items) {
        const itemsContainer = items.length > 0 ? items : [items];
        itemsContainer.forEach(item => {
            // set variables search Selected Item
            let itemName = '';
            let itemId = '';
            let itemPrice = '';
            let itemCurrency = '';
            let itemBrand = '';
            let itemModule = module;
            let itemRegion = '';
            let itemClass = '';
            let itemPeople = '';
            let itemIATA = '';
            let itemVariant = '';

            const CABIN_CLASS = {
                Y: { value: 'Economica' },
                P: { value: 'Premium' },
                W: { value: 'Premium' },
                B: { value: 'Business' },
                F: { value: 'Clase' }
            };

            // Case Flights
            if (module === "flights") {
                let varFlightType = search.tripType;
                itemPrice = Number(Number(item?.ExtendedFareInfo?.NetTotalAmountWithFee || item.TotalAmount || item.totalAmount || 0).toFixed(2));
                let varPeople = search.adults + search.kids + search.babys;
                itemPeople = varPeople + " personas";
                itemClass = "";
                itemCurrency = item.Currency || item.currency;
                itemRegion = search.cabotage ? "nacional" : "internacional";

                let arrayTramos = [];

                const legs = item.Legs || item.legs;

                legs.forEach(tramo => {
                    let arraySegmentos = [];
                    tramo.Options[0].Segments.forEach(segmento => {
                        arraySegmentos.push({
                            fromIata: segmento.Departure.CityCode,
                            fromCity: segmento.Departure.CityName,
                            fromCountry: segmento.Departure.CountryName,
                            fromDate: segmento.Departure.Date,
                            toIata: segmento.Arrival.CityCode,
                            toCity: segmento.Arrival.CityName,
                            toCountry: segmento.Arrival.CountryName,
                            toDate: segmento.Arrival.Date,
                            airlineName: segmento.AirlineName || segmento.OperatingAirlineName,
                            airlineCode: segmento.Airline || segmento.OperatingAirline,
                            flightNumber: segmento.FlightNumber,
                            flightClass: CABIN_CLASS[segmento.CabinClass].value
                        });
                    })
                    arrayTramos.push({ arraySegmentos });
                })

                let rutaIata = '';

                arrayTramos.forEach((tramo, i) => {
                    tramo.arraySegmentos.forEach((segmento, j) => {
                        rutaIata = rutaIata + segmento.fromIata + "-" + segmento.toIata + (j < (tramo.arraySegmentos.length - 1) ? "-" : i < (arrayTramos.length - 1) ? ";" : "");
                        itemVariant = itemVariant + segmento.flightNumber + (j < (tramo.arraySegmentos.length - 1) ? "-" : i < (arrayTramos.length - 1) ? ";" : "");
                        itemClass = itemClass + segmento.flightClass + (j < (tramo.arraySegmentos.length - 1) ? "-" : i < (arrayTramos.length - 1) ? ";" : "");
                        itemBrand = itemBrand + segmento.airlineName + (j < (tramo.arraySegmentos.length - 1) ? "-" : i < (arrayTramos.length - 1) ? ";" : "");
                    })
                    itemName = itemName + (i >= 1 ? ";" : "") + tramo.arraySegmentos[0].fromCity + (tramo.arraySegmentos[tramo.arraySegmentos.length - 1].toCity ? "-" : "") + tramo.arraySegmentos[tramo.arraySegmentos.length - 1].toCity;
                })

                itemId = "VL:" + rutaIata;
                itemIATA = (varFlightType === "roundtrip" || varFlightType === "one way") ? arrayTramos[0].arraySegmentos[(arrayTramos[0].arraySegmentos.length - 1)].toIata : arrayTramos[(arrayTramos.length - 1)].arraySegmentos[(arrayTramos[(arrayTramos.length - 1)].arraySegmentos.length - 1)].toIata;
            }

            arrayItems.push({
                currency: itemCurrency,
                item_brand: itemBrand,
                item_category: itemModule,
                item_category2: itemRegion,
                item_category3: itemClass,
                item_category4: itemPeople,
                item_category5: itemIATA,
                item_id: itemId,
                item_name: itemName,
                item_variant: itemVariant,
                price: itemPrice,
                quantity: 1
            });
        })
    }

    return arrayItems;
}

function getDatalayerParams(datalayerName, module, search, items, crossell) {
    let varItemsParams = [];
    varItemsParams = getItemsParams(module, search, items, crossell);

    if (search?.segments !== undefined) {
        let searchRutaIATA = '';
        let searchOrigenIATA = '';
        let searchOrigenCiudad = '';
        let searchOrigenPais = '';
        let searchDestinoIATA = '';
        let searchDestinoCiudad = '';
        let searchDestinoPais = '';
        let searchTipoVuelo = '';
        let searchDaysUntilDeparture = '';
        let searchWeeksUntilDeparture = '';
        let searchPax = 0;
        let searchAdultos = 0;
        let searchMenores = 0;
        let searchBebes = 0;
        let searchCurrency = '';

        // CASE FLIGHTS
        if (module === "flights") {
            searchCurrency = search.currency;
            searchTipoVuelo = search.tripType;
            let arrayTramos = [];

            search.segments.forEach(tramo => {
                let arraySegmentos = [];
                arraySegmentos.push({
                    fromIata: tramo.origin.iata,
                    fromCity: tramo.origin.city,
                    fromCountry: tramo.origin.country,
                    fromDate: tramo.dateDeparture,
                    toIata: tramo.destination.iata,
                    toCity: tramo.destination.city,
                    toCountry: tramo.destination.country,
                    toDate: tramo.dateDeparture,
                });
                arrayTramos.push({ arraySegmentos });
            })

            let rutaIata = '';
            arrayTramos.forEach((tramo, i) => {
                tramo.arraySegmentos.forEach((segmento, j) => {
                    rutaIata = rutaIata + segmento.fromIata + "-" + segmento.toIata + (j < (tramo.arraySegmentos.length - 1) ? "-" : i < (arrayTramos.length - 1) ? ";" : "");
                })
            })

            searchRutaIATA = rutaIata;
            searchOrigenIATA = arrayTramos[0].arraySegmentos[0].fromIata;
            searchOrigenCiudad = arrayTramos[0].arraySegmentos[0].fromCity;
            searchOrigenPais = arrayTramos[0].arraySegmentos[0].fromCountry;

            searchDestinoIATA = arrayTramos[0].arraySegmentos[(arrayTramos[0].arraySegmentos.length - 1)].toIata;
            searchDestinoCiudad = arrayTramos[0].arraySegmentos[(arrayTramos[0].arraySegmentos.length - 1)].toCity;
            searchDestinoPais = arrayTramos[0].arraySegmentos[(arrayTramos[0].arraySegmentos.length - 1)].toCountry;

            searchAdultos = search.adults;
            searchMenores = search.kids;
            searchBebes = search.babys;
            searchPax = searchAdultos + searchMenores + searchBebes;

            // Convertir el formato "DD/MM/YYYY" a "YYYY-MM-DD"
            const convertToValidDate = (dateStr) => {
                const [day, month, year] = dateStr.split("/"); // Divide la fecha en partes
                return `${year}-${month}-${day}`; // Reorganiza al formato "YYYY-MM-DD"
            };
            let diffTime = Math.abs(new Date(convertToValidDate(arrayTramos[0].arraySegmentos[0].fromDate)) - new Date());
            searchDaysUntilDeparture = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            searchWeeksUntilDeparture = Math.round((Math.ceil(diffTime / (1000 * 60 * 60 * 24))) / 7);
        }

        let datalayerParams = {
            event: datalayerName,
            producto: module,
            rutaIATA: searchRutaIATA,
            origenIATA: searchOrigenIATA,
            origenCiudad: searchOrigenCiudad,
            origenPais: searchOrigenPais,
            destinoIATA: searchDestinoIATA,
            destinoCiudad: searchDestinoCiudad,
            destinoPais: searchDestinoPais,
            tipo_vuelo: searchTipoVuelo,
            days_until_departure: searchDaysUntilDeparture,
            weeks_until_departure: searchWeeksUntilDeparture,
            pax: searchPax,
            adultos: searchAdultos,
            menores: searchMenores,
            bebes: searchBebes,
            currency: searchCurrency,
            items: varItemsParams,
        }

        return datalayerParams;
    } else {
        let datalayerParams = {
            event: datalayerName,
            producto: module,
            items: varItemsParams,
        }

        return datalayerParams;
    }
}

// Trigger DatalayerSearch
export const DatalayerSearch = (datalayerName, module, search, items, crossell) => {
    let datalayerParams = [];
    datalayerParams = getDatalayerParams(datalayerName, module, search, items, crossell, false);

    window.dataLayer.push({ 'items': undefined });
    window.dataLayer.push(datalayerParams);

    // console.log('%c ---- DatalayerSearch / datalayerParams: ', 'color: #ffffff; background: #E65413', datalayerParams);
}

// Trigger DatalayerResults
export const DatalayerResults = (datalayerName, module, search, items, crossell) => {
    // console.log('%c ---- DatalayerBotonComprar / items: ', 'color: #ffffff; background: #e6aa00', items);
    let datalayerParams = [];
    datalayerParams = getDatalayerParams(datalayerName, module, search, items, crossell, false);

    window.dataLayer.push({ 'items': undefined });
    window.dataLayer.push(datalayerParams);

    // console.log('%c ---- DatalayerResults / datalayerParams: ', 'color: #ffffff; background: #E65413', datalayerParams);
}

// Trigger botonComprar
export const DatalayerBotonComprar = (datalayerName, module, search, items, crossell) => {
    // console.log('%c ---- DatalayerBotonComprar / search: ', 'color: #ffffff; background: #e6aa00', search);
    // console.log('%c ---- DatalayerBotonComprar / items: ', 'color: #ffffff; background: #e6aa00', items);
    let datalayerParams = [];
    datalayerParams = getDatalayerParams(datalayerName, module, search, items, crossell, false);

    window.dataLayer.push({ 'items': undefined });
    window.dataLayer.push(datalayerParams);

    // console.log('%c ---- DatalayerResults / datalayerParams: ', 'color: #eeeeee; background: #E65413', datalayerParams);
}

// Trigger DatalayerCheckoutBegin
export const DatalayerCheckoutBegin = (datalayerName, module, search, items, crossell) => {
    // console.log('%c ---- DatalayerCheckoutBegin / search: ', 'color: #ffffff; background: #e6aa00', search);
    // console.log('%c ---- DatalayerCheckoutBegin / items: ', 'color: #ffffff; background: #e6aa00', items);

    let datalayerParams = [];
    datalayerParams = getDatalayerParams(datalayerName, module, search, items, crossell);
    window.dataLayer.push({ 'items': undefined });
    window.dataLayer.push(datalayerParams);

    // console.log('%c ---- DatalayerCheckoutBegin / datalayerParams: ', 'color: #eeeeee; background: #E65413', datalayerParams)
}

// Trigger DatalayerSeleccionarFormaPago
export const DatalayerSeleccionarFormaPago = (datalayerName, module, paymentMethod, items) => {
    window.dataLayer.push({
        'event': datalayerName,
        'payment_options': paymentMethod,
    });
    // console.log('%c ---- DatalayerSeleccionarFormaPago / datalayerName: ', 'color: #eeeeee; background: #E65413', datalayerName);
}

// export const DatalayerCheckoutSteps = (datalayerName, module, paymentMethod, items) => {
//     window.dataLayer.push({
//         'event': datalayerName,
//         'preventRefresh': true
//     });
//     console.log('%c ---- DatalayerCheckoutSteps / datalayerName: ', 'color: #eeeeee; background: #E65413', datalayerName);
// }

export const DatalayerAddPassengerInfo = (datalayerName, module, paymentMethod, items) => {
    window.dataLayer.push({
        'event': datalayerName,
        'preventRefresh': true
    });
    // console.log('%c ---- DatalayerAddPassengerInfo / datalayerName: ', 'color: #eeeeee; background: #E65413', datalayerName);
}

export const DatalayerProcessingModal = (datalayerName, module, vacio, vacio2) => {
    window.dataLayer.push({
        'event': datalayerName
    });
    // console.log('%c ---- DatalayerProcessingModal / datalayerName: ', 'color: #eeeeee; background: #E65413', datalayerName);
}

export const DatalayerPurchaseError = (datalayerName, module, idTransaction, status) => {
    window.dataLayer.push({
        'event': datalayerName,
        'transaction_id': idTransaction,
        'status': status
    });
    // console.log('%c ---- DatalayerPurchaseError / datalayerName: ', 'color: #eeeeee; background: #E65413', datalayerName);
}

export const DatalayerReservation = (datalayerName, module, idTransaction, purchaseDetail) => {
    window.dataLayer.push({
        'event': datalayerName,
        'transaction_id': idTransaction,
        'currency': purchaseDetail?.currency,
        'value': Number(Number(purchaseDetail?.totalAmountReal || 0).toFixed(2))
    });
}

export const DatalayerPurchase = (datalayerName, module, idTransaction, purchaseDetail) => {
    window.dataLayer.push({
        'event': datalayerName,
        'transaction_id': idTransaction,
        'currency': purchaseDetail?.currency,
        'value': Number(Number(purchaseDetail?.totalAmountReal || 0).toFixed(2))
    });
    // console.log('%c ---- DatalayerPurchase / datalayerName: ', 'color: #eeeeee; background: #E65413', datalayerName);
}
