import React, { Fragment, useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Fab } from '@mui/material';
import { Close } from '@mui/icons-material';

export default function DialogModal({
  id,
  fullWidth,
  maxWidth,
  size,
  startIcon,
  endIcon,
  className,
  button,
  header,
  content
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Fragment>
      <Button
        className={`button-${className}`}
        onClick={handleClickOpen}
        startIcon={startIcon}
        endIcon={endIcon}
        size={size}
      >
        {button}
      </Button>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby={id}
        open={open}
        className={`closable-modal dialog-${className} ${id}`}
      >
        <Fab size="small" onClick={handleClose} className='close-button'>
          <Close fontSize='small' />
        </Fab>
        <DialogTitle id={id} onClose={handleClose}>{header}</DialogTitle>
        <DialogContent>{content}</DialogContent>
      </Dialog>
    </Fragment>
  );
}
