import { Fragment } from 'react';
import { Grid, Hidden, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Image from '../../common/Image';
import { useClientData } from '../../../context/ClientContext';
import { ArrowBack } from '@mui/icons-material';

const CABIN_CLASS = {
  Y: { value: 'economy', valueB2C: 'economyB2C' },
  P: { value: 'premium' },
  W: { value: 'premium' },
  B: { value: 'business' },
  F: { value: 'firstClass' }
};

const FlightScales = ({ option, handleCloseDetail }) => {
  const { t } = useTranslation();
  const { clientData } = useClientData();

  const getDaysAfterMessage = (days) => {
    return days === 1
      ? t('results.flights.dayAfter')
      : t('results.flights.daysAfter', { days });
  }

  const getCabinClass = (segment) => {
    const cabinClass = CABIN_CLASS[segment.CabinClass];
    if (cabinClass) {
      const value = clientData?.client?.isB2C && cabinClass.valueB2C ? cabinClass.valueB2C : cabinClass.value;
      return t(`results.flights.${value}`);
    }
  }

  const getItineraryData = () => {
    return <>
      <Grid
        item
        xs={12}
        sm={6}
        className='row'
      >
        {Boolean(handleCloseDetail) && (
          <Hidden smUp>
            <IconButton onClick={handleCloseDetail}>
              <ArrowBack />
            </IconButton>
            <b>{t('results.flights.segmentsDetails')}</b>
          </Hidden>
        )}

        <Hidden smDown>
          <b>{t('results.flights.itinerary')}</b>
        </Hidden>
      </Grid>
      <Grid item xs={12} sm={6} className='text-right' style={{ marginBottom: 10 }}>
        <Typography>
          {t('results.flights.flightTime')}: <b>{option?.OptionDurationHours}h {option?.OptionDurationMinutes}m</b>
        </Typography>
        {option?.DayAfter > 0 && (
          <Typography><span className='plus-days'>+{option?.DayAfter}D</span> ({getDaysAfterMessage(option?.DayAfter)})</Typography>
        )}
      </Grid>
    </>
  }

  return <Grid className='flight-scales-container'>
    <Hidden smUp>
      <Grid container columnSpacing={2}>
        {getItineraryData()}
      </Grid>
    </Hidden>
    {option?.Segments.map((elem, i) => (
      <Fragment key={i}>
        {(elem.WaitHours || elem.WaitMinutes) && (
          <Grid className='connection-container'>
            {t('results.flights.scaleWait', {
              city: `${elem.Departure.CityName}, ${elem.Departure.CountryName} (${elem.Departure.AirportCode})`,
              hours: elem.WaitHours,
              minutes: elem.WaitMinutes
            })}
          </Grid>
        )}
        <Grid container className='scale-container' columnSpacing={2}>
          <Hidden smDown>
            {i === 0 && getItineraryData()}
          </Hidden>
          <Grid item xs={4} sm={6} className='row' style={{ marginBottom: 10 }}>
            <Image img={`providersFlights/${elem.Airline}.png`} alt='logo-provider' className='airline-logo' hideBrokenImage />
            {elem.AirlineName}
          </Grid>
          <Grid item xs={8} sm={6} className='text-right' style={{ marginBottom: 10 }}>
            <Typography>{t('results.flights.class')}: <b>{getCabinClass(elem)}</b></Typography>
            <Typography>{t('results.flights.flightNumber')}: <b>{elem.FlightNumber}</b></Typography>
          </Grid>
          <Grid item xs={12} className='airports-container row'>
            <Grid className='scale-points column'>
              <div className='circle empty' />
              <div className='line'/>
              <div className='circle' />
            </Grid>
            <Grid className='airports-info column'>
              <Grid container className='row' spacing={1}>
                <Grid item xs={3} sm={2} className='airport-code'>{elem.Departure.AirportCode}</Grid>
                <Grid item xs={3} className='time'>{elem.Departure.Time}</Grid>
                <Grid item xs={6} sm={7} className='date'>{dayjs(elem.Departure.Date).format('ddd, DD MMM YY')}</Grid>
                <Grid item xs={12} className='airport'>{elem.Departure.AirportName}</Grid>
              </Grid>
              <Grid container className='row' spacing={1} style={{ marginTop: 20 }}>
                <Grid item xs={3} sm={2} className='airport-code'>{elem.Arrival.AirportCode}</Grid>
                <Grid item xs={3} className='time'>{elem.Arrival.Time}</Grid>
                <Grid item xs={6} sm={7} className='date'>{dayjs(elem.Arrival.Date).format('ddd, DD MMM YY')}</Grid>
                <Grid item xs={12} className='airport'>{elem.Arrival.AirportName}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    ))}
  </Grid>
}

export default FlightScales;
