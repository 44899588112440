import React, { memo, useMemo } from 'react';
import { Grid, Typography, Icon } from '@mui/material';

const GetDetailSearch = memo(({ detailSearch }) => (
  <Typography component='p' className='detail-item-list mb-1'>
    {detailSearch.map((item, i) => (
      <span key={i} className='item-detail-search'>
        <strong>{item.title}</strong> {item.description}
      </span>
    ))}
  </Typography>
));

const GetDetailProduct = memo(({ detailProduct }) => (
  <ul className='detail-item-list products'>
    {detailProduct.map((item, i) => (
      <li key={i} className={`list-item ${item.className}`}>
        <Typography component='p' className={item.icon ? 'has-icon' : ''}>
          {item.icon && <Icon>{item.icon}</Icon>}
          {item.title && <strong>{item.title}</strong>}
          {item.description}
        </Typography>
      </li>
    ))}
  </ul>
));

const GetDetailProductSelection = memo(({ detailProduct }) => {
  const detailProductSelection = useMemo(() => detailProduct.slice(0, 4), [detailProduct]);

  return (
    <ul className='detail-item-list products'>
      {detailProductSelection.map((item, i) => (
        <li key={i} className={`list-item ${item.className}`}>
          <Typography component='p' className={item.icon ? 'has-icon' : ' '} noWrap>
            {item.icon && <Icon>{item.icon}</Icon>}
            {item.title && <strong>{item.title}</strong>}
            {item.description}
          </Typography>
        </li>
      ))}
    </ul>
  );
});

export default function CardTwoCols(
  {
    template,
    item,
    showDetailSearch,
    showDetailProductFull,
    detailButton
}) {
  const detailSearch = item.details?.detailSearch;
  const detailProduct = item.details?.detailProduct;

  return (
    <div className={template}>
      <Grid container spacing={1}>
        <Grid item xs={2} md={3}>
          <div className='detail-item-header'>
            {item.image && (
              <img className='detail-item-image' src={item.image} alt={item.title} />
            )}
          </div>
        </Grid>
        <Grid item xs={10} md={9}>
          {item.title && (
            <Typography className='detail-item-title' variant='h4'>
              {item.title}
            </Typography>
          )}
          {showDetailSearch && detailSearch && (
            <GetDetailSearch detailSearch={detailSearch} />
          )}
          {detailProduct && (
            showDetailProductFull ? (
              <GetDetailProduct detailProduct={detailProduct} />
            ) : (
              <GetDetailProductSelection detailProduct={detailProduct} />
            )
          )}
          {detailButton}
        </Grid>
      </Grid>
    </div>
  );
};
