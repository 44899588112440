import React, { createContext, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { getBanners, getLandings } from '../store/services/Login';
import { MODULES } from '../utils/modules';
import { useLocation } from 'react-router-dom';

const BannersLandingsContext = createContext();

export const BannersLandingsProvider = ({ banners, landings, allowedIframes, children }) => {
  const location = useLocation();
  const [bannersData, setBannersData] = useState(null);
  const [landingsData, setLandingsData] = useState(null);

  useEffect(() => {
    setBannersData(banners);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banners])

  useEffect(() => {
    setLandingsData(landings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landings])

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    const banners = JSON.parse(localStorage.getItem('banners') || '{}');
    let refreshBanners = true;

    if (banners && Object.keys(banners).length > 0) {
      setBannersData(banners);

      if (dayjs().diff(dayjs.unix(banners.time), 'hour') < 1) {
        refreshBanners = false;
      }
    }

    if (refreshBanners && token) {
      getBanners(token).then(res => {
        if (res?.data) {
          const newBanners = {
            ...res,
            time: dayjs().unix()
          };
          localStorage.setItem('banners', JSON.stringify(newBanners));
          setBannersData(newBanners);
        }
      })
    }

    const landings = JSON.parse(localStorage.getItem('landings') || '{}');
    let refreshLandings = true;

    if (landings && Object.keys(landings).length > 0) {
      setLandingsData(landings);

      if (dayjs().diff(dayjs.unix(landings.time), 'hour') < 1) {
        refreshLandings = false;
      }
    }

    if (refreshLandings && token) {
      getLandings(token).then(res => {
        if (res && res.length > 0) {
          const newLandings = {
            data: res,
            time: dayjs().unix()
          };
          localStorage.setItem('landings', JSON.stringify(newLandings));
          setLandingsData(newLandings);
        }
      })
    }

    updateSEO();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (bannersData) {
      getFavicon();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannersData]);

  const getFavicon = () => {
    const favicon = bannersData?.data?.modules?.['otros']?.flows?.login?.banners?.Favicon?.slice(-1)?.[0]?.image_url;
    if (favicon) {
      const link = document.querySelector("link[rel~='icon']") || document.createElement('link');
      link.rel = 'icon';
      link.href = favicon;
      document.head.appendChild(link);
    }
  };

  const updateSEO = () => {
    const client = JSON.parse(localStorage.getItem('clientData') || '{}');
    const isIframe = allowedIframes.includes(window.location.host);
    let index = isIframe ? 2 : 1;
    if (location.pathname.split('/')[index] === 'checkout') {
      index += 1;
    }
    const path = location.pathname.split('/')[index];
    const module = MODULES.find(elem => elem.module === path || elem.path === `/${path}`);
    const modulePath = module?.path?.split('/')[1];
    const seoData = client?.seo?.[modulePath];

    if (seoData && typeof seoData === 'object') {
      const { title, metaDescription } = seoData;
      if (title) {
        document.title = title;
      }
      if (metaDescription) {
        const metaTag = document.querySelector("meta[name='description']");
        if (metaTag) {
          metaTag.setAttribute("content", metaDescription);
        } else {
          const newMetaTag = document.createElement("meta");
          newMetaTag.setAttribute("name", "description");
          newMetaTag.setAttribute("content", metaDescription);
          document.head.appendChild(newMetaTag);
        }
      }
    }
  };

  return (
    <BannersLandingsContext.Provider value={{
        bannersData,
        landingsData,
        setBannersData,
        setLandingsData
      }}>
      {children}
    </BannersLandingsContext.Provider>
  );
};

// Hook personalizado para acceder al contexto fácilmente
export const useBannersLandingsData = () => {
  const context = useContext(BannersLandingsContext);

  if (context === undefined) {
    throw new Error('useBannersLandingsData debe ser usado dentro de un BannersLandingsProvider');
  }

  return context;
};
