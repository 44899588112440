import React  from 'react';
import PreloadFlights from './PreloadFlights';

export default function PreloadMetabuscador(props) {
  switch (props.module) {
    case 'flights':
      return <PreloadFlights {...props} />;
    default:
      return null;
  }
}
