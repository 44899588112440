import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Fab, Table, TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight, Remove } from '@mui/icons-material';
import Image from '../../common/Image';
import { useClientData } from '../../../context/ClientContext';
import { formatNumber, getCurrency } from '../../../utils/price';

const DUPLICATED_AIRLINES = {
  H8: 'H2'
};

function RatesByAirline({ results, filters, dataForm, handleChangeFilters }) {
  const { t } = useTranslation();
  const { clientData, currencies } = useClientData();
  const [initialLoad, setInitialLoad] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [sliceAirlines, setSliceAirlines] = useState(0);

  useEffect(() => {
    setSliceAirlines(0);

    if (initialLoad && results && Object.keys(results).length > 0) {
      setExpanded(e => e || !dataForm?.threeDays);
      setInitialLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results])

  useEffect(() => {
    setInitialLoad(true);
  }, [dataForm])

  const getAirlines = (slice = true) => {
    const airlines = [];

    results?.Fares?.forEach(fare => (
      fare?.Legs?.forEach(leg => (
        leg?.Options?.forEach(option => {
          const airline = DUPLICATED_AIRLINES[option?.Segments?.[0].Airline] || option?.Segments?.[0].Airline
          if (!airlines.find(elem => elem === airline)) {
            airlines.push(airline);
          }
        })
      ))
    ));

    if (slice) {
      return airlines.slice(sliceAirlines, sliceAirlines + 5);
    } else {
      return airlines;
    }
  }

  const getAirlinesCells = () => {
    return getAirlines().map((airline, i) => (
      <TableCell key={i}>
        <div className='column'>
          <Image img={`providersFlights/${airline}.png`} alt='logo-provider' className='airline-logo' hideBrokenImage />
        </div>
      </TableCell>
    ))
  }

  const getMinPrice = (fares) => {
    return parseInt(Math.min.apply(Math, fares.map(elem => elem?.ExtendedFareInfo?.NetTotalAmountWithFee || elem?.TotalAmount)));
  }

  const getPrices = (scales) => {
    const airlines = getAirlines();
    const faresByScales = filterFaresByScales(results?.Fares, scales);

    return airlines.map((airline, i) => {
      const faresByAirline = filterFaresByAirline(faresByScales, airline);
      let className = '';
      let clickable = false;

      if (faresByAirline.length > 0) {
        className += ' clickable';
        clickable = true;

        if ((filters?.airlineCount?.resultados?.find(elem => elem.idFilter === airline && elem.active))
          && (filters?.stopoversCount?.resultados?.find(elem => elem.idFilter === scales.toString() && elem.active))
        ) {
          className += ' selected';
        }
      }

      const newFiltersFields = [];
      const newFiltersValues = [];

      if ((!filters?.stopoversCount?.resultados?.find(elem => elem.idFilter === scales.toString() && elem.active))
        || (!filters?.airlineCount?.resultados?.find(elem => elem.idFilter !== airline && elem.active))
      ) {
        newFiltersFields.push('stopover');
        newFiltersValues.push(scales.toString());
      }

      if ((!filters?.airlineCount?.resultados?.find(elem => elem.idFilter === airline && elem.active))
        || (!filters?.stopoversCount?.resultados?.find(elem => elem.idFilter !== scales.toString() && elem.active))
      ) {
        newFiltersFields.push('airline');
        newFiltersValues.push(airline);
      }

      return <TableCell
        key={i}
        className={className}
        onClick={() => clickable && onClickCell(airline, scales.toString())}
      >
        {faresByAirline.length > 0 ? (
          <span>{getCurrency(clientData?.client, currencies, faresByAirline?.[0]?.Currency)} {formatNumber(getMinPrice(faresByAirline))}</span>
        ) : <Remove sx={{ marginTop: '8px' }} />}

        {i === airlines.length - 1 && scales === 1 && getRightArrow()}
      </TableCell>
    })
  }

  const filterFaresByAirline = (fares, airline) => {
    const airlines = [
      airline,
      ...Object.entries(DUPLICATED_AIRLINES).filter(entry => entry[1] === airline).map(entry => entry[0])
    ]
    const filteredFares = fares?.filter(fare => (
      fare?.Legs?.find(leg => (
        leg?.Options?.find(option => airlines.includes(option?.Segments?.[0]?.Airline))
      ))
    )) || [];

    return filteredFares;
  }

  const filterFaresByScales = (fares, scales) => {
    const filteredFares = fares?.filter(fare => (
      fare?.Legs?.find(leg => (
        leg?.Options?.find(option => option?.Segments?.slice(0, 5).length === scales + 1)
      ))
    )) || [];

    return filteredFares;
  }

  const checkFlightsByScales = (scales) => (
    filterFaresByScales(results?.Fares, scales).length === 0
  )

  const getTableRows = () => {
    const rows = [
      { title: t('results.flights.direct'), scales: 0, disabled: checkFlightsByScales(0) },
      { title: t('results.flights.oneScale'), scales: 1, disabled: checkFlightsByScales(1) },
      { title: t('results.flights.twoScales'), scales: 2, disabled: checkFlightsByScales(2) }
    ];

    rows.forEach(elem => elem.columns = getPrices(elem.scales));

    return rows;
  }

  const getLeftArrow = () => {
    const airlines = getAirlines(false);

    if (airlines.length > 5) {
      return <Fab
        size='small'
        className='arrow-button'
        disabled={sliceAirlines === 0}
        onClick={(e) => {
          e.stopPropagation();
          setSliceAirlines(current => current - 1);
        }}
      >
        <KeyboardArrowLeft />
      </Fab>
    }
  }

  const getRightArrow = () => {
    const airlines = getAirlines(false);
    const airlinesSlice = getAirlines();
    const isDisabled = JSON.stringify(airlines.slice(-1)) === JSON.stringify(airlinesSlice.slice(-1));

    if (airlines.length > 5) {
      return <Fab
        size='small'
        className='arrow-button'
        disabled={isDisabled}
        onClick={(e) => {
          e.stopPropagation();
          setSliceAirlines(current => current + 1)}
        }
      >
        <KeyboardArrowRight />
      </Fab>
    }
  }

  const onClickCell = (airline, stopovers) => {
    const filtersAux = {
      stopover: [],
      fareType: [],
      contentType: [],
      baggage: [],
      airline: [],
      airport: []
    };

    if (!filters['airlineCount']?.resultados?.find(elem => elem.idFilter === airline && elem.active)
      || !filters['stopoversCount']?.resultados?.find(elem => elem.idFilter === stopovers && elem.active))
    {
      filtersAux.airline.push({ idFilter: airline });
      filtersAux.stopover.push({ idFilter: stopovers });
    }

    handleChangeFilters && handleChangeFilters(filtersAux);
  }

  return (
    <Accordion className='rates-airlines-accordion' expanded={expanded}>
      <AccordionSummary expandIcon={<KeyboardArrowDown />} onClick={() => setExpanded(!expanded)}>
        <Typography className='title'>
          {clientData?.client?.isB2C ? t('results.flights.ratesByAirlinesB2C') : t('results.flights.ratesByAirlines')}
        </Typography>
      </AccordionSummary>

      {results && Object.keys(results).length > 0 && (
        <AccordionDetails>
          <Table size='small'>
            <TableRow>
              <TableCell className='row-title'>{t('results.flights.airline')}</TableCell>
              {getAirlinesCells()}
            </TableRow>
            {getTableRows().map((elem, i) => (
              <TableRow key={i}>
                <TableCell className={`row-title ${elem.scales === 0 ? 'direct' : ''}`}>
                  {elem.title}

                  {i === 1 && getLeftArrow()}
                </TableCell>
                {elem.columns}
              </TableRow>
            ))}
          </Table>
        </AccordionDetails>
      )}
    </Accordion>
  );
}

export default RatesByAirline;
