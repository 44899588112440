import React, { useEffect, useState }  from 'react';
import { connect } from 'react-redux';
import { availabilityActionFlights } from '../../../store/actions';
import { Navigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useClientData } from '../../../context/ClientContext';
import Preloader from '../../common/Preloader';
import Preload from '../../pages/CheckoutB2C/Preload';

const PRELOAD = {
  hotels: 'preloadHotel.gif',
  trains: 'preloadTrenes.gif',
  flights: 'preloadAereos.gif'
};

function LayoutMetabuscador({
  client,
  ip,
  user,
  startCheckout,
  resultsAvailability,
  errorAvailability,
  ...props
}) {
  const { t } = useTranslation();
  const params = useParams();
  const { currentProduct, updateProductToken } = useClientData();
  const [loadingAvailability, setLoadingAvailability] = useState(false);
  const [redirectErrorAvailability, setRedirectErrorAvailability] = useState(false);

  useEffect(() => {
    updateProductToken(['air_detail']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingAvailability && currentProduct && Object.keys(currentProduct).length > 0) {
      getAvailabilitiy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProduct]);

  useEffect(() => {
    if (ip && user && resultsAvailability?.status) {
      startCheckout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsAvailability, ip, user]);

  useEffect(() => {
    if (errorAvailability) {
      setRedirectErrorAvailability(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorAvailability]);

  const getAvailabilitiy = () => {
    const { recommendationID, fareID, optionID, flightType, currency } = params;
    const token = currentProduct?.config?.['API-Token']?.[currency];
    const dataToSend = {
      recommendationID,
      fareID,
      optionID: optionID.split(','),
      flightType
    };
    props.getAvailability(token, dataToSend);
    setLoadingAvailability(true);
  }

  return (
    redirectErrorAvailability ? (
      <Navigate to={`/checkout/${params.module}/error/disponibilidad`} />
    ) : (
      <>
        <Preloader
          addDots
          image={`sites/${client.client.name}/${PRELOAD[params.module]}`}
          text={t('common.processingRequest')}
        />
        <Preload />
      </>
    )
  );
}

const mapStateToProps = reducers => {
  return reducers.flightsReducer;
};

const mapDispatchToProps = dispatch => {
  return {
    getAvailability: (token, data, signalAbort = null) => dispatch(availabilityActionFlights(token, data, signalAbort))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutMetabuscador);
