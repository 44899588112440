import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Hidden,
  Icon,
  Link,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material';
import Timer, { TimerProvider } from './Timer';
import { Suspense } from 'react';
import PreloadStep from './PreloadStep';
import PurchaseDetail from './PurchaseDetail';
import SecureSite from './SecureSite';
import TotalAPagar from './TotalAPagar';
import ContainerFixedMobile from 'react-headroom';
import { connect } from 'react-redux';
import { getBookingAction, getInfoStepAction, getPrebookingAction } from '../../../store/actions';
import Preloader from '../../common/Preloader';
import { useTranslation } from 'react-i18next';
import { ArrowBack, ErrorOutline } from '@mui/icons-material';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useClientData } from '../../../context/ClientContext';
import * as TrainsDetail from './utils/TrainsDetail';
import Preload from './Preload';
import { usePaymentContext } from '../../../context/PaymentContext';
import { getPayment } from '../../../store/services/Login';
import { LoadingButton } from '@mui/lab';
import { MessagesTopSidebar } from './CardDetailMessages';
import PreloadBooking from './PreloadBooking';
import { getClientIp } from '../../../store/services/IpServices';

const PRELOAD = {
  hotels: 'preloadHotel.gif',
  trains: 'preloadTrenes.gif',
  flights: 'preloadAereos.gif'
};

const ChangePriceDialog = ({ dataStep, purchaseDetail }) => {
  const { t } = useTranslation();
  const [openChangePriceModal, setOpenChangePriceModal] = useState(false);

  useEffect(() => {
    if (dataStep?.GET_PAYMENT?.data?.change_price) {
      setOpenChangePriceModal(true);
    }
  }, [dataStep]);

  useEffect(() => {
    if (!dataStep?.GET_PAYMENT) {
      const changePrice = purchaseDetail?.priceDetail?.find((priceDetail) => (
        priceDetail?.charge?.find((elem) => elem?.fare_class?.change_price)
      )) ? true : false;
      setOpenChangePriceModal(changePrice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseDetail]);

  return (
    <Dialog
      open={openChangePriceModal}
      className='change-price-modal'
      maxWidth='sm'
    >
      <ErrorOutline className='error-icon' />

      <DialogTitle>
        {t('checkout.common.attention')}
      </DialogTitle>

      <DialogContent>
        {t('checkout.common.changePriceMessage')}
      </DialogContent>

      <DialogActions>
        <Button
          variant='contained'
          onClick={() => setOpenChangePriceModal(false)}
        >
          {t('checkout.common.accept')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const Steps = ({
  client,
  selectedReservation,
  purchaseDetailProp,
  startRenderProp,
  errorAvailabilityProp,
  responsePrebooking,
  dataStep,
  errorStep,
  dataFinal,
  ...props
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const componentRef = useRef();
  const { clientData, payment, currentProduct, setPayment, setClientData } = useClientData();
  const { numberRetry, setPaymentError, setNumberRetry } = usePaymentContext();
  const [ip, setIp] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState(null);
  const [purchaseDetail, setPurchaseDetail] = useState(null);
  const [preload, setPreload] = useState(true);
  const [finalStepPreload, setFinalStepPreload] = useState(false);
  const [startRender, setStartRender] = useState(true);
  const [prebooking, setPrebooking] = useState();
  const [errorAvailability, setErrorAvailability] = useState();
  const [locationState, setLocationState] = useState({});

  useEffect(() => {
    if (Object.keys(currentProduct || {}).length > 0) {
      (async () => {
        const tokenCheckout = currentProduct?.config?.api_checkout_token || currentProduct?.config_work_unit?.api_checkout_token;

        if (params.hash && tokenCheckout) {
          const clientIp = await getClientIp();
          const dataToSend = {
            hash: params.hash,
            ip: clientIp
          }
          setIp(clientIp);
          props.getPrebooking(tokenCheckout, dataToSend);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProduct]);

  useEffect(() => {
    setPrebooking(responsePrebooking);
  }, [responsePrebooking]);

  useEffect(() => {
    setStartRender(startRenderProp);
    if (startRenderProp === false) {
      setPreload(false);
    }
  }, [startRenderProp]);

  useEffect(() => {
    setErrorAvailability(errorAvailabilityProp);
  }, [errorAvailabilityProp]);

  useEffect(() => {
    setData(dataStep);
    if (dataStep) {
      setPreload(false);
    }
  }, [dataStep]);

  useEffect(() => {
    setPurchaseDetail(purchaseDetailProp)
  }, [purchaseDetailProp]);

  useEffect(() => {
    if (errorStep) {
      const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
      navigate(iframePath + `/checkout/${params.module}/error`, {
        replace: true,
        state: {
          errorTimeout:
            dataStep?.GET_PAYMENT?.status === 504
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorStep]);

  // useEffect(() => {
  //   if (
  //     prebooking?.step &&
  //     activeStep === 0 &&
  //     !(prebooking?.step?.[activeStep]?.stepName === 'initStepPaymentV2' && paymentError)
  //   ) {
  //     ;(async () => {
  //       const accessToken = localStorage.getItem('jwt');
  //       const tokenCheckout = currentProduct?.config?.api_checkout_token || currentProduct?.config_work_unit?.api_checkout_token;;
  //       const dataToSend = {
  //         hash: params.hash,
  //         ip,
  //         step: 'initStepCrossell',
  //         finishedStep: []
  //       };

  //       if (prebooking?.step?.[activeStep]?.stepName === 'initStepPaymentV2') {
  //         let paymentAux = payment;
  //         if (!paymentAux) {
  //           paymentAux = await getPaymentData();
  //         }

  //         dataToSend.clientPaymentInfo = paymentAux;
  //       }

  //       props.getInfoStepAction(accessToken, tokenCheckout, dataToSend, prebooking?.step[activeStep].action);
  //     })();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [prebooking]);

  useEffect(() => {
    if (dataFinal && Object.keys(dataFinal).length > 0 && params.module) {
      if (dataFinal.status) {
        const id = dataFinal.data?.responseSonata?.id || dataFinal.data.idTransaction;
        const number = dataFinal.data?.responseSonata?.data?.number
          || dataFinal.data?.responseSonata?.data?.reservation_number
          || dataFinal.data?.responseSonata?.reservation_number;
        const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
        const url = iframePath + `/checkout/${params.module}/booking/${id}/${number}`;
        const status = (dataFinal?.data?.responseSonata?.booking_status === false || dataFinal?.data?.paymentResponse?.payment_status === null)
          ? 'REJECTED'
          : dataFinal?.data?.paymentResponse?.payment_status || locationState?.status;

        if (dataFinal?.data?.paymentResponse?.payment_status !== 'REJECTED' || !dataFinal?.data?.paymentResponse?.hasRetry) {
          const pendingPayment = dataFinal?.pendingPayment
            || ['PENDING', 'FRAUD_ANALYSIS'].includes(dataFinal?.data?.paymentResponse?.payment_status);

          navigate(url, {
            state: prebooking ? {
              purchaseDetail: prebooking.purchaseDetail,
              products: prebooking.products,
              dataFinal: dataFinal.data,
              ...locationState,
              status,
              bookingDuplicated: dataFinal?.data?.responseSonata?.booking_duplicated || false,
              bookingDetail: dataFinal?.data?.responseSonata?.booking_detail || '',
              pendingPayment
            } : null,
            replace: true
          });
        } else {
          setPreload(false);
          setPaymentError(true);
          setNumberRetry(numberRetry + 1);
        }
      } else if (dataFinal?.message?.includes('Error')) {
        const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
        navigate(iframePath + `/checkout/${params.module}/error`, { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFinal]);

  const getPaymentData = async () => {
    if (!clientData?.client?.noPayment) {
      try {
        const token = localStorage.getItem('jwt');
        if (token) {
          const response = await getPayment(token);
          setPayment(response || null);
          localStorage.setItem('payment', JSON.stringify(response || null));
          return response;
        }
      } catch (error) {
        const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
        if (iframePath) {
          localStorage.clear();
          setClientData(null);
          navigate(iframePath);
        } else {
          navigate('/login', { state: { expiredSession: true } });
        }
      }
    } else {
      localStorage.removeItem('payment');
    }
  }

  const getTotalPrice = () => {
    if (params.module === 'hotels') {
      return purchaseDetail.totalAmount;
    } else if (params.module === 'trains') {
      return TrainsDetail.getTotalPrice(purchaseDetail);
    }
  }

  const handleNextStep = async (skipStep = false, paymentData = {}) => {
    const responseChildComponent = skipStep
      ? { data: null, status: true }
      : await componentRef?.current?.handleSubmit();

    if (responseChildComponent?.status) {
      let dataChild = JSON.parse(JSON.stringify(responseChildComponent.data));
      if (activeStep === prebooking.step.length - 1) {
        const isPaymentStep = prebooking.step?.[activeStep]?.stepName === 'initStepPaymentV2';
        let locationStateAux = null;
        let extraData = {};

        const id = responseChildComponent.reservationId
          || data?.GET_PAYMENT?.data?.responseSonata?.data?.number
          || data?.GET_PAYMENT?.data?.responseSonata?.reservation_number;
        if (id) {
          extraData.reservation_id = id;
        }

        if (isPaymentStep) {
          const selectedPayment = data?.GET_PAYMENT?.data?.payments?.find(elem => elem.name === responseChildComponent.paymentSelected);
          const index = dataChild?.dataForm?.payments?.findIndex(elem => elem.paymentMethod?.name === responseChildComponent.paymentSelected);
          if (index > -1) {
            dataChild.dataForm.payments[index] = {
              ...dataChild.dataForm.payments[index],
              ...paymentData
            }
          }

          locationStateAux = {
            pendingPayment: false,
            bookingStatus: {
              emited: true,
              payment: true
            },
            paymentData: selectedPayment
          }

          const availableCredit = payment?.config?.credit?.remaining_balance || 0;
          const totalPrice = Number(getTotalPrice());

          if (selectedPayment?.name === 'credit' && availableCredit < totalPrice) {
            locationStateAux.pendingPayment = true;
            locationStateAux.bookingStatus = {};
          } else if (paymentData?.status === 'REJECTED') {
            locationStateAux.status = paymentData?.status;
          }
        }

        if (!locationStateAux?.pendingPayment) {
          handleFinalStep([dataChild], prebooking.step[activeStep].stepName, extraData, locationStateAux);
        } else {
          const id = data?.GET_PAYMENT?.data?.idTransaction;
          const number = data?.GET_PAYMENT?.data?.responseSonata?.data?.number
            || data?.GET_PAYMENT?.data?.responseSonata?.reservation_number;
          const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
          const url = iframePath + `/checkout/${params.module}/booking/${id}/${number}`;
          navigate(url, {
            state: {
              purchaseDetail: purchaseDetail,
              products: prebooking.products,
              hash: params.hash,
              dataStep: [dataChild],
              ...locationState
            },
            replace: true
          })
        }
      } else {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        setPreload(true);
        setActiveStep(current => current + 1);

        const accessToken = localStorage.getItem('jwt');
        const tokenCheckout = currentProduct?.config?.api_checkout_token || currentProduct?.config_work_unit?.api_checkout_token;
        const dataToSend = {
          hash: params.hash,
          ip,
          step: prebooking.step[activeStep].stepName,
          finishedStep: [dataChild]
        };

        if (prebooking.step?.[activeStep + 1]?.stepName === 'initStepPaymentV2') {
          let paymentAux = payment;
          if (!paymentAux && !clientData?.client?.noPayment) {
            paymentAux = await getPaymentData();
          }

          dataToSend.clientPaymentInfo = paymentAux;
        }

        if (responseChildComponent?.reservationId) {
          dataToSend.reservation_id = responseChildComponent.reservationId;
        }

        props.getInfoStepAction(accessToken, tokenCheckout, dataToSend, prebooking.step[activeStep + 1].action);
      }
    }

    return true;
  }

  const handleFinalStep = (dataStep, prevStep, extraData = {}, locationStateAux = {}) => {
    const accessToken = localStorage.getItem('jwt');
    const tokenCheckout = currentProduct?.config?.api_checkout_token || currentProduct?.config_work_unit?.api_checkout_token;
    const user = JSON.parse(localStorage.getItem('user') || '{}');

    if (accessToken && tokenCheckout) {
      const prevStepName = prevStep !== null ? prevStep.replace('V2', '') : null;
      const dataToSend = {
        hash: params.hash,
        ip,
        step: prevStepName,
        username: user?.username,
        finishedStep: dataStep,
        ...extraData
      };

      props.getBooking(accessToken, tokenCheckout, dataToSend);
      setFinalStepPreload(true);
      setLocationState(locationStateAux);

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  const StepperCustom = () => {
    if (prebooking?.step && prebooking.step.length > 1 && prebooking.step?.[activeStep]?.stepper?.show) {
      const maxWidth = prebooking.step.length > 3
        ? 1170
        : 300 * prebooking.step.length;

      return (
        <section className='stepper-container'>
          <Stepper activeStep={activeStep} className='stepper-component' style={{ maxWidth: maxWidth }}>
            {prebooking.step.map((step, index) => (
              <Step key={index}>
                <StepLabel>{step.name}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </section>
      )
    }
  }

  const memoizedChangePriceDialog = useMemo(() => {
    return (
      <ChangePriceDialog
        dataStep={dataStep}
        purchaseDetail={purchaseDetail}
      />
    );
  }, [dataStep, purchaseDetail]);

  const getPreload = () => {
    return <>
      <Preloader
        addDots
        image={`sites/${client.client.name}/${PRELOAD[params.module]}`}
        text={t('common.processingRequest')}
      />
      <PreloadStep />
    </>
  }

  const StepContent = () => {
    if (data) {
      const Component = React.lazy(() => import(`./scenes/${prebooking.step[activeStep].nameContainer}`));
      return <Suspense fallback={getPreload()}>
        <Component
          ref={componentRef}
          data={data}
          module={params.module}
          selectedCountry={prebooking.country_selected}
          products={prebooking.products}
          purchaseDetail={purchaseDetail}
          selectedReservation={selectedReservation}
          preload={props.preloadStep}
          activeStep={prebooking.step?.[activeStep]}
          timer={prebooking.timer}
          expired={prebooking.expired || false}
          handleNextStep={handleNextStep}
        />
      </Suspense>
    }

    return getPreload();
  }

  const getMessages = () => {
    let arrayMessages = [];

    if (data) {
      let keysData = Object.keys(data);
      keysData.forEach(key => {
        if (data?.[key]?.data?.messages) {
          arrayMessages = arrayMessages.concat(data?.[key]?.data?.messages);
        }
      })
    }

    return arrayMessages;
  }

  const getExpediaConditions = (provider) => {
    if (provider) {
      const providerName = provider.replaceAll(' ', '').toLowerCase();
      if (providerName.includes('expedia')) {
        const url = 'https://developer.expediapartnersolutions.com/terms/es';
        return <>
          &nbsp;y de&nbsp;
          <Link href={url} target='_blank' color='#155dff'>Expedia</Link>
        </>
      }
    }

    return null;
  }

  const getBackUrl = () => (
    prebooking?.timer?.redirect || clientData?.modules?.find((elem) => elem.module === params.module)?.path
  )

  const ContinueButton = () => {
    const { paymentSelected } = usePaymentContext();
    const [acceptedConditions, setAcceptedConditions] = useState(activeStep === 0 ? false : true);
    const [loading, setLoading] = useState(false);

    const isPaymentStep = prebooking.step?.[activeStep]?.stepName === 'initStepPaymentV2';
    const isPaymentMethodSelected = isPaymentStep && paymentSelected;

    const availableCredit = payment?.config?.credit?.remaining_balance || 0;
    const totalPrice = Number(getTotalPrice());

    const showButton = !isPaymentStep || isPaymentMethodSelected?.name !== 'pix';

    const textButton = !isPaymentStep || !isPaymentMethodSelected
      ? t('checkout.common.continue')
      : isPaymentMethodSelected && availableCredit < totalPrice
        ? t('checkout.common.finish')
        : t('checkout.common.pay');

    const onClickButton = async () => {
      setLoading(true);
      await handleNextStep(false);
      setLoading(false);
    }

    return <>
      <div className='text-total-sumary'>
        <TotalAPagar
          purchaseDetail={purchaseDetail}
          products={prebooking.products}
          module={params.module}
        />
        <FormControlLabel
          control={<Checkbox
            checked={acceptedConditions}
            disabled={activeStep !== 0}
            onChange={(e, checked) => setAcceptedConditions(checked)}
          />}
          label={
            <Typography>
              {t('checkout.common.readAndAccept')}&nbsp;
              <Link href={client?.client.conditions} target='_blank' color='#155dff'>
                {t('checkout.common.generalConditions')}
              </Link>
              {getExpediaConditions(prebooking.products?.pivot?.[0]?.cardDetail?.provider)}
            </Typography>
          }
        />
      </div>

      {showButton && (
        <LoadingButton
          className='btn-next-step'
          color='secondary'
          variant='contained'
          onClick={onClickButton}
          endIcon={<Icon>arrow_forward</Icon>}
          disabled={!acceptedConditions || (isPaymentStep && !isPaymentMethodSelected)}
          loading={loading}
        >
          <span>{textButton}</span>
        </LoadingButton>
      )}
    </>
  }

  return (
    <>
      {preload && (!prebooking || finalStepPreload) && (
        <>
          <Preloader
            addDots
            image={`sites/${client.client.name}/${PRELOAD[params.module]}`}
            text={finalStepPreload ? t('common.processingBooking') : t('common.processingRequest')}
          />
          {finalStepPreload ? <PreloadBooking /> : <Preload />}
        </>
      )}

      {prebooking && (
        startRender ? (
          prebooking?.error ? (
            <Navigate to={prebooking?.step?.path} />
          ) : (
            <TimerProvider initialMinutes={prebooking?.timer?.value}>
              <div className='steps'>
                <StepperCustom />

                <Hidden mdUp>
                  <ContainerFixedMobile pinStart={210}>
                    <PurchaseDetail
                      purchaseDetail={purchaseDetail}
                      products={prebooking?.products}
                      module={params.module}
                    />
                  </ContainerFixedMobile>
                </Hidden>

                <Container>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8} className='col-content'>
                      {preload ? (
                        <>
                          <Preloader
                            addDots
                            image={`sites/${client.client.name}/${PRELOAD[params.module]}`}
                            text={t('common.processingRequest')}
                          />
                          <Preload />
                        </>
                      ) : (<>
                        <Button href={getBackUrl()} size='small' style={{ marginBottom: 10 }}>
                          <ArrowBack style={{ marginRight: 5 }} /> {t('checkout.timer.returnResults')}
                        </Button>

                        <StepContent />

                        {memoizedChangePriceDialog}
                      </>
                      )}
                    </Grid>

                    <Hidden mdDown>
                      <Grid item md={4} className='col-sidebar'>
                        <Grid>
                          <Timer
                            timer={prebooking.timer}
                            expired={prebooking.expired || false}
                          />
                          <PurchaseDetail
                            purchaseDetail={purchaseDetail}
                            products={prebooking.products}
                            module={params.module}
                          />
                        </Grid>
                        <MessagesTopSidebar messages={getMessages()} />
                        <SecureSite client={client?.client} />
                      </Grid>
                    </Hidden>
                  </Grid>
                </Container>

                <div className='steps-buttons'>
                  <Container className='row'>
                    <div className='row help-container'>
                      {/* <Hidden lgDown>
                        <div className='icon-container'>
                          <QuestionAnswer />
                        </div>
                        <div className='message-container'>
                          <Typography>Hola,</Typography>
                          <Typography>¿Cómo podemos ayudarle?</Typography>
                        </div>
                      </Hidden> */}
                    </div>
                    <div className='steps-buttons-inner'>
                      <ContinueButton />
                    </div>
                  </Container>
                </div>
              </div>
            </TimerProvider>
          )
        ) : (
          errorAvailability
            ? <Navigate to={`/checkout/${params.module}/error/disponibilidad`} />
            : <Navigate to={`/checkout/${params.module}/error404`} />
        )
      )}
    </>
  );
}

const mapStateToProps = reducers => {
  return reducers.checkoutReducer;
};

const mapDispatchToProps = dispatch => {
  return {
    getPrebooking: (tokenCheckout, data) => dispatch(getPrebookingAction(tokenCheckout, data)),
    getInfoStepAction: (access, tokenCheckout, hash, ip, nextStep, currentDataStep, prevStep) =>
      dispatch(getInfoStepAction(access, tokenCheckout, hash, ip, nextStep, currentDataStep, prevStep)),
    getBooking: (access, tokenCheckout, data) => dispatch(getBookingAction(access, tokenCheckout, data))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Steps);
