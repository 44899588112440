import { Buffer } from 'buffer';
import { getCredentials } from '../sagas/util';

export const generateAccessToken = async (clientId, clientSecret) => {
  try {
    if (!clientId || !clientSecret) {
      throw new Error('Faltan credenciales de PayPal');
    } else {
      const auth = Buffer.from(
        clientId + ':' + clientSecret,
      ).toString('base64');
      const response = await fetch(`${getCredentials().urlPaypal}/v1/oauth2/token`, {
        method: 'POST',
        body: 'grant_type=client_credentials',
        headers: {
          Authorization: `Basic ${auth}`,
        },
      });

      const data = await response.json();
      return data.access_token;
    }
  } catch (error) {
    console.error('Failed to generate Access Token:', error);
  }
}
export const generateClientToken = async (clientId, clientSecret) => {
  const accessToken = await generateAccessToken(clientId, clientSecret);
  const url = `${getCredentials().urlPaypal}/v1/identity/generate-token`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
    Authorization: `Bearer ${accessToken}`,
    'Accept-Language': 'en_US',
    'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  return data.client_token;
}

export const createOrder = async (clientId, clientSecret, payload) => {
  const accessToken = await generateAccessToken(clientId, clientSecret);
  const response_orders = await fetch(`${getCredentials().urlPaypal}/v2/checkout/orders`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    method: 'POST',
    body: JSON.stringify(payload),
  });
  const orderData = await response_orders.json();
  return orderData;
}

export const captureOrder = async (clientId, clientSecret, orderID) => {
  try {
    const accessToken = await generateAccessToken(clientId, clientSecret);
    /*
    Aca esta el ejemplo para forzar un tipo de error especifico
    https://developer.paypal.com/tools/sandbox/negative-testing/request-headers/
    */

    const response_capture = await fetch(`${getCredentials().urlPaypal}/v2/checkout/orders/${orderID}/capture`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        // 'paypal-mock-response': {'mock_application_codes':'INTERNAL_SERVER_ERROR'}
      },
      method: 'POST'
    });
    if (!response_capture.ok) {
      // Manejar errores HTTP aquí, por ejemplo:
      console.error(`Error al capturar la orden: ${response_capture.status}`);
      return response_capture;
    }
      const orderData = await response_capture.json();
      return orderData;
  } catch (error) {
    // Manejar errores de red u otros problemas aquí
    console.error('Error durante la solicitud:', error.message);
    return error;
  }
}