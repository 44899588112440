import React, { useEffect, useState } from 'react';
import { useClientData } from '../../../context/ClientContext';
import MultibuscadorGimmonix from './MultibuscadorGimmonix';
import MultibuscadorTrains from './MultibuscadorTrains';
import MultibuscadorFlights from './MultibuscadorFlights';

const Multibuscador = ({ moduleName, ...props }) => {
  const { clientData } = useClientData();
  const [module, setModule] = useState();

  useEffect(() => {
    setModule(clientData.modules.find(elem => elem.module === moduleName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (moduleName === 'hotels') {
    return <MultibuscadorGimmonix module={module} {...props} />
  } else if (moduleName === 'trains') {
    return <MultibuscadorTrains module={module} {...props} />
  } else if (moduleName === 'flights') {
    return <MultibuscadorFlights module={module} {...props} />
  }
};

export default Multibuscador;
