import React, { useEffect, useState } from 'react';
import { useClientData } from './context/ClientContext';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { GlobalStyles } from '@mui/system';
import createThemeFromData, { globalStyles } from './assets/Theme.js';
import Layout from './components/layout/Layout.js';
import LayoutPreHome from './components/layout/LayoutPreHome.js';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  GimmonixHomepage,
  GimmonixResults,
  GimmonixFavorites,
  TrainsHomepage,
  TrainsResults,
  FlightsResults,
  FlightsHomepage
} from './components/modules';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Login from './components/pages/Login/Login'
import dayjs from 'dayjs';
import LayoutCheckout from './components/layoutCheckout/Layout.js';
import LayoutCheckoutB2C from './components/layoutCheckoutB2C/Layout.js';
import './App.css';
import Register from './components/pages/Register/Register';
import ThankYouPage from './components/pages/Checkout/ThankYouPage';
import ThankYouPageB2C from './components/pages/CheckoutB2C/ThankYouPage.js';
import Error404 from './components/pages/Checkout/Error404.js';
import RecoverPassword from './components/pages/Recover/RecoverPassword.js';
import ErrorAvailability from './components/pages/Checkout/ErrorAvailability.js';
import ScrollToTop from './components/common/ScrollToTop.js';
import { autologin, getBanners, getClient, getCurrencies, getLandings, getPayment, getProducts } from './store/services/Login.js';
import DefaultLogin from './components/pages/Login/DefaultLogin.js';
import DefaultRecoverPassword from './components/pages/Recover/DefaultRecoverPassword.js';
import { BannersLandingsProvider } from './context/BannersLandingsContext.js';
import InfoPage from './components/pages/InfoPage/InfoPage';
import CondicionesGenerales from './components/pages/CondicionesGenerales/CondicionesGenerales';
import ThanksPage from './components/pages/ThanksPage/ThanksPage';
import ZohoBot from './components/common/ZohoBot.js';
// import PreHome from './components/pages/PreHome/PreHome.js';
import { getClientIpService } from './store/services/IpServices.js';
import { MODULES } from './utils/modules.js';
import LayoutMetabuscador from './components/layoutCheckout/LayoutMetabuscador.js';
import LayoutMetabuscadorB2C from './components/layoutCheckoutB2C/LayoutMetabuscador.js';
import { PaymentProvider } from './context/PaymentContext.js';
import { PuchaseDetailProvider } from './context/PurchaseDetailContext.js';
import ContactPage from './components/pages/ContactPage/ContactPage.js';
import PaymentUnavailableModal from './components/pages/CheckoutB2C/payment/PaymentUnavailableModal.js';

//Para probar local
// import configSite from './sites/gotravelres/config/config.json' // GTR
// import configSite from './sites/ttoperadora/config/config.json' // TT Operadora
// import configSite from './sites/gotrenes/config/config.json' // GoTrenes

// const urlLocal = 'dev-gotravelres-react.tije.travel' // Dev GTR
// const urlLocal = 'stg-hoteles-react.tije.travel' // Stg GTR
// const urlLocal = 'gotravelres.com.ar' // Prod GTR
const urlLocal = 'newcom-stg-tije-react.tije.travel' // Stg Tije
// const urlLocal = 'stg-ttoperadora-react.tije.travel' // Stg TTO
// const urlLocal = 'stg-gotrenes.tije.travel' // Stg GoTrenes

dayjs.extend(require('dayjs/plugin/isBetween'));
dayjs.extend(require('dayjs/plugin/isSameOrBefore'));
dayjs.extend(require('dayjs/plugin/isSameOrAfter'));
dayjs.extend(require('dayjs/plugin/isBetween'));
dayjs.extend(require('dayjs/plugin/isoWeek'));

const ALLOWED_IFRAMES = ['stg-viajoentren.tije.travel', 'trenes.viajoentren.com'];
const ALLOWED_B2C = ['localhost', 'newcom-stg-tije-react.tije.travel', 'tije.com'];

function App() {
  const {
    clientData,
    setClientData,
    setPayment,
    setCurrentProduct,
    language,
    setLanguage,
    setCurrencies
  } = useClientData();
  const [banners, setBanners] = useState({});
  const [landings, setLandings] = useState({});
  const [reservations, setReservations] = useState([]);
  const [selectedReservation, setSelectedReservation] = useState();
  const [loadingProducts, setLoadingProducts] = useState(false);

  const iframePath = ALLOWED_IFRAMES.some(elem => window.location.host.includes(elem))
    ? '/:tokenIframe'
    : '';

  const isB2C = ALLOWED_B2C.some(elem => window.location.host.includes(elem));

  const metasearchClient = window.location.pathname.includes('meta')
    ? window.location.pathname.split('/')[3]
    : (new URLSearchParams(window.location.search)).get('meta');

  useEffect(() => {
    if (!metasearchClient) {
      const payment = JSON.parse(localStorage.getItem('payment') || '{}');
      setPayment(payment);

      const currencies = JSON.parse(localStorage.getItem('currencies') || '[]');
      setCurrencies(currencies);

      const currentProduct = JSON.parse(localStorage.getItem('currentProduct') || '{}');
      setCurrentProduct(currentProduct);

      const landingsAux = JSON.parse(localStorage.getItem('landings') || '{}');
      setLandings(landingsAux);

      let client = localStorage.getItem('clientData');
      client = client ? JSON.parse(client) : undefined;

      let bannersData = JSON.parse(localStorage.getItem('banners') || '{}');
      if (!bannersData.data) {
        bannersData = {
          data: client?.banners || {},
          time: null
        };
      } else {
        client.banners = bannersData.data;
      }

      setBanners(bannersData);
      setLanguage(currentProduct?.config?.LOCALE || client?.client?.locale || 'es');

      getPaymentData(client);
      getClientData(client);
    } else {
      localStorage.clear();
      getClientData();
    }

    ;(async () => {
      const ip = await getClientIpService();
      localStorage.setItem('ip', ip);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clientData === null) {
      getClientData();

      ;(async () => {
        const ip = await getClientIpService();
        localStorage.setItem('ip', ip);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData]);

  const getPaymentData = async (client, tokenAux = '') => {
    if (!client?.client?.noPayment) {
      try {
        const token = tokenAux || localStorage.getItem('jwt');
        if (token) {
          getPayment(token).then(response => {
            setPayment(response || null);
            localStorage.setItem('payment', JSON.stringify(response || null));
          });
        }
      } catch (error) {
        window.location.href = '/login';
      }
    } else {
      localStorage.removeItem('payment');
    }
  }

  const isDifferentConfig = (config1, config2) => {
    const config1Aux = {...config1};
    delete config1Aux.banners;
    return Object.keys(config1Aux).some(key => (
      !(config2.hasOwnProperty(key) && JSON.stringify(config2[key]) === JSON.stringify(config1Aux[key]))
    ));
  }

  const getClientData = async (clientDataAux = {}) => {
    const workUnitUrl = JSON.parse(localStorage.getItem('workUnit') || '{}')?.url;
    let client = workUnitUrl || window.location.host;

    if (iframePath) {
      client = window.location.pathname.split('/')[1];
    } else if (window.location.host.includes('localhost')) {
      client = urlLocal;
    }

    try {
      getClient({ client }).then(response => {
        if (response) {
          if (response.id !== client) {
            setClientData({});
            localStorage.removeItem('clientData');
            localStorage.removeItem('autologinData');
          } else {
            clientDataAux = {
              ...clientDataAux,
              ...response,
              banners: clientDataAux?.banners || response.banners || {}
            };
            localStorage.setItem('clientData', JSON.stringify(clientDataAux));

            // Se hace comprobación para evitar rerenderizados
            if (!clientData || isDifferentConfig(response, clientDataAux)) {
              setClientData(clientDataAux);
              setBanners({
                data: clientDataAux.banners || {},
                time: dayjs().unix()
              });
            }

            const clientAutologin = iframePath ? client
              : metasearchClient ? metasearchClient
              : isB2C ? response?.client?.name
              : '';

            if (clientAutologin) {
              const autologinData = JSON.parse(localStorage.getItem('autologinData') || '{}');
              if (autologinData?.client !== clientAutologin || !autologinData?.time || dayjs().diff(dayjs.unix(autologinData?.time), 'day') >= 1) {
                getAutologinData(clientAutologin, clientDataAux);
              }
            }
          }
        }
      });
    } catch (e) {}
  };

  const getRedirectUrl = () => {
    const token = localStorage.getItem('jwt');
    let path = '';

    if (iframePath) {
      const autologinData = JSON.parse(localStorage.getItem('autologinData') || '{}');
      if (autologinData.client) {
        if (autologinData.client === clientData?.id) {
          path = `/${autologinData.client}`;
        } else if (window.location.pathname.split('/').length <= 2) {
          return `/${autologinData.client}`;
        }
      }
    }

    if (token && clientData?.modules?.[0]?.path) {
      path += clientData?.modules?.[0]?.path;
    } else if (!clientData?.client?.isB2C) {
      path += '/login';
    }

    return path;
  }

  const getAutologinData = (client, clientData = {}) => {
    setLoadingProducts(true)
    const dataIframe = { iframe: client };
    autologin(dataIframe).then(async response => {
      if (response) {
        const autologinData = {
          client,
          time: dayjs().unix()
        }
        localStorage.setItem('autologinData', JSON.stringify(autologinData));
        localStorage.setItem('jwt', response.jwt);

        getBannersAutologin(response.jwt);
        getLandingsAutologin(response.jwt);
        getPaymentData(clientData, response.jwt);
        getCurrenciesAutologin(response.jwt);

        const modules = await getProductsAutologin(response.jwt, response.user);

        const clientDataAux = {
          ...clientData,
          modules,
          autologinData
        };
        localStorage.setItem('clientData', JSON.stringify(clientDataAux));
        setClientData(clientDataAux);
        setTimeout(() => {
          setLoadingProducts(false);
        }, 1000)
      }
    });
  }

  const getProductsAutologin = async (token, user) => {
    const response = await getProducts(token);
    if (response) {
      user.id = response.user_id;
      user.username = response.username;
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('userProducts', JSON.stringify(response));

      if (response.work_unit_relation[0]) {
        localStorage.setItem('workUnit', JSON.stringify(response.work_unit_relation[0]));
      }
      if (response.business_unit_relation[0]) {
        localStorage.setItem('businessUnit', JSON.stringify(response.business_unit_relation[0]));
      }

      const refTableDetails = response?.products?.map(elem => elem.ref_table_detail) || [];
      const modules = MODULES.filter(module => {
        let flag = module.refTableDetail.some(elem => refTableDetails.includes(elem));
        if (module.refTableDetail?.[0] === 'other_detail') {
          flag = flag && response?.products?.find(elem => elem.short_name.toLowerCase() === module.module)
        }
        return flag;
      });

      return modules;
    }
  }

  const getBannersAutologin = (token) => {
    getBanners(token).then(response => {
      if (response?.data) {
        const bannersData = {
          ...response,
          time: dayjs().unix()
        };
        localStorage.setItem('banners', JSON.stringify(bannersData));
        setBanners(bannersData);
      }
    });
  }

  const getLandingsAutologin = (token) => {
    getLandings(token).then(response => {
      if (response) {
        const landingsData = {
          data: response,
          time: dayjs().unix()
        };
        localStorage.setItem('landings', JSON.stringify(landingsData));
        setLandings(landingsData);
      }
    });
  }

  const getCurrenciesAutologin = (token) => {
    getCurrencies(token).then(response => {
      setCurrencies(response);
      localStorage.setItem('currencies', JSON.stringify(response));
    });
  }

  if (clientData?.styles) {
    const theme = createThemeFromData(clientData?.styles);

    const componentMapping = {
      hotels: GimmonixHomepage,
      trains: TrainsHomepage,
      flights: FlightsHomepage
    };

    const componentMappingResults = {
      hotels: {
        component: GimmonixResults,
        fixedElements: 2,
        path: '/resultados/:destination/:type/:pointInterest/:codes/:checkin/:checkout/0/0/:rooms/:nationality'
      },
      trains: {
        component: TrainsResults,
        fixedElements: 2,
        path: '/resultados/:ticketType/*'
      },
      flights: {
        component: FlightsResults,
        path: '/resultados/:tripType/:origins/:destinations/:datesDeparture/:datesReturn/:adults/:kids/:babys/:cabinClasses/:threeDays/:currency'
      }
    };

    const componentMappingFavorites = {
      hotels: GimmonixFavorites,
    };

    const getRoutes = () => {
      const routes = [];

      if (!clientData?.client?.isB2C) {
        routes.push({ path: '/login', element: <Login /> });
        routes.push({ path: '/recover', element: <RecoverPassword client={clientData} /> });
      }

      if (clientData?.client?.name === 'gotrenes') {
        routes.push({
          path: '/trenes/register',
          fixedElements: 2,
          element: <Register client={clientData} />
        });
        routes.push({
          path: '/trenes/condiciones-generales',
          fixedElements: 2,
          element: <CondicionesGenerales client={clientData} />
        });
      }

      if (clientData?.header?.showInfoTrenes) {
        routes.push({ path: '/trenes/info', fixedElements: 2, element: <InfoPage /> });
      }

      if (clientData?.modules && Array.isArray(clientData?.modules)) {
        clientData.modules.forEach((item, index) => {
          const Component = componentMapping[item.module];
          const results = componentMappingResults[item.module];
          const ComponentResults = results?.component;
          const ComponentFavorites = componentMappingFavorites[item.module];

          if (Component) {
            routes.push({
              key: `homePage_${index}`,
              path: iframePath + item.path,
              element: <Component selectedReservation={selectedReservation} />
            })
          }

          if (ComponentResults) {
            routes.push({
              key: `results_${index}`,
              path: iframePath + `${item.path}${results.path}`,
              fixedElements: results.fixedElements,
              element: <ComponentResults client={clientData} module={item} />
            })
          }

          if (ComponentFavorites) {
            routes.push({
              key: `favorites_${index}`,
              path: iframePath + `${item.path}/favoritos`,
              fixedElements: 2,
              element: <ComponentFavorites client={clientData} module={item} selectedReservation={selectedReservation} />
            })
          }
        })
      }

      return routes;
    }

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles styles={globalStyles(theme, clientData?.styles, clientData?.client?.isB2C)} />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language}>
          <PuchaseDetailProvider>
            <Router>
              <BannersLandingsProvider banners={banners} landings={landings} allowedIframes={ALLOWED_IFRAMES}>
                <ScrollToTop />
                <Routes>
                  {!clientData?.client ? (
                    <>
                      <Route path='/login' element={<DefaultLogin />} />
                      <Route path='/recover' element={<DefaultRecoverPassword />} />
                      <Route path='*' element={<Navigate to='/login' />} />
                    </>
                  ) : (
                    <>
                      <Route path={`${iframePath}/:module/prebooking/:hash`} element={
                        <PaymentProvider>
                          {clientData?.client?.isB2C ? (
                            <LayoutCheckoutB2C
                              reservations={reservations}
                              selectedReservation={selectedReservation}
                              isIframe={Boolean(iframePath)}
                              setReservations={setReservations}
                              handleSelectReservation={setSelectedReservation}
                            />
                          ) : (
                            <LayoutCheckout
                              client={clientData}
                              reservations={reservations}
                              selectedReservation={selectedReservation}
                              isIframe={Boolean(iframePath)}
                              setReservations={setReservations}
                              handleSelectReservation={setSelectedReservation}
                            />
                          )}
                        </PaymentProvider>
                      }/>
                      <Route
                        path={`${iframePath}/:module/meta/:supplier/:currency/:recommendationID/:fareID/:optionID`}
                        element={
                          clientData?.client?.isB2C ? (
                            <LayoutMetabuscadorB2C
                              client={clientData}
                              reservations={reservations}
                              selectedReservation={selectedReservation}
                              isIframe={Boolean(iframePath)}
                              loadingProducts={loadingProducts}
                              setReservations={setReservations}
                              handleSelectReservation={setSelectedReservation}
                            />
                          ) : (
                            <LayoutMetabuscador
                              client={clientData}
                              reservations={reservations}
                              selectedReservation={selectedReservation}
                              isIframe={Boolean(iframePath)}
                              setReservations={setReservations}
                              handleSelectReservation={setSelectedReservation}
                            />
                          )
                      }/>
                      {[
                        `${iframePath}/checkout/:module/booking/:idTransaction/:reservationNumber`,
                        `${iframePath}/checkout/:module/booking/:operationNumber`,
                        `${iframePath}/checkout/:module/error`
                      ].map((path, i) => (
                        <Route path={path} key={i} element={
                          clientData?.client?.isB2C ? (
                            <ThankYouPageB2C
                              client={clientData}
                              reservations={reservations}
                              selectedReservation={selectedReservation}
                              isIframe={Boolean(iframePath)}
                              setReservations={setReservations}
                              handleSelectReservation={setSelectedReservation}
                            />
                          ) : (
                            <ThankYouPage
                              client={clientData}
                              reservations={reservations}
                              selectedReservation={selectedReservation}
                              isIframe={Boolean(iframePath)}
                              setReservations={setReservations}
                              handleSelectReservation={setSelectedReservation}
                            />
                          )
                        }/>
                      ))}
                      <Route path={`${iframePath}/checkout/:module/error404`} element={
                        <Error404
                          client={clientData}
                          reservations={reservations}
                          isIframe={Boolean(iframePath)}
                          setReservations={setReservations}
                          handleSelectReservation={setSelectedReservation}
                        />
                      }/>
                      {[
                        `${iframePath}/checkout/:module/error/disponibilidad`,
                        `${iframePath}/checkout/error/disponibilidad`
                      ].map((path, i) => (
                        <Route path={path} key={i} element={
                          <ErrorAvailability
                            client={clientData}
                            reservations={reservations}
                            isIframe={Boolean(iframePath)}
                            setReservations={setReservations}
                            handleSelectReservation={setSelectedReservation}
                          />
                        }/>
                      ))}
                      <Route path='/gracias' element={
                        <LayoutPreHome client={clientData}><ThanksPage /></LayoutPreHome>
                      } />
                      <Route path='/formularioContacto/:reason?' element={
                        <ContactPage />
                      } />
                      <Route path='/*' element={
                        <Routes>
                          {/* {clientData.client?.name === 'gotrenes' && (
                            <Route path='/' element={
                              <LayoutPreHome client={clientData}><PreHome /></LayoutPreHome>
                            } />
                          )} */}
                          <Route path='/*' element={
                            <Layout
                              client={clientData}
                              reservations={reservations}
                              selectedReservation={selectedReservation}
                              isIframe={Boolean(iframePath)}
                              loadingProducts={loadingProducts}
                              routes={getRoutes().map(elem => ({ path: elem.path, fixedElements: elem.fixedElements }))}
                              redirectUrl={getRedirectUrl()}
                              setReservations={setReservations}
                              handleSelectReservation={setSelectedReservation}
                            >
                              <Routes>
                                {getRoutes().map((route, i) => (
                                  <Route key={i} {...route} />
                                ))}
                              </Routes>
                            </Layout>
                          }/>
                        </Routes>
                      }/>
                    </>
                  )}
                </Routes>
              </BannersLandingsProvider>
              <PaymentUnavailableModal />
            </Router>
          </PuchaseDetailProvider>
        </LocalizationProvider>

        <ZohoBot showZohoBot={clientData?.footer?.showBot} />
      </ThemeProvider>
    );
  }
}

export default App;
