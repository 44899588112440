import React from 'react';
import { Container, Typography, Grid, useTheme, useMediaQuery, Hidden } from '@mui/material';
import RegisterForm from './RegisterForm';
import AllBanners from '../../common/Banners/AllBanners';
import { useBannersLandingsData } from '../../../context/BannersLandingsContext';

const Register = () => {
  const { bannersData } = useBannersLandingsData();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const getBackgroundImage = () => {
    const image = isMobile
      ? bannersData?.data?.modules?.['otros']?.flows?.login?.banners?.BackgroundLogin?.slice(-1)?.[0]?.image_url_mobile
      : bannersData?.data?.modules?.['otros']?.flows?.login?.banners?.BackgroundLogin?.slice(-1)?.[0]?.image_url;

    if (image) {
      return  { backgroundImage: `url('${image}')` };
    }

    return {};
  }

  return (
    <div className="wrapper-login login">
      <Grid className='banner-form-container' style={getBackgroundImage()}>
        <Container className='form-container'>
          <Grid container className='row'>
            <Grid item xs={12} md={7} lg={6} xl={5}>
              <RegisterForm />
            </Grid>
            <Hidden mdDown>
              <Grid item md={5} lg={6} xl={7} className='title-container'>
                <Typography>
                  {bannersData?.data?.modules?.['otros']?.flows?.login?.banners?.BackgroundLogin?.[0]?.title}
                </Typography>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </Grid>

      <AllBanners
        banners={bannersData?.data?.modules?.['otros']?.flows?.login?.banners || {}}
      />
    </div>
  );
}

export default Register;
