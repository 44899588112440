import React, { useEffect, useState }  from 'react';
import { connect } from 'react-redux';
import { availabilityActionFlights, reportMetricsActionFlights } from '../../../store/actions';
import { Navigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useClientData } from '../../../context/ClientContext';
import Preloader from '../../common/Preloader';
import Preload from '../../pages/CheckoutB2C/Preload';

function LayoutMetabuscador({
  client,
  ip,
  user,
  loadingProducts,
  startCheckout,
  resultsAvailability,
  errorAvailability,
  ...props
}) {
  const { t } = useTranslation();
  const params = useParams();
  const { clientData, currentProduct, updateProductToken } = useClientData();
  const [loadingAvailability, setLoadingAvailability] = useState(false);
  const [redirectErrorAvailability, setRedirectErrorAvailability] = useState(false);
  const [availabilityRequest, setAvailabilityRequest] = useState(null);
  const [metricsSent, setMetricsSent] = useState(false);

  useEffect(() => {
    const autologinData = clientData?.autologinData;
    const oldClient = autologinData?.client;
    const newClient = params?.supplier;

    if (oldClient && newClient && oldClient === newClient && !loadingProducts) {
      updateProductToken(['air_detail']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData, loadingProducts]);

  useEffect(() => {
    if (!loadingAvailability && currentProduct && Object.keys(currentProduct).length > 0) {
      getAvailabilitiy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProduct]);

  useEffect(() => {
    if (ip && user && resultsAvailability?.status) {
      startCheckout();
      if (!metricsSent) {
        sendAvailabilityMetrics(false, resultsAvailability);
        setMetricsSent(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsAvailability, ip, user]);

  useEffect(() => {
    if (errorAvailability) {
      setRedirectErrorAvailability(true);
      if (!metricsSent) {
        sendAvailabilityMetrics(true, errorAvailability);
        setMetricsSent(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorAvailability]);

  const getAvailabilitiy = () => {
    const { recommendationID, fareID, optionID, flightType, currency } = params;
    const token = currentProduct?.config?.['API-Token']?.[currency];
    if (token) {
      const dataToSend = {
        recommendationID,
        fareID,
        optionID: optionID.split(','),
        flightType
      };
      setAvailabilityRequest(dataToSend);
      setMetricsSent(false);
      props.getAvailability(token, dataToSend);
      setLoadingAvailability(true);
    }
  }

  const sendAvailabilityMetrics = (isError, availabilityResponse) => {
    const accessToken = localStorage.getItem('jwt');
    const tokenProduct = currentProduct?.token_id; 
    const metricsPayload = {
      module: "flights",
      flow: "availability",
      data: {
        request: {
          recommendationID: availabilityRequest?.recommendationID || "",
          fareID: availabilityRequest?.fareID || "",
          optionID: availabilityRequest?.optionID || []
        },
        flight_type: isError
          ? availabilityRequest?.flightType || "multidestination"
          : (availabilityResponse.data.Booking.BookingFare.FlightType || "multidestination"),
        response: isError
          ? {
              status: false,
              data: null,
              error: errorAvailability?.error || {}
            }
          : {
              status: true,
              data: availabilityResponse.data
            },
        currency: params.currency || "ARS"
      }
    };
    props.reportMetricsProducts(accessToken, tokenProduct, metricsPayload);
  };

  return (
    redirectErrorAvailability ? (
      <Navigate to={`/checkout/${params.module}/error/disponibilidad`} />
    ) : (
      <>
        <Preloader
          addDots
          image={`sites/${client.client.name}/preloadAereos.gif`}
          text={t('common.processingRequest')}
        />
        <Preload />
      </>
    )
  );
}

const mapStateToProps = reducers => {
  return reducers.flightsReducer;
};

const mapDispatchToProps = dispatch => {
  return {
    getAvailability: (token, data, signalAbort = null) => dispatch(availabilityActionFlights(token, data, signalAbort)),
    reportMetricsProducts: (token, tokenProduct, data, signalAbort = null) => dispatch(reportMetricsActionFlights(token, tokenProduct, data, signalAbort))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutMetabuscador);
