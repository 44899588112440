import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { ClientProvider } from './context/ClientContext';
import { Provider } from 'react-redux';
import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducer from './store/reducers';
import rootSaga from './store/sagas';
import { I18nextProvider } from 'react-i18next';
import i18n from './utils/languages/i18n';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);

const root = document.getElementById('root');
const app = (
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <ClientProvider>
        <App />
      </ClientProvider>
    </I18nextProvider>
  </Provider>
);

const reactRoot = createRoot(root);
reactRoot.render(app);
