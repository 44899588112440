import React, { useRef, Suspense, forwardRef, useImperativeHandle, useEffect } from 'react';
import PreloadStep from '../PreloadStep';
import { useParams } from 'react-router';
import { DatalayerCheckoutBegin, DatalayerAddPassengerInfo } from '../../../../store/services/DatalayerGeneral';
import { useClientData } from '../../../../context/ClientContext';
import { usePurchaseDetailContext } from '../../../../context/PurchaseDetailContext';

const Passenger = (props, ref) => {
  const componentRef = useRef();
  const params = useParams();
  const { searchedProduct } = useClientData();
  const { products } = usePurchaseDetailContext();

  useImperativeHandle(ref, () => ({
    handleSubmit: handleSubmit,
  }));

  useEffect(() => {
    DatalayerCheckoutBegin(
      "checkoutBegin",
      'flights',
      searchedProduct,
      products.pivot[0].cardDetail,
      null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const responseChildComponent = await componentRef?.current?.handleSubmit();
    const data = {
      status: responseChildComponent.status,
      data: [{
        event_name: 'form_passengers',
        event_data: {
          passengers: responseChildComponent.data?.passengers
        }
      }, {
        event_name: "form_cuitcuil",
        event_data: responseChildComponent.data?.cuilcuitData
      }, {
        event_name: "form_contact",
        event_data: responseChildComponent.data?.contact
      }],
      reservationId: responseChildComponent.reservationId
    };

    // start - send Datalayer Passengers
    if (responseChildComponent.status === true) {
      DatalayerAddPassengerInfo(
        "addPassengerInfo",
        'flights',
        null,
        null);
      return data;
    }
    // end - send Datalayer Passengers
  }

  const PassengerForm = () => {
    const module = params.module;
    const Component = React.lazy(() => import(`../form/${module}/DefaultForm`));

    return (
      <Suspense fallback={<PreloadStep />}>
        <Component
          ref={componentRef}
          data={props.data}
          generalData={props.generalData}
          messages={props.messages}
          selectedReservation={props.selectedReservation}
          selectedCountry={props.selectedCountry}
        />
      </Suspense>
    )
  }

  return (
    <section className='step-passenger'>
      <PassengerForm />
    </section>
  );
}

export default forwardRef(Passenger);
