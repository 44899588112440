import React, { useRef, forwardRef, useImperativeHandle, createRef, useMemo } from 'react';
import { AttachMoney, Edit } from '@mui/icons-material';
import PaymentMethod from '../payment/PaymentMethod';
import * as TrainsDetail from '../utils/TrainsDetail';
import * as FlightsDetail from '../utils/FlightsDetail';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from '@mui/material';
import { usePaymentContext } from '../../../../context/PaymentContext';
import { IconPaymentMethod } from '../payment/utils';
import { usePurchaseDetailContext } from '../../../../context/PurchaseDetailContext';

const Payment = (props, ref) => {
  const { t } = useTranslation();
  const componentRef = useRef([]);
  const { paymentSelected, numberRetry, setPaymentSelected } = usePaymentContext();
  const { purchaseDetail, setInterest, refreshTotalAmount } = usePurchaseDetailContext();

  useImperativeHandle(ref, () => ({
    handleSubmit: handleSubmit
  }));

  const handleSubmit = () => {
    if (paymentSelected !== null) {
      if (componentRef?.current?.[paymentSelected.index]?.current) {
        const component = componentRef.current[paymentSelected.index];
        const responseChildComponent = component.current.handleSubmit();

        const data = [{
          event_name: 'form_payment',
          event_data: responseChildComponent?.data?.payments
        }];

        if (responseChildComponent?.data?.billing) {
          data.push({
            event_name: 'form_billing',
            event_data: responseChildComponent?.data?.billing
          });
        }

        return { status: responseChildComponent.status, data, paymentSelected, numberRetry };
      }
    }

    return { status: false, data: null, paymentSelected };
  }

  // const getBookingInfo = () => {
  //   const data = props.data?.GET_PAYMENT?.data?.responseSonata;
  //   if (data?.reservation_number) {
  //     return <div className='booking-info-container row'>
  //       <CheckCircleOutline fontSize='large' />
  //       <div>
  //         <div className='row title-container'>
  //           <Typography className='title'>{t('checkout.thankYouPage.successBooking')}</Typography>
  //           <Typography className='booking-number'>{t('checkout.common.bookingNumber')}: {data.reservation_number}</Typography>
  //         </div>
  //         <Typography>{t('checkout.thankYouPage.successBookingPendingIssue')}</Typography>
  //       </div>
  //     </div>
  //   }
  // }

  const getTotalPrice = () => {
    if (props.module === 'hotels') {
      return purchaseDetail.totalAmount;
    } else if (props.module === 'trains') {
      return TrainsDetail.getTotalPrice(purchaseDetail);
    } else if (props.module === 'flights') {
      return FlightsDetail.getTotalPrice(purchaseDetail);
    }
  }

  const installmentsData = useMemo(() => {
    return props.data.find(elem => elem.name === 'get_installments')?.tasks?.get_installments?.data || [];
  }, [props.data]);

  const paymentMethods = useMemo(() => {
    componentRef.current = Array(installmentsData.length).fill().map(() => createRef());

    return installmentsData.map((paymentMethod, i) => (
      <PaymentMethod
        ref={componentRef.current[i]}
        key={i}
        index={i}
        data={paymentMethod}
        dataStep={props.data}
        price={getTotalPrice()}
        previewsStepsData={props.previewsStepsData}
        externalId={props.externalId}
      />
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installmentsData, props.data, props.previewsStepsData, props.externalId]);

  const getPaymentIcon = () => {
    if (paymentSelected.payment?.icon) {
      return paymentSelected.payment?.icon;
    }

    if (paymentSelected.payment?.form === 'creditCard' && paymentSelected.payment?.code?.includes('2')) {
      return `${paymentSelected.payment?.form}_2`;
    }

    return paymentSelected.payment?.form;
  }

  const handleClickBack = () => {
    setPaymentSelected(null);
    setInterest(null);
    refreshTotalAmount();
  }

  return (
    <section className='step-payment'>
      {/* {getBookingInfo()} */}

      {paymentSelected ? (
        <Grid container className='title-container row' spacing={2}>
          <Grid item xs={12} lg={8}>
            <Typography component='h2' className='paper-title row'>
              <IconPaymentMethod icon={getPaymentIcon()} />
              {`${t('checkout.common.payWith')} ${paymentSelected.payment?.displayName?.toLowerCase()}`}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} className='text-right'>
            <Button
              variant='outlined'
              startIcon={<Edit />}
              onClick={handleClickBack}
            >
              {t('checkout.common.modify')}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Typography component='h2' className='paper-title row'>
          <AttachMoney />
          {t('checkout.common.howToPay')}
        </Typography>
      )}

      {paymentMethods}
    </section>
  );
}

export default forwardRef(Payment);
