import React, { useEffect, useRef }  from 'react';
import Footer from './Footer';
import { KeyboardDoubleArrowUp } from '@mui/icons-material';
import { LayoutProvider } from '../../context/LayoutContext';
import { AppBar, Toolbar, Button, IconButton, Link } from '@mui/material';
import { useBannersLandingsData } from '../../context/BannersLandingsContext';
import Image from '../common/Image';

function LayoutPreHome ({ client, children }) {
    const { bannersData } = useBannersLandingsData();
    const buttonRef = useRef();

    useEffect(() => {
        document.title = 'GoTrenes';
        window.onscroll = () => scrollButtonStyle();
    }, []);

    const scrollButtonStyle = () => {
        if (buttonRef.current) {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                buttonRef.current.classList.add('show');
            } else {
                buttonRef.current.classList.remove('show');
            }
        }
    }

    const scrollTop = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }

    const getLogo = () => {
        const bannerLogo = bannersData?.data?.modules?.['otros']?.flows?.login?.banners?.Logo?.slice(-1)?.[0]?.image_url;
        const url = client?.modules?.[0]?.path || '/';
        if (bannerLogo) {
            return <Link href={url}>
                <Image className='logo-header' img={bannerLogo} alt='logo-cliente' isFullPath />
            </Link>
        }

        return <div />
    }

    return (
        <LayoutProvider>
            <div className={`wrapper site-${client.client.name} prehome`}>
                <AppBar position='static' className='site-header'>
                    <Toolbar style={{ justifyContent: 'space-between' }}>
                        {getLogo()}
                        <Button variant='contained' color='secondary' href='/login'>
                            Acceso Agencias
                        </Button>
                    </Toolbar>
                </AppBar>

                <main>
                    {children}
                </main>

                <Footer client={client} />

                <IconButton className='scroll-to-top-button' ref={buttonRef} onClick={scrollTop}>
                    <KeyboardDoubleArrowUp />
                </IconButton>
            </div>
        </LayoutProvider>
    );
}

export default LayoutPreHome;
