import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Button, Container, Drawer, Grid, Hidden, IconButton, InputAdornment, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { getAutocompleteFlights } from '../../../store/services/Autocomplete';
import { filterActionFlights, getFiltersActionFlights, searchActionFlights } from '../../../store/actions';
import { connect } from 'react-redux';
import Preloader from '../../common/Preloader';
import { ArrowBack, Close, KeyboardArrowDown, Sort } from '@mui/icons-material';
import FiltersComponent from './FiltersComponent';
import ResultsComponent from './ResultsComponent';
import Multibuscador from '../../common/Multibuscador/Multibuscador';
import ThreeDaysTable from './ThreeDaysTable';
import { checkToken } from '../../../store/services/Login';
import { useClientData } from '../../../context/ClientContext';
import RatesByAirline from './RatesByAirline';
import Image from '../../common/Image';
import { ReactComponent as SmallBaggage } from '../../../assets/images/baggage/smallBaggage.svg';
import { ReactComponent as MediumBaggage } from '../../../assets/images/baggage/mediumBaggage.svg';
import { ReactComponent as LargeBaggage } from '../../../assets/images/baggage/largeBaggage.svg';
import ComparationComponent from './ComparationComponent';
import { useBannersLandingsData } from '../../../context/BannersLandingsContext';
import BannerResults from '../../common/Banners/BannerResults';
import { reportMetricsActionFlights } from '../../../store/actions';
import { formatNumber, getCurrency } from '../../../utils/price';
import { isMobile } from 'react-device-detect';

const DATE_FORMAT = 'DD/MM/YYYY';
const DATE_FORMAT_INTERNATIONAL = 'YYYY-MM-DD';

const STOPOVERS = [
  'results.flights.direct',
  'results.flights.oneScale',
  'results.flights.twoScales',
  'results.flights.threeScales'
];

const FILTERS = [
  'Stopovers Count',
  'Fare Type Count',
  'Content Type Count',
  'Airline Count',
  'Class Count',
  'Airport Count',
  'Baggage Count',
  'Price Count',
  'Schedule Departure Count'
];

const SORTING_OPTIONS = {
  b2b: [
    { name: 'price', text: 'price' },
    { name: 'duration', text: 'duration' },
    { name: 'stopover', text: 'stopover' }
  ],
  b2c: [
    { name: 'price', text: 'lowerPrice' },
    { name: 'stopover', text: 'directFlights' },
    { name: 'duration', text: 'faster' }
  ]
};

function ResultsView({
  client,
  resultSearch,
  error,
  module,
  filtersResult,
  filterSearch,
  ...props
}) {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { clientData, currentProduct, currencies } = useClientData();
  const { bannersData } = useBannersLandingsData();
  const [dataForm, setDataForm] = useState();
  const [results, setResults] = useState();
  const [initialResults, setInitialResults] = useState();
  const [filters, setFilters] = useState({});
  const [hashFlow, setHashFlow] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [abortController, setAbortController] = useState();
  const [resultsToCompare, setResultsToCompare] = useState([]);
  const [showComparation, setShowComparation] = useState(false);
  const [allDestinations, setAllDestinations] = useState([]);
  const [activeSort, setActiveSort] = useState({ name: 'price', text: 'price' });
  const [lastSearch, setLastSearch] = useState();
  const [openFilters, setOpenFilters] = useState(false);
  const [metricsSent, setMetricsSent] = useState(false);

  const currency = useMemo(() => {
    return getCurrency(clientData?.client, currencies, results?.Fares?.[0].Currency);
  }, [clientData, currencies, results])

  useEffect(() => {
    setResults({});
    //Agregué que se permita reenviar la métrica cada vez que se corrige la url
    setMetricsSent(false);
    setInitialResults(location.state?.initialResults || null);
    setInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (loading && resultSearch) {
      if (resultSearch?.status) {
        resultSearch.data?.Fares?.forEach((elem, index) => elem.index = index);
        setResults(resultSearch.data);
        setInitialResults(resultSearch.data);
        setNoResults(false);
        setHashFlow(resultSearch?.data?.CacheID);

        if (Object.values(location.state?.advancedSearch || {}).some(elem => elem)) {
          const filters = {
            airline: location.state?.advancedSearch?.airline
              ? location.state?.advancedSearch?.airline?.code?.map(idFilter => ({ idFilter }))
              : [],
            stopover: location.state?.advancedSearch?.stopover ? [{ idFilter: location.state?.advancedSearch?.stopover }] : [],
            baggage: location.state?.advancedSearch?.baggage ? [{ idFilter: location.state?.advancedSearch?.baggage }] : []
          };
          handleChangeFilters(filters, resultSearch?.data?.CacheID);
        } else {
          props.getFiltersProducts(`?searchId=${resultSearch?.data?.CacheID}`, null);
          setLoading(false);
          setLoadingFilters(true);
        }
      } else {
        setResults(null);
        setNoResults(true);
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultSearch]);

  useEffect(() => {
    if (filtersResult?.filters) {
      const search = new URLSearchParams(location.search);

      if (!results || results.length === 0 || search.size === 0) {
        setFilters(transformFilters(filtersResult?.filters?.result, true));
        setLoadingFilters(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersResult]);

  useEffect(() => {
    if (filterSearch && filterSearch?.data && Object.keys(filterSearch?.data).length > 0) {
      const flights = filterSearch?.data?.flights || [];
      flights?.forEach((elem, index) => elem.index = index);
      setAbortController(null);
      setLoading(false);
      setResults({
        Fares: flights,
        RecommendationID: filterSearch?.data?.recommendation_id
      });
      setNoResults(flights.length === 0);
      // setRepeatLastFilters(null);

      if (!hashFlow) {
        setHashFlow(filterSearch?.data?.hash_flow);
      }

      const filtersAux = transformFilters(filterSearch?.data?.filters?.result);
      setFilters(filtersAux);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSearch]);


  const loadDefaultData = async (code) => {
    const response = await getAutocompleteFlights(code);
    if (response && Array.isArray(response)) {
      const destination = response
        .sort((a, b) => a.category > b.category ? -1 : 1)
        .flatMap(elem => elem.data)
        .filter(elem => elem.iata === code)[0]
        || { iata: code, label: '' };
      return destination;
    }
  }

  const getDestinationsData = async (destinations) => {
    const destinationsPromises = destinations.map(elem => loadDefaultData(elem) || '');

    return Promise.all(destinationsPromises).then(values => {
      setAllDestinations([...new Set(values.flat())]);
      return values;
    });
  }

  const setInitialData = async () => {
    try {
      setLoading(true);
      const accessToken = localStorage.getItem('jwt');
      await checkToken(accessToken);

      const { tripType, origins, destinations, datesDeparture, datesReturn, adults, kids, babys, cabinClasses, threeDays, currency } = params;

      const originsAux = origins.split(',');
      const destinationsAux = destinations.split(',');
      const datesDepartureAux = datesDeparture.split(',');
      const datesReturnAux = datesReturn !== 'NA' ? datesReturn.split(',') : null;

      const currentLocation = location.pathname + location.search.slice(1);
      const search = new URLSearchParams(location.search);

      const sort = search.get('sorting');
      if (sort) {
        const split = sort.split('_');
        setActiveSort({ name: split[0], sort: split[1] });
      }

      let lastSearchAux = lastSearch?.indexOf('pag=');
      let currentLocationAux = currentLocation?.indexOf('pag=');
      lastSearchAux = lastSearchAux > -1 ? lastSearch.slice(0, lastSearchAux - 1) : lastSearch;
      currentLocationAux = currentLocationAux > -1 ? currentLocation.slice(0, currentLocationAux) : currentLocation;

      const uniqueDestinations = [...new Set(originsAux.concat(destinationsAux))];

      const allDestinationsAux = search.size > 0 && search.get('searchId') && allDestinations?.length > 0
        ? allDestinations
        : await getDestinationsData(uniqueDestinations);

      const segments = originsAux.map((origin, i) => ({
        origin: allDestinationsAux.find(destination => destination.iata === origin),
        destination: allDestinationsAux.find(destination => destination.iata === destinationsAux[i]),
        dateDeparture: dayjs(datesDepartureAux[i], 'DDMMYY').format(DATE_FORMAT),
        dateArrive: datesReturnAux?.[i] ? dayjs(datesReturnAux[i], 'DDMMYY').format(DATE_FORMAT) : null
      }));

      const newDataForm = {
        segments,
        adults: parseInt(adults),
        kids: parseInt(kids),
        babys: parseInt(babys),
        currency,
        tripType,
        cabinClasses,
        threeDays: threeDays === '1',
        advancedSearch: {}
      };

      if (JSON.stringify(dataForm || {}) !== JSON.stringify(newDataForm)) {
        setDataForm(newDataForm);
      }

      if (lastSearchAux !== currentLocationAux || search.size === 0) {
        setLastSearch(location.pathname + location.search.slice(1));
        setNoResults(false);
        setResultsToCompare([]);

        const legs = originsAux.map((origin, i) => ({
          departureAirportCity: origin,
          arrivalAirportCity: destinationsAux[i],
          flightDate: dayjs(datesDepartureAux[i], 'DDMMYY').format(DATE_FORMAT_INTERNATIONAL)
        }));

        if (tripType === 'roundtrip') {
          legs.push({
            departureAirportCity: destinationsAux[0],
            arrivalAirportCity: originsAux[0],
            flightDate: dayjs(datesReturnAux[0], 'DDMMYY').format(DATE_FORMAT_INTERNATIONAL)
          })
        }

        const passengers = [];

        if (newDataForm.adults) {
          passengers.push({
            type: 'ADT',
            subType: '',
            count: adults
          });
        }
        if (newDataForm.kids) {
          passengers.push({
            type: 'CHD',
            subType: '',
            count: kids
          });
        }
        if (newDataForm.babys) {
          passengers.push({
            type: 'INF',
            subType: '',
            count: babys
          });
        }

        const dataToSend = {
          legs,
          passengers,
          airlines: 'NA',
          excludedAirlines: 'NA',
          cabinClasses,
          market: 'NA',
          device: isMobile ? 'mobile' : 'desktop'
        };

        const controller = new AbortController();

        if (search.size > 0 && search.get('searchId')) {
          abortController && abortController.abort();
          props.filterProducts(location.search);
        } else {
          setActiveSort({ name: 'price', sort: 'asc' });

          const token = currentProduct?.config?.['API-Token']?.[currency];
          abortController && abortController.abort();
          setAbortController(controller);
          setHashFlow(null);
          props.searchProducts(token, dataToSend, controller.signal);
        }
      }
    } catch (error) {
      navigate('/login', { state: { expiredSession: true } });
    }
  }

  const getMinimunPrice = () => {
    if (results?.Fares) {
      const min = Math.min.apply(Math, results.Fares?.map(elem => elem?.ExtendedFareInfo?.NetTotalAmountWithFee || elem?.TotalAmount) || []);
      return min;
    }

    return 0;
  }

  const handleChangeSort = (name) => {
    let newActiveSort = { name, sort: 'asc' };

    if (clientData?.client?.isB2C && activeSort?.name === name) {
      newActiveSort = null;
    } else if (!(activeSort?.name !== name || activeSort?.sort === 'desc')) {
      newActiveSort.sort = 'desc';
    }

    setActiveSort(newActiveSort);

    let url = location.pathname + `?searchId=${hashFlow}`;

    if (newActiveSort?.name) {
      url += `&sorting=${newActiveSort.name}`;

      if (newActiveSort.sort) {
        url += `_${newActiveSort.sort}`;
      }
    }

    navigate(url, { state: { initialResults } });
  }

  const transformFilters = (filtersAux) => {
    const transformedFilters = {};
    FILTERS.forEach(elem => {
      let name = elem.replaceAll(' ', '');
      name = name[0].toLowerCase() + name.slice(1);
      transformedFilters[name] = getFilter(filtersAux, elem);
    });
    return transformedFilters;
  }

  const getFilter = (filtersAux, filter) => {
    return filtersAux?.find(elem => {
      const keys = Object.keys(elem);
      return keys.includes(filter);
    })?.[filter]
  }

  const handleChangeFilters = (data, hashFlow) => {
    if (hashFlow) {
      let url = location.pathname + `?searchId=${hashFlow}`;

      if (activeSort?.name) {
        url += `&sorting=${activeSort.name}`;

        if (activeSort.sort) {
          url += `_${activeSort.sort}`;
        }
      }

      if (data?.price && data?.price.length === 2) {
        url += `&price=${data?.price.join(',')}`;
      }

      if (data?.stopover && data?.stopover.length > 0) {
        url += `&stopover=${data?.stopover.map(elem => elem.idFilter).join(',')}`;
      }

      if (data?.fareType && data?.fareType.length > 0) {
        url += `&fare_type=${data?.fareType.map(elem => elem.idFilter).join(',')}`;
      }

      if (data?.contentType && data?.contentType.length > 0) {
        url += `&content_type=${data?.contentType.map(elem => elem.idFilter).join(',')}`;
      }

      if (data?.baggage && data?.baggage.length > 0) {
        url += `&baggage=${data?.baggage.map(elem => elem.idFilter).join(',')}`;
      }

      if (data?.airline && data?.airline.length > 0) {
        url += `&airline=${data?.airline.map(elem => elem.idFilter).join(',')}`;
      }

      if (data?.class && data?.class.length > 0) {
        url += `&class=${data?.class.map(elem => elem.idFilter).join(',')}`;
      }

      if (data?.airport && data?.airport.length > 0) {
        url += `&airport=${data?.airport.map(elem => elem.idFilter).join(',')}`;
      }

      if (data?.schedule && data?.schedule.length > 0) {
        const departure = data?.schedule?.map(schedule => schedule.departure.map(elem => dayjs.unix(elem).format('YYYY-MM-DD_HH:mm')).join('-'));
        const arrival = data?.schedule?.map(schedule => schedule.arrival.map(elem => dayjs.unix(elem).format('YYYY-MM-DD_HH:mm')).join('-'));
        url += `&schedule_departure=${departure.join(',')}&schedule_arrival=${arrival.join(',')}`;
      }

      navigate(url, { state: { initialResults } });

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

      handleCloseFilters();
    }
  }

  const getLoadingText = () => {
    if (location?.state?.price) {
      return t('results.flights.checkPrice', { currency: params.currency, price: location.state.price });
    }

    return t('common.searchingBestPrices')
  }

  const handleClickCompareFlight = (flight, checked) => {
    let resultsToCompareAux = [...resultsToCompare];
    if (checked) {
      resultsToCompareAux.push(flight);
    } else {
      resultsToCompareAux = resultsToCompareAux.filter(elem => elem.FareID !== flight.FareID);
    }
    if (checked && resultsToCompareAux.length < 2) {
      setShowComparation(false);
    }

    setResultsToCompare(resultsToCompareAux);
  }

  const getBaggages = (flight, vertical = true) => {
    const baggages = [
      {
        icon: <SmallBaggage />,
        title: t('results.flights.smallBaggageTitle'),
        description: t('results.flights.smallBaggageDescription')
      }
    ];

    const mediumBaggage = {
      icon: <MediumBaggage className='not-included' />,
      title: t('results.flights.mediumBaggageNotIncluded')
    };
    const largeBaggage = {
      icon: <LargeBaggage className='not-included' />,
      title: t('results.flights.largeBaggageNotIncluded')
    };

    if (!['0PC', '0K'].includes(flight?.Options?.[0]?.Segments?.[0]?.Baggage)) {
      const description = [];

      if (flight?.Options?.[0]?.Segments?.[0]?.Baggage?.includes('PC')) {
        const splitPC = flight?.Options?.[0]?.Segments?.[0]?.Baggage?.split('PC');
        const pieces = splitPC.length > 0 ? splitPC[0] : 0;
        flight?.PaxFares?.forEach(elem => {
          const passenger = elem.PaxType === 'ADT'
            ? t('results.flights.adult')
            : elem.PaxType === 'CHD'
              ? t('results.flights.kid')
              : t('results.flights.baby');
          description.push(t(
            `results.flights.${pieces > 1 ? 'piecesPerPassenger' : 'piecePerPassenger'}`,
            { pieces, passenger: passenger.toLowerCase() }
          ));
        })
      } else if (flight?.Options?.[0]?.Segments?.[0]?.Baggage?.includes('K')) {
        const splitPC = flight?.Options?.[0]?.Segments?.[0]?.Baggage?.split('K');
        const kg = splitPC.length > 0 ? splitPC[0] : 0;
        flight?.PaxFares?.forEach(elem => {
          const passenger = elem.PaxType === 'ADT'
            ? t('results.flights.adult')
            : elem.PaxType === 'CHD'
              ? t('results.flights.kid')
              : t('results.flights.baby');
          description.push(t('results.flights.pieceKgPerPassenger', { kg, passenger: passenger.toLowerCase() }));
        })
      }

      description.push(t('results.flights.largeBaggageDescription'));

      mediumBaggage.title = t('results.flights.mediumBaggageTitle');
      mediumBaggage.description = t('results.flights.mediumBaggageDescription');
      mediumBaggage.icon = <MediumBaggage />

      largeBaggage.title = t('results.flights.largeBaggageIncluded');
      largeBaggage.description = description.join(' ');
      largeBaggage.icon = <LargeBaggage />
    }

    baggages.push(mediumBaggage);
    baggages.push(largeBaggage);

    return <Grid container className='baggage-container' spacing={1}>
      {baggages.map((elem, i) => (
        <Grid item xs={vertical ? 12 : 4} key={i}>
          <Grid container className='row' key={i} spacing={1} columns={24}>
            <Grid item xs={3} className='icon-container'>{elem.icon}</Grid>
            <Grid item xs={21} className='column baggage-description'>
              <b>{elem.title}</b>
              {elem.description && <span>{elem.description}</span>}
            </Grid>
          </Grid>
        </ Grid>
      ))}
    </Grid>
  }

  const getBaggageIcons = (flight) => {
    const mediumLargeBaggageClassName = ['0PC', '0K'].includes(flight?.Options?.[0]?.Segments?.[0]?.Baggage) ? 'not-included' : '';

    return <Tooltip title={getBaggages(flight)}>
      <SmallBaggage />
      <MediumBaggage className={mediumLargeBaggageClassName} />
      <LargeBaggage className={mediumLargeBaggageClassName} />
    </Tooltip>
  }

  const handleClickComparation = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    setShowComparation(true)
  }

  const getComparatorContainer = () => {
    return <Grid className='comparator-container'>
      <Container>
        <Grid className='row' justifyContent='space-between' marginBottom='15px'>
          <Typography className='title'>{t('results.flights.compareFlights')} ({resultsToCompare.length})</Typography>
          <Grid className='row buttons-container'>
            <Button variant='standard' onClick={() => setResultsToCompare([])}>
              <Close />{t('results.flights.clearSelection')}
            </Button>
            <Button
              variant='outlined'
              onClick={handleClickComparation}
              disabled={resultsToCompare.length < 2}
            >
              {t('results.flights.compare')}
            </Button>
          </Grid>
        </Grid>

        <Grid container className='row' spacing={2}>
          {resultsToCompare.map((elem, i) => {
            const provider = elem?.Options?.[0]?.Segments?.[0]?.Airline;

            return <Grid item key={i} xs={12} md={3}>
              <Grid container className='flight-info'>
                <Grid item xs={6} className='column left-side'>
                  <Image img={`providersFlights/${provider}.png`} alt='logo-provider' className='airline-logo' hideBrokenImage />

                  <Grid>
                    {elem?.Options?.map((option, j) => (
                      <Grid className='row section' key={j}>
                        <Typography>{t('results.flights.section')} {j + 1}:&nbsp;</Typography>
                        <Typography>{t(STOPOVERS[option?.Segments?.length - 1])}</Typography>
                        <Typography>{option?.OptionDurationHours}h {option?.OptionDurationMinutes}m</Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>

                <Grid item xs={6} className='column right-side'>
                  <Grid className='baggage-container'>
                    {getBaggageIcons(elem)}
                  </Grid>
                  <Grid className='price-container column'>
                    <Typography>{t('results.flights.totalPrice')}</Typography>
                    <Typography className='price'>{`${elem.Currency} ${formatNumber(elem?.ExtendedFareInfo?.NetTotalAmountWithFee || elem?.TotalAmount || 0)} `}</Typography>
                  </Grid>
                </Grid>

                <IconButton className='close-button' size='small' onClick={() => handleClickCompareFlight(elem, false)}>
                  <Close fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          })}
        </Grid>
      </Container>
    </Grid>
  }

  const handleOpenFilters = () => {
    setOpenFilters(true);
  }

  const handleCloseFilters = () => {
    setOpenFilters(false);
  }

  return (
    <Grid className='flights-results results-page'>
      <Grid className='search-container'>
        <Multibuscador
          moduleName='flights'
          defaultData={dataForm}
          isResultsView
          allDestinations={allDestinations}
        />
      </Grid>

      {loading && (
        <Preloader
          addDots
          image={`sites/${client.client.name}/preloadAereos.gif`}
          text={getLoadingText()}
        />
      )}

      <Container>
        {clientData?.client?.isB2C && (
          <BannerResults banners={bannersData?.data?.modules?.AEREOS?.flows?.results?.banners?.TitleResults} />
        )}

        {!loading && noResults ? (
          <Alert severity='error' className='alert-no-results'>
            <Typography className='title'>{t('results.flights.noFlights')}</Typography>
            <Typography className='subtitle'>{t('results.flights.otherSearch')}</Typography>
          </Alert>
        ) : (
          <>
            {(!showComparation || resultsToCompare.length === 0) ? (
              <Grid className='results-container'>
                {!loading && !clientData?.client?.isB2C && (
                  <Typography className='title'>
                    {t('results.flights.availableFlights', {
                      flights: results?.Fares?.length || 0,
                      destination: allDestinations.find(elem => elem.iata === dataForm?.segments?.slice(-1)?.[0]?.destination?.iata)?.label
                    })}
                    &nbsp;{!results?.Fares || results?.Fares?.length === 0
                      ? ''
                      : t('results.flights.fromPrice', { currency: currency, price: formatNumber(getMinimunPrice()) })
                    }
                  </Typography>
                )}

                <Grid container columnSpacing={3} rowSpacing={2}>
                  <Hidden lgDown>
                    <Grid item lg={3}>
                      <FiltersComponent
                        filters={filters}
                        loading={loading || loadingFilters}
                        dataForm={dataForm}
                        currency={currency}
                        handleChangeFilters={(data) => handleChangeFilters(data, hashFlow)}
                      />
                    </Grid>
                  </Hidden>

                  <Grid item xs={12} lg={9}>
                    {params.threeDays === '1' && (
                      <ThreeDaysTable
                        client={client}
                        loadingResults={loading}
                      />
                    )}

                    <Hidden smDown>
                      <RatesByAirline
                        results={initialResults || results}
                        filters={filters}
                        dataForm={dataForm}
                        handleChangeFilters={(data) => handleChangeFilters(data, hashFlow)}
                      />
                    </Hidden>

                    <Hidden lgUp>
                      <Grid className='row sort-filter-container'>
                        <Select
                          size='small'
                          className='sort-input'
                          value={activeSort?.name}
                          onChange={(e) => handleChangeSort(e.target.value)}
                          startAdornment={
                            <InputAdornment position='start'>
                              <Sort />
                            </InputAdornment>
                          }
                          IconComponent={() => <KeyboardArrowDown className='arrow-icon' />}
                        >
                          {SORTING_OPTIONS[clientData?.client?.isB2C ? 'b2c' : 'b2b'].map((option, i) => (
                            <MenuItem value={option.name} key={i}>{t(`results.flights.${option.text}`)}</MenuItem>
                          ))}
                        </Select>

                        <Button
                          variant='outlined'
                          className='filter-input'
                          endIcon={<KeyboardArrowDown className='arrow-icon' />}
                          onClick={handleOpenFilters}
                        >
                          {t('common.filter')}
                        </Button>

                        <Drawer
                          variant='temporary'
                          open={openFilters}
                          onClose={handleCloseFilters}
                        >
                          <FiltersComponent
                            filters={filters}
                            loading={loading || loadingFilters}
                            dataForm={dataForm}
                            currency={currency}
                            handleChangeFilters={(data) => handleChangeFilters(data, hashFlow)}
                            handleCloseFilters={handleCloseFilters}
                          />
                        </Drawer>
                      </Grid>
                    </Hidden>

                    <ResultsComponent
                      client={client}
                      results={results}
                      loading={loading}
                      module={module.module}
                      activeSort={activeSort}
                      resultsToCompare={resultsToCompare}
                      handleChangeSort={handleChangeSort}
                      handleClickCompareFlight={handleClickCompareFlight}
                    />
                  </Grid>
                </Grid>

                {resultsToCompare.length > 0 && getComparatorContainer()}
              </Grid>
            ) : (
              <Grid className='comparation-component'>
                <Button className='button row' onClick={() => setShowComparation(false)}>
                  <ArrowBack />{t('results.flights.backResults')}
                </Button>

                <ComparationComponent
                  client={client}
                  destination={allDestinations.find(elem => elem.iata === dataForm?.segments?.slice(-1)?.[0]?.destination?.iata)?.label}
                  dataForm={dataForm}
                  resultsToCompare={resultsToCompare}
                  hashFlow={hashFlow}
                  flights={results}
                  handleGoBack={() => setShowComparation(false)}
                // handleClickFlight={handleClickFlight}
                // handleClickAddFlight={hotel => handleClickCompareFlight(hotel, true)}
                // handleClickRemoveFlight={hotel => handleClickCompareFlight(hotel, false)}
                />
              </Grid>
            )}
          </>
        )}
      </Container>
    </Grid>
  );
}

const mapStateToProps = reducers => {
  return reducers.flightsReducer;
};

const mapDispatchToProps = dispatch => {
  return {
    searchProducts: (token, dataForm, signalAbort = null) => dispatch(searchActionFlights(token, dataForm, signalAbort)),
    getFiltersProducts: (query, signalAbort) => dispatch(getFiltersActionFlights(query, signalAbort)),
    filterProducts: (query, signalAbort) => dispatch(filterActionFlights(query, signalAbort)),
    reportMetricsProducts: (token, tokenProduct, data, signalAbort = null) => dispatch(reportMetricsActionFlights(token, tokenProduct, data, signalAbort))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultsView);
