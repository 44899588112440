import React, { Fragment, Suspense, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button, Card, CardHeader, Grid, Typography } from '@mui/material'
import { ChevronRight } from '@mui/icons-material';
import Image from '../../../common/Image';
import { getBookingAction } from '../../../../store/actions';
import { connect } from 'react-redux';
import { IconPaymentMethod } from './utils';
import ModalPaypal from './ModalPaypal';
import { useParams } from 'react-router-dom';
import { usePaymentContext } from '../../../../context/PaymentContext';
import { DatalayerSeleccionarFormaPago } from '../../../../store/services/DatalayerGeneral';
import PreloadStep from '../PreloadStep';

const PaymentMethod = forwardRef((props, ref) => {
  const { data: { code, form, displayName, icon }, index } = props;
  const componentPaymentRef = useRef();
  const params = useParams();
  const { paymentSelected, setPaymentSelected } = usePaymentContext();
  const [openModalPaypal, setOpenModalPaypal] = useState(false);

  useImperativeHandle(ref, () => ({
    handleSubmit: handleSubmit,
  }));

  useEffect(() => {
    if (paymentSelected?.index === index && form === 'paypal') {
      setOpenModalPaypal(true);
    } else {
      setOpenModalPaypal(false);
    }

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentSelected]);

  const handleSubmit = () => {
    if (componentPaymentRef) {
      const paymentResponse = componentPaymentRef?.current?.handleSubmit();
      return { status: paymentResponse?.status, data: paymentResponse?.data };
    }

    return { status: false, data: {} };
  }

  const showPaymentForm = (index) => {
    const allowFormSelected = { index, payment: props.data };
    setPaymentSelected(allowFormSelected);

    const existingScript = document.getElementById('deviceId_fp');
    if (existingScript) {
      existingScript.remove();
    }

    // start - send Datalayer Forma Pago
    DatalayerSeleccionarFormaPago(
      "addPaymentInfo",
      'flights',
      props.data.displayName,
      null);
    // end - send Datalayer Forma Pago
  }

  const getPaymentForm = () => {
    const showForm = paymentSelected
      && paymentSelected.index === index
      && form !== 'paypal';

    if (showForm) {
      const payment = form.charAt(0).toUpperCase() + form.slice(1);
      const Component = React.lazy(() => import(`./${payment}`));

      return <Suspense
        fallback={<PreloadStep />}
      >
        <Component
          ref={componentPaymentRef}
          payment={form}
          restartPayment={() => setPaymentSelected(null)}
          // info={info}
          data={props.data}
          previewsStepsData={props.previewsStepsData}
          externalId={props.externalId}
        // dataStep={props.dataStep}
        // products={products}
        // price={props.price}
        />
      </Suspense>
    }
  }

  const handleCompletePaymentPaypal = async (paypalData) => {
    props.handleNextStep && props.handleNextStep(false, paypalData);
  }

  const getPaymentIcon = () => {
    if (icon) {
      return icon;
    }

    if (form === 'creditCard' && code?.includes('2')) {
      return `${form}_2`;
    }

    return form;
  }

  return (
    <Fragment>
      {(paymentSelected === null || paymentSelected?.payment?.form === 'paypal') && (
        <Card className='payment-method-card'>
          <Button onClick={() => showPaymentForm(index)}>
            <CardHeader
              className='payment-method-card-header'
              avatar={<IconPaymentMethod icon={getPaymentIcon()} />}
              title={<Grid className='row'>
                <Typography variant='h3'>{displayName}</Typography>
                {form === 'paypal' && (
                  <Image className='bank-image' img={`banks/paypal-small.png`} alt='Pago PayPal' />
                )}
              </Grid>}
              action={<ChevronRight />}
            />
          </Button>
        </Card>
      )}

      {getPaymentForm()}

      <ModalPaypal
        data={props.data}
        openModal={openModalPaypal}
        module={params.module}
        timer={props.timer}
        expired={props.expired || false}
        onClose={() => setPaymentSelected(null)}
        onApprove={(data) => handleCompletePaymentPaypal(data)}
      />
    </Fragment>
  )
});

const mapDispatchToProps = dispatch => {
  return {
    getBooking: (access, tokenCheckout, data) => dispatch(getBookingAction(access, tokenCheckout, data))
  };
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(PaymentMethod);
