import React from 'react';
import { Container, Grid, Typography, Link, Box, } from '@mui/material';
import { useBannersLandingsData } from '../../context/BannersLandingsContext';
import Image from '../common/Image';
import IconComponent from '../common/IconComponent';
import RegretContainer from '../common/RegretContainer';

const Footer = ({ client }) => {
  const { bannersData } = useBannersLandingsData();

  const getLogo = () => {
    const businessUnit = JSON.parse(localStorage.getItem('businessUnit') || '{}');
    const workUnit = JSON.parse(localStorage.getItem('workUnit') || '{}');
    const bannerLogo = bannersData?.data?.modules?.['otros']?.flows?.footer?.banners?.Logo?.slice(-1)?.[0]?.image_url;
    const logo = bannerLogo || businessUnit?.logo?.src || workUnit?.logo?.src;
    if (logo) {
      return <Image img={logo} alt='logo-cliente' className='logo' isFullPath />
    }
  }

  return (
    <section className={`footer footer-checkout footer-${client.client.name}`}>
      {client?.footer?.containersCheckout && client?.footer?.containersCheckout?.map((container, containerIndex) => (
        <div key={containerIndex} style={container?.styles || {}}>
          <Container className={container.class || ""}>
            {(container.title || container.title === 'blank') && (
              <Typography variant='h4' className='title-container' mb={2}>
                {container.icon && <IconComponent iconName={container.icon} />} {container.title !== 'blank' ? container.title : <>&nbsp;</>}
              </Typography>
            )}
            <Grid container item columns={24} spacing={2}>
              {container.columns.map((column, colIndex) => (
                <Grid
                  item
                  xs={24}
                  sm={column.sm}
                  md={column.md}
                  lg={column.lg}
                  xl={column.xl}
                  key={colIndex}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: column.alignment || 'flex-start',
                    justifyContent: column.justifyContent || 'normal'
                  }}
                >
                  <div className={column.class ? column.class : ''} style={{ width: '100%' }}>
                    {column.rows.map((row, rowIndex) => (
                      <Box key={rowIndex} className={row.class}>
                        {row.class === 'regret' ? (
                          <RegretContainer
                            title={row.title}
                            content={row.content}
                            link={row.link}
                          />
                        ) : (
                          <>
                            {(row.title || row.title === 'blank') && (
                              <Typography variant="h5" className='title' sx={{ display: 'flex' }} mt={rowIndex !== 0 ? 2 : 0}>
                                {row.icon && <IconComponent iconName={row.icon} />} {row.title !== 'blank' ? row.title : <>&nbsp;</>}
                              </Typography>
                            )}

                            {row.subtitle && (
                              <Typography variant="h6" className='subtitle' sx={{ display: 'flex'}} mt={rowIndex !== 0 ? 2 : 0}>
                                {row.icon && <IconComponent iconName={row.icon} />} {row.subtitle}
                              </Typography>
                            )}

                            {row.content && row.content.map((text, textIndex) => (
                              <Typography key={textIndex}>
                                {row.icon && <IconComponent iconName={row.icon} />} {text}
                              </Typography>
                            ))}

                            {row.submenu && (
                              <Box className="row-submenu" mt={rowIndex !== 0 ? 2 : 0}>
                                {row.submenu.map((item, itemIndex) => {
                                  if (item.link) {
                                    return (
                                      <Link key={itemIndex} href={item.link} target='_blank' display="block" gutterBottom className='row'>
                                        {item.icon && <IconComponent iconName={item.icon} />} {item.clave}
                                      </Link>
                                    )
                                  } else {
                                    return (
                                      <Typography key={itemIndex} display="block" gutterBottom>
                                        {item.icon && <IconComponent iconName={item.icon} />} {item.clave}
                                      </Typography>
                                    )
                                  }
                                })}
                              </Box>
                            )}

                            {row.images && row.class === "logo" ? (
                              getLogo()
                            ) : row.images && (
                              <Box className="row-images">
                                {row.images.map((image, imageIndex) => (
                                  image.link && image.link !== "" ? (
                                    <Link href={image.link} key={imageIndex} target='_blank'>
                                      <Image
                                        className='mr-05'
                                        img={image.file}
                                        isFullPath={image.fullPath || false}
                                        alt={image.alt}
                                        hideBrokenImage
                                      />
                                    </Link>
                                  ) : (
                                    <Image
                                      className='mr-05'
                                      key={imageIndex}
                                      img={image.file}
                                      isFullPath={image.fullPath || false}
                                      alt={image.alt}
                                      hideBrokenImage
                                    />
                                  )
                                ))}
                              </Box>
                            )}
                          </>
                        )}
                      </Box>
                    ))}
                  </div>
                </Grid>
              ))}
            </Grid>
          </Container>
        </div>
      ))}
    </section>
  );
}

export default Footer;
