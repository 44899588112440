import React, { useEffect, useState }  from 'react';
import Header from './Header';
import Footer from './Footer';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Steps from '../pages/Checkout/Steps';
import { useClientData } from '../../context/ClientContext';

const PRODUCTS = {
  hotels: ['hotel_detail'],
  trains: ['train_detail_segment', 'train_detail_passes'],
  flights: ['air_detail']
};

function LayoutCheckout({
  children,
  client,
  reservations,
  selectedReservation,
  isIframe,
  setReservations,
  handleSelectReservation,
  ...props
}) {
  const params = useParams();
  const { clientData, currentProduct, updateProductToken } = useClientData();
  const [user, setUser] = useState();

  useEffect(() => {
    const user = localStorage.getItem('user');
    setUser(user ? JSON.parse(user) : null);

    if (Object.keys(currentProduct || {}).length === 0 && PRODUCTS[params.module]) {
      updateProductToken(PRODUCTS[params.module]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`wrapper checkout site-${client.client.name}`}>
      <Header
        client={client}
        user={user}
        reservations={reservations}
        selectedReservation={selectedReservation}
        isIframe={isIframe}
        setReservations={setReservations}
        handleSelectReservation={handleSelectReservation}
      />

      <Steps
        client={client}
        selectedReservation={selectedReservation}
      />

      <Footer client={client} />
    </div>
  );
}

const mapStateToProps = reducers => {
  return reducers.checkoutReducer;
};

const mapDispatchToProps = dispatch => {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutCheckout);
