import React, { useCallback } from 'react';
import { Grid, Typography, Button, Paper, Icon, Hidden } from '@mui/material';
import DetailItem from './cardDetails/DetailItem';
import DialogCrossell from './DialogCrossell';
import { GetMessageConditions } from '../CardDetailMessages';
import DialogModal from '../DialogModal';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BaggageImage } from '../../../../assets/images/baggage/baggage.svg';
import { Add, MedicalServicesOutlined, TipsAndUpdates } from '@mui/icons-material';
import { useClientData } from '../../../../context/ClientContext';
import { getCurrency } from '../../../../utils/price';

const BRANDED_CLASSES = {
  Y: { value: 'economy', valueB2C: 'economyB2C' },
  P: { value: 'premium' },
  W: { value: 'premium' },
  B: { value: 'business' },
  F: { value: 'firstClass' }
};

const CrossellResume = ({
  initOpen,
  resumenCrossell,
  item,
  crossellData,
  onSelectCrossell,
  onRemoveCrossell
}) => {
  const { t } = useTranslation();
  const { clientData, currencies } = useClientData();

  const ButtonDetail = () => (
    <span>{resumenCrossell?.cardDetail?.buttons?.[0]?.detail?.text}</span>
  );

  const ButtonRemove = () => (
    <Button
      fullWidth
      className='button-remove-product'
      color='secondary'
      variant='outlined'
      startIcon={<Icon>remove_outline</Icon>}
      onClick={onRemoveCrossell}
    >
      {resumenCrossell?.cardDetail?.buttons?.[0]?.remove?.text}
    </Button>
  );

  const ButtonChange = () => (
    <Button
      fullWidth
      className='button-change-product'
      color='secondary'
      variant='outlined'
      startIcon={<Icon>edit</Icon>}
    >
      {resumenCrossell?.cardDetail?.buttons?.[0]?.change?.text}
    </Button>
  );

  const getCrosselIcon = useCallback((module) => {
    switch (module) {
      case 'brandedFares':
        return <BaggageImage className='baggage-image' />;
      case 'assistmed':
        return <MedicalServicesOutlined />;
      default:
        return null;
    }
  }, []);

  const DetailModal = useCallback(() => (
    <DialogModal
      id={item.template === 'default' ? 'cardProductDialogSingleCard' : 'cardProductDialog'}
      fullWidth
      maxWidth='md'
      endIcon={<Add />}
      className='card-product'
      button={<ButtonDetail />}
      header={<Typography variant='h4' className='dialog-card-product-title'>
        {getCrosselIcon(module)}
        {resumenCrossell?.cardDetail?.title}
      </Typography>}
      content={<div className='dialog-card-product-content'>
        {resumenCrossell?.cardDetail?.providerImage && (
          <div className='img-provider'>
            <img src={resumenCrossell?.cardDetail.providerImage} alt={resumenCrossell?.cardDetail?.provider} />
          </div>
        )}
        {resumenCrossell?.cardDetail?.product && (
          <Typography variant='h3' className='product-card-title'>{resumenCrossell?.cardDetail.product}</Typography>
        )}
        {resumenCrossell?.cardDetail?.secondLine && (
          <Typography component='p' variant='body2'>{resumenCrossell?.cardDetail.secondLine}</Typography>
        )}
        {resumenCrossell?.cardDetail?.detailItems.map((item, i) => (
          <DetailItem
            key={i}
            item={item}
            template={resumenCrossell?.cardDetail?.template}
            showDetailSearch
            showDetailProductFull
          />
        ))}
        <GetMessageConditions messages={resumenCrossell?.cardDetail?.messages} />
      </div>}
    />
  ), [resumenCrossell, item, getCrosselIcon]);

  if (resumenCrossell) {
    const {
      cardDetail: {
        template,
        providerImage,
        provider,
        product,
        secondLine,
        detailItems,
        messages,
        cabinClass,
        buttons
      },
      priceDetail: {
        currencyCode,
        amount,
        priceOffer,
        promoLabel,
        pricePerPerson
      }
    } = resumenCrossell;
    const module = resumenCrossell?.module || resumenCrossell?.cardDetail?.module;

    const ResumeDefaultProduct = () => {
      const currency = getCurrency(clientData?.client, currencies, currencyCode);

      return (
        <Paper className='paper crossell-resume crossell-resume-single-card'>
          <Grid container spacing={1}>
            <Grid item sm={8}>
              <Hidden mdUp>{providerImage && <div className='img-provider'><img src={providerImage} alt={provider} /></div>}</Hidden>
              <Typography className='title-product' component='h3' variant='h3'>{product}</Typography>
              {detailItems.map((item, i) => <DetailItem key={i} item={item} template={template} detailButton={<DetailModal />} />)}
              <GetMessageConditions messages={messages} />
            </Grid>
            <Grid item sm={4}>
              <div className={'col-right text-right ' + (promoLabel ? 'has-promo' : '')}>
                {promoLabel && <div className='promo-label'><Icon>local_offer</Icon> <span>{promoLabel}</span></div>}
                <Grid container>
                  <Grid item xs={6} md={12}>
                    <div className={`detail-price-product ${priceOffer ? 'has-price-offer' : ''}`}>
                      <Hidden smDown>
                        {providerImage && (<div className='img-provider'><img src={providerImage} alt={provider} /></div>)}
                      </Hidden>
                      <Typography className='second-line' component='p' variant='body1'>{secondLine}</Typography>
                      {priceOffer && <span className='price-offer'>{currency} {priceOffer}</span>}
                      <span className='product-price'>{currency} {amount}</span>
                      {pricePerPerson && <span className='price-perperson'>{currency} {pricePerPerson} por persona</span>}
                    </div>
                  </Grid>
                  <Grid item xs={6} md={12}>
                    <ButtonRemove />
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Paper>
      )
    };

    const ResumeBrandedFares = () => {
      const brandedClass = BRANDED_CLASSES[cabinClass]
      const value = clientData?.client?.isB2C && brandedClass.valueB2C ? brandedClass.valueB2C : brandedClass.value;
      const secondLine = value ? `${t('results.flights.class')} ${t(`results.flights.${value}`)}` : '';

      return <Paper className='paper crossell-resume crossell-resume-branded-fares'>
        {promoLabel && (
          <div className='promo-label'>
            <div className='content-label'>
              <div className='text'>{promoLabel}</div>
              <div className='triangle'></div>
            </div>
          </div>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography className='title-product' component='h3' variant='h3'>
              {t('checkout.crossell.selectedOption')}:&nbsp;
              <span className='title'>{product}&nbsp;</span>
              <span className='subtitle'>({secondLine})</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {detailItems.map((detailItem, i) =>
              <DetailItem
                key={i}
                item={detailItem}
                template={template}
                dialogCrossell={item.cardProducts?.length > 1 &&
                  <DialogCrossell
                    fullWidth
                    maxWidth='md'
                    initOpen={initOpen}
                    crossellData={crossellData}
                    crosselFormName='brandedFares'
                    products={item.cardProducts}
                    header={<div className='modal-title-container row'>
                      <Hidden smDown>
                        {getCrosselIcon(module)}
                      </Hidden>
                      <div>
                        <Typography className='modal-title'>{item.titleModal || item.title}</Typography>
                        <Typography className='modal-subtitle'>{item.subtitleModal}</Typography>
                      </div>
                    </div>}
                    onSelectCrossell={onSelectCrossell}
                  >
                    <Grid>
                      <ButtonChange />
                      <Grid className='row recommended'>
                        <TipsAndUpdates />
                        {t('checkout.flights.recommended')}
                      </Grid>
                    </Grid>
                  </DialogCrossell>
                }
              />
            )}
            <GetMessageConditions messages={messages} />
          </Grid>
        </Grid>
      </Paper>
    };

    const ResumeSingleCardProduct = () => {
      const currency = getCurrency(clientData?.client, currencies, currencyCode);

      return (
        <Paper className={`paper crossell-resume crossell-resume-default crossell-resume-${module}`}>
          {promoLabel && (
            <div className='promo-label'>
              <div className='content-label'>
                <div className='text'>{promoLabel}</div>
                <div className='triangle'></div>
              </div>
            </div>
          )}
          <Grid container spacing={1}>
            <Grid item xs={12} sm={8} className='col-left'>
              <Typography component='h3' variant='h3' className='product-card-title'>
                {product}
              </Typography>
              <Typography component='p' variant='body1' className='product-card-subtitle'>
                {secondLine}
              </Typography>
              {detailItems.map((item, i) => (
                <DetailItem
                  key={i}
                  item={item}
                  template={template}
                />
              ))}
              {buttons?.[0]?.detail &&
                <div className='container-button-modal'>
                  <DetailModal />
                </div>
              }
            </Grid>
            <Grid item xs={12} sm={4} className='col-right'>
              <div className={'text-center ' + (promoLabel ? 'has-promo' : '')}>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <div className={`detail-price-product ${priceOffer ? 'has-price-offer' : ''}`}>
                      {providerImage && (
                        <div className='img-provider'>
                          <img src={providerImage} alt={provider} />
                        </div>
                      )}
                      {priceOffer && <span className='price-offer'>{currency} {priceOffer}</span>}
                      <span className='product-price'>
                        <span className='currency'>{currency}</span>
                        <span className='amount'>{amount}</span>
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}><ButtonRemove /></Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Paper>
      );
    };

    return (
      <section>
        {item.template === 'default' ? (
          <ResumeDefaultProduct />
        ) : (
          module === 'brandedFares' ? <ResumeBrandedFares /> : <ResumeSingleCardProduct />
        )}
      </section>
    )
  }
}

export default CrossellResume;
