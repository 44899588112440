const FEATURES = {
  CABIN_BAG: {
    CABIN_BAG: {
      all: {
        included: ['all']
      }
    },
  },
  CHECKED_BAG: {
    CHECKED_BAG: {
      all: {
        included: ['all']
      }
    },
  },
  SEAT_SELECTION: {
    SEAT_SELECTION: {
      all: {
        included: ['all']
      }
    },
  },
  CHANGEABLE: {
    CHANGEABLE: {
      all: {
        included: ['all']
      }
    },
  },
  REFUNDABLE: {
    REFUNDABLE: {
      all: {
        included: ['all']
      }
    },
  },
  FREE_TEXT: {
    CABIN_BAG: {
      AV: {
        included: [
          'EQUIPAJE DE MANO'
        ],
        not_included: [
          '$',
          'NO INCLUIDO',
          'NO APLICA'
        ]
      }
    },
    CHANGEABLE: {
      AV: {
        included: [
          'CAMBIOS ANTES DE LA SALIDA DEL VUELO',
          'PERMITIDO'
        ],
        not_included: [
          'NO PERMITIDO'
        ]
      }
    },
    CHECKED_BAG: {
      AV: {
        included: [
          'EQUIPAJE DE BODEGA'
        ],
        not_included: [
          '$',
          'NO INCLUIDO',
          'NO APLICA'
        ]
      }
    },
    REFUNDABLE: {
      AV: {
        included: [
          'REEMBOLSOS ANTES DE LA SALIDA DEL VUELO',
          'PERMITIDO'
        ],
        not_included: [
          'NO PERMITIDO'
        ]
      }
    },
    SEAT_SELECTION: {
      AV: {
        included: [
          'SELECCION',
          'ASIENTO'
        ],
        not_included: [
          '$',
          'NO INCLUIDO',
          'NO APLICA'
        ]
      }
    }
  },
  OTHER: {
    CABIN_BAG: {
      all: {
        included: [
          'EQUIPAJE DE MANO'
        ]
      }
    },
  }
};

const PENALTY = [
  'WITH COST',
  'PENALIDAD'
];

export {
  FEATURES,
  PENALTY
}
