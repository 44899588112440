import React, { Fragment, useEffect, useState } from 'react'
import { Container, Grid } from '@mui/material';
import StatusMessage from '../StatusMessage';
import CardStatusTransaction from '../CardStatusTransaction';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ERROR } from './utils';
import { connect } from 'react-redux';
import { resetReservationAction } from '../../../../store/actions';
import { useClientData } from '../../../../context/ClientContext';
import { DatalayerPurchaseError } from '../../../../store/services/DatalayerGeneral';

const Error = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { clientData } = useClientData();
  const [content, setContent] = useState({});

  useEffect(() => {

    // start - send Datalayer Error purchase
    DatalayerPurchaseError(
      "purchaseError",
      'flights',
      "error",
      params.operationNumber);
    // end - send Datalayer Error purchase

    const { content } = location?.state?.errorTimeout
      ? {
        content: {
          statusMessage: 'attention',
          title: 'errorBookingProccess',
          icon: 'error',
          status_booking_icon: 'error',
          status_booking_title: 'rejected',
          status_booking: 'ko',
          show_idTransaction: false,
          show_status_booking: false,
          show_status_payment: false,
          messages: [
            {
              subtitle: 'checkRealStatusConfirm',
              hideInfoIcon: true,
              addNewSearchButton: false
            }
          ]
        }
      }
      : ERROR[params.module] || { content: {} };
    setContent(content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CardContentStatusTransaction = () => (
    <CardStatusTransaction
      reservationNumber={params.reservationNumber}
      content={content}
      handleSelectReservation={handleSelectReservation}
    />
  )

  const getRedirectUrl = () => {
    if (location?.state?.redirectUrl) {
      return location.state.redirectUrl;
    }
    const module = clientData?.modules && Array.isArray(clientData?.modules) && clientData?.modules?.filter(elem => elem.module === params.module)?.slice(-1)?.[0];
    return module?.path || '/';
  }

  const handleSelectReservation = () => {
    localStorage.removeItem('selectedReservation');
    props.resetReservation();
    navigate(getRedirectUrl());
  }

  return (
    <Container className='booking-status-ko'>
      <Grid container spacing={2}>
        <Grid item md={8} className='col-content'>
          <StatusMessage
            title={content.title}
            icon={content.icon}
            statusMessage={content.statusMessage}
            cardContentTop={<Fragment />}
            cardContentStatusTransaction={<CardContentStatusTransaction />}
            cardContentMessage={<Fragment />}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    resetReservation: () => dispatch(resetReservationAction())
  };
}

export default connect(null, mapDispatchToProps)(Error);
