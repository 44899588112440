import React, { useEffect, useRef, useState }  from 'react';
import Header from './Header';
import Footer from './Footer';
import { KeyboardDoubleArrowUp } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Newsletter from '../common/Newsletter';
import { useLocation, useNavigate } from 'react-router-dom';
import { LayoutProvider } from '../../context/LayoutContext';
import Landing from '../pages/Landing/Landing';
import { getLandingByURL, getLandingByURLNoLogin } from '../../store/services/Login';
import { useClientData } from '../../context/ClientContext';
import MultibuscadorSkeleton from '../common/Multibuscador/MultibuscadorSkeleton';

function Layout({
    children,
    client,
    reservations,
    isIframe,
    loadingProducts,
    routes,
    redirectUrl,
    selectedReservation,
    setReservations,
    handleSelectReservation
}) {
    const buttonRef = useRef();
    const prevLoadingProducts = useRef(loadingProducts);
    const location = useLocation();
    const navigate = useNavigate();
    const { setClientData } = useClientData();
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(!Boolean(prevLoadingProducts?.current));
    const [landing, setLanding] = useState();

    useEffect(() => {
        window.onscroll = () => scrollButtonStyle();
    }, []);

    useEffect(() => {
        if (prevLoadingProducts.current && !loadingProducts && redirectUrl) {
            navigate(redirectUrl);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingProducts])

    useEffect(() => {
        if (location.pathname.includes('login') || location.pathname.includes('register') || location.pathname.includes('condiciones-generales'))  {
            setUser(null);
        } else if (!user) {
            const userAux = localStorage.getItem('user');
            setUser(userAux ? JSON.parse(userAux) : null);
        }

        if (!loadingProducts) {
            if (location.pathname === '/') {
                navigate(redirectUrl);
            } else {
                ;(async () => {
                    const isLanding = routes.find(elem => {
                        const route = elem.path.split('/').slice(1 + (isIframe ? 1 : 0)).slice(0, (elem.fixedElements || 1)).join('/');
                        const pathname = location.pathname.split('/').slice(1 + (isIframe ? 1 : 0)).slice(0, (elem.fixedElements || 1)).join('/');
                        return route && pathname && route === pathname;
                    }) ? false : true;

                    if (isLanding) {
                        setLoading(true);
                        setLanding(null);
                        const pathname = location.pathname.slice(1);

                        let landingAux = client?.landings?.find(elem => {
                            let url = elem.landing_relation?.url;
                            if (url[0] === '/') {
                            url = url.slice(1)
                            }
                            return url === pathname;
                        });

                        if (!landingAux) {
                            try {
                                const token = localStorage.getItem('jwt');
                                landingAux = token
                                    ? await getLandingByURL(token, location.pathname.slice(1))
                                    : await getLandingByURLNoLogin(window.location.host, location.pathname.slice(1))
                                landingAux = landingAux[0];
                            } catch (e) {
                                landingAux = null;
                            }
                        }

                        if (landingAux) {
                            const clientData = {
                                ...client,
                                landings: [...(client.landing || []), landingAux]
                            };
                            setClientData(clientData);
                            setLanding(landingAux.landing_relation);
                            localStorage.setItem('clientData', JSON.stringify(clientData));
                        } else {
                            navigate(redirectUrl);
                        }
                    }

                    setLoading(false);
                })();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const scrollButtonStyle = () => {
        if (buttonRef.current) {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                buttonRef.current.classList.add('show');
            } else {
                buttonRef.current.classList.remove('show');
            }
        }
    }

    const scrollTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    if ((!loading || loadingProducts) && (!isIframe || window.location.pathname.split('/').length > 2)) {
        return (
            <LayoutProvider>
                <div className={`wrapper site-${client.client.name}`}>
                    <Header
                        client={client}
                        user={user}
                        reservations={reservations}
                        isIframe={isIframe}
                        landing={landing}
                        selectedReservation={selectedReservation}
                        setReservations={setReservations}
                        handleSelectReservation={handleSelectReservation}
                    />

                    <main>
                        {landing ? (
                            <Landing landing={landing} />
                        ) : (!loadingProducts && (client?.modules || !client.client?.isB2C))
                            ? children :
                            <MultibuscadorSkeleton />
                        }
                    </main>

                    {(!landing || landing.newsletter_visible) && client?.newsletter?.form && (
                        <Newsletter />
                    )}

                    <Footer client={client} />

                    <IconButton className='scroll-to-top-button' ref={buttonRef} onClick={scrollTop}>
                        <KeyboardDoubleArrowUp />
                    </IconButton>
                </div>
            </LayoutProvider>
        );
    }
}

export default Layout;
