import { Fragment } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, CardHeader, Grid, Hidden, Icon, Tooltip, Typography } from '@mui/material';
import FlightScales from '../../../modules/Flights/FlightScales';
import { AirlineSeatReclineNormal, AirplaneTicketOutlined, CalendarToday, ChangeCircleOutlined, CurrencyExchange, ExpandMore, Flight, Info } from '@mui/icons-material';
import Image from '../../../common/Image';
import { ReactComponent as SmallBaggage } from '../../../../assets/images/baggage/smallBaggage.svg';
import { ReactComponent as MediumBaggage } from '../../../../assets/images/baggage/mediumBaggage.svg';
import { ReactComponent as LargeBaggage } from '../../../../assets/images/baggage/largeBaggage.svg';
import { ReactComponent as MultiplesAirlines } from '../../../../assets/images/providersFlights/multiple.svg';
import InfoPopover from '../InfoPopover';
import dayjs from 'dayjs';
import { formatNumber } from '../../../../utils/price';
import CardBrandedFares from '../crossell/cardDetails/CardBrandedFares';

const CABIN_CLASS = {
  Y: { value: 'economy', valueB2C: 'economyB2C' },
  P: { value: 'premium' },
  W: { value: 'premium' },
  B: { value: 'business' },
  F: { value: 'firstClass' }
};
const DATE_FORMAT_INTERNATIONAL = 'YYYY-MM-DD';

const getTotalPrice = (purchaseDetail) => {
  const charges = purchaseDetail.priceDetail.flatMap(elem => elem.charge);
  const netAgency = charges.reduce((acc, current) => acc + (current?.valorReal || 0), 0);
  return formatNumber(netAgency, 2);
}

const getProductsDetails = (purchaseDetail) => {
  const charges = purchaseDetail?.priceDetail
    .flatMap(product => product.charge)
    .filter(charge => (
      charge.title.includes('Adulto')
      || charge.title.includes('Niño')
      || charge.title.includes('Infante')
    ));

  return charges.map((item, i) => (
    <Grid
      key={i}
      container
      className='item-resume'
    >
      <Grid item md={6}>
        <div className='container-item-resume row'>
          <span>{item.title}</span>
        </div>
      </Grid>
      <Grid item md={6}>
        <div className={`text-right`}>
          <span>
            {purchaseDetail?.currency} {formatNumber(item.valorReal, 2)}
          </span>
        </div>
      </Grid>
    </Grid>
  ));
}

const formatTitle = (title) => {
  if (!title) return '';
  const [firstWord, ...rest] = title.split(' ');
  return [firstWord[0].toUpperCase() + firstWord.slice(1).toLowerCase(), ...rest].join(' ');
};

const getProductsDetailsB2C = (product, currency, t) => {
  let flightPrice = [];
  let taxes = [];

  product?.priceDetail?.charge?.forEach(charge => {
    if (charge.title.includes('Adulto')
      || charge.title.includes('Niño')
      || charge.title.includes('Infante')
    ) {
      flightPrice.push(charge);
    } else {
      taxes.push(charge);
    }
  });

  const onlyArgentina = product?.cardDetail?.legs?.every(leg => {
    const firstSegment = leg?.Options?.[0]?.Segments?.[0];
    const lastSegment = leg?.Options?.[0]?.Segments?.[leg?.Options?.[0]?.Segments?.length - 1];
    return lastSegment?.Departure?.CountryName === 'Argentina' && firstSegment?.Arrival?.CountryName === 'Argentina';
  });

  const charges = [
    {
      title: formatTitle(product?.priceDetail?.title),
      icon: product?.cardDetail?.icon,
      value: flightPrice.reduce((current, acc) => current + (acc?.valorReal || 0), 0)
    },
    {
      title: t('checkout.flights.taxesIncluded'),
      value: taxes.reduce((current, acc) => current + (acc?.valorReal || 0), 0),
      charge: taxes,
      messages: (currency.text === 'ARS' && !onlyArgentina)
        ? [{ text: t('results.flights.includeTaxes'), className: 'taxes-info' }]
        : []
    }
  ];

  return charges.map((item, i) => (
    <Grid
      key={i}
      container
      className='item-resume'
    >
      <Grid item xs={8}>
        <div className='container-item-resume row'>
          <span className='row'>
            {item?.icon && <Icon className='product-icon'>{item?.icon}</Icon>}
            {item?.title}
          </span>
          {item?.charge && item.charge.length > 0 && (
            <InfoPopover
              className='info-charge'
              charge={item.charge}
              icon={product?.priceDetail?.icon}
              currency={currency.symbol}
              messages={item.messages || []}
            />
          )}
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={`text-right`}>
          <span>
            {currency.symbol}&nbsp;
            {formatNumber(item?.value, 2)}
          </span>
        </div>
      </Grid>
    </Grid>
  ))
}

const getPriceDetails = (client, purchaseDetail, isModal = false) => {
  const charges = purchaseDetail?.priceDetail.flatMap(elem => elem.charge) || [];
  let netAgency = charges.reduce((acc, current) => (
    acc + (current.priceDiffReal !== undefined ? current.priceDiffReal : current?.valorReal || current.amountReal || 0)
  ), 0);
  const taxes = charges.filter(elem => (
    !elem.title.includes('Adulto') && !elem.title.includes('Niño') && !elem.title.includes('Infante')
  ));
  const totalTickets = netAgency - taxes.reduce((acc, current) => acc + (current.valorReal || 0), 0);

  if (isModal) {
    return [
      { title: client?.isB2C ? 'totalPrice' : 'netAgency', value: formatNumber(netAgency, 2), realValue: netAgency, classname: 'strong amount', showTax: true }
    ]
  } else {
    return [
      { title: 'totalTickets', value: formatNumber(totalTickets, 2), realValue: totalTickets, classname: 'strong sub-amount' },
      ...taxes.map(elem => ({ title: elem.title, value: formatNumber(elem.valorReal, 2), realValue: elem.valorReal, noTranslate: true })),
      { title: client?.isB2C ? 'totalPrice' : 'netAgency', value: formatNumber(netAgency, 2), realValue: netAgency, classname: 'strong amount', showTax: true }
    ];
  }
}

const getCardDetail = (product, client, t, isFinalView = false) => {

  const getCabinClass = () => {
    const cabinClass = product?.cardDetail?.fareFeatures?.find(elem => elem.Code === 'CABIN_CLASS')?.Value
      || product?.cardDetail?.legs?.[0]?.Options?.[0]?.Segments?.[0]?.CabinClass;
    if (cabinClass && CABIN_CLASS[cabinClass]) {
      const value = client?.isB2C && CABIN_CLASS[cabinClass].valueB2C ? CABIN_CLASS[cabinClass].valueB2C : CABIN_CLASS[cabinClass].value;
      return `${t('results.flights.class')} ${t(`results.flights.${value}`)}`;
    }

    return '';
  }

  const getFareFeatures = () => {
    if (product?.cardDetail?.fareFeatures && !client.isB2C) {
      const allFeatures = {
        SEAT_SELECTION: { value: t('results.flights.withExtraCost') },
        CHANGEABLE: { value: t('results.flights.notAllowed') },
        REFUNDABLE: { value: t('results.flights.notRefundable') },
      };

      const seatSelection = product?.cardDetail?.fareFeatures?.filter(elem => elem.Code === 'SEAT_SELECTION');
      if (seatSelection.length > 0) {
        allFeatures['SEAT_SELECTION'].value = t('results.flights.included');
        allFeatures['SEAT_SELECTION'].className = 'included';
      }

      const changeable = product?.cardDetail?.fareFeatures?.filter(elem => elem.Code === 'CHANGEABLE');
      if (changeable.length > 0) {
        if (changeable.find(elem => elem?.Description?.includes('WITH COST'))) {
          allFeatures['CHANGEABLE'].value = t('results.flights.withExtraCost');
        } else {
          allFeatures['CHANGEABLE'].value = t('results.flights.withoutExtraCost');
          allFeatures['CHANGEABLE'].className = 'included';
        }
      }

      const refundable = product?.cardDetail?.fareFeatures?.filter(elem => elem.Code === 'REFUNDABLE');
      if (refundable.length > 0) {
        if (refundable.find(elem => elem?.Description?.includes('WITH COST'))) {
          allFeatures['REFUNDABLE'].value = t('results.flights.allowedWithPenalty');
        } else {
          allFeatures['REFUNDABLE'].value = t('results.flights.allowedWithoutPenalty');
          allFeatures['REFUNDABLE'].className = 'included';
        }
      }

      return <Grid container>
        <Grid xs={12} md={4} className='row fare-feature'>
          <AirlineSeatReclineNormal className={allFeatures['SEAT_SELECTION'].className || ''} />
          <Typography><b>{t('results.flights.seatSelection')}:</b> {allFeatures['SEAT_SELECTION'].value}</Typography>
        </Grid>

        <Grid xs={12} md={3} className='row fare-feature'>
          <ChangeCircleOutlined className={allFeatures['CHANGEABLE'].className || ''} />
          <Typography><b>{t('results.flights.changeable')}:</b> {allFeatures['CHANGEABLE'].value}</Typography>
        </Grid>

        <Grid xs={12} md={5} className='row fare-feature'>
          <CurrencyExchange className={allFeatures['REFUNDABLE'].className || ''} />
          <Typography><b>{t('results.flights.refundable')}:</b> {allFeatures['REFUNDABLE'].value}</Typography>
        </Grid>
      </Grid>
    }
  }

  const getScalesTooltip = (segments) => {
    if (segments.length === 1) {
      return '';
    }

    return <>
      {segments.slice(1).map((segment, i) => (
        <Grid key={i}>
          <Typography><b>{segment.Departure.CountryName} ({segment.Departure.CityCode})</b></Typography>
          <Typography>
            {t('results.flights.scaleWait', {
              city: segment.Departure.CityName,
              hours: segment.WaitHours,
              minutes: segment.WaitMinutes
            })}
          </Typography>
        </Grid>
      ))}
    </>
  }

  const getSegmentsDuration = (segments) => {
    return <>
      {segments.map((segment, i) => {
        const hours = Math.floor(segment.Duration / 60);
        const minutes = segment.Duration % 60;

        return <Fragment key={i}>
          {i > 0 && (
            <Grid className='row' justifyContent='space-between'>
              <Typography style={{ marginRight: 20 }}>
                {t('results.flights.scaleIn', { airport: segment.Departure.AirportCode })}
              </Typography>
              <Typography>{segment.WaitHours}h {segment.WaitMinutes}m</Typography>
            </Grid>
          )}

          <Grid className='row' justifyContent='space-between'>
            <Typography style={{ marginRight: 20 }}>
              <b>{segment.Departure.AirportCode} - {segment.Arrival.AirportCode}</b>
            </Typography>
            <Typography><b>{hours}h {minutes}m</b></Typography>
          </Grid>
        </Fragment>
      })}
    </>
  }

  const getDaysAfterTooltip = (days) => {
    return days === 1
      ? t('results.flights.dayAfter')
      : t('results.flights.daysAfter', { days });
  }

  const getBaggages = (option, vertical = true) => {
    const fareFeatures = product?.cardDetail?.fareFeatures;
    const brandedFare = product?.brandedFare?.cardDetail?.detailItems?.[0]?.details?.detailProduct;
    const baggages = [
      {
        icon: <SmallBaggage />,
        title: t('results.flights.smallBaggageTitle'),
        description: t('results.flights.smallBaggageDescription')
      }
    ];

    let mediumBaggage = {
      icon: <MediumBaggage className='not-included' />,
      title: t('results.flights.mediumBaggageNotIncluded')
    };

    let largeBaggage = {
      icon: <LargeBaggage className='not-included' />,
      title: t('results.flights.largeBaggageNotIncluded')
    };

    if (brandedFare) {
      const mediumBaggageIncluded = brandedFare.find(elem => elem.title === 'mediumBaggage' && elem.className === 'included');
      const largeBaggageIncluded = brandedFare.find(elem => elem.title === 'largeBaggage' && elem.className === 'included');
      if (mediumBaggageIncluded) {
        mediumBaggage = {
          icon: <MediumBaggage />,
          title: t('results.flights.mediumBaggageTitle'),
          description: t('results.flights.mediumBaggageDescription')
        };
      }
      if (largeBaggageIncluded) {
        largeBaggage = {
          icon: <MediumBaggage />,
          title: t('results.flights.mediumBaggageTitle'),
          description: `${largeBaggageIncluded.description} ${t('results.flights.largeBaggageDescription')}`
        };
      }
    } else if (fareFeatures) {
      const mediumBaggageIncluded = fareFeatures?.find(elem => elem.Code === 'CABIN_BAG');
      if (mediumBaggageIncluded) {
        mediumBaggage = {
          icon: <MediumBaggage />,
          title: t('results.flights.mediumBaggageTitle'),
          description: t('results.flights.mediumBaggageDescription')
        };
      }

      const largeBaggageIncluded = fareFeatures?.filter(elem => elem.Code === 'CHECKED_BAG');
      if (largeBaggageIncluded.length > 0) {
        const description = [];

        product?.cardDetail?.paxFares?.forEach(elem => {
          const passenger = elem.PaxType === 'ADT'
            ? t('results.flights.adult')
            : elem.PaxType === 'CHD'
              ? t('results.flights.kid')
              : t('results.flights.baby');
          const pieces = largeBaggageIncluded.length;
          description.push(t(
            `results.flights.${pieces > 1 ? 'piecesPerPassenger' : 'piecePerPassenger'}`,
            { pieces, passenger: passenger.toLowerCase() }
          ));
        });

        description.push(t('results.flights.largeBaggageDescription'));

        largeBaggage = {
          icon: <LargeBaggage />,
          title: t('results.flights.largeBaggageIncluded'),
          description: description.join(' ')
        };
      }
    } else if (!['0PC', '0K'].includes(option?.Segments?.[0]?.Baggage)) {
      const description = [];

      if (option?.Segments?.[0]?.Baggage?.includes('PC')) {
        const splitPC = option?.Segments?.[0]?.Baggage?.split('PC');
        const pieces = splitPC.length > 0 ? splitPC[0] : 0;
        product?.cardDetail?.paxFares?.forEach(elem => {
          const passenger = elem.PaxType === 'ADT'
            ? t('results.flights.adult')
            : elem.PaxType === 'CHD'
              ? t('results.flights.kid')
              : t('results.flights.baby');
          description.push(t(
            `results.flights.${pieces > 1 ? 'piecesPerPassenger' : 'piecePerPassenger'}`,
            { pieces, passenger: passenger.toLowerCase() }
          ));
        })
      } else if (option?.Segments?.[0]?.Baggage?.includes('K')) {
        const splitPC = option?.Segments?.[0]?.Baggage?.split('K');
        const kg = splitPC.length > 0 ? splitPC[0] : 0;
        product?.cardDetail?.paxFares?.forEach(elem => {
          const passenger = elem.PaxType === 'ADT'
            ? t('results.flights.adult')
            : elem.PaxType === 'CHD'
              ? t('results.flights.kid')
              : t('results.flights.baby');
          description.push(t('results.flights.pieceKgPerPassenger', { kg, passenger: passenger.toLowerCase() }));
        })
      }

      description.push(t('results.flights.largeBaggageDescription'));

      mediumBaggage.title = t('results.flights.mediumBaggageTitle');
      mediumBaggage.description = t('results.flights.mediumBaggageDescription');
      mediumBaggage.icon = <MediumBaggage />

      largeBaggage.title = t('results.flights.largeBaggageIncluded');
      largeBaggage.description = description.join(' ');
      largeBaggage.icon = <LargeBaggage />
    } else if (option?.Segments?.[0]?.CarryOnBagInfo) {
      mediumBaggage.title = t('results.flights.mediumBaggageTitle');
      mediumBaggage.description = t('results.flights.mediumBaggageDescription');
      mediumBaggage.icon = <MediumBaggage />
    }

    baggages.push(mediumBaggage);
    baggages.push(largeBaggage);

    return <Grid container className='baggage-container' spacing={1}>
      {baggages.map((elem, i) => (
        <Grid item xs={vertical ? 12 : 4} key={i}>
          <Grid container className='row' key={i} spacing={1} columns={24}>
            <Grid item xs={3} className='icon-container'>{elem.icon}</Grid>
            <Grid item xs={21} className='column baggage-description'>
              <b>{elem.title}</b>
              {elem.description && <span>{elem.description}</span>}
            </Grid>
          </Grid>
        </ Grid>
      ))}
    </Grid>
  }

  const getBaggageIcons = (option) => {
    const fareFeatures = product?.cardDetail?.fareFeatures;
    const detailProduct = product?.brandedFare?.cardDetail?.detailItems?.[0]?.details?.detailProduct;

    const getBaggageStatus = (brandedFare, title, code) => {
      if (brandedFare) {
        return brandedFare.find(elem => elem.title === title && elem.className === 'included') ? '' : 'not-included';
      }
      if (fareFeatures) {
        return fareFeatures.find(elem => elem.Code === code) ? '' : 'not-included';
      }
      return '';
    };

    const baggage = {
      medium: getBaggageStatus(detailProduct, 'mediumBaggage', 'CABIN_BAG'),
      large: getBaggageStatus(detailProduct, 'largeBaggage', 'CHECKED_BAG'),
    };

    if (!fareFeatures && !detailProduct && ['0PC', '0K'].includes(option?.Segments?.[0]?.Baggage)) {
      baggage.medium = 'not-included';
      baggage.large = 'not-included';
    }
    if (option?.Segments?.[0]?.CarryOnBagInfo) {
      baggage.medium = '';
    }

    return (
      <Tooltip title={getBaggages(option)}>
        <SmallBaggage />
        <MediumBaggage className={baggage.medium} />
        <LargeBaggage className={baggage.large} />
      </Tooltip>
    );
  }

  const getOptionContainer = (option) => {
    const segmentsLength = option?.Segments?.length;
    const departure = option?.Segments?.[0]?.Departure;
    const arrival = option?.Segments?.[segmentsLength - 1]?.Arrival;
    const providers = [...new Set(option?.Segments?.map(elem => elem.Airline))];

    return <Grid container columns={24} columnSpacing={1} className='option-container'>
      <Hidden smDown>
        <Grid item className='row' sm={3}>
          {providers.length === 1 ? (
            <Image img={`providersFlights/${providers[0]}.png`} alt='logo-provider' className='airline-logo' hideBrokenImage />
          ) : (
            <div className='row'>
              <MultiplesAirlines className='multiple-logo' />
              &nbsp;<span>{t('results.flights.multipleAirlines')}</span>
            </div>
          )}
        </Grid>
      </Hidden>

      <Grid item className='column' xs={5} sm={4} md={6}>
        <Tooltip title={departure?.AirportName || ''}>
          <Typography className='title'>{departure?.AirportCode}</Typography>
        </Tooltip>
        <Typography className='time'>{departure?.Time}</Typography>
      </Grid>

      <Grid item className='column scales-container' xs={7} sm={6} md={4}>
        <Tooltip title={getScalesTooltip(option?.Segments)} placement='top'>
          <Typography className={`scales-text ${segmentsLength === 1 && 'direct'}`}>
            {segmentsLength > 1 && <>{segmentsLength - 1}&nbsp;</>}
            {t(`results.flights.${segmentsLength === 1 ? 'direct' : segmentsLength === 2 ? 'scale' : 'scales'}`)}
          </Typography>
        </Tooltip>
        <Hidden smDown>
          <div className='scale-line'>
            <div className='circles-container row'>
              {option?.Segments.slice(1).map((elem, i) => <div key={i} className='circle' />)}
            </div>
          </div>
          <span>
            <Tooltip title={getSegmentsDuration(option?.Segments)} placement='bottom'>
              <span className='duration'>{option.OptionDurationHours}h {option.OptionDurationMinutes}m</span>
            </Tooltip>
            {option.DayAfter > 0 && (
              <Tooltip title={getDaysAfterTooltip(option.DayAfter)} placement='bottom'>
                <span className='plus-days'>+{option.DayAfter}D</span>
              </Tooltip>
            )}
          </span>
        </Hidden>
      </Grid>

      <Grid item className='column' xs={5} sm={4} md={6}>
        <Tooltip title={arrival?.AirportName || ''}>
          <Typography className='title'>{arrival?.AirportCode}</Typography>
        </Tooltip>
        <Typography className='time'>{arrival?.Time}</Typography>
      </Grid>

      <Grid item className='row baggage-container-icons' xs={7} md={5}>
        {getBaggageIcons(option)}
      </Grid>
    </Grid>
  }

  const getLegTitle = (leg) => {
    return product?.cardDetail?.legs.length > 2
      ? `${t('results.flights.flight')} ${leg.LegNumber}`
      : leg.LegNumber === 2
        ? t('results.flights.return')
        : t('results.flights.departure');
  }

  const getLegTitleDestination = (leg) => {
    const segmentsLength = leg?.Options?.[0]?.Segments?.length;
    const departure = leg?.Options?.[0]?.Segments?.[0]?.Departure?.CityName;
    const arrival = leg?.Options?.[0]?.Segments?.[segmentsLength - 1]?.Arrival?.CityName;
    return `${departure} - ${arrival}`
  }

  const getDate = (leg) => {
    const date = leg?.Options?.[0]?.Segments?.[0]?.Departure?.Date;
    return dayjs(date, DATE_FORMAT_INTERNATIONAL).format('dddd, DD MMM YY');
  }

  const getCabinClassLeg = (leg) => {
    const brandedFare = product?.brandedFare?.cardDetail;
    let value = '';
    if (brandedFare) {
      const brandedClass = CABIN_CLASS[brandedFare?.cabinClass]
      value = client.isB2C && brandedClass?.valueB2C ? brandedClass?.valueB2C : brandedClass?.value;
    } else {
      const cabinClass = CABIN_CLASS[leg?.Options?.[0]?.Segments?.[0]?.CabinClass];
      if (cabinClass) {
        value = client?.isB2C && cabinClass.valueB2C ? cabinClass.valueB2C : cabinClass.value;
      }
    }

    return value ? t(`results.flights.${value}`) : '';
  }

  const getFeaturesBranded = () => {
    const brandedFare = product?.brandedFare;
    const cardDetail = brandedFare?.cardDetail || product?.cardDetail;
    const cabinClass = cardDetail?.cabinClass || cardDetail?.cabin;
    const brandedClass = CABIN_CLASS[cabinClass]
    const value = client.isB2C && brandedClass?.valueB2C ? brandedClass?.valueB2C : brandedClass?.value;
    const secondLine = value ? `${t('results.flights.class')} ${t(`results.flights.${value}`)}` : '';

    if (cardDetail && (isFinalView || !brandedFare)) {
      return (
        <Card className='mt-2 fare-features-branded-card'>
          {!isFinalView && (
            <CardHeader
              avatar={<Info />}
              title={t('checkout.flights.fareDetailTitle')}
            />
          )}
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className='title-product' component='h3' variant='h3'>
                  <span className='title'>{cardDetail?.product || t('checkout.flights.baseFare')}&nbsp;</span>
                  <span className='subtitle'>({secondLine})</span>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <CardBrandedFares
                  template='CardOneColV2brandedFares'
                  item={!brandedFare ? cardDetail.detailItems : cardDetail.detailItems?.[0]}
                  isFinalView
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )
    }
  }

  return (
    <>
      <Card className='fare-features-card'>
        <CardContent>
          {!isFinalView && (
            <div className='title-container text-center'>
              {getCabinClass()}
            </div>
          )}

          {getFareFeatures()}

          {product?.cardDetail?.legs?.map((leg, i) => {
            const isReturn = product?.cardDetail?.legs.length === 2 && leg.LegNumber === 2;
            return (
              <Fragment key={i}>
                {isFinalView && (
                  <Grid className='row leg-title-container'>
                    <Grid className='left row'>
                      <Grid className='row trip-type-container'>
                        <Flight className={`flight-icon ${isReturn && 'return'}`} />
                        {getLegTitle(leg)}
                      </Grid>
                      <Typography className='title'>{getLegTitleDestination(leg)}</Typography>
                      <Typography className='row date'>
                        <CalendarToday fontSize='small' /> {getDate(leg)}
                      </Typography>
                    </Grid>
                    <Grid className='row cabin-class'>
                      <AirplaneTicketOutlined /> {getCabinClassLeg(leg)}
                    </Grid>
                  </Grid>
                )}

                <Accordion className='option-accordion' defaultExpanded={Boolean(client?.isB2C)}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    {getOptionContainer(leg.Options[0])}
                  </AccordionSummary>
                  <AccordionDetails>
                    <FlightScales option={leg.Options[0]} />
                    {(!client?.isB2C && !isFinalView) && getBaggages(leg.Options[0], false)}
                  </AccordionDetails>
                </Accordion>
              </Fragment>
            )
          })}
        </CardContent>
      </Card>

      {getFeaturesBranded()}
    </>
  )
}

export {
  getTotalPrice,
  getProductsDetails,
  getProductsDetailsB2C,
  getPriceDetails,
  getCardDetail
};
