import React, { Fragment, useEffect, useState } from 'react';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControlLabel, Grid, Tooltip, Typography } from '@mui/material';
import { Close, Flight, PictureAsPdf, Print, Settings } from '@mui/icons-material';
import { availabilityParallelActionFlights, startCheckoutAction } from '../../../store/actions';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PDFComparation } from './PDFComparation';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { useClientData } from '../../../context/ClientContext';
import Image from '../../common/Image';
import { ReactComponent as SmallBaggage } from '../../../assets/images/baggage/smallBaggage.svg';
import { ReactComponent as MediumBaggage } from '../../../assets/images/baggage/mediumBaggage.svg';
import { ReactComponent as LargeBaggage } from '../../../assets/images/baggage/largeBaggage.svg';
import { getClientIp } from '../../../store/services/IpServices';
import { LoadingButton } from '@mui/lab';
import { formatNumber } from '../../../utils/price';
import { isMobile } from 'react-device-detect';

const CRITERIA = [
  'duration',
  'scales',
  'baggage',
  'changeable',
  'refundable',
  'seatSelection'
];

const STOPOVERS = [
  'results.flights.direct',
  'results.flights.oneScale',
  'results.flights.twoScales',
  'results.flights.threeScales'
];

const ComparationComponent = ({
  dataForm,
  destination,
  resultsToCompare,
  providers,
  hashFlow,
  resultAvailabilityParallel,
  flights,
  errorAvailability,
  errorApiCheckout,
  apiCheckout,
  handleGoBack,
  handleClickHotel,
  handleClickAddHotel,
  handleClickRemoveHotel,
  ...props
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { clientData, currentProduct, setClientData } = useClientData();
  const [activeCriteria, setActiveCriteria] = useState(CRITERIA);
  const [availabities, setAvailabilities] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [criteriaModal, setCriteriaModal] = useState(CRITERIA);
  const [abortController, setAbortController] = useState();
  const [loadingAvailability, setLoadingAvailability] = useState([]);
  const [loadingCheckout, setLoadingCheckout] = useState(false);

  useEffect(() => {
    const { tripType, currency } = params;
    const token = currentProduct?.config?.['API-Token']?.[currency];
    if (token) {
      const currentAvailabities = availabities.map(elem => elem?.FareID);
      const requests = resultsToCompare.filter(flight => !currentAvailabities.includes(flight.FareID)).map(flight => ({
        recommendationID: flights.RecommendationID,
        fareID: flight.FareID,
        optionID: flight.Options?.map(elem => elem.FlightOptionID),
        flightType: tripType
      }));

      setLoadingAvailability(requests.map(elem => elem.fareID));

      abortController && abortController.abort();
      const controller = new AbortController();
      setAbortController(controller);

      if (requests.length > 0) {
        props.getAvailabilityParallel(token, requests, controller.signal);
      } else {
        setLoadingAvailability([]);
      }
    } else {
      const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
      if (iframePath) {
        localStorage.clear();
        setClientData(null);
        navigate(iframePath);
      } else {
        navigate('/login', { state: { expiredSession: true } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsToCompare]);

  useEffect(() => {
    if (loadingAvailability.length > 0 && resultAvailabilityParallel.length > 0) {
      setAvailabilities(current => [
        ...current,
        ...resultAvailabilityParallel.map(elem => elem.data).filter(elem => elem)
      ]);
      setLoadingAvailability([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultAvailabilityParallel]);

  useEffect(() => {
    if (loadingAvailability.length > 0 && errorAvailability) {
      setLoadingAvailability([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorAvailability]);

  useEffect(() => {
    if (loadingCheckout && apiCheckout && Object.keys(apiCheckout).length !== 0) {
      if (!errorApiCheckout) {
        const iframePath = params.tokenIframe ? `${params.tokenIframe}/` : '';
        if (apiCheckout.baseDomain) {
          if (window.location.hostname === 'localhost' || (`${window.location.origin}/` === apiCheckout.baseDomain)) {
            navigate(apiCheckout.urlRedirect.replace(apiCheckout.baseDomain, '/' + iframePath));
          } else {
            window.location.href = apiCheckout.urlRedirect.replace(apiCheckout.baseDomain, apiCheckout.baseDomain + iframePath)
          }
        } else {
          navigate(`/${iframePath}${apiCheckout.urlRedirect}`);
        }
      } else {
        setLoadingCheckout(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorApiCheckout, apiCheckout]);

  const handleOpenModal = () => {
    setCriteriaModal([...activeCriteria]);
    setOpenModal(true);
  }

  const handleCloseModal = (accepted) => {
    if (accepted) {
      setActiveCriteria([...criteriaModal]);
    } else {
      setCriteriaModal([...activeCriteria]);
    }
    setOpenModal(false);
  }

  const handleChangeCheckbox = (value, checked) => {
    let criteriaModalAux = [...criteriaModal];

    if (checked) {
      criteriaModalAux.push(value);
    } else {
      criteriaModalAux = criteriaModalAux.filter(elem => elem !== value);
    }

    setCriteriaModal(criteriaModalAux);
  }

  const getLegTitle = (leg) => {
    const segmentsLength = leg?.Options?.[0]?.Segments?.length;
    const departure = leg?.Options?.[0]?.Segments?.[0]?.Departure?.CityName;
    const arrival = leg?.Options?.[0]?.Segments?.[segmentsLength - 1]?.Arrival?.CityName;
    return `${departure} - ${arrival}`
  }

  const getFirstColumn = (leg, index) => {
    const isReturn = resultsToCompare?.[0]?.Legs?.length === 2 && leg.LegNumber === 2;

    return <Fragment key={index}>
      <Grid className='flight-info-container'>
        <Grid className='row destination-container'>
          <Grid className='row trip-type-container'>
            <Flight className={`flight-icon ${isReturn && 'return'}`} fontSize='small' />
            {resultsToCompare?.[0]?.Legs.length > 2
              ? `${t('results.flights.flight')} ${leg.LegNumber}`
              : leg.LegNumber === 2
                ? t('results.flights.return')
                : t('results.flights.departure')
            }
          </Grid>
          <Typography className='leg-title'>{getLegTitle(leg)}</Typography>
        </Grid>
        <Grid className='data-container info-title'>{t('results.flights.airline')}</Grid>
        <Grid className='data-container info-title'>{t('results.flights.airports')}</Grid>
      </Grid>
      {activeCriteria.map((criterion, i) => (
        <Grid key={i} className='data-container info-title'>
          {t(`results.flights.criteria.${criterion}`)}
        </Grid>
      ))}
    </Fragment>
  }

  const getBaggagesAlternativeFare = (fare) => {
    const baggages = {
      small: {
        icon: <SmallBaggage />,
        title: t('results.flights.smallBaggageTitle'),
        description: t('results.flights.smallBaggageDescription')
      },
      medium: {
        icon: <MediumBaggage className='not-included' />,
        title: t('results.flights.mediumBaggageNotIncluded')
      },
      large: {
        icon: <LargeBaggage className='not-included' />,
        title: t('results.flights.largeBaggageNotIncluded')
      }
    };

    const mediumBaggageIncluded = fare?.FareFeatures?.find(elem => elem.Code === 'CABIN_BAG');
    if (mediumBaggageIncluded) {
      baggages['medium'] = {
        icon: <MediumBaggage />,
        title: t('results.flights.mediumBaggageTitle'),
        description: t('results.flights.mediumBaggageDescription')
      };
    }

    const largeBaggageIncluded = fare?.FareFeatures?.filter(elem => elem.Code === 'CHECKED_BAG');
    if (largeBaggageIncluded.length > 0) {
      const description = [];

      fare?.PaxFares?.forEach(elem => {
        const passenger = elem.PaxType === 'ADT'
          ? t('results.flights.adult')
          : elem.PaxType === 'CHD'
            ? t('results.flights.kid')
            : t('results.flights.baby');
        const pieces = largeBaggageIncluded.length;
        description.push(t(
          `results.flights.${pieces > 1 ? 'piecesPerPassenger' : 'piecePerPassenger'}`,
          { pieces, passenger: passenger.toLowerCase() }
        ));
      });

      description.push(t('results.flights.largeBaggageDescription'));

      baggages['large'] = {
        icon: <LargeBaggage />,
        title: t('results.flights.largeBaggageIncluded'),
        description: description.join(' ')
      };
    }

    return <Grid container className='baggage-container' spacing={1}>
      {Object.values(baggages).map((elem, i) => (
        <Grid item xs={12} key={i}>
          <Grid container className='row' key={i} spacing={1} columns={24}>
            <Grid item xs={3} className='icon-container'>{elem.icon}</Grid>
            <Grid item xs={21} className='column baggage-description'>
              <b>{elem.title}</b>
              {elem.description && <span>{elem.description}</span>}
            </Grid>
          </Grid>
        </ Grid>
      ))}
    </Grid>
  }

  const getBaggagesBaseFare = (flight, option) => {
    const baggages = [
      {
        icon: <SmallBaggage />,
        title: t('results.flights.smallBaggageTitle'),
        description: t('results.flights.smallBaggageDescription')
      }
    ];

    const mediumBaggage = {
      icon: <MediumBaggage className='not-included' />,
      title: t('results.flights.mediumBaggageNotIncluded')
    };
    const largeBaggage = {
      icon: <LargeBaggage className='not-included' />,
      title: t('results.flights.largeBaggageNotIncluded')
    };

    if (!['0PC', '0K'].includes(option?.Segments?.[0]?.Baggage)) {
      const description = [];

      if (option?.Segments?.[0]?.Baggage?.includes('PC')) {
        const splitPC = option?.Segments?.[0]?.Baggage?.split('PC');
        const pieces = splitPC.length > 0 ? splitPC[0] : 0;
        flight?.PaxFares?.forEach(elem => {
          const passenger = elem.PaxType === 'ADT'
            ? t('results.flights.adult')
            : elem.PaxType === 'CHD'
              ? t('results.flights.kid')
              : t('results.flights.baby');
          description.push(t(
            `results.flights.${pieces > 1 ? 'piecesPerPassenger' : 'piecePerPassenger'}`,
            { pieces, passenger: passenger.toLowerCase() }
          ));
        })
      } else if (option?.Segments?.[0]?.Baggage?.includes('K')) {
        const splitPC = option?.Segments?.[0]?.Baggage?.split('K');
        const kg = splitPC.length > 0 ? splitPC[0] : 0;
        flight?.PaxFares?.forEach(elem => {
          const passenger = elem.PaxType === 'ADT'
            ? t('results.flights.adult')
            : elem.PaxType === 'CHD'
              ? t('results.flights.kid')
              : t('results.flights.baby');
          description.push(t('results.flights.pieceKgPerPassenger', { kg, passenger: passenger.toLowerCase() }));
        })
      }

      description.push(t('results.flights.largeBaggageDescription'));

      mediumBaggage.title = t('results.flights.mediumBaggageTitle');
      mediumBaggage.description = t('results.flights.mediumBaggageDescription');
      mediumBaggage.icon = <MediumBaggage />

      largeBaggage.title = t('results.flights.largeBaggageIncluded');
      largeBaggage.description = description.join(' ');
      largeBaggage.icon = <LargeBaggage />
    }

    baggages.push(mediumBaggage);
    baggages.push(largeBaggage);

    return <Grid container className='baggage-container' spacing={1}>
      {baggages.map((elem, i) => (
        <Grid item xs={12} key={i}>
          <Grid container className='row' key={i} spacing={1} columns={24}>
            <Grid item xs={3} className='icon-container'>{elem.icon}</Grid>
            <Grid item xs={21} className='column baggage-description'>
              <b>{elem.title}</b>
              {elem.description && <span>{elem.description}</span>}
            </Grid>
          </Grid>
        </ Grid>
      ))}
    </Grid>
  }

  const getBaggageValue = (flight, option, fare) => {
    if (fare) {
      const baggage = {};

      const mediumBaggageNotIncluded = !fare?.FareFeatures?.find(elem => elem.Code === 'CABIN_BAG');
      if (mediumBaggageNotIncluded) {
        baggage['medium'] = 'not-included';
      }

      const largeBaggageNotIncluded = !fare?.FareFeatures?.find(elem => elem.Code === 'CHECKED_BAG');
      if (largeBaggageNotIncluded) {
        baggage['large'] = 'not-included';
      }

      return <Tooltip title={getBaggagesAlternativeFare(fare)} className='tooltip-baggage'>
        <SmallBaggage className='baggage-icon small' />
        <MediumBaggage className={`baggage-icon medium ${baggage['medium']}`} />
        <LargeBaggage className={`baggage-icon large ${baggage['large']}`} />
      </Tooltip>
    } else {
      const mediumLargeBaggageClassName = ['0PC', '0K'].includes(option?.Segments?.[0]?.Baggage)
        ? 'baggage-icon not-included' : 'baggage-icon';

      return <Tooltip title={getBaggagesBaseFare(flight, option)} className='tooltip-baggage'>
        <SmallBaggage className='baggage-icon' />
        <MediumBaggage className={mediumLargeBaggageClassName} />
        <LargeBaggage className={mediumLargeBaggageClassName} />
      </Tooltip>
    }
  }

  const getChangeableValue = (fare) => {
    if (fare) {
      const changeable = fare?.FareFeatures?.filter(elem => elem.Code === 'CHANGEABLE');
      if (changeable.length > 0) {
        return changeable.find(elem => elem?.Description?.includes('WITH COST'))
          ? t('results.flights.withExtraCost')
          : t('results.flights.withoutExtraCost');
      }
    }

    return t('results.flights.notAllowed');
  }

  const getRefundableValue = (fare) => {
    if (fare) {
      const refundable = fare?.FareFeatures?.filter(elem => elem.Code === 'REFUNDABLE');
      if (refundable.length > 0) {
        return refundable.find(elem => elem?.Description?.includes('WITH COST'))
          ? t('results.flights.allowedWithPenalty')
          : t('results.flights.allowedWithoutPenalty');
      }
    }

    return t('results.flights.notRefundable');
  }

  const getSeatSelectionValue = (fare) => {
    if (fare) {
      const seatSelection = fare?.FareFeatures?.filter(elem => elem.Code === 'SEAT_SELECTION');
      if (seatSelection.length > 0) {
        return t('results.flights.included');
      }
    }

    return t('results.flights.withExtraCost');
  }

  const getCriterionFlight = (criterion, flight, option) => {
    const fare = availabities.find(elem => elem.FareID === flight.FareID)?.AlternativeFares?.[0];
    const criterionMap = {
      duration: `${option.OptionDurationHours}h ${option.OptionDurationMinutes}m`,
      scales: t(STOPOVERS[option.Segments.length - 1]),
      baggage: getBaggageValue(flight, option, fare),
      changeable: getChangeableValue(fare),
      refundable: getRefundableValue(fare),
      seatSelection: getSeatSelectionValue(fare)
    };

    return criterionMap[criterion] || '';
  }

  const getCriteriaFlight = (flight, option) => {
    return activeCriteria.map((criterion, i) => (
      <Grid key={i} className='data-container criterion'>
        {getCriterionFlight(criterion, flight, option)}
      </Grid>
    ))
  }

  const handleClickReserve = async (flight) => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const accessToken = localStorage.getItem('jwt');
    const tokenCheckout = currentProduct?.config?.api_checkout_token || currentProduct?.config_work_unit?.api_checkout_token;
    const currency = flight.Currency;
    const token = currentProduct?.config?.['API-Token']?.[currency];

    if (user && accessToken && tokenCheckout && token) {
      setLoadingCheckout(flight.FareID);

      const clientIp = await getClientIp();
      const urlRedirect = location.pathname;
      const alternativeFare = availabities.find(elem => elem.FareID === flight.FareID)?.AlternativeFares?.[0];
      const userProducts = JSON.parse(localStorage.getItem('userProducts') || '{}');
      const userProduct = {
        work_unit_relation: {
          token: userProducts?.work_unit_relation?.[0]?.token || null,
          long_name: userProducts?.work_unit_relation?.[0]?.long_name || null
        },
        username: userProducts?.username,
        hash_client: userProducts?.hash_client,
        products: [
          {
            product_type_id: currentProduct?.product_type_id || null,
            ref_table_detail: currentProduct?.ref_table_detail || null,
            parameters: currentProduct?.parameters || null,
            work_unit_id: currentProduct?.work_unit_id || null,
            token_id: currentProduct?.token_id || null,
            work_unit_token: currentProduct?.work_unit_token || null,
            business_unit_token: currentProduct?.business_unit_token || null
          }
        ]
      }

      const requestData = {
        module: clientData?.client?.isB2C ? 'flights' : 'airNarrative',
        dataProduct: {
          recommendationID: flights.RecommendationID,
          fareId: flight.FareID,
          optionId: flight.Options?.map(elem => elem.FlightOptionID),
          brandedFare: alternativeFare?.FareID || null,
          brandedFareIndex: alternativeFare ? 0 : null,
          adults: params.adults,
          children: params.kids,
          infants: params.babys,
          dateFrom: params.datesDeparture,
          dateTo: params.datesReturn,
          urlRedirect,
          supplier: null,
          ip: clientIp,
          utm: '',
          loginData: null,
          cabin: params.cabinClasses,
          urlMeta: window.location.href,
          token,
          currency
        },
        username: user?.username,
        user_product: userProduct,
        deviceType: isMobile ? 'mobile' : 'desktop'
      }

      props.startCheckout(accessToken, tokenCheckout, requestData);
    } else {
      const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
      if (iframePath) {
        localStorage.clear();
        setClientData(null);
        navigate(iframePath);
      } else {
        navigate('/login', { state: { expiredSession: true } });
      }
    }
  }

  const getPriceFlight = (flight) => {
    const alternativeFares = availabities.find(elem => elem.FareID === flight.FareID)?.AlternativeFares;
    const price = alternativeFares?.[0]?.ExtendedFareInfo?.NetTotalAmountWithFee
      || alternativeFares?.[0]?.TotalAmount
      || flight?.ExtendedFareInfo?.NetTotalAmountWithFee
      || flight?.TotalAmount
      || 0;

    return <Fragment>
      <Typography className='price'>
        {flight.Currency} {formatNumber(price)}
      </Typography>

      <LoadingButton
        size='small'
        variant='contained'
        color='secondary'
        className='reserve-button'
        fullWidth
        loading={loadingAvailability.includes(flight.FareID) || loadingCheckout === flight.FareID}
        disabled={!alternativeFares || loadingCheckout}
        onClick={() => handleClickReserve(flight)}
      >
        <span>{t('common.reserve')}</span>
      </LoadingButton>

      {!alternativeFares && !loadingAvailability.includes(flight.FareID) && (
        <Typography className='not-available-message'>
          {t('results.flights.notAvailable')}
        </Typography>
      )}
    </Fragment>
  }

  const handlePrintButtonClick = (url) => {
    const printWindow = window.open(url, '_blank');
    printWindow.onload = () => {
      printWindow.print();
    };
  };

  return (
    <>
      <Grid className='row title-container'>
        <Typography className='title'>
          {t('results.flights.compareFlightsInDestination', { destination })}
        </Typography>

        <Grid className='row buttons-container'>
          <Button className='button row' onClick={handleOpenModal}>
            <Settings />{t('results.flights.modifyCriteria')}
          </Button>

          <PDFDownloadLink
            document={
              <PDFComparation
                client={props.client}
                searchData={dataForm}
                availabities={availabities}
                resultsToCompare={resultsToCompare}
                activeCriteria={activeCriteria}
                destination={destination}
              />
            }
            fileName={`${t('results.flights.compareFlightsInDestination', { destination })}.pdf`}
            style={{ textDecoration: 'none' }}
            className='row'
          >
            {({ url, loading, error }) => (
              <>
                <Button className='button row' disabled={loading || error}>
                  <PictureAsPdf />{t('results.flights.download')}
                </Button>
                <Button
                  className='button row'
                  disabled={loading || error}
                  onClick={(e) => {
                    e.preventDefault();
                    handlePrintButtonClick(url);
                  }}
                >
                  <Print />{t('results.flights.print')}
                </Button>
              </>
            )}
          </PDFDownloadLink>
        </Grid>
      </Grid>

      <Grid container columns={20} spacing={2}>
        <Grid item xs={(resultsToCompare?.length + 1) * 4}>
          {resultsToCompare?.[0]?.Legs?.map((leg, i) => (
            <Grid key={i} container columns={(resultsToCompare?.length + 1) * 4} spacing={2} className='leg-container'>
              <Grid item xs={4}>{getFirstColumn(leg, i)}</Grid>

              {resultsToCompare?.map((elem, j) => {
                return <Grid item xs={4} key={j} className='option-column column'>
                  <Grid className='flight-info-container column'>
                    <Grid className='data-container logo-container'>
                      <Image
                        img={`providersFlights/${elem?.Legs?.[i]?.Options?.[0]?.Segments?.[0]?.Airline}.png`}
                        alt='logo-provider'
                        className='airline-logo'
                        hideBrokenImage
                      />
                    </Grid>
                    <Grid className='data-container airports'>
                      {elem?.Legs?.[i]?.Options?.[0]?.Segments?.[0]?.Departure?.AirportCode} -&nbsp;
                      {elem?.Legs?.[i]?.Options?.[0]?.Segments?.slice(-1)?.[0]?.Arrival?.AirportCode}
                    </Grid>
                  </Grid>
                  {getCriteriaFlight(elem, elem?.Legs?.[i]?.Options?.[0])}
                </Grid>
              })}

              {i === (resultsToCompare?.[0]?.Legs?.length - 1) && (
                <>
                  <Grid item xs={4} />

                  {resultsToCompare?.map((elem, z) => (
                    <Grid key={z} item xs={4} className='price-container'>
                      <Grid className='data-container column'>
                        {getPriceFlight(elem)}
                      </Grid>
                    </Grid>
                  ))}
                </>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Dialog className='closable-modal' open={openModal} maxWidth='sm' fullWidth onClose={() => handleCloseModal(false)}>
        <Fab size="small" onClick={() => handleCloseModal(false)} className='close-button'>
          <Close fontSize='small' />
        </Fab>

        <DialogTitle>
          {t('results.flights.selectCriteria')}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            {CRITERIA.map((criterion, i) => (
              <Grid item key={i} xs={12} md={6}>
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox
                      onChange={(e, checked) => handleChangeCheckbox(criterion, checked)}
                      checked={criteriaModal.find(elem => elem === criterion) ? true : false}
                    />
                  }
                  label={t(`results.flights.criteria.${criterion}`)}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCriteriaModal(CRITERIA)} style={{ marginRight: 'auto' }}>
            {t('results.flights.selectAll')}
          </Button>
          <Button variant='contained' color='primary' onClick={() => handleCloseModal(true)}>{t('results.flights.updateComparation')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = reducers => {
  return reducers.flightsReducer;
};

const mapDispatchToProps = dispatch => {
  return {
    getAvailabilityParallel: (token, requests, signalAbort) => dispatch(availabilityParallelActionFlights(token, requests, signalAbort)),
    startCheckout: (access, tokenCheckout, data) => dispatch(startCheckoutAction(access, tokenCheckout, data))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ComparationComponent);
