import React, { memo, useMemo } from 'react';
import { Typography, Icon } from '@mui/material';

const GetDetailSearch = memo(({ detailSearch }) => (
  <Typography component='p' className='detail-item-list mb-1'>
    {detailSearch.map((item, i) => (
      <span key={i} className='item-detail-search'>
        <strong>{item.title}</strong> {item.description}
      </span>
    ))}
  </Typography>
));

const GetDetailProduct = memo(({ detailProduct }) => (
  <ul className='detail-item-list products'>
    {detailProduct.map((item, i) => (
      <li key={i} className='list-item'>
        <div className={`${item.className}`}>
          <Typography component='p' className={item.icon ? 'has-icon' : ''}>
            {item.icon && <Icon>{item.icon}</Icon>}
            {item.title && <strong>{item.title}</strong>}
            {item.description}
          </Typography>
        </div>
      </li>
    ))}
  </ul>
));

const GetDetailProductSelection = memo(({ detailProduct }) => {
  const detailProductSelection = useMemo(
    () => detailProduct.filter((_, i) => i <= 4),
    [detailProduct]
  );

  return (
    <ul className='detail-item-list products'>
      {detailProductSelection.map((item, i) => (
        <li key={i} className='list-item'>
          <div className={`${item.className}`}>
            <Typography component='p' className={item.icon ? 'has-icon' : ''}>
              {item.icon && <Icon>{item.icon}</Icon>}
              {item.title && <strong>{item.title}</strong>}
              {item.description}
            </Typography>
          </div>
        </li>
      ))}
    </ul>
  );
});

const CardOneColV3 = ({ template, item, showDetailSearch, showDetailProductFull, detailButton }) => (
  <div className={template}>
    {item.title && <Typography className='detail-item-title' variant='h4'>{item.title}</Typography>}

    {showDetailSearch && item.details?.detailSearch && (
      <GetDetailSearch detailSearch={item.details.detailSearch} />
    )}

    {item.details?.detailProduct && (
      showDetailProductFull ? (
        <GetDetailProduct detailProduct={item.details.detailProduct} />
      ) : (
        <GetDetailProductSelection detailProduct={item.details.detailProduct} />
      )
    )}

    {detailButton}
  </div>
);

export default memo(CardOneColV3);
