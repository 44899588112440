import React, { Fragment, useEffect, useState } from 'react';
import {
	Avatar,
	Badge,
	Card,
	CardHeader,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	Icon,
	IconButton,
	Typography
} from '@mui/material';
import CardDetail from './CardDetail';
import * as HotelsDetail from './utils/HotelsDetail';
import * as TrainsDetail from './utils/TrainsDetail';
import * as FlightsDetail from './utils/FlightsDetail';
import { useTranslation } from 'react-i18next';
import { useClientData } from '../../../context/ClientContext';
import { usePurchaseDetailContext } from '../../../context/PurchaseDetailContext';
import { useParams } from 'react-router-dom';
import { ShoppingCart } from '@mui/icons-material';
import { formatNumber, getCurrency } from '../../../utils/price';

const DetailDialog = ({ icon }) => {
  const { t } = useTranslation();
  const params = useParams();
  const { clientData, currencies } = useClientData();
  const { purchaseDetail, products, includeComission } = usePurchaseDetailContext();
	const [open, setOpen] = useState(false);
  const [selectedDataTotal, setSelectedDataTotal] = useState([]);

  useEffect(() => {
    if (!products) {
      setSelectedDataTotal([]);
      return;
    }

    const productsAux = Array.isArray(products) ? products : [products];
    const selectedDataTotalAux = productsAux.flatMap(product => {
      let pivot = [product.pivot[0]];
      if (product.pivot?.[0]?.brandedFare) {
        pivot.push(product.pivot[0].brandedFare);
      }
      if (product.pivot.length > 1) {
        pivot = pivot.concat(product.pivot.slice(1));
      }

      return pivot;
    });

    setSelectedDataTotal(selectedDataTotalAux);
  }, [products]);

	const handleClickOpen = () => setOpen(true);

	const handleClose = () => setOpen(false);

  const getProductsDetails = (products) => {
    if (params.module === 'hotels') {
      return HotelsDetail.getProductsDetails(products);
    }
  }

  const getPriceDetails = () => {
    const currency = getCurrency(clientData?.client, currencies, purchaseDetail?.currency);
    let priceDetails = null;
    let showDivider = true;

    if (clientData.client.isB2C) {
      let totalAmount = 0;
      if (purchaseDetail?.totalAmountRealFinal) {
        totalAmount = purchaseDetail.totalAmountRealFinal;
      } else {
        const cart = products.pivot?.map(elem => elem.priceDetail).filter(elem => elem.addToCart);
        totalAmount = cart.reduce((acc, current) =>(
          acc + current.amountReal
        ), 0);
      }

      priceDetails = [{
        title: 'totalPrice',
        value: formatNumber(totalAmount, 2),
        classname: 'strong amount'
      }];
      showDivider = false;
    } else if (params.module === 'trains') {
      priceDetails = TrainsDetail.getPriceDetails(purchaseDetail, includeComission, true);
      showDivider = false;
    } else if (params.module === 'flights') {
      priceDetails = FlightsDetail.getPriceDetails(clientData?.client, purchaseDetail, true);
      showDivider = false;
    }

    if (priceDetails) {
      return <Grid className='prices'>
        {showDivider && <Divider />}
        <Grid container className='row' columns={24}>
          {priceDetails.map((elem, i) => (
            <Fragment key={i}>
              {elem.value && parseInt(elem.value.replaceAll('.', '')) > 0 && (
                <>
                  <Grid item xs={9} className='left'>
                    <Typography className={elem.classname}>{t(`checkout.common.${elem.title}`)}</Typography>
                    {elem.showTax && Boolean(purchaseDetail?.taxAmount) && purchaseDetail?.taxAmount > 0 && (
                      <Typography className='taxes'>
                        {t('checkout.common.taxesIncluded')} {currency} {purchaseDetail?.taxAmount}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={15} className='right'>
                    <Typography className={`text-right ${elem.classname}`}>
                      {currency} {elem.value}
                    </Typography>
                  </Grid>
                </>
              )}
            </Fragment>
          ))}
        </Grid>
      </Grid>
    }
  }

  const getBadgeLength = () => {
    return selectedDataTotal?.filter(elem => elem.module !== 'brandedFares').length || 1;
  }

  return (
		<Fragment>
			<IconButton onClick={handleClickOpen}>
				<Icon>{icon}</Icon>
			</IconButton>

			<Dialog
				onClose={handleClose}
				open={open}
				className={`modal-detail-purchase ${params.module}-detail-purchase`}
        maxWidth='md'
        fullWidth
			>
				<DialogTitle>
					<Card className='purchase-card-modal'>
						<CardHeader
							avatar={
                <Avatar className='avatar-purchase-detail'>
                  <Badge
                    badgeContent={getBadgeLength()}
                    color='primary'
                    overlap='circular'
                  >
                    <ShoppingCart />
                  </Badge>
                </Avatar>
              }
              title={
                <Typography className='title-card-header'>
                  {clientData?.client?.isB2C ? t('checkout.common.purchaseDetail') : t('checkout.common.bookingDetail')}
                </Typography>
              }
              subheader={
                <Typography noWrap className='title-card-subheader' >
                  {purchaseDetail?.subtitle}
                </Typography>
              }
              action={
								<IconButton className='card-header-action' onClick={handleClose}>
									<Icon>close</Icon>
								</IconButton>
              }
						/>
					</Card>
				</DialogTitle>
        <DialogContent dividers className='dialog-content-resume'>
          <Grid className='products'>
            {getProductsDetails(purchaseDetail?.priceDetail)}
          </Grid>

          {getPriceDetails()}

          <Grid className='dialog-footer'>
            {selectedDataTotal.map((data, i) => (
              <CardDetail
                key={i}
                product={data}
                module={data.module || params.module}
                isDetailDialog
              />
            ))}
          </Grid>
        </DialogContent>
			</Dialog>
		</Fragment>
	);
}

export default DetailDialog;
