import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Fab, Grid, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import CardProduct from './CardProduct';
import Carousel from 'react-multi-carousel';
import { useTranslation } from 'react-i18next';
import { useClientData } from '../../../../context/ClientContext';
import { formatNumber, getCurrency } from '../../../../utils/price';

export default function DialogCrossell({
  children,
  id,
  initOpen = false,
  crossellData,
  crosselFormName,
  products,
  header,
  button,
  content,
  contentMobile,
  maxWidth,
  fullWidth = false,
  className,
  onSelectCrossell,
  ...props
}) {
  const { t } = useTranslation();
  const { clientData, currencies } = useClientData();
  const [open, setOpen] = useState(initOpen);
  const [crossellDataAux, setCrossellDataAux] = useState();
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    if (open) {
      setSelectedOption(null);
      setCrossellDataAux(crossellData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const carouselResponsive = useMemo(() => ({
    breakpoint3: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3,
    },
    breakpoint2: {
      breakpoint: { max: 1023, min: 640 },
      items: 2,
    },
    breakpoint1: {
      breakpoint: { max: 639, min: 0 },
      items: 1,
    },
  }), []);

  const handleClickOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    if (selectedOption) {
      onSelectCrossell(selectedOption);
    }

    setOpen(false);
  }

  const onSelectOption = (resumen) => {
    setSelectedOption(resumen);
    setCrossellDataAux(current => ({
      ...current,
      [crosselFormName]: {
        ...current[crosselFormName],
        ...resumen
      }
    }));
  }

  const renderCardProducts = useCallback(() => (
    products.map((cardProduct, index) => (
      <div key={index} className='carousel-item-crossell'>
        <CardProduct
          cardProduct={cardProduct}
          crossellData={crossellDataAux?.[crosselFormName]}
          isModal={true}
          onSelectCrossell={() => onSelectOption(cardProduct)}
        />
      </div>
    ))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [products, crossellDataAux, crosselFormName]);

  const getContinueButton = () => {
    const { currencyCode, amountReal } = crossellDataAux?.[crosselFormName]?.priceDetail || {};

    return <Grid className='row total-price-container'>
      <Typography>
        {t('results.flights.totalPrice')}&nbsp;
        <span className='total-price'>
          {getCurrency(clientData?.client, currencies, currencyCode)}&nbsp;
          {formatNumber(amountReal || 0)}
        </span>
      </Typography>
      <Button
        variant='contained'
        color='secondary'
        onClick={handleClose}
      >
        {t('common.continue')}
      </Button>
    </Grid>
  }

  return (
    <Fragment>
      {children && (
        <div className='button-modal-crossell' onClick={handleClickOpen}>
          {children}
        </div>
      )}

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby={id}
        open={open}
        className={`closable-modal dialog-crossell ${className || ''} ${id || ''}`}
      >
        <Fab size="small" onClick={handleClose} className='close-button'>
          <Close fontSize='small' />
        </Fab>
        <DialogTitle id={id} onClose={handleClose} component='div'>{header}</DialogTitle>
        <DialogContent>
          <Carousel className='carousel-crossell' responsive={carouselResponsive}>
            {renderCardProducts()}
          </Carousel>
          {getContinueButton()}
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}
