import React from 'react'
import { Card, CardHeader, CardContent, Avatar, Typography, Icon } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StatusMessage = ({ title, icon, statusMessage, cardContentTop, cardContentStatusTransaction, cardContentMessage, error }) => {
  const { t } = useTranslation();

  const GetTitle = () => (
    title && <Typography className={`booking-title text-center ${error ? 'error' : ''}`}>
      {t(`checkout.thankYouPage.${title}`)}
    </Typography>
  )

  return (
    <Card className="booking-message mb-2">
      <CardHeader
        className='booking-message-header'
        avatar={<Avatar><Icon>{icon}</Icon></Avatar>}
        title={statusMessage ? t(`checkout.thankYouPage.${statusMessage}`) : ''}
      />

      <CardContent className="card-divider">
        <GetTitle />
        {cardContentTop}
      </CardContent>

      <CardContent className='text-center'>
        <div className="card-content-booking">
          {cardContentStatusTransaction}
        </div>
      </CardContent>
      {cardContentMessage}
    </Card>
  )
}

export default StatusMessage;
