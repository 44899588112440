import React, { Fragment, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Button, Checkbox, Divider, Drawer, FormControl, FormControlLabel, Grid, Hidden, IconButton, Radio, RadioGroup, Snackbar, Tooltip, Typography } from '@mui/material';
import { Flight, CalendarToday, AirplaneTicketOutlined, ExpandMore, StarOutline, AddCircleOutline, RemoveCircleOutline, KeyboardArrowRight } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import FlightScales from './FlightScales';
import { ReactComponent as BaggageImage } from '../../../assets/images/baggage/baggage.svg';
import { ReactComponent as SmallBaggage } from '../../../assets/images/baggage/smallBaggage.svg';
import { ReactComponent as MediumBaggage } from '../../../assets/images/baggage/mediumBaggage.svg';
import { ReactComponent as LargeBaggage } from '../../../assets/images/baggage/largeBaggage.svg';
import { ReactComponent as MultiplesAirlines } from '../../../assets/images/providersFlights/multiple.svg';
import { connect } from 'react-redux';
import { availabilityActionFlights, startCheckoutAction, reportMetricsActionFlights } from '../../../store/actions';
import AvailabilityModal from './AvailabilityModal';
import Preloader from '../../common/Preloader';
import Image from '../../common/Image';
import { useClientData } from '../../../context/ClientContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getClientIp } from '../../../store/services/IpServices';
import { DatalayerBotonComprar } from '../../../store/services/DatalayerGeneral';
import { formatNumber, getCurrency } from '../../../utils/price';
import { isMobile } from 'react-device-detect';

const DATE_FORMAT_INTERNATIONAL = 'YYYY-MM-DD';
const CABIN_CLASS = {
  Y: { value: 'economy', valueB2C: 'economyB2C' },
  P: { value: 'premium' },
  W: { value: 'premium' },
  B: { value: 'business' },
  F: { value: 'firstClass' }
};
const PAX_TYPES = {
  ADT: 'priceByAdult',
  CHD: 'priceByKid',
  INF: 'priceByBaby'
};

const FlightCard = ({
  client,
  recommendationID,
  flight,
  disabledCompare,
  selectedToCompare,
  handleClickCompare,
  resultsAvailability,
  errorAvailability,
  apiCheckout,
  errorApiCheckout,
  ...props
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { searchedProduct, clientData, currencies, currentProduct, setClientData } = useClientData();
  const [selectedFlights, setSelectedFlights] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loadingAvailability, setLoadingAvailability] = useState(false);
  const [availability, setAvailability] = useState();
  const [loadingCheckout, setLoadingCheckout] = useState(false);
  const [startCheckout, setStartCheckout] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState();
  const [notAvailable, setNotAvailable] = useState(false);
  const [extendedLegs, setExtendedLegs] = useState([]);
  const [openDetail, setOpenDetail] = useState();
  const [availabilityRequest, setAvailabilityRequest] = useState(null);

  useEffect(() => {
    const firstOptions = flight.Legs.map(elem => elem.Options?.[0]?.FlightOptionID);
    setSelectedFlights(firstOptions);
  }, [flight]);

  useEffect(() => {
    if ((loadingAvailability || startCheckout) && resultsAvailability?.status) {
      setLoadingAvailability(false);
      setAvailability(resultsAvailability.data);
      if (startCheckout) {
        handleClickReserve(resultsAvailability.data);
      } else if (resultsAvailability?.data?.AlternativeFares?.length > 0) {
        setOpenModal(true);
      } else {
        setMessageSnackbar(t('results.flights.notAlternativeFares'));
      }
      sendAvailabilityMetrics(false, resultsAvailability);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsAvailability]);

  useEffect(() => {
    if ((loadingAvailability || startCheckout) && errorAvailability) {
      setLoadingAvailability(false);
      setLoadingCheckout(false);
      setAvailability(null);
      setStartCheckout(false);
      setMessageSnackbar(t('results.flights.notAvailable'));
      setNotAvailable(true);
      sendAvailabilityMetrics(true, errorAvailability);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorAvailability]);

  useEffect(() => {
    if (loadingCheckout && apiCheckout && Object.keys(apiCheckout).length !== 0) {
      if (!errorApiCheckout) {
        const iframePath = params.tokenIframe ? `${params.tokenIframe}/` : '';
        if (apiCheckout.baseDomain) {
          if (window.location.hostname === 'localhost' || (`${window.location.origin}/` === apiCheckout.baseDomain)) {
            navigate(apiCheckout.urlRedirect.replace(apiCheckout.baseDomain, '/' + iframePath));
          } else {
            window.location.href = apiCheckout.urlRedirect.replace(apiCheckout.baseDomain, apiCheckout.baseDomain + iframePath)
          }
        } else {
          navigate(`/${iframePath}${apiCheckout.urlRedirect}`);
        }
      } else {
        setLoadingCheckout(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorApiCheckout, apiCheckout]);

  const getLegTitleDestination = (leg) => {
    const segmentsLength = leg?.Options?.[0]?.Segments?.length;
    const departure = leg?.Options?.[0]?.Segments?.[0]?.Departure?.CityName;
    const arrival = leg?.Options?.[0]?.Segments?.[segmentsLength - 1]?.Arrival?.CityName;
    return `${departure} - ${arrival}`
  }

  const getDate = (leg) => {
    const date = leg?.Options?.[0]?.Segments?.[0]?.Departure?.Date;
    return dayjs(date, DATE_FORMAT_INTERNATIONAL).format('dddd, DD MMM YY');
  }

  const getCabinClass = (leg) => {
    const cabinClass = CABIN_CLASS[leg?.Options?.[0]?.Segments?.[0]?.CabinClass];
    if (cabinClass) {
      const value = clientData?.client?.isB2C && cabinClass.valueB2C ? cabinClass.valueB2C : cabinClass.value;
      return t(`results.flights.${value}`);
    }
  }

  const getScalesTooltip = (segments) => {
    if (segments.length === 1) {
      return '';
    }

    return <>
      {segments.slice(1).map((segment, i) => (
        <Grid key={i}>
          <Typography><b>{segment.Departure.CountryName} ({segment.Departure.CityCode})</b></Typography>
          <Typography>
            {t('results.flights.scaleWait', {
              city: segment.Departure.CityName,
              hours: segment.WaitHours,
              minutes: segment.WaitMinutes
            })}
          </Typography>
        </Grid>
      ))}
    </>
  }

  const getSegmentsDuration = (segments) => {
    return <>
      {segments.map((segment, i) => {
        const hours = Math.floor(segment.Duration / 60);
        const minutes = segment.Duration % 60;

        return <Fragment key={i}>
          {i > 0 && (
            <Grid className='row' justifyContent='space-between'>
              <Typography style={{ marginRight: 20 }}>
                {t('results.flights.scaleIn', { airport: segment.Departure.AirportCode })}
              </Typography>
              <Typography>{segment.WaitHours}h {segment.WaitMinutes}m</Typography>
            </Grid>
          )}

          <Grid className='row' justifyContent='space-between'>
            <Typography style={{ marginRight: 20 }}>
              <b>{segment.Departure.AirportCode} - {segment.Arrival.AirportCode}</b>
            </Typography>
            <Typography><b>{hours}h {minutes}m</b></Typography>
          </Grid>
        </Fragment>
      })}
    </>
  }

  const getDaysAfterTooltip = (days) => {
    return days === 1
      ? t('results.flights.dayAfter')
      : t('results.flights.daysAfter', { days });
  }

  const getBaggages = (option, vertical = true) => {
    const baggages = [
      {
        icon: <SmallBaggage />,
        title: t('results.flights.smallBaggageTitle'),
        description: t('results.flights.smallBaggageDescription')
      }
    ];

    const mediumBaggage = {
      icon: <MediumBaggage className='not-included' />,
      title: t('results.flights.mediumBaggageNotIncluded')
    };
    const largeBaggage = {
      icon: <LargeBaggage className='not-included' />,
      title: t('results.flights.largeBaggageNotIncluded')
    };

    if (!['0PC', '0K'].includes(option?.Segments?.[0]?.Baggage)) {
      const description = [];

      if (option?.Segments?.[0]?.Baggage?.includes('PC')) {
        const splitPC = option?.Segments?.[0]?.Baggage?.split('PC');
        const pieces = splitPC.length > 0 ? splitPC[0] : 0;
        flight?.PaxFares?.forEach(elem => {
          const passenger = elem.PaxType === 'ADT'
            ? t('results.flights.adult')
            : elem.PaxType === 'CHD'
              ? t('results.flights.kid')
              : t('results.flights.baby');
          description.push(t(
            `results.flights.${pieces > 1 ? 'piecesPerPassenger' : 'piecePerPassenger'}`,
            { pieces, passenger: passenger.toLowerCase() }
          ));
        })
      } else if (option?.Segments?.[0]?.Baggage?.includes('K')) {
        const splitPC = option?.Segments?.[0]?.Baggage?.split('K');
        const kg = splitPC.length > 0 ? splitPC[0] : 0;
        flight?.PaxFares?.forEach(elem => {
          const passenger = elem.PaxType === 'ADT'
            ? t('results.flights.adult')
            : elem.PaxType === 'CHD'
              ? t('results.flights.kid')
              : t('results.flights.baby');
          description.push(t('results.flights.pieceKgPerPassenger', { kg, passenger: passenger.toLowerCase() }));
        })
      }

      description.push(t('results.flights.largeBaggageDescription'));

      mediumBaggage.title = t('results.flights.mediumBaggageTitle');
      mediumBaggage.description = t('results.flights.mediumBaggageDescription');
      mediumBaggage.icon = <MediumBaggage />

      largeBaggage.title = t('results.flights.largeBaggageIncluded');
      largeBaggage.description = description.join(' ');
      largeBaggage.icon = <LargeBaggage />
    } else if (option?.Segments?.[0]?.CarryOnBagInfo) {
      mediumBaggage.title = t('results.flights.mediumBaggageTitle');
      mediumBaggage.description = t('results.flights.mediumBaggageDescription');
      mediumBaggage.icon = <MediumBaggage />
    }

    baggages.push(mediumBaggage);
    baggages.push(largeBaggage);

    return <Grid container className='baggage-container' spacing={1}>
      {baggages.map((elem, i) => (
        <Grid item xs={vertical ? 12 : 4} key={i}>
          <Grid container className='row' key={i} spacing={1} columns={24}>
            <Grid item xs={3} className='icon-container'>{elem.icon}</Grid>
            <Grid item xs={21} className='column baggage-description'>
              <b>{elem.title}</b>
              {elem.description && <span>{elem.description}</span>}
            </Grid>
          </Grid>
        </ Grid>
      ))}
    </Grid>
  }

  const getBaggageIcons = (option) => {
    let mediumBaggageClassName = 'not-included';
    let largeBaggageClassName = 'not-included';

    if (!['0PC', '0K'].includes(option?.Segments?.[0]?.Baggage)) {
      mediumBaggageClassName = '';
      largeBaggageClassName = '';
    } else if (option?.Segments?.[0]?.CarryOnBagInfo) {
      mediumBaggageClassName = '';
    }

    return <Tooltip title={getBaggages(option)}>
      <SmallBaggage />
      <MediumBaggage className={mediumBaggageClassName} />
      <LargeBaggage className={largeBaggageClassName} />
    </Tooltip>
  }

  const getAirlineLogo = (option) => {
    const providers = [...new Set(option?.Segments?.map(elem => elem.Airline))];

    if (providers.length === 1) {
      return <Image
        img={`providersFlights/${providers[0]}.png`}
        alt='logo-provider'
        className='airline-logo'
        hideBrokenImage
      />
    } else {
      return <div className='row'>
        <MultiplesAirlines className='multiple-logo' />
        &nbsp;<span>{t('results.flights.multipleAirlines')}</span>
      </div>
    }
  }

  const getOptionContainer = (option) => {
    const segmentsLength = option?.Segments?.length;
    const departure = option?.Segments?.[0]?.Departure;
    const arrival = option?.Segments?.[segmentsLength - 1]?.Arrival;

    return <Grid container columns={24}>
      <Grid item className='row' xs={3}>
        {getAirlineLogo(option)}
      </Grid>

      <Grid item className='column' xs={6}>
        <Tooltip title={departure?.AirportName || ''}>
          <Typography className='title'>{departure?.AirportCode}</Typography>
        </Tooltip>
        <Typography className='time'>{departure?.Time}</Typography>
      </Grid>

      <Grid item className='column' xs={6}>
        <Tooltip title={getScalesTooltip(option?.Segments)} placement='top'>
          <Typography className={`scales-text ${segmentsLength === 1 && 'direct'}`}>
            {segmentsLength > 1 && <>{segmentsLength - 1}&nbsp;</>}
            {t(`results.flights.${segmentsLength === 1 ? 'direct' : segmentsLength === 2 ? 'scale' : 'scales'}`)}
          </Typography>
        </Tooltip>
        <div className='scale-line'>
          <div className='circles-container row'>
            {option?.Segments.slice(1).map((elem, i) => <div key={i} className='circle' />)}
          </div>
        </div>
        <span>
          <Tooltip title={getSegmentsDuration(option?.Segments)} placement='bottom'>
            <span className='duration'>{option.OptionDurationHours}h {option.OptionDurationMinutes}m</span>
          </Tooltip>
          {option.DayAfter > 0 && (
            <Tooltip title={getDaysAfterTooltip(option.DayAfter)} placement='bottom'>
              <span className='plus-days'>+{option.DayAfter}D</span>
            </Tooltip>
          )}
        </span>
      </Grid>

      <Grid item className='column' xs={6}>
        <Tooltip title={arrival?.AirportName || ''}>
          <Typography className='title'>{arrival?.AirportCode}</Typography>
        </Tooltip>
        <Typography className='time'>{arrival?.Time}</Typography>
      </Grid>

      <Grid item className='row baggage-container-icons' xs={3}>
        {getBaggageIcons(option)}
      </Grid>
    </Grid>
  }

  const getOptionContainerMobile = (option) => {
    const segmentsLength = option?.Segments?.length;
    const departure = option?.Segments?.[0]?.Departure;
    const arrival = option?.Segments?.[segmentsLength - 1]?.Arrival;

    return <Grid container columns={24}>
      <Grid item className='column' xs={5}>
        <Tooltip title={departure?.AirportName || ''}>
          <Typography className='title'>{departure?.AirportCode}</Typography>
        </Tooltip>
        <Typography className='time'>{departure?.Time}</Typography>
      </Grid>

      <Grid item className='column scales-container' xs={5}>
        <Typography className={`scales-text ${segmentsLength === 1 && 'direct'}`}>
          {segmentsLength > 1 && <>{segmentsLength - 1}&nbsp;</>}
          {t(`results.flights.${segmentsLength === 1 ? 'direct' : segmentsLength === 2 ? 'scale' : 'scales'}`)}
        </Typography>
      </Grid>

      <Grid item className='column' xs={5}>
        <Tooltip title={arrival?.AirportName || ''}>
          <Typography className='title'>{arrival?.AirportCode}</Typography>
        </Tooltip>
        <Typography className='time'>{arrival?.Time}</Typography>
      </Grid>

      <Grid item className='row baggage-container-icons' xs={9}>
        {getBaggageIcons(option)}
      </Grid>

      <Grid item xs={24} className='duration-container'>
        <span className='total-duration'>{t('results.flights.totalDuration')}:&nbsp;</span>
        <span className='duration'>{option.OptionDurationHours}h {option.OptionDurationMinutes}m</span>&nbsp;
        <span className='plus-days'>+{option.DayAfter}D</span>
      </Grid>
    </Grid>
  }

  const handleSelectOption = (event, index, optionId) => {
    event.stopPropagation();

    let selectedFlightsAux = [...selectedFlights];
    selectedFlightsAux[index] = optionId;
    setSelectedFlights(selectedFlightsAux);
  }

  const handleClickMoreRates = (startCheckout = false) => {
    const { tripType, currency } = params;
    const token = currentProduct?.config?.['API-Token']?.[currency];
    const dataToSend = {
      recommendationID,
      fareID: flight.FareID,
      optionID: selectedFlights,
      flightType: tripType
    };
    setAvailabilityRequest(dataToSend);
    props.getAvailability(token, dataToSend);

    if (startCheckout) {
      setLoadingCheckout(true);
    } else {
      setLoadingAvailability(true);
    }
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  const getPriceByPaxTooltip = () => {
    return flight.PaxFares.map((elem, i) => {
      const fare = elem?.PaxFareAmount || 0;
      const taxes = elem?.PaxTaxAmount || 0;
      const fee = flight.ServiceAmount || 0;
      const currency = getCurrency(clientData?.client, currencies, flight.Currency);

      return <Typography key={i}>
        {t('results.flights.by')} {elem.PaxType}&nbsp;
        {currency} {formatNumber(fare)}&nbsp;
        + {t('results.flights.taxesAbb')}&nbsp;
        {currency} {formatNumber(taxes + fee)}
      </Typography>
    })
  }

  const getPrice = (flight) => {
    return flight.PaxFares.map((elem, i) => {
      let price = null;
      if (flight.ExtendedFareInfo) {
        const fare = elem?.PaxTotalAmount || 0;
        const fee = flight.ExtendedFareInfo?.Fee?.PaxDetail?.find(fee => fee?.PTC === elem?.PaxType)?.AmountPerPax || 0;
        const commission = flight.ExtendedFareInfo?.Commission?.PaxDetail?.find(comission => comission?.PTC === elem?.PaxType)?.AmountPerPax;
        const over = flight.ExtendedFareInfo?.Over?.PaxDetail?.find(over => over?.PTC === elem?.PaxType)?.AmountPerPax;
        price = (fare + fee - commission - over) * elem.Count;
      } else {
        const fare = elem?.PaxFareAmount || 0;
        const taxes = elem?.PaxTaxAmount || 0;
        const fee = flight.ServiceAmount || 0;
        price = (fare + taxes + fee) * elem.Count;
      }

      return <div className='row price-detail' key={i}>
        <Typography>{t(`results.flights.${PAX_TYPES[elem.PaxType]}`)}</Typography>&nbsp;
        <Typography>{getCurrency(clientData?.client, currencies, flight.Currency)} {formatNumber(price)}</Typography>
      </div>
    })
  }

  const getPriceB2C = (flight) => {
    const { currency } = params;
    const onlyArgentina = flight?.Legs?.every(leg => {
      const firstSegment = leg?.Options?.[0]?.Segments?.[0];
      const lastSegment = leg?.Options?.[0]?.Segments?.[leg?.Options?.[0]?.Segments?.length - 1];
      return lastSegment?.Departure?.CountryName === 'Argentina' && firstSegment?.Arrival?.CountryName === 'Argentina';
    });
    const flightCurrency = getCurrency(clientData?.client, currencies, flight.Currency);

    return <>
      {flight.PaxFares.map((elem, i) => {
        let price = null;
        if (flight.ExtendedFareInfo) {
          const fare = elem?.PaxTotalAmount || 0;
          const fee = flight.ExtendedFareInfo?.Fee?.PaxDetail?.find(fee => fee?.PTC === elem?.PaxType)?.AmountPerPax || 0;
          const commission = flight.ExtendedFareInfo?.Commission?.PaxDetail?.find(comission => comission?.PTC === elem?.PaxType)?.AmountPerPax;
          const over = flight.ExtendedFareInfo?.Over?.PaxDetail?.find(over => over?.PTC === elem?.PaxType)?.AmountPerPax;
          price = (fare + fee - commission - over) * elem.Count;
        } else {
          const fare = elem?.PaxFareAmount || 0;
          const taxes = elem?.PaxTaxAmount || 0;
          const fee = flight.ServiceAmount || 0;
          price = (fare + taxes + fee) * elem.Count;
        }

        return <div className='row price-detail' key={i}>
          <Typography>{t(`results.flights.${PAX_TYPES[elem.PaxType]}`)}</Typography>&nbsp;
          <Typography>{flightCurrency} {formatNumber(price)}</Typography>
        </div>
      })}

      {currency === 'ARS' && !onlyArgentina && (
        <Typography className='taxes-message'>
          {t('results.flights.includeTaxes')}
        </Typography>
      )}

      <div className='mt-05 row price-detail'>
        <b>{t('results.flights.total')}</b>&nbsp;
        <b>{flightCurrency} {formatNumber(flight?.ExtendedFareInfo?.NetTotalAmountWithFee || flight?.TotalAmount || 0)}</b>
      </div>
    </>
  }

  const handleClickReserve = async (availabilityAux) => {
    if (availabilityAux) {
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      const accessToken = localStorage.getItem('jwt');
      const tokenCheckout = currentProduct?.config?.api_checkout_token || currentProduct?.config_work_unit?.api_checkout_token;
      const { currency } = params;
      const token = currentProduct?.config?.['API-Token']?.[currency];

      if (user && accessToken && tokenCheckout && token) {
        setLoadingCheckout(true);

        const clientIp = await getClientIp();
        const urlRedirect = location.pathname;
        const userProducts = JSON.parse(localStorage.getItem('userProducts') || '{}');
        const userProduct = {
          work_unit_relation: {
            token: userProducts?.work_unit_relation?.[0]?.token || null,
            long_name: userProducts?.work_unit_relation?.[0]?.long_name || null
          },
          username: userProducts?.username,
          hash_client: userProducts?.hash_client,
          products: [
            {
              product_type_id: currentProduct?.product_type_id || null,
              ref_table_detail: currentProduct?.ref_table_detail || null,
              parameters: currentProduct?.parameters || null,
              work_unit_id: currentProduct?.work_unit_id || null,
              token_id: currentProduct?.token_id || null,
              work_unit_token: currentProduct?.work_unit_token || null,
              business_unit_token: currentProduct?.business_unit_token || null
            }
          ]
        }

        const requestData = {
          module: clientData?.client?.isB2C ? 'flights' : 'airNarrative',
          dataProduct: {
            recommendationID,
            fareId: flight.FareID,
            optionId: selectedFlights,
            brandedFare: null,
            brandedFareIndex: null,
            adults: params.adults,
            children: params.kids,
            infants: params.babys,
            dateFrom: params.datesDeparture,
            dateTo: params.datesReturn,
            urlRedirect,
            supplier: null,
            ip: clientIp,
            utm: '',
            loginData: null,
            cabin: params.cabinClasses,
            urlMeta: window.location.href,
            token,
            currency
          },
          username: user?.username,
          user_product: userProduct,
          deviceType: isMobile ? 'mobile' : 'desktop'
        };

        DatalayerBotonComprar(
          "botonComprar",
          'flights',
          searchedProduct,
          flight,
          null);
        props.startCheckout(accessToken, tokenCheckout, requestData);
      } else {
        const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
        if (iframePath) {
          localStorage.clear();
          setClientData(null);
          navigate(iframePath);
        } else {
          navigate('/login', { state: { expiredSession: true } });
        }
      }
    } else {
      handleClickMoreRates(true);
      setStartCheckout(true);
    }
  }

  const handleClickCompareAux = (checked) => {
    const flightToCompare = { ...flight };
    flightToCompare.Options = selectedFlights.map((option, i) => (
      flight?.Legs?.[i]?.Options.find(elem => elem.FlightOptionID === option)
    ));
    handleClickCompare && handleClickCompare(flightToCompare, checked);
  }

  const handleExtendLeg = (index, expanded) => {
    if (expanded) {
      setExtendedLegs(current => current.filter(elem => elem !== index));
    } else {
      setExtendedLegs(current => [...current, index]);
    }
  }

  const getLegTitle = (leg) => {
    return flight.Legs.length > 2
      ? `${t('results.flights.flight')} ${leg.LegNumber}`
      : leg.LegNumber === 2
        ? t('results.flights.return')
        : t('results.flights.departure');
  }

  const getLegOptions = (index) => {
    const options = (flight.Legs?.[index]?.Options?.length < 3 || extendedLegs.includes(index))
      ? flight.Legs[index].Options
      : flight.Legs[index].Options.slice(0, 3);
    return options;
  }

  const getShowMoreButton = (index) => {
    if (flight.Legs?.[index]?.Options?.length > 3) {
      const expanded = extendedLegs.includes(index);
      if (expanded) {
        return <Button onClick={() => handleExtendLeg(index, expanded)} className='more-options-button'>
          <RemoveCircleOutline />
          {t('results.flights.showLessResultsOf')} {getLegTitle(flight.Legs?.[index])}
        </Button>
      } else {
        return <Button onClick={() => handleExtendLeg(index, expanded)} className='more-options-button'>
          <AddCircleOutline />
          {t('results.flights.showMoreResultsOf')} {getLegTitle(flight.Legs?.[index])}
        </Button>
      }
    }
  }

  const getMoreRates = () => {
    return <Grid container className='more-rates-container'>
      <Grid item xs={6} className='row'>
        <Hidden smDown>
          <BaggageImage className='baggage-image' />
        </Hidden>
        <Typography className='text'>{t('results.flights.improveFlight')}</Typography>
      </Grid>
      <Grid item xs={6} className='row button-container'>
        <Button
          variant='outlined'
          disabled={notAvailable}
          onClick={() => handleClickMoreRates()}
        >
          {t('results.flights.seeMoreRates')} <ExpandMore />
        </Button>
      </Grid>
    </Grid>
  }

  const handleOpenDetail = (detail) => {
    setOpenDetail(detail);
  }

  const handleCloseDetail = () => {
    setOpenDetail(null);
  }

  const sendAvailabilityMetrics = (isError, availabilityResponse) => {
    const accessToken = localStorage.getItem('jwt');
    const tokenProduct = currentProduct?.token_id;
    const metricsPayload = {
      module: "flights",
      flow: "availability",
      data: {
        request: {
          recommendationID: availabilityRequest?.recommendationID || "",
          fareID: availabilityRequest?.fareID || "",
          optionID: availabilityRequest?.optionID || []
        },
        flight_type: isError
          ? availabilityRequest?.flightType || "multidestination"
          : (availabilityResponse.data.Booking.BookingFare.FlightType || "multidestination"),
        response: isError
          ? {
              status: false,
              data: null,
              error: errorAvailability?.error || {}
            }
          : {
              status: true,
              data: availabilityResponse.data
            },
        currency: params.currency || "ARS"
      }
    };
    props.reportMetricsProducts(accessToken, tokenProduct, metricsPayload);
  };
  // AQUÍ FINALIZA EL CAMBIO

  return (
    <Grid className={`flight-card ${flight.recommended ? 'recommended' : ''}`}>
      {flight.recommended && (
        <Grid className='row recommended-container'>
          <StarOutline /> {t('results.flights.flightRecommended')}
        </Grid>
      )}

      <Grid container className='flight-content' columns={24}>
        <Grid item xs={24} sm={17} md={16} className='left'>
          {flight.Legs.map((leg, i) => {
            const isReturn = flight.Legs.length === 2 && leg.LegNumber === 2;
            return <Grid key={i} className='leg-container'>
              <Grid className='row leg-title-container'>
                <Grid className='left row'>
                  <Grid className='row trip-type-container'>
                    <Flight className={`flight-icon ${isReturn && 'return'}`} />
                    {getLegTitle(leg)}
                  </Grid>
                  <Hidden smDown>
                    <Typography className='title'>{getLegTitleDestination(leg)}</Typography>
                  </Hidden>
                  <Typography className='row date'>
                    <CalendarToday fontSize='small' /> {getDate(leg)}
                  </Typography>
                </Grid>
                <Grid className='row cabin-class'>
                  <AirplaneTicketOutlined /> {getCabinClass(leg)}
                </Grid>
              </Grid>
              <Grid className='leg-options-container'>
                <FormControl fullWidth>
                  <RadioGroup value={selectedFlights[i] || null}>
                    {getLegOptions(i).map((option, j) => (
                      <Fragment key={j}>
                        <Hidden smDown>
                          <Accordion className='option-accordion'>
                            <AccordionSummary expandIcon={<ExpandMore />} onClick={e => e.preventDefault()}>
                              <FormControlLabel
                                value={option.FlightOptionID}
                                control={<Radio onClick={e => handleSelectOption(e, i, option.FlightOptionID)} />}
                                label={getOptionContainer(option)}
                                className='option-container'
                              />
                            </AccordionSummary>
                            <AccordionDetails>
                              <FlightScales option={option} />
                              {getBaggages(option, false)}
                            </AccordionDetails>
                          </Accordion>
                        </Hidden>

                        <Hidden smUp>
                          <Grid className='option-container-mobile'>
                            <FormControlLabel
                              value={option.FlightOptionID}
                              control={<Radio onClick={e => handleSelectOption(e, i, option.FlightOptionID)} />}
                              label={<div className='row'>
                                {getAirlineLogo(option)}

                                <IconButton onClick={() => handleOpenDetail(option)}>
                                  <KeyboardArrowRight fontSize='large' />
                                </IconButton>
                              </div>}
                            />
                            {getOptionContainerMobile(option)}
                          </Grid>
                        </Hidden>
                      </Fragment>
                    ))}
                    {getShowMoreButton(i)}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          })}
          <Hidden smDown>
            <Divider style={{ marginBottom: 10 }} />
            {getMoreRates()}
          </Hidden>
        </Grid>

        <Grid item xs={24} sm={7} md={8} className='right'>
          {!client?.client?.isB2C && (
            <Grid className='top row'>
              <div>
                <Image
                  img={`fareSupplierFlights/${flight?.FareSupplierCode}.png`}
                  alt='logo-provider'
                  className='provider-logo'
                  hideBrokenImage
                />
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    disabled={!selectedToCompare && disabledCompare}
                    checked={selectedToCompare}
                    onChange={(e, checked) => handleClickCompareAux(checked)}
                  />
                }
                label={t('results.flights.compare')}
                className='compare-checkbox'
              />
            </Grid>
          )}

          <Grid className='bottom column'>
            {!client?.client?.isB2C && (
              <Grid className='fare-type-container'>
                {flight.FareType === 'N' ? t('results.flights.privateFare') : t('results.flights.publicFare')}
              </Grid>
            )}
            <Typography className='total-price-text'>{t('results.flights.totalPrice')}</Typography>
            <Typography className='amount'>{getCurrency(clientData?.client, currencies, flight.Currency)} {formatNumber(flight?.ExtendedFareInfo?.NetTotalAmountWithFee || flight?.TotalAmount || 0)}</Typography>
            <Accordion className='detail-accordion'>
              <AccordionSummary expandIcon={<ExpandMore />}>
                {t('results.flights.seeDetail')}
              </AccordionSummary>
              <AccordionDetails>
                {client?.client?.isB2C ? (
                  getPriceB2C(flight)
                ) : (
                  <Tooltip title={getPriceByPaxTooltip()}>
                    {getPrice(flight)}
                  </Tooltip>
                )}
              </AccordionDetails>
            </Accordion>
            <Button
              size='small'
              variant='contained'
              color='secondary'
              className='reserve-button'
              disabled={notAvailable}
              onClick={() => handleClickReserve(availability)}
            >
              {clientData?.client?.isB2C ? t('common.buy') : t('common.reserve')}
            </Button>
          </Grid>
        </Grid>

        <Hidden smUp>
          <Grid item xs={24}>
            {getMoreRates()}
          </Grid>
        </Hidden>
      </Grid>

      <Snackbar
        open={Boolean(messageSnackbar)}
        autoHideDuration={4000}
        onClose={() => setMessageSnackbar(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setMessageSnackbar(null)} severity="error" sx={{ width: '100%' }}>
          {messageSnackbar}
        </Alert>
      </Snackbar>

      {loadingAvailability && (
        <Preloader
          addDots
          image={`sites/${client.client.name}/preloadAereos.gif`}
          text={t('common.searchingBestPrices')}
        />
      )}

      {loadingCheckout && (
        <Preloader
          addDots
          image={`sites/${client.client.name}/preloadAereos.gif`}
          text={t('common.processingRequest')}
        />
      )}

      <AvailabilityModal
        recommendationID={recommendationID}
        flight={flight}
        client={client}
        selectedFlights={selectedFlights}
        open={openModal}
        availability={availability}
        handleClose={handleCloseModal}
      />

      <Hidden smUp>
        <Drawer
          variant='temporary'
          className='flights-scales-drawer'
          open={openDetail}
          onClose={handleCloseDetail}
        >
          <FlightScales
            option={openDetail}
            handleCloseDetail={handleCloseDetail}
          />
          {getBaggages(openDetail)}
        </Drawer>
      </Hidden>
    </Grid>
  );
};

const mapStateToProps = reducers => {
  return reducers.flightsReducer;
};

const mapDispatchToProps = dispatch => {
  return {
    getAvailability: (token, data, signalAbort = null) => dispatch(availabilityActionFlights(token, data, signalAbort)),
    startCheckout: (access, tokenCheckout, data) => dispatch(startCheckoutAction(access, tokenCheckout, data)),
    reportMetricsProducts: (token, tokenProduct, data, signalAbort = null) => dispatch(reportMetricsActionFlights(token, tokenProduct, data, signalAbort))

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FlightCard);
