import React, { Fragment, memo, useMemo } from 'react';
import { Grid, Typography, Icon, Rating } from '@mui/material';

const CardTwoColsV2 = ({ template, item, detailProduct }) => {
  const renderedDetailProduct = useMemo(() => (
    detailProduct.map((product, i) => (
      <li key={i} className='list-item'>
        {product.className.includes('stars') ? (
          <Rating value={parseInt(product.description)} className={product.className} readOnly />
        ) : (
          <Typography component='p' className={`${product.className} ${product.icon ? 'has-icon' : ''}`}>
            {product.icon && <Icon>{product.icon}</Icon>}
            {product.title && (
              <span className={product.className.includes('strong-title') ? 'strong' : ''}>
                {product.title}&nbsp;
              </span>
            )}
            {product.description}
          </Typography>
        )}
      </li>
    ))
  ), [detailProduct]);

  return (
    <div className={template}>
      <Grid container spacing={1}>
        <Grid item xs={2} md={3}>
          <div className='detail-item-header'>
            {item.image && <img className='detail-item-image' src={item.image} alt={item.title} />}
          </div>
        </Grid>
        <Grid item xs={10} md={9}>
          {item.title && (
            <Typography className='detail-item-title' variant='h4'>
              {item.title}
            </Typography>
          )}
          <Fragment>
            <ul className='detail-item-list products'>
              {renderedDetailProduct}
            </ul>
          </Fragment>
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(CardTwoColsV2);
