import React, { Fragment } from 'react';
import { Avatar, Badge, Card, CardContent, CardHeader, Grid, Hidden, Icon, Typography } from '@mui/material';
import DetailDialog from './DetailDialog';
import * as HotelsDetail from './utils/HotelsDetail';
import * as TrainsDetail from './utils/TrainsDetail';
import * as FlightsDetail from './utils/FlightsDetail';
import { useTranslation } from 'react-i18next';
import { useClientData } from '../../../context/ClientContext';
import { usePurchaseDetailContext } from '../../../context/PurchaseDetailContext';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

const PurchaseDetail = ({ purchaseDetail, products, ...props }) => {
  const { t } = useTranslation();
  const params = useParams();
  const { clientData } = useClientData();
  const { includeComission, addCart } = usePurchaseDetailContext();

  const getProductsDetails = () => {
    if (params.module === 'hotels') {
      return HotelsDetail.getProductsDetails(purchaseDetail);
    } else if (params.module === 'trains') {
      return TrainsDetail.getProductsDetails(purchaseDetail);
    } else if (params.module === 'flights') {
      return FlightsDetail.getProductsDetails(purchaseDetail);
    }
  }

  const getPriceDetails = () => {
    if (params.module === 'trains') {
      return TrainsDetail.getPriceDetails(purchaseDetail, includeComission, false);
    } else if (params.module === 'flights') {
      return FlightsDetail.getPriceDetails(clientData?.client, purchaseDetail);
    }

    return [
      {
        title: 'total',
        value: purchaseDetail?.totalAmount,
        classname: 'strong amount',
        showTax: true
      }
    ];
  }

  const getBadgeLength = () => {
    return [
      ...products.pivot?.map(elem => elem.priceDetail),
      ...Object.values(addCart)?.map(elem => elem.priceDetail)
    ]?.filter(elem => elem.addToCart)?.length || 0;
  }

  return (
    <Card className='purchase-card'>
      <CardHeader
        avatar={
          <Avatar className='avatar-purchase-detail'>
            <Badge
              badgeContent={getBadgeLength()}
              color='secondary'
              overlap='circular'
            >
              <Icon>{purchaseDetail?.icon}</Icon>
            </Badge>
          </Avatar>
        }
        action={products &&
          <DetailDialog
            icon='expand_more'
            purchaseDetail={purchaseDetail}
            products={products}
            module={params.module}
          />
        }
        title={
          <Typography className='title-card-header'>
            {purchaseDetail?.title}
          </Typography>
        }
        subheader={
          <Typography noWrap className='title-card-subheader' >
						{purchaseDetail?.subtitle}
          </Typography>
        }
      />

      <Hidden smDown>
        <CardContent>
          {getProductsDetails()}
        </CardContent>
      </Hidden>

      <Grid container className='price-details-container row'>
        {getPriceDetails().map((elem, i) => (
          <Fragment key={i}>
            {elem.value && parseInt(elem.value.replaceAll('.', '')) > 0 && (
              <>
                <Grid item xs={6} className='left'>
                  <Typography className={elem.classname}>{elem.noTranslate ? elem.title : t(`checkout.common.${elem.title}`)}</Typography>
                  {elem.showTax && Boolean(purchaseDetail?.taxAmount) && purchaseDetail?.taxAmount > 0 && (
                    <Typography className='taxes'>
                      {t('checkout.common.taxesIncluded')} {purchaseDetail?.currency} {purchaseDetail?.taxAmount}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6} className='right'>
                  <Typography className={`text-right ${elem.classname}`}>
                    {elem.parentehsis && '('}
                    {purchaseDetail?.currency} {elem.value}
                    {elem.parentehsis && ')'}
                  </Typography>
                </Grid>
              </>
            )}
          </Fragment>
        ))}
      </Grid>
    </Card>
  )
}

const mapStateToProps = reducers => {
  return reducers.checkoutReducer;
};

export default connect(mapStateToProps, null)(PurchaseDetail);
