import React, {Fragment, useState} from 'react';
import { Grid, Icon, Popover, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { formatNumber } from '../../../utils/price';

export default function InfoPopover(props) {
  const [anchorEl, setAnchorEl] = useState();

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Fragment>
      <InfoOutlined
        className="info-charge-button"
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="mouse-over-popover"
        className={`charge-popover ${props.className}`}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {props.charge.map((item, i) => (
          <Grid container className="item-resume" key={i}>
            {!(item.title === "Cargos" && item.valor < 0) && (
              <>
                <Grid item xs={item.valor ? 6 : 12}>
                  <Typography variant="body2">{item.title} </Typography>
                </Grid>
                {item.valor && (
                  <Grid item xs={6}>
                    <Typography noWrap variant="body2" className="text-right">{props.currency} {formatNumber(item.valorReal || 0, 2)}</Typography>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        ))}

        {props.messages?.map((message, i) => (
          <Typography key={i} className={message?.className || ''}>
            {message.icon && <Icon>{message.icon}</Icon>}
            {message.text}
          </Typography>
        ))}
      </Popover>
    </Fragment>
  );
}
