import React from 'react'
import { Grid, Typography, Icon, Button } from '@mui/material';
import { Info, Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { useLocation } from 'react-router-dom';

const CardStatusTransaction = ({
  reservationNumber,
  operationNumber,
  content,
  minutes,
  seconds,
  handleSelectReservation,
  ...props
}) => {
  const { t } = useTranslation();
  
  const location = useLocation();

  return (
    <Grid className='content'>
      {content.show_idTransaction && (reservationNumber || operationNumber) &&
        <Grid container className="row row-booking-number mb-1">
          <Grid item xs={12} sm={6}>
            <Typography className="text-left">
              <strong>{t(`checkout.common.${reservationNumber ? 'bookingNumber' : 'operationNumber'}`)}:</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className="booking-number">
              {reservationNumber || operationNumber}
            </Typography>
          </Grid>
        </Grid>
      }

      {content.show_status_booking &&
        <Grid container className="row row-status-booking mb-1">
          <Grid item xs={6}><Typography className="text-left">
            <strong>{t('checkout.common.bookingStatus')}:</strong></Typography></Grid>
          <Grid item xs={6}>
            <Typography className='status-booking'>
              <Icon className={`status-booking-icon ${content.status_booking ? `status-${content.status_booking}` : ''}`}>
                {content.status_booking_icon}
              </Icon>
              <span>{t(`checkout.thankYouPage.${content.status_booking_title}`)}</span>
            </Typography>

            {content.show_booking_detail && (
              <Typography className='booking-detail'>
                {content.booking_detail_message}
              </Typography>
            )}
          </Grid>
        </Grid>
      }

      {content.show_status_payment &&
        <Grid container className="row row-status-payment mb-1">
          <Grid item xs={6}><Typography className="text-left"><strong>
            {t('checkout.common.paymentStatus')}:</strong></Typography></Grid>
          <Grid item xs={6}>
            <Typography className='status-payment'>
              <Icon className={`status-payment-icon ${content.status_payment ? `status-${content.status_payment}` : ''}`}>
                {content.status_payment_icon}
              </Icon>
              <span>{t(`checkout.thankYouPage.${content.status_payment_title}`)}</span>
            </Typography>
          </Grid>
        </Grid>
      }

      {content.messages?.map((message, i) => (
        <Grid className={`message-container mt-2 mb-1 ${message.className || ''}`} key={i}>
          {!message.hideInfoIcon && <Info className='info-icon' />}
          {message.title && <Typography><strong>{t(`checkout.thankYouPage.${message.title}`)}</strong></Typography>}
            {location?.state?.redirectUrl
              ? <Typography>{t(`checkout.thankYouPage.noAvailableFee`)}</Typography>
              : <Typography>{t(`checkout.thankYouPage.${message.subtitle}`)}</Typography>
            }
          {message.show_timer && minutes !== undefined && seconds !== undefined && (
            <Typography className='mt-05 timer'>
              {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            </Typography>
          )}
          {message.addNewSearchButton && (
            <Button
              startIcon={<Search />}
              className='new-search-button'
              onClick={() => handleSelectReservation(reservationNumber)}
            >
                {location?.state?.redirectUrl
                  ? t(`checkout.thankYouPage.noAvailableFeeButton`)
                  : t(`checkout.thankYouPage.${message.newSearchButtonTitle}`)
                }
            </Button>
          )}
        </Grid>
      ))}
    </Grid>
  )
}

export default CardStatusTransaction;
