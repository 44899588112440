import { createContext, useContext, useState } from 'react';
import { formatNumber } from '../utils/price';

const PuchaseDetailContext = createContext();

export const PuchaseDetailProvider = ({ children }) => {
  const [purchaseDetail, setPurchaseDetail] = useState();
  const [products, setProducts] = useState();
  const [includeComission, setIncludeComission] = useState(false);
  const [interest, setInterest] = useState();

  const calculateTotals = (priceDetail) => {
    const totalAmountReal = priceDetail.reduce((acc, current) => {
      const price = current.addToCart
        ? Number(current.amountReal || 0)
        : 0;
      return acc + price;
    }, 0);

    return {
      totalAmountReal,
      totalAmount: formatNumber(totalAmountReal, 2),
    };
  }

  const handleAddCrosselToCart = (product) => {
    const purchaseDetailAux = { ...purchaseDetail, priceDetail: [...(purchaseDetail?.priceDetail || [])] };
    const productsAux = { ...products, pivot: [...(products?.pivot || [])] };
    let { priceDetail, cardDetail, module } = product;

    if (module === 'brandedFares') {
      priceDetail.title = productsAux?.pivot?.[0]?.priceDetail?.title || priceDetail.title;
      productsAux.pivot = [
        {
          ...productsAux.pivot[0],
          priceDetail,
          brandedFare: product
        },
        ...productsAux.pivot.slice(1)
      ];
      const index = purchaseDetailAux?.priceDetail?.findIndex(elem => elem.title === priceDetail.title);
      if (index > -1) {
        purchaseDetailAux.priceDetail[index] = priceDetail;
      }
    } else {
      const updatedPriceDetail = { ...priceDetail, module };
      const updatedCardDetail = { ...cardDetail, module };

      const index = purchaseDetailAux.priceDetail.findIndex(elem => elem.module === module);

      if (index > -1) {
        purchaseDetailAux.priceDetail[index] = updatedPriceDetail;
        productsAux.pivot[index] = { ...product, priceDetail: updatedPriceDetail, cardDetail: updatedCardDetail };
      } else {
        purchaseDetailAux.priceDetail.push(updatedPriceDetail);
        productsAux.pivot.push({ ...product, priceDetail: updatedPriceDetail, cardDetail: updatedCardDetail });
      }
    }

    const { totalAmountReal, totalAmount } = calculateTotals(purchaseDetailAux.priceDetail);
    purchaseDetailAux.totalAmountReal = totalAmountReal;
    purchaseDetailAux.totalAmount = totalAmount;

    setPurchaseDetail(purchaseDetailAux);
    setProducts(productsAux);
  }

  const handleRemoveCrosselToCart = (module) => {
    const priceDetail = purchaseDetail?.priceDetail?.filter(item => item.module !== module) || [];
    const { totalAmountReal, totalAmount } = calculateTotals(priceDetail);
    const purchaseDetailAux = {
      ...purchaseDetail,
      priceDetail,
      totalAmountReal,
      totalAmount
    };

    const productsAux = {
      ...products,
      pivot: products?.pivot?.filter(product => product.module !== module) || []
    };

    setPurchaseDetail(purchaseDetailAux);
    setProducts(productsAux);
  }

  const refreshTotalAmount = (totalAmount = null) => {
    if (purchaseDetail) {
      const purchaseDetailAux = JSON.parse(JSON.stringify(purchaseDetail));
      if (totalAmount !== null) {
        purchaseDetailAux.totalAmountFinal = totalAmount;
        purchaseDetailAux.totalAmountRealFinal = Number(totalAmount || 0);
      } else {
        delete purchaseDetailAux.totalAmountFinal;
        delete purchaseDetailAux.totalAmountRealFinal;
      }
      setPurchaseDetail(purchaseDetailAux);
    }
  }

  return (
    <PuchaseDetailContext.Provider value={{
      purchaseDetail,
      setPurchaseDetail,
      products,
      setProducts,
      includeComission,
      setIncludeComission,
      handleAddCrosselToCart,
      handleRemoveCrosselToCart,
      interest,
      setInterest,
      refreshTotalAmount
    }}>
      {children}
    </PuchaseDetailContext.Provider>
  );
};

export const usePurchaseDetailContext = () => {
  return useContext(PuchaseDetailContext);
};
