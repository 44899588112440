import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardHeader, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, Icon, IconButton, Typography } from '@mui/material';
import { PayPalButtons, PayPalHostedField, PayPalHostedFieldsProvider, PayPalScriptProvider, usePayPalHostedFields } from '@paypal/react-paypal-js';
import * as TrainsDetail from './../utils/TrainsDetail';
import { useTranslation } from 'react-i18next';
import { captureOrder, generateClientToken, createOrder as createOrderPayPalHostedFields } from '../../../../store/services/Paypal';
import { ArrowForward, CreditCard } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useClientData } from '../../../../context/ClientContext';
import Timer from '../Timer';
import { usePurchaseDetailContext } from '../../../../context/PurchaseDetailContext';

const SubmitPayment = ({ inputRefCardName, inputRefZipCode, onApprovePayPalHostedFieldsProvider }) => {
  const { t } = useTranslation();
  const { cardFields } = usePayPalHostedFields();
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    const cardHolderName = inputRefCardName.current?.value;
    const zipCode = inputRefZipCode.current?.value;

    if (typeof cardFields.submit !== 'function') return; // validate that \`submit()\` exists before using it

    if (Object.values(cardFields.getState().fields).some((field) => !field.isValid) || !cardHolderName || !zipCode) {
      setIsFormInvalid(true);
      setLoading(false);
    } else {
      setIsFormInvalid(false);
      setLoading(true);

      cardFields
        .submit({
          cardholderName: cardHolderName,
          zipCode: zipCode,
        })
        .then(async (data) => {
          await onApprovePayPalHostedFieldsProvider(data.orderId)
        })
        .catch((orderData) => {
          console.error('catch, submit payment', orderData)
        });
    }
  };

  return (
    <>
      {isFormInvalid && <p style={{ color: 'red', marginTop: 10 }}>
        {t('checkout.common.completeAllFieldsPaypal')}
      </p>}
      <LoadingButton
        variant='contained'
        className='pay-button'
        color='secondary'
        disabled={!cardFields}
        loading={loading}
        onClick={onSubmit}
      >
        <span>{t('checkout.common.pay')}</span><ArrowForward />
      </LoadingButton>
    </>
  )
}

const ModalPaypal = (props) => {
  const { t } = useTranslation();
  const inputRefCardName = useRef();
  const inputRefZipCode = useRef();
  const { currentProduct } = useClientData();
  const { purchaseDetail } = usePurchaseDetailContext();
  const [clientToken, setClientToken] = useState();
  const [showHostedFields, setShowHostedFields] = useState(false);

  useEffect(() => {
    if (props.openModal && currentProduct) {
      (async () => {
        const clientTokenAux = await generateClientToken(currentProduct?.config_work_unit?.PayPal?.client_id, currentProduct?.config_work_unit?.PayPal?.client_secret);
        setClientToken(clientTokenAux);
      })();
    } else {
      setShowHostedFields(false);
      setClientToken(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProduct, props.openModal]);

  const getTotalPrice = () => {
    if (props.module === 'hotels') {
      return purchaseDetail.totalAmount;
    } else if (props.module === 'trains') {
      return TrainsDetail.getTotalPrice(purchaseDetail);
    }
  }

  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [{
          amount: {
            value: getTotalPrice(),
            currency_code: purchaseDetail.currency,
          }
        }]
      })
      .then((orderID) => orderID);
  }

  const onApprove = (data, actions) => {
    if (props.onApprove) {
      return actions.order.capture().then((details) => {
        const status_paypal = details.purchase_units[0].payments.captures[0].status;
        if (status_paypal === 'COMPLETED') {
          props.onApprove(details);
        }
      });
    }
  }

  const onError = (orderData) => {
    const data = {
      status: 'REJECTED',
      currency: purchaseDetail.currency,
      statePayment: 'ERROR',
      totalAmount: getTotalPrice()
    };
    props.onApprove(data);
  }

  const createOrderPayPalHostedFieldsProvider = async () => {
    try {
      const payload = {
        intent: 'CAPTURE',
        purchase_units: [{
          amount: {
            value: getTotalPrice(),
            currency_code: purchaseDetail.currency,
          }
        }],
        payment_source:{
          paypal:{
            experience_context:{
              shipping_preference:'NO_SHIPPING'
            }
          }
        }
      };

      const orderData = await createOrderPayPalHostedFields(currentProduct?.config_work_unit?.PayPal?.client_id, currentProduct?.config_work_unit?.PayPal?.client_secret, payload);

      if (orderData.id) {
        return orderData.id;
      } else {
        const errorDetail = orderData?.details?.[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
          : JSON.stringify(orderData);

          throw new Error(errorMessage);
      }
    } catch (error) {
      console.error(error);
      return `Could not initiate PayPal Checkout...${error}`;
    }
  }

  const onApprovePayPalHostedFieldsProvider = async (orderID) => {
    const orderData = await captureOrder(currentProduct?.config_work_unit?.PayPal?.client_id, currentProduct?.config_work_unit?.PayPal?.client_secret, orderID);
    if (orderData?.purchase_units && orderData.purchase_units[0].payments.captures[0].status === 'COMPLETED') {
      props.onApprove(orderData);
    } else {
      onError(orderData);
    }
  }

  const onClose = () => {
    setShowHostedFields(false);
    props.onClose && props.onClose();
  }

  return (
    <Dialog
      open={props.openModal}
      className='modal-paypal'
      maxWidth='md'
      fullWidth
    >
      <DialogTitle>
        <Card>
          <CardHeader
            title={
              <Typography className='title-card-header'>
                {t('checkout.common.payWith')} PayPal
              </Typography>
            }
            action={
              <IconButton className='card-header-action' onClick={onClose}>
                <Icon>close</Icon>
              </IconButton>
            }
          />
        </Card>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2} style={{ marginBottom: 15 }}>
            <Grid item xs={12} sm={4} md={6} className='subtitle'>
              <Typography>
                {showHostedFields ? t('checkout.common.completeFieldsPaypal') : t('checkout.common.makePayment')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={6}>
              <Timer
                timer={props.timer}
                expired={props.expired || false}
              />
            </Grid>
        </Grid>

        {currentProduct?.config_work_unit?.PayPal?.client_id && clientToken ? (
          <PayPalScriptProvider
            options={{
              clientId: currentProduct?.config_work_unit?.PayPal?.client_id,
              dataClientToken: clientToken,
              currency: purchaseDetail.currency,
              components: 'buttons,hosted-fields',
              intent: 'capture',
              vault: false,
              'data-sdk-integration-source': 'integrationbuilder_ac'
            }}
          >
            {(!showHostedFields || !props.openModal) ? (
              <>
                <PayPalButtons
                  createOrder={createOrder}
                  onApprove={onApprove}
                  onError={onError}
                  style={{ disableMaxWidth: true }}
                />
                <Button
                  variant='contained'
                  fullWidth
                  className='paypal-button'
                  onClick={() => setShowHostedFields(true)}
                >
                  <CreditCard fontSize='large' /> {t('checkout.common.creditCardButton')}
                </Button>
              </>
            ) : (
              <PayPalHostedFieldsProvider
                styles={{
                  '.valid': { 'color': '#28a745' },
                  '.invalid': { 'color': '#dc3545' },
                  'input': { 'font-family': 'monospace', 'font-size': '16px'}
                }}
                createOrder={createOrderPayPalHostedFieldsProvider}
              >
                <div style={{ marginTop: '4px', marginBottom: '4px' }}>
                  <PayPalHostedField
                    id='card-number'
                    hostedFieldType='number'
                    options={{
                      selector: '#card-number',
                      placeholder: 'Número de Tarjeta',
                    }}
                    className='paypal-field'
                  />

                  <div style={{display: 'flex', justifyContent: 'spaceBetween'}}>
                    <PayPalHostedField
                      id='expiration-date'
                      hostedFieldType='expirationDate'
                      options={{
                        selector: '#expiration-date',
                        placeholder: 'Fecha de vencimiento',
                      }}
                      className='paypal-field'
                    />

                    <PayPalHostedField
                      id='cvv'
                      hostedFieldType='cvv'
                      options={{
                        selector: '#cvv',
                        placeholder: 'CVV',
                      }}
                      className='paypal-field'
                    />
                  </div>

                  <div style={{display: 'flex', justifyContent: 'spaceBetween'}}>
                    <input
                      ref={inputRefCardName}
                      id='card-holder'
                      type='text'
                      placeholder='Nombre del titular'
                      className='paypal-field'
                    />
                    <input
                      ref={inputRefZipCode}
                      id='card-billing-address-country'
                      type='text'
                      placeholder='Código Postal'
                      className='paypal-field'
                    />
                  </div>
                  <SubmitPayment
                    inputRefCardName={inputRefCardName}
                    inputRefZipCode={inputRefZipCode}
                    onApprovePayPalHostedFieldsProvider={onApprovePayPalHostedFieldsProvider}
                  />
                </div>
              </PayPalHostedFieldsProvider>
            )}

          </PayPalScriptProvider>
        ): (
          <div className='spinner-container'>
            <CircularProgress size={55} />
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ModalPaypal;
