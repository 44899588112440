import React, { useState } from 'react';
import { Container, Grid, TextField, Typography, Alert, Snackbar, Icon, Hidden } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { useClientData } from '../../context/ClientContext';
import { subscribesNewsletter } from '../../store/services/Login';
import dayjs from 'dayjs';

const Newsletter = () => {
  const [t] = useTranslation();
  const { clientData } = useClientData();
  const [email, setEmail] = useState('');
  const [messagesSnackbar, setMessagesSnackbar] = useState();
  const [loadingNewsletter, setLoadingNewsletter] = useState(false);

  const sanitizeInput = (input) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(input)) {
        return '';
    }
    return input
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#x27;")
      .replace(/\//g, "&#x2F;");
  }

  const handleSubscribe = async () => {
    const { credential, newsletter } = clientData;
    const { urlNewsletter, tokenNewsletter } = credential.api;
    const formUrl = newsletter?.urlNewsletterForm;
  
    if (!(urlNewsletter && tokenNewsletter) && !formUrl) return;
  
    const sanitizeEmail = sanitizeInput(email);
    if (!sanitizeEmail) return;
  
    setLoadingNewsletter(true);
  
    try {
      if (urlNewsletter && tokenNewsletter) {
        await subscribesNewsletter(sanitizeEmail, window.location.origin);
      } else if (formUrl) {
        await fetch(
          `${formUrl}/formResponse?` +
            new URLSearchParams({
              'entry.699369465': dayjs().format('DD-MM-YYYY'),
              'entry.1076154613': sanitizeEmail
            }),
          { mode: 'no-cors' }
        );
      }
      setMessagesSnackbar(t('common.successSuscribes'));
    } catch (error) {
      setMessagesSnackbar(t('common.errorSuscribes'));
    } finally {
      setLoadingNewsletter(false);
    }
  }

  return (
    <section className='newsletter'>
      <Container>
        <Grid className='row'>
          <Typography className='title row'>
            <Hidden mdDown>
              {clientData?.newsletter?.icon && (
                <Icon fontSize='large' className='material-icons-outlined mr-05'>
                  {clientData?.newsletter?.icon}
                </Icon>
              )}
            </Hidden>
            {clientData?.newsletter?.title || t('common.suscribe')}
          </Typography>
          <TextField
            fullWidth
            size='small'
            placeholder={t('common.enterEmail')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <LoadingButton
            variant='contained'
            onClick={handleSubscribe}
            loading={loadingNewsletter}
          >
            <span>{t('common.sendEmail')}</span>
          </LoadingButton>
        </Grid>
        <Grid>
          {clientData?.newsletter?.messages?.map((message, i) => (
            <Typography key={i}>{message.item}</Typography>
          ))}
        </Grid>
      </Container>
      <Snackbar
        open={Boolean(messagesSnackbar)}
        autoHideDuration={4000}
        onClose={() => setMessagesSnackbar(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setMessagesSnackbar(null)}
          severity="success"
          sx={{
            width: '100%',
            padding: '10px'
          }}
        >
          <Typography style={{ fontSize: '1.2rem' }}>
            {messagesSnackbar}
          </Typography>
        </Alert>

      </Snackbar>
    </section>
  );
}

export default Newsletter;
