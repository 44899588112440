export const resetResultsActionGimmonix = () => {
    return {
        type: 'RESET_PRODUCT_SEARCH_GIMMONIX'
    }
}

export const searchActionGimmonix = (token, queryRule, query, signalAbort) => {
    return {
        type: 'GET_PRODUCT_SEARCH_GIMMONIX',
        token,
        queryRule,
        query,
        signalAbort
    }
}

export const cacheActionGimmonix = (dataToSend, signalAbort) => {
    return {
        type: 'GET_CACHE_SEARCH_GIMMONIX',
        dataToSend,
        signalAbort
    }
}

export const getFiltersActionGimmonix = (query, signalAbort) => {
    return {
        type: 'GET_PRODUCT_FILTERS_GIMMONIX',
        query,
        signalAbort
    }
}

export const filterActionGimmonix = (query, signalAbort) => {
    return {
        type: 'GET_PRODUCT_FILTER_GIMMONIX',
        query,
        signalAbort
    }
}

export const sendMetricSearchActionGimmonix = (data, signalAbort) => {
    return {
        type: 'POST_HOTEL_METRICS_SEARCH_GIMMONIX',
        data,
        signalAbort
    }
}

export const availabilityActionGimmonix = (data, signalAbort) => {
    return {
        type: 'GET_PRODUCT_AVAILABILITY_GIMMONIX',
        data,
        signalAbort
    }
}

export const availabilityParallelActionGimmonix = (requests, signalAbort) => {
    return {
        type: 'GET_PRODUCT_AVAILABILITY_PARALLEL_GIMMONIX',
        requests,
        signalAbort
    }
}

export const availabilityDetailOnlyActionGimmonix = (data, signalAbort) => {
    return {
        type: 'GET_PRODUCT_AVAILABILITY_DETAIL_ONLY_GIMMONIX',
        data,
        signalAbort
    }
}

export const sendMetricAvailabilityActionGimmonix = (data, signalAbort) => {
    return {
        type: 'POST_HOTEL_METRICS_AVAILABILITY_GIMMONIX',
        data,
        signalAbort
    }
}

export const cancelationPolicyActionGimmonix = (data) => {
    return {
        type: 'GET_CANCELATION_POLICY_GIMMONIX',
        data
    }
}

export const moreAvailabilityActionGimmonix = (data) => {
    return {
        type: 'GET_MORE_PRODUCT_AVAILABILITY_GIMMONIX',
        data
    }
}

export const resetFavoritesActionGimmonix = () => {
    return {
        type: 'RESET_FAVORITES_GIMMONIX'
    }
}

export const getFavoritesActionGimmonix = (user) => {
    return {
        type: 'GET_FAVORITES_GIMMONIX',
        user
    }
}

export const addFavoriteActionGimmonix = (data) => {
    return {
        type: 'ADD_FAVORITE_GIMMONIX',
        data
    }
}

export const deleteFavoriteActionGimmonix = (user, hotelId) => {
    return {
        type: 'DELETE_FAVORITE_GIMMONIX',
        user,
        hotelId
    }
}

export const getPaymentMethodsByCurrenciesAction = (access, tokenCheckout, product) => {
    return {
        type: 'GET_PAYMENT_METHODS_BY_CURRENCIES',
        access,
        tokenCheckout,
        product
    }
}

export const startCheckoutAction = (access, tokenCheckout, data) => {
    return {
        type: 'START_CHECKOUT',
        access,
        tokenCheckout,
        data
    }
}

export const getCheckoutInfoAction = (access, tokenCheckout, data) => {
    return {
        type: 'GET_CHECKOUT_INFO',
        access,
        tokenCheckout,
        data
    }
}

export const resetCheckoutInfoAction = () => {
    return {
        type: 'RESET_CHECKOUT_INFO'
    }
}

export const getProcessesAction = (access, tokenCheckout, data) => {
    return {
        type: 'GET_PROCESSES',
        access,
        tokenCheckout,
        data
    }
}

export const getPrebookingAction = (tokenCheckout, data) => {
    return {
        type: 'GET_PREBOOKING',
        tokenCheckout,
        data
    }
}

export const getInfoStepAction = (access, tokenCheckout, data, nextStep) => {
    let action = '';

    switch(nextStep) {
        case 'getPassenger':
            action = 'GET_PASSENGER';
            break;
        case 'getPayment':
            action = 'GET_PAYMENT';
            break;
        default:
            break;
    }

    if (action) {
        return {
            type: action,
            access,
            tokenCheckout,
            data
        }
    }
}

export const getBookingAction = (access, tokenCheckout, data) => {
    return {
        type: 'GET_BOOKING',
        access,
        tokenCheckout,
        data
    }
}

export const getQrCodePixAction = (access, tokenCheckout, data) => {
    return {
        type: 'GET_QR_CODE_PIX',
        access,
        tokenCheckout,
        data
    }
}

export const getPaymentStatusPixAction = (access, tokenCheckout, data) => {
    return {
        type: 'GET_PAYMENT_STATUS_PIX',
        access,
        tokenCheckout,
        data
    }
}

export const getRetrieveAction = (tokenCheckout, data) => {
    return {
        type: "GET_RETRIEVE",
        tokenCheckout,
        data
    }
}

export const sendMetricUpdateMetricsActionGimmonix = (data, signalAbort) => {
    return {
        type: 'POST_HOTEL_METRICS_UPDATE_BOOKING_GIMMONIX',
        data,
        signalAbort
    }
}

export const getReservationsAction = (access, query) => {
    return {
        type: 'GET_RESERVATIONS',
        access,
        query
    }
}

export const resetReservationAction = () => {
    return {
        type: 'RESET_RESERVATION'
    }
}

export const getReservationAction = (access, reservationId, signalAbort) => {
    return {
        type: 'GET_RESERVATION',
        access,
        reservationId,
        signalAbort
    }
}

export const getCurrenciesByProductAction = (access, productToken) => {
    return {
        type: 'GET_CURRENCIES_BY_PRODUCT',
        access,
        productToken
    }
}

export const searchActionTrains = (token, data, signalAbort) => {
    return {
        type: 'GET_PRODUCT_SEARCH_TRAINS',
        token,
        data,
        signalAbort
    }
}

export const searchActionFlights = (token, data, signalAbort) => {
    return {
        type: 'GET_PRODUCT_SEARCH_FLIGHTS',
        token,
        data,
        signalAbort
    }
}

export const reportMetricsActionFlights = (token, tokenProduct, data, signalAbort) => {
    return {
        type: 'REPORT_METRICS_FLIGHTS',
        token,
        tokenProduct,
        data,
        signalAbort
    };
};

export const searchThreeDaysActionFlights = (token, data, signalAbort) => {
    return {
        type: 'GET_PRODUCT_SEARCH_THREE_DAYS_FLIGHTS',
        token,
        data,
        signalAbort
    }
}

export const getMinPriceActionFlights = (token, data, signalAbort) => {
    return {
        type: 'GET_MIN_PRICE_FLIGHTS',
        token,
        data,
        signalAbort
    }
}

export const availabilityActionFlights = (token, data, signalAbort) => {
    return {
        type: 'GET_PRODUCT_AVAILABILITY_FLIGHTS',
        token,
        data,
        signalAbort
    }
}

export const availabilityParallelActionFlights = (token, requests, signalAbort) => {
    return {
        type: 'GET_PRODUCT_AVAILABILITY_PARALLEL_FLIGHTS',
        token,
        requests,
        signalAbort
    }
}

export const getFiltersActionFlights = (query, signalAbort) => {
    return {
        type: 'GET_PRODUCT_FILTERS_FLIGHTS',
        query,
        signalAbort
    }
}

export const filterActionFlights = (query, signalAbort) => {
    return {
        type: 'GET_PRODUCT_FILTER_FLIGHTS',
        query,
        signalAbort
    }
}
