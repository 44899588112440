import React, { Fragment, useEffect, useState } from 'react';
import {
    Box,
    TextField,
    Button,
    InputAdornment,
    FormLabel,
    Select,
    MenuItem,
    Typography,
    Autocomplete,
    Grid,
    IconButton,
    FormHelperText,
    Divider,
    Popover,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Hidden,
    FormControlLabel,
    Switch,
    Container,
    useTheme,
    useMediaQuery
} from '@mui/material';
import dayjs from 'dayjs';
import { DesktopDatePicker, PickersDay } from '@mui/x-date-pickers';
import { getAutocompleteFlights } from '../../../store/services/Autocomplete';
import {
    AddBox,
    Add,
    ArrowBackOutlined,
    ArrowDropDown,
    ArrowDropUp,
    Close,
    Delete,
    LocationOn,
    PeopleAlt,
    Person,
    Remove,
    Search,
    SwapHoriz,
    InsertInvitation,
    DateRange,
    Clear,
    ArrowRightAlt
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useClientData } from '../../../context/ClientContext';
import airlines from '../../../store/services/allAirlines.json';
import { DatalayerSearch } from '../../../store/services/DatalayerGeneral';
import IconCustom from '../IconCustom';
import { connect } from 'react-redux';
import { getPaymentMethodsByCurrenciesAction } from '../../../store/actions';

const DATE_FORMAT = 'DD/MM/YYYY';

const MultibuscadorFlights = ({
    module,
    defaultData,
    isResultsView,
    allDestinations,
    paymentMethodsByCurrencies,
    ...props
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { clientData, currentProduct, currencies, setClientData, setSearchedProduct } = useClientData();
    const [formData, setFormData] = useState({
        segments: [{
            origin: '',
            destination: '',
            dateDeparture: dayjs().add(2, 'day').format(DATE_FORMAT),
            dateArrive: ''
        }],
        adults: 1,
        kids: 0,
        babys: 0,
        currency: 'ARS',
        tripType: 'roundtrip',
        cabinClasses: 'Y',
        threeDays: false,
        advancedSearch: {}
    });
    const [resumeData, setResumeData] = useState();
    const [anchorEl, setAnchorEl] = useState();
    const [openCheckin, setOpenCheckin] = useState(false);
    const [openCheckout, setOpenCheckout] = useState(false);
    const [timeoutAutocomplete, setTimeoutAutocomplete] = useState();
    const [abortController, setAbortController] = useState();
    const [loadingDestinations, setLoadingDestinations] = useState();
    const [destinations, setDestinations] = useState([]);
    const [currenciesOptions, setCurrenciesOptions] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState({});
    const [errors, setErrors] = useState({ segments: [], minorAges: [], advancedSearch: {} });
    const [expanded, setExpanded] = useState(true);
    const [dayHover, setDayHover] = useState();
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
    const [showThreeDays, setShowThreeDays] = useState(true);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (isResultsView) {
            setExpanded(false);
        }

        ; (async () => {
            if (!defaultData) {
                const response = await getAutocompleteFlights('buenos aires');
                if (response && Array.isArray(response)) {
                    const defaultOrigin = response
                        .find(elem => elem.category.toLowerCase() === 'ciudades')
                        ?.data
                        ?.find(elem => elem.iata === 'BUE');
                    setFormData(current => ({
                        ...current,
                        segments: [{
                            ...current.segments[0],
                            origin: defaultOrigin || ''
                        }]
                    }))
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentProduct?.ref_table_detail === 'air_detail') {
            const accessToken = localStorage.getItem('jwt');
            const tokenCheckout = currentProduct?.config?.api_checkout_token || currentProduct?.config_work_unit?.api_checkout_token;
            props.getPaymentMethodsByCurrencies(accessToken, tokenCheckout, 'flights');
        }
    }, [currentProduct])

    useEffect(() => {
        if (currentProduct?.ref_table_detail === 'air_detail') {
            const currenciesAux = Object.keys(currentProduct?.config?.['API-Token'] || {}).reduce((acc, elem) => {
                const currency = currencies.find(currencyAux => currencyAux.short_name === elem);
                if (currency) {
                    acc.push({
                        longName: currency.long_name,
                        symbol: currency.symbol,
                        currency: elem
                    });
                }
                return acc;
            }, []);

            setCurrenciesOptions(currenciesAux);

            if (formData.currency && !currenciesAux.find(elem => elem.currency === formData.currency)) {
                setFormData(current => ({
                    ...current,
                    currency: ''
                }));
            }
        }
    }, [currentProduct, currencies, paymentMethodsByCurrencies])

    useEffect(() => {
        if (paymentMethodsByCurrencies) {
            setPaymentMethods(paymentMethodsByCurrencies);

            if (formData.currency && (!paymentMethodsByCurrencies?.[formData.currency] || paymentMethodsByCurrencies?.[formData.currency]?.length === 0)) {
                setFormData(current => ({
                    ...current,
                    currency: ''
                }));
            }
        }
    }, [paymentMethodsByCurrencies])

    useEffect(() => {
        if (defaultData) {
            setFormData(defaultData);

            const resumeDataAux = JSON.parse(JSON.stringify(defaultData));
            resumeDataAux.segments.forEach(elem => {
                elem.origin = allDestinations.filter(destination => destination?.iata === elem.origin?.iata).pop();
                elem.destination = allDestinations.filter(destination => destination?.iata === elem.destination?.iata).pop();
            })
            setResumeData(resumeDataAux);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultData, allDestinations]);

    const handleSubmit = (e) => {
        e?.preventDefault();

        const user = localStorage.getItem('user');
        if (user) {
            const isPasses = formData.tripType === 'passes';
            const errorsAux = {};
            let errorsCount = 0;

            errorsAux.segments = formData.segments.map(elem => {
                const errors = {
                    origin: !isPasses ? !Boolean(elem.origin) : false,
                    destination: !Boolean(elem.destination),
                    dateDeparture: !Boolean(elem.dateDeparture),
                    dateArrive: !isPasses ? formData.tripType.includes('roundtrip') && !Boolean(elem.dateArrive) : false
                };
                errorsCount += Object.values(errors).filter(elem => elem === true).length;
                return errors;
            });

            const totalPassengers = formData.adults + formData.kids + formData.babys;
            if (totalPassengers === 0) {
                errorsAux.noPassengers = true;
                errorsCount += 1;
            }

            if (!formData.currency) {
                errorsAux.currency = true;
                errorsCount += 1;
            }

            setErrors(errorsAux);

            if (errorsCount === 0) {
                setExpanded(false);
                setShowAdvancedSearch(false);
                // Datalayer - Guardar formData en el contexto
                setSearchedProduct(formData);
                // Datalayer Search event
                DatalayerSearch(
                    "trackEventSearch",
                    module.module,
                    formData,
                    null,
                    null);

                const origin = [];
                const destination = [];
                const dateDeparture = [];
                const dateArrive = formData.tripType === 'roundtrip' ? [] : null;

                formData.segments.forEach(elem => {
                    origin.push(elem.origin.iata);
                    destination.push(elem.destination.iata);
                    dateDeparture.push(dayjs(elem.dateDeparture, DATE_FORMAT).format('DDMMYY'));
                    if (elem.dateArrive) {
                        dateArrive.push(dayjs(elem.dateArrive, DATE_FORMAT).format('DDMMYY'));
                    }
                });

                const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
                const url = iframePath + `/vuelos/resultados/${formData.tripType}/${origin.join(',')}/${destination.join(',')}/${dateDeparture.join(',')}/${dateArrive?.join(',') || 'NA'}/${formData.adults}/${formData.kids}/${formData.babys}/${formData.cabinClasses}/${formData.threeDays ? 1 : 0}/${formData.currency}`;
                navigate(url, { state: { advancedSearch: formData.advancedSearch } });
            }
        } else {
            const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
            if (iframePath) {
                localStorage.clear();
                setClientData(null);
                navigate(iframePath);
            } else {
                navigate('/login', { state: { expiredSession: true } });
            }
        }
    }

    const handleChangeDestination = (value) => {
        if (value && value.length > 2) {
            clearTimeout(timeoutAutocomplete);
            const timeout = setTimeout(async () => {
                abortController && abortController.abort();
                const controller = new AbortController();
                setAbortController(controller);
                setLoadingDestinations(true);
                const response = await getAutocompleteFlights(value, controller.signal);
                response?.forEach(data => {
                    data.data = data.data.map(elem => ({
                        ...elem,
                        category: data.category.toLowerCase() === 'ciudades' ? 'cities' : 'airports',
                    }))
                });
                setDestinations(response?.flatMap(group => group.data) || []);
                setAbortController(null);
                setLoadingDestinations(false);
            }, 500);
            setTimeoutAutocomplete(timeout);
        }
    }

    const handleBlurField = () => {
        abortController && abortController.abort();
        clearTimeout(timeoutAutocomplete);
        setDestinations([]);
        setAbortController(null);
        setLoadingDestinations(false);
    }

    const handleClickSwapDestinations = (index) => {
        const segments = [...formData.segments];
        const originAux = segments[index].origin;
        segments[index].origin = segments[index].destination;
        segments[index].destination = originAux;

        setFormData(current => ({
            ...current,
            segments
        }));
    }

    const handleChangeSegmentField = (field, index, value) => {
        const formDataAux = JSON.parse(JSON.stringify(formData));
        formDataAux.segments[index][field] = value;

        if (field === 'origin' || field === 'destination') {
            const onlyArgentina = formDataAux.segments.every(elem => elem.origin?.label?.includes('Argentina') && elem.destination?.label?.includes('Argentina'));
            formDataAux.currency = onlyArgentina ? 'ARS' : formDataAux.currency;
            setCurrenciesOptions(current => current.map(elem => ({
                ...elem,
                hide: elem.currency !== 'ARS' && onlyArgentina ? true : false
            })))
        } else if (field === 'dateDeparture') {
            const dateDeparture = value ? dayjs(value, DATE_FORMAT) : null;
            const dateArrive = formDataAux.segments[index].dateArrive ? dayjs(formDataAux.segments[index].dateArrive, DATE_FORMAT) : null;

            if (dateDeparture) {
                if (dateArrive && dateDeparture.isAfter(dateArrive, 'date')) {
                    formDataAux.segments[index].dateArrive = '';
                }

                formDataAux.segments.forEach((elem, i) => {
                    if (i > index) {
                        const dateDepartureAux = elem.dateDeparture ? dayjs(elem.dateDeparture, DATE_FORMAT) : null;
                        const dateToCheck = dayjs(formDataAux.segments[i - 1].dateDeparture, DATE_FORMAT);
                        if (dateToCheck.isSame(dayjs().add(11, 'month'), 'date')) {
                            formDataAux.segments[i].dateDeparture = '';
                        } else if (dateDepartureAux && dateToCheck.isSameOrAfter(dateDepartureAux, 'date')) {
                            formDataAux.segments[i].dateDeparture = dayjs(dateToCheck).add(1, 'day').format(DATE_FORMAT);
                        }
                    }
                })
            }

            if (formData.tripType.includes('roundtrip') && value) {
                setOpenCheckout(index);
            }
        }

        setFormData(formDataAux);
    }

    const handleChangeField = (field, value) => {
        let formDataAux = {
            ...formData,
            [field]: value
        };

        if (field === 'tripType') {
            formDataAux.threeDays = false;

            if (value === 'multidestination') {
                formDataAux.segments = [
                    {
                        ...formDataAux.segments[0],
                        dateArrive: ''
                    },
                    {
                        origin: '',
                        destination: '',
                        dateDeparture: '',
                        dateArrive: ''
                    }
                ];
            } else {
                formDataAux.segments = [{
                    ...formDataAux.segments[0],
                    dateArrive: ''
                }];
            }
        }

        setFormData(formDataAux);
    }

    const handleAddSegment = () => {
        let dateDeparture = '';
        if (formData.segments[formData.segments.length - 1].dateDeparture) {
            dateDeparture = dayjs(formData.segments[formData.segments.length - 1].dateDeparture, DATE_FORMAT);
            if (dateDeparture.isSame(dayjs().add(11, 'month'), 'date')) {
                dateDeparture = '';
            } else {
                dateDeparture = dayjs(dateDeparture, DATE_FORMAT).add(1, 'day').format(DATE_FORMAT);
            }
        }
        const segments = [...formData.segments];
        segments.forEach(elem => elem.dateArrive = '');
        segments.push({
            origin: '',
            destination: '',
            dateDeparture
        });
        setFormData(current => ({
            ...current,
            segments,
            tripType: 'multidestination'
        }));
        setOpenCheckin(null);
        setOpenCheckout(null);
    }

    const handleRemoveSegment = (index) => {
        const segments = [...formData.segments];
        segments.splice(index, 1);
        if (segments.length === 1) {
            segments[0].dateArrive = '';
        }

        setFormData(current => ({
            ...current,
            segments,
            tripType: segments.length === 1 ? 'roundtrip' : current.tripType
        }))
    }

    const getMaxPassengers = (field) => {
        const fields = ['adults', 'kids'];
        let count = 9;

        fields.forEach(elem => {
            if (elem !== field) {
                count -= (formData[elem] || 0);
            }
        });

        return count;
    }

    const handleClickGuests = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleCloseGuests = () => {
        setAnchorEl(null);
    }

    const getResumeData = () => {
        const getDestinationsData = (segment) => {
            return <Grid className='row destination'>
                <LocationOn className='field-icon' />
                <Hidden mdDown>
                    {resumeData?.segments.length === 1 ? segment?.origin?.label : segment?.origin?.iata}
                </Hidden>
                <Hidden mdUp>
                    {segment?.origin?.iata}
                </Hidden>
                {clientData?.client?.isB2C ? <ArrowRightAlt className='arrow-icon' /> : ' - '}
                <Hidden mdDown>
                    {resumeData?.segments.length === 1 ? segment?.destination?.label : segment?.destination?.iata}
                </Hidden>
                <Hidden mdUp>
                    {segment?.destination?.iata}
                </Hidden>
            </Grid>
        }

        const getDateData = (segment) => {
            return <Grid className='row date'>
                <InsertInvitation className='field-icon' />
                <span>{dayjs(segment.dateDeparture, DATE_FORMAT).format('ddd DD MMM')}</span>
                {segment.dateArrive && (
                    <>
                        &nbsp;-&nbsp;
                        <span>{dayjs(segment.dateArrive, DATE_FORMAT).format('ddd DD MMM')}</span>
                    </>
                )}
            </Grid>
        }

        const getPassengersData = () => {
            const passengers = resumeData?.adults + resumeData?.kids + resumeData?.babys;
            if (passengers) {
                return <Grid className='row pax'>
                    <PeopleAlt className='field-icon' />
                    {passengers}
                </Grid>
            }
        }

        if (resumeData) {
            return <Grid className='resume-data'>
                {resumeData?.segments?.map((segment, i) => (
                    <Fragment key={i}>
                        <Grid
                            className={(resumeData?.segments?.length > 1 || isMobile) ? 'column' : 'row'}
                        >
                            {(resumeData?.segments?.length > 1 && !isMobile) && getDateData(segment)}
                            {getDestinationsData(segment)}
                            {(resumeData?.segments?.length === 1 || isMobile) && (
                                <>
                                    <Divider flexItem orientation='vertical' />
                                    {getDateData(segment)}
                                </>
                            )}
                        </Grid>
                        <Divider
                            flexItem
                            orientation='vertical'
                            className={resumeData?.segments?.length === 1 ? 'small' : ''}
                        />
                    </Fragment>
                ))}
                {getPassengersData()}
            </Grid>
        }

        if (resumeData) {
            return <Grid className='resume-data'>
                {resumeData?.segments?.length === 1 && !isMobile ? (
                    <Grid className={isMobile ? 'column' : 'row'}>
                        {getDestinationsData(resumeData?.segments?.[0])}
                        <Hidden smDown>
                            <Divider flexItem orientation='vertical' />
                        </Hidden>
                        {getDateData(resumeData?.segments?.[0])}
                        <Hidden smDown>
                            <Divider flexItem orientation='vertical' />
                        </Hidden>
                        {getPassengersData()}
                    </Grid>
                ) : (
                    <Grid className={isMobile ? 'column' : ''}>
                        {resumeData?.segments?.map((segment, i) => (
                            <Fragment key={i}>
                                <Grid className={isMobile ? 'column' : 'row'}>
                                    {getDestinationsData(segment)}
                                    {getDateData(segment)}
                                </Grid>
                                <Divider flexItem orientation='vertical' />
                            </Fragment>
                        ))}
                        {getPassengersData()}
                    </Grid>
                )}
            </Grid>
        }
    }

    const getSubmitButton = () => {
        return <Button
            variant="contained"
            color="secondary"
            size="mdall"
            type='submit'
            className='submit-button'
        >
            {t('common.search')}
        </Button>
    }

    const isSelected = (day, segment) => {
        const startDate = segment.dateDeparture && dayjs(segment.dateDeparture, DATE_FORMAT);
        const endDate = segment.dateArrive && dayjs(segment.dateArrive, DATE_FORMAT);

        return (startDate ? startDate.isSame(day, 'date') : false)
            || (endDate ? endDate.isSame(day, 'date') : false);
    }

    const isBetween = (day, segment) => {
        if (segment.dateDeparture && segment.dateArrive) {
            return day.isBetween(dayjs(segment.dateDeparture, DATE_FORMAT), dayjs(segment.dateArrive, DATE_FORMAT), 'date', '[]');
        }

        return false
    }

    const isDisabled = (day, segment, index) => {
        let flag = false;

        if (day.isBefore(dayjs().add(2, 'day'), 'date') || day.isAfter(dayjs().add(11, 'month'), 'date')) {
            flag = flag || true;
        }

        if (openCheckin !== null) {
            let previousDate = formData?.segments?.[index - 1]?.dateArrive || formData?.segments?.[index - 1]?.dateDeparture;
            if (previousDate) {
                previousDate = dayjs(previousDate, DATE_FORMAT);
                flag = flag || day.isBefore(previousDate, 'date');
            }
        }

        if (openCheckout !== null && segment.dateDeparture) {
            const startDate = dayjs(segment.dateDeparture, DATE_FORMAT);
            flag = flag || day.isBefore(startDate, 'date');
        }

        return flag;
    }

    const getPickerDay = (day, _value, DayComponentProps, segment, index) => {
        const date = dayjs(day.$d);
        const isBetweenAux = isBetween(date, segment);
        const isStartDate = segment.dateDeparture === date.format(DATE_FORMAT);
        const isEndDate = segment.dateArrive === date.format(DATE_FORMAT);

        const classNames = [];
        if (isBetweenAux) classNames.push('range-between');
        if (isStartDate) classNames.push('start-date');
        if (isEndDate) classNames.push('end-date');

        const classNamesDay = [];
        if (!isStartDate && !isEndDate) {
            if (isBetweenAux) {
                classNamesDay.push('between');
            } else if (segment.dateDeparture && dayHover) {
                if (openCheckin !== null && segment.dateArrive && date.isBetween(dayHover, dayjs(segment.dateDeparture, DATE_FORMAT), 'date', '()')) {
                    classNamesDay.push('preselected-day');
                } else if (openCheckout !== null) {
                    const dateAux = segment.dateArrive ? dayjs(segment.dateArrive, DATE_FORMAT) : dayjs(segment.dateDeparture, DATE_FORMAT);
                    segment.dateArrive && date.isBetween(dayjs(segment.dateArrive, DATE_FORMAT), dayHover, 'date', '()')
                    if (dateAux && date.isBetween(dateAux, dayHover, 'date', '()')) {
                        classNamesDay.push('preselected-day');
                    }
                }
            }
        }

        return (
            <div className={classNames.join(' ')} key={DayComponentProps.key}>
                <PickersDay
                    {...DayComponentProps}
                    className={classNamesDay.join(' ')}
                    selected={isSelected(date, segment)}
                    disabled={isDisabled(date, segment, index)}
                    onMouseOver={() => setDayHover(date)}
                    onMouseLeave={() => setDayHover(null)}
                    disableHighlightToday
                />
            </div>
        );
    }

    const getPassengersValue = () => {
        const passengers = [
            `${formData.adults} ${formData.adults === 1 ? t('multiSearch.flights.adult') : t('multiSearch.flights.adults')}`,
            `${formData.kids} ${formData.kids === 1 ? t('multiSearch.flights.kid') : t('multiSearch.flights.kids')}`,
            `${formData.babys} ${formData.babys === 1 ? t('multiSearch.flights.baby') : t('multiSearch.flights.babys')}`
        ];

        return passengers.join(', ');
    }

    const getMinDate = (index, field = 'dateDeparture') => {
        if (field === 'dateDeparture') {
            if (index === 0) {
                return dayjs().add(2, 'day');
            }

            const previousDates = formData.segments
                .slice(0, index)
                .reverse()
                .flatMap(elem => [elem.dateArrive, elem.dateDeparture])
                .filter(elem => elem);

            return dayjs(previousDates[0], DATE_FORMAT);
        } else {
            return formData.segments[index].dateDeparture
                ? dayjs(formData.segments[index].dateDeparture, DATE_FORMAT)
                : dayjs().add(2, 'day');
        }
    }

    const handleChangeAdvancedSearchField = (field, value) => {
        const formDataAux = JSON.parse(JSON.stringify(formData));
        formDataAux.advancedSearch[field] = value;
        const showThreeDays = !Object.values(formDataAux?.advancedSearch || {})?.some(elem => elem);
        formDataAux.threeDays = showThreeDays ? formDataAux.threeDays : false;
        setFormData(formDataAux);
        setShowThreeDays(showThreeDays);
    }

    const getCurrencies = () => {
        return currenciesOptions?.filter(elem => (
            paymentMethods?.[elem?.currency] &&
            paymentMethods?.[elem?.currency]?.length > 0
        ));
    }

    return (
        <Box className="flights-search search-box-container">
            {isResultsView && (
                <Container>
                    <Grid className='resume-container row'>
                        <Button className='back-button' onClick={() => navigate('/vuelos')}>
                            <ArrowBackOutlined fontSize='small' />
                            <Hidden lgDown>
                                {t('common.back')}
                            </Hidden>
                        </Button>

                        {getResumeData()}

                        <Button
                            className='modify-button'
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <Close /> : <Search />}
                            <Hidden lgDown>
                                {expanded ? t('common.cancel') : t('common.modify')}
                            </Hidden>
                        </Button>
                    </Grid>
                </Container>
            )}

            <Accordion expanded={expanded}>
                <AccordionSummary />
                <AccordionDetails>
                    {isResultsView && clientData?.client?.isB2C && (
                        <Divider className='divider-accordion' />
                    )}

                    <Container disableGutters={!Boolean(isResultsView)}>
                        <form onSubmit={e => handleSubmit(e)}>
                            <Grid container className="search-box">
                                <Grid item xs={12} sm={8} className='row buttons-container'>
                                    {module && clientData?.client?.isB2C && (
                                        <Grid className='row'>
                                            <div className='icon-module-container'>
                                                <IconCustom icon={module.icon} alt={module.moduleName} className="custom-icon" />
                                            </div>
                                            <Typography className='title-module'>Vuelos</Typography>
                                        </Grid>
                                    )}
                                    <Grid className='trip-type-buttons'>
                                        <Button
                                            variant='outlined'
                                            className={formData.tripType === 'roundtrip' ? 'selected' : ''}
                                            onClick={e => handleChangeField('tripType', 'roundtrip')}
                                        >
                                            {t('multiSearch.flights.roundtrip')}
                                        </Button>
                                        <Button
                                            variant='outlined'
                                            className={formData.tripType === 'oneway' ? 'selected' : ''}
                                            onClick={e => handleChangeField('tripType', 'oneway')}
                                        >
                                            {t('multiSearch.flights.oneWay')}
                                        </Button>
                                        <Button
                                            variant='outlined'
                                            className={formData.tripType === 'multidestination' ? 'selected' : ''}
                                            onClick={e => handleChangeField('tripType', 'multidestination')}
                                        >
                                            {t('multiSearch.flights.multidestination')}
                                        </Button>
                                    </Grid>
                                    {!clientData?.client?.isB2C && (
                                        <>
                                            <Hidden smDown>
                                                <Divider orientation="vertical" flexItem className='divider-buttons' />
                                            </Hidden>
                                            <Grid className='class-input row'>
                                                <Typography>{t('multiSearch.flights.class')}</Typography>
                                                <Select
                                                    variant='outlined'
                                                    size='small'
                                                    value={formData.cabinClasses || null}
                                                    onChange={e => handleChangeField('cabinClasses', e.target.value)}
                                                >
                                                    <MenuItem value='NA'>{t('multiSearch.flights.all')}</MenuItem>
                                                    <MenuItem value='Y'>
                                                        {clientData?.client?.isB2C ? t('multiSearch.flights.economyB2C') : t('multiSearch.flights.economy')}
                                                    </MenuItem>
                                                    <MenuItem value='W'>{t('multiSearch.flights.premium')}</MenuItem>
                                                    <MenuItem value='B'>{t('multiSearch.flights.business')}</MenuItem>
                                                    <MenuItem value='F'>{t('multiSearch.flights.firstClass')}</MenuItem>
                                                </Select>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>

                                <Hidden smDown>
                                    {formData.tripType === 'roundtrip' && showThreeDays && (
                                        <Grid item sm={4} className='three-days-container'>
                                            <FormControlLabel
                                                label={t(`multiSearch.flights.${clientData?.client?.isB2C ? 'threeDaysB2C' : 'threeDays'}`)}
                                                control={
                                                    <Switch
                                                        size='small'
                                                        color='secondary'
                                                        checked={formData.threeDays}
                                                        onChange={(e, checked) => handleChangeField('threeDays', checked)}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    )}
                                </Hidden>

                                <Grid item xs={12} xl={11}>
                                    <Grid container spacing={1} columns={24}>
                                        <Grid
                                            item
                                            xs={24}
                                            lg={16}
                                        >
                                            <Grid container columnSpacing={1} rowSpacing={2}>
                                                {formData.segments.map((segment, i) => (
                                                    <Grid item key={i} xs={12} className='segment-container depper-container'>
                                                        <Hidden smUp>
                                                            {formData.tripType === 'multidestination' && (
                                                                <div className='segment-number-container mobile row'>
                                                                    <span className='segment-number mobile'>
                                                                        {t('results.flights.section')} {i + 1}
                                                                    </span>
                                                                    {i > 0 && (
                                                                        <IconButton className='delete-segment-button mobile' onClick={() => handleRemoveSegment(i)}>
                                                                            <Delete />
                                                                        </IconButton>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </Hidden>
                                                        <Hidden smDown>
                                                            {formData.tripType === 'multidestination' && (
                                                                <div className='segment-number'>{i + 1}</div>
                                                            )}
                                                        </Hidden>
                                                        <Grid
                                                            container
                                                            spacing={1}
                                                            columns={24}
                                                            sx={formData.tripType === 'multidestination'
                                                                ? { paddingLeft: { sm: '40px' } }
                                                                : {}
                                                            }
                                                        >
                                                            <Grid
                                                                item
                                                                xs={24}
                                                                md={16}
                                                                lg={15}
                                                                xl={formData.tripType === 'roundtrip' ? 16 : 19}
                                                                className='destinations-container'
                                                            >
                                                                <Grid container spacing={1}>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={6}
                                                                        sx={{
                                                                            position: 'relative',
                                                                            paddingRight: { sm: '15px' }
                                                                        }}
                                                                    >
                                                                        <FormLabel>{t('multiSearch.flights.origin')}</FormLabel>
                                                                        <Autocomplete
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            size="small"
                                                                            options={destinations || []}
                                                                            getOptionLabel={option => option.label}
                                                                            groupBy={(option) => option.category}
                                                                            filterOptions={options => options}
                                                                            value={segment?.origin || null}
                                                                            onChange={(e, value) => handleChangeSegmentField('origin', i, value)}
                                                                            noOptionsText={t('common.noOptions')}
                                                                            loadingText={`${t('common.loading')}...`}
                                                                            loading={loadingDestinations}
                                                                            renderInput={(params) =>
                                                                                <TextField
                                                                                    {...params}
                                                                                    placeholder={t('multiSearch.flights.selectOrigin')}
                                                                                    onChange={(e) => handleChangeDestination(e.target.value)}
                                                                                    error={errors.segments?.[i]?.origin}
                                                                                    onBlur={handleBlurField}
                                                                                />
                                                                            }
                                                                            renderGroup={(params) => (
                                                                                <li {...params}>
                                                                                    <span className='group-title'>
                                                                                        {t(`multiSearch.flights.${params.group}`)}
                                                                                    </span>
                                                                                    <ul className='group-option'>{params.children}</ul>
                                                                                </li>
                                                                            )}
                                                                        />
                                                                        <Hidden smDown>
                                                                            <IconButton
                                                                                className='search-destinations-switcher'
                                                                                onClick={() => handleClickSwapDestinations(i)}
                                                                            >
                                                                                <SwapHoriz />
                                                                            </IconButton>
                                                                        </Hidden>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6} style={{ paddingLeft: 24, position: 'relative' }}>
                                                                        <FormLabel style={{ position: 'unset' }}>
                                                                            {t('multiSearch.flights.destination')}
                                                                            <Hidden smUp>
                                                                                <IconButton
                                                                                    className='search-destinations-switcher'
                                                                                    onClick={() => handleClickSwapDestinations(i)}
                                                                                >
                                                                                    <SwapHoriz />
                                                                                </IconButton>
                                                                            </Hidden>
                                                                        </FormLabel>
                                                                        <Autocomplete
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            size="small"
                                                                            options={destinations || []}
                                                                            getOptionLabel={option => option.label}
                                                                            groupBy={(option) => option.category}
                                                                            filterOptions={options => options}
                                                                            value={segment?.destination || null}
                                                                            onChange={(e, value) => handleChangeSegmentField('destination', i, value)}
                                                                            noOptionsText={t('common.noOptions')}
                                                                            loadingText={`${t('common.loading')}...`}
                                                                            loading={loadingDestinations}
                                                                            renderInput={(params) =>
                                                                                <TextField
                                                                                    {...params}
                                                                                    placeholder={t('multiSearch.flights.selectDestination')}
                                                                                    onChange={(e) => handleChangeDestination(e.target.value)}
                                                                                    error={errors.segments?.[i]?.destination}
                                                                                    onBlur={handleBlurField}
                                                                                />
                                                                            }
                                                                            renderGroup={(params) => (
                                                                                <li {...params}>
                                                                                    <span className='group-title'>
                                                                                        {t(`multiSearch.flights.${params.group}`)}
                                                                                    </span>
                                                                                    <ul className='group-option'>{params.children}</ul>
                                                                                </li>
                                                                            )}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={24}
                                                                sm={formData.tripType === 'multidestination' ? 23 : 24}
                                                                md={formData.tripType === 'multidestination' ? 7 : 8}
                                                                lg={9}
                                                                xl={formData.tripType === 'roundtrip' ? 8 : 5}
                                                            >
                                                                <Grid container spacing={1}>
                                                                    <Grid
                                                                        item
                                                                        xs={formData.tripType === 'roundtrip' ? 6 : 12}
                                                                    >
                                                                        <FormLabel>{t('multiSearch.flights.departure')}</FormLabel>
                                                                        <DesktopDatePicker
                                                                            open={openCheckin === i}
                                                                            onClose={() => setOpenCheckin(false)}
                                                                            onChange={(e) => handleChangeSegmentField('dateDeparture', i, e.format('L'))}
                                                                            showDaysOutsideCurrentMonth
                                                                            value={segment.dateDeparture
                                                                                ? dayjs(segment.dateDeparture, DATE_FORMAT)
                                                                                : i === 0 ? '' : getMinDate(i)
                                                                            }
                                                                            defaultCalendarMonth={segment.dateArrive
                                                                                ? dayjs(segment.dateArrive, DATE_FORMAT)
                                                                                : getMinDate(i, 'dateArrive')
                                                                            }
                                                                            className='pointer-input date-picker'
                                                                            minDate={getMinDate(i)}
                                                                            maxDate={dayjs().add(11, 'month')}
                                                                            renderDay={(day, _value, DayComponentProps) => getPickerDay(
                                                                                day,
                                                                                _value,
                                                                                DayComponentProps,
                                                                                segment,
                                                                                i
                                                                            )}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    id='checkin-input'
                                                                                    focused={false}
                                                                                    placeholder={DATE_FORMAT}
                                                                                    value={segment.dateDeparture ? segment.dateDeparture : ''}
                                                                                    inputProps={{ readOnly: true }}
                                                                                    size='small'
                                                                                    fullWidth
                                                                                    error={errors.segments?.[i]?.dateDeparture}
                                                                                    InputProps={{
                                                                                        startAdornment: (
                                                                                            <InputAdornment position="start">
                                                                                                <DateRange />
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="end">
                                                                                                {segment.dateDeparture && (
                                                                                                    <IconButton
                                                                                                        edge="end"
                                                                                                        size='small'
                                                                                                        onClick={(e) => {
                                                                                                            e.stopPropagation();
                                                                                                            handleChangeSegmentField('dateDeparture', i, '');
                                                                                                        }}
                                                                                                    >
                                                                                                        <Clear fontSize='small' />
                                                                                                    </IconButton>
                                                                                                )}
                                                                                            </InputAdornment>
                                                                                        )
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setOpenCheckout(null)
                                                                                        setOpenCheckin(i)
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Grid>
                                                                    {formData.tripType === 'roundtrip' && (
                                                                        <Grid item xs={6}>
                                                                            <FormLabel>{t('multiSearch.flights.return')}</FormLabel>
                                                                            <DesktopDatePicker
                                                                                open={openCheckout === i}
                                                                                onClose={() => setOpenCheckout(false)}
                                                                                onChange={(e) => handleChangeSegmentField('dateArrive', i, e.format('L'))}
                                                                                showDaysOutsideCurrentMonth
                                                                                value={segment.dateArrive
                                                                                    ? dayjs(segment.dateArrive, DATE_FORMAT)
                                                                                    : getMinDate(i, 'dateArrive')
                                                                                }
                                                                                className='pointer-input date-picker'
                                                                                minDate={getMinDate(i, 'dateArrive')}
                                                                                maxDate={dayjs().add(11, 'month')}
                                                                                renderDay={(day, _value, DayComponentProps) => getPickerDay(
                                                                                    day,
                                                                                    _value,
                                                                                    DayComponentProps,
                                                                                    segment,
                                                                                    i
                                                                                )}
                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                        {...params}
                                                                                        id='checkout-input'
                                                                                        focused={false}
                                                                                        placeholder={DATE_FORMAT}
                                                                                        value={segment.dateArrive ? segment.dateArrive : ''}
                                                                                        inputProps={{ readOnly: true }}
                                                                                        size='small'
                                                                                        fullWidth
                                                                                        error={errors.segments?.[i]?.dateArrive}
                                                                                        InputProps={{
                                                                                            startAdornment: (
                                                                                                <InputAdornment position="start">
                                                                                                    <DateRange />
                                                                                                </InputAdornment>
                                                                                            ),
                                                                                            endAdornment: (
                                                                                                <InputAdornment position="end">
                                                                                                    {segment.dateArrive && (
                                                                                                        <IconButton
                                                                                                            edge="end"
                                                                                                            size='small'
                                                                                                            onClick={(e) => {
                                                                                                                e.stopPropagation();
                                                                                                                handleChangeSegmentField('dateArrive', i, '');
                                                                                                            }}
                                                                                                        >
                                                                                                            <Clear fontSize='small' />
                                                                                                        </IconButton>
                                                                                                    )}
                                                                                                </InputAdornment>
                                                                                            )
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            setOpenCheckin(null)
                                                                                            setOpenCheckout(i)
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </Grid>
                                                                    )}

                                                                </Grid>
                                                            </Grid>
                                                            <Hidden lgUp>
                                                                {i > 0 && (
                                                                    <Grid item sm={1} display='flex'>
                                                                        <IconButton className='delete-segment-button mobile' onClick={() => handleRemoveSegment(i)}>
                                                                            <Delete />
                                                                        </IconButton>
                                                                    </Grid>
                                                                )}
                                                            </Hidden>
                                                        </Grid>
                                                        <Hidden lgDown>
                                                            {i > 0 && (
                                                                <IconButton className='delete-segment-button' onClick={() => handleRemoveSegment(i)}>
                                                                    <Delete />
                                                                </IconButton>
                                                            )}
                                                        </Hidden>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={24}
                                            sm={16}
                                            md={12}
                                            lg={5}
                                            className='depper-container'
                                        >
                                            <FormLabel>{t('multiSearch.flights.passengers')}</FormLabel>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                onClick={handleClickGuests}
                                                className='pointer-input'
                                                value={getPassengersValue()}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Person />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end" style={{ marginLeft: '6px' }}>
                                                            {Boolean(anchorEl) ? <ArrowDropUp /> : <ArrowDropDown />}
                                                        </InputAdornment>
                                                    ),
                                                    readOnly: true
                                                }}
                                            />
                                            <FormHelperText
                                                error={true}
                                                sx={{ display: errors.noPassengers ? 'block' : 'none' }}
                                            >
                                                {errors.noPassengers ? t('multiSearch.flights.atLeastOnePassenger') : <>&nbsp;</>}
                                            </FormHelperText>
                                            <Popover
                                                open={Boolean(anchorEl)}
                                                anchorEl={anchorEl}
                                                onClose={handleCloseGuests}
                                                className='flights-guests-popover'
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <Grid p={2}>
                                                    <Grid className='passengers-input'>
                                                        <div>
                                                            <Typography className='title'>{t('multiSearch.flights.adults')}</Typography>
                                                            <Typography className='subtitle'>
                                                                {t('multiSearch.flights.fromYears', { from: 12 })}
                                                            </Typography>
                                                        </div>

                                                        <div className='buttons-container row'>
                                                            <IconButton
                                                                disabled={formData.adults === 0}
                                                                onClick={() => handleChangeField('adults', formData.adults - 1)}
                                                            >
                                                                <Remove />
                                                            </IconButton>
                                                            <span>{formData.adults}</span>
                                                            <IconButton
                                                                disabled={formData.adults === getMaxPassengers('adults')}
                                                                onClick={() => handleChangeField('adults', formData.adults + 1)}
                                                            >
                                                                <Add />
                                                            </IconButton>
                                                        </div>
                                                    </Grid>

                                                    <Divider />

                                                    <Grid className='passengers-input'>
                                                        <div>
                                                            <Typography className='title'>{t('multiSearch.flights.kids')}</Typography>
                                                            <Typography className='subtitle'>
                                                                {t('multiSearch.flights.fromToYears', { from: 2, to: 11 })}
                                                            </Typography>
                                                        </div>

                                                        <div className='buttons-container row'>
                                                            <IconButton
                                                                disabled={formData.kids === 0}
                                                                onClick={() => handleChangeField('kids', formData.kids - 1)}
                                                            >
                                                                <Remove />
                                                            </IconButton>
                                                            <span>{formData.kids}</span>
                                                            <IconButton
                                                                disabled={formData.kids === getMaxPassengers('kids')}
                                                                onClick={() => handleChangeField('kids', formData.kids + 1)}
                                                            >
                                                                <Add />
                                                            </IconButton>
                                                        </div>
                                                    </Grid>

                                                    <Divider />

                                                    <Grid className='passengers-input'>
                                                        <div>
                                                            <Typography className='title'>{t('multiSearch.flights.babys')}</Typography>
                                                            <Typography className='subtitle'>
                                                                {t('multiSearch.flights.minorYears', { years: 2 })}
                                                            </Typography>
                                                        </div>

                                                        <div className='buttons-container row'>
                                                            <IconButton
                                                                disabled={formData.babys === 0}
                                                                onClick={() => handleChangeField('babys', formData.babys - 1)}
                                                            >
                                                                <Remove />
                                                            </IconButton>
                                                            <span>{formData.babys}</span>
                                                            <IconButton
                                                                disabled={formData.babys === formData.adults}
                                                                onClick={() => handleChangeField('babys', formData.babys + 1)}
                                                            >
                                                                <Add />
                                                            </IconButton>
                                                        </div>
                                                    </Grid>

                                                    <Grid container>
                                                        <Typography className='minor-ages-text'>
                                                            {t('multiSearch.flights.minorAges')}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid container justifyContent="flex-end">
                                                        <Button size='small' variant="contained" color="secondary" onClick={handleCloseGuests} >
                                                            {t('multiSearch.flights.apply')}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Popover>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={24}
                                            sm={8}
                                            md={12}
                                            lg={3}
                                            className='depper-container'
                                        >
                                            <FormLabel>{t('multiSearch.flights.currency')}</FormLabel>
                                            <Select
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                className='currency-input'
                                                value={formData.currency || null}
                                                onChange={e => handleChangeField('currency', e.target.value)}
                                                error={errors.currency}
                                                MenuProps={{
                                                    className: 'currency-input'
                                                }}
                                            >
                                                {getCurrencies().map((elem, i) => (
                                                    !elem.hide && <MenuItem value={elem.currency} key={i}>
                                                        <span className='currency'>{elem.symbol}</span>&nbsp;
                                                        {clientData?.client?.isB2C ? elem.longName : elem.currency}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Hidden xlDown>
                                    <Grid item xs={12} xl={1}>
                                        {getSubmitButton()}
                                    </Grid>
                                </Hidden>

                                <Grid container className='add-segment-container'>
                                    <Grid item xs={12} sm={6}>
                                        {formData.segments.length < 5 && (
                                            <Button
                                                className='add-button'
                                                startIcon={<AddBox />}
                                                onClick={handleAddSegment}
                                            >
                                                {t('multiSearch.flights.addSegment')}
                                            </Button>
                                        )}
                                    </Grid>
                                    {!clientData?.client?.isB2C && (
                                        <>
                                            <Grid item xs={12} sm={6} className='advanced-search-container'>
                                                <Button
                                                    endIcon={showAdvancedSearch ? <ArrowDropUp /> : <ArrowDropDown />}
                                                    onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
                                                >
                                                    {t('multiSearch.flights.advancedSearch')}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Accordion expanded={showAdvancedSearch} className='advanced-search-accordion'>
                                                    <AccordionSummary />
                                                    <AccordionDetails className='mt-05 mb-05'>
                                                        <Grid container spacing={1} columns={24}>
                                                            <Grid item xs={24} md={12} lg={8} xl={6}>
                                                                <FormLabel>{t('multiSearch.flights.airline')}</FormLabel>
                                                                <Autocomplete
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    size="small"
                                                                    options={airlines || []}
                                                                    getOptionLabel={option => option.label}
                                                                    value={formData.advancedSearch?.airline || null}
                                                                    onChange={(e, value) => handleChangeAdvancedSearchField('airline', value)}
                                                                    renderInput={(params) =>
                                                                        <TextField
                                                                            {...params}
                                                                            placeholder={t('multiSearch.flights.selectAirline')}
                                                                        />
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid item xs={24} sm={12} md={5} xl={4}>
                                                                <FormLabel>{t('multiSearch.flights.scaleType')}</FormLabel>
                                                                <Select
                                                                    variant="outlined"
                                                                    size="small"
                                                                    className='clearable-select'
                                                                    fullWidth
                                                                    value={formData.advancedSearch?.stopover || ''}
                                                                    onChange={e => handleChangeAdvancedSearchField('stopover', e.target.value)}
                                                                    endAdornment={
                                                                        <InputAdornment position="end">
                                                                            {formData.advancedSearch?.stopover && (
                                                                                <IconButton
                                                                                    edge="end"
                                                                                    size='small'
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        handleChangeAdvancedSearchField('stopover', '');
                                                                                    }}
                                                                                >
                                                                                    <Clear fontSize='small' />
                                                                                </IconButton>
                                                                            )}
                                                                        </InputAdornment>
                                                                    }
                                                                >
                                                                    <MenuItem value='0'>{t('multiSearch.flights.direct')}</MenuItem>
                                                                    <MenuItem value='1'>{t('multiSearch.flights.oneScale')}</MenuItem>
                                                                    <MenuItem value='2'>{t('multiSearch.flights.twoScales')}</MenuItem>
                                                                </Select>
                                                            </Grid>
                                                            <Grid item xs={24} sm={12} md={5} xl={4}>
                                                                <FormLabel>{t('multiSearch.flights.luggage')}</FormLabel>
                                                                <Select
                                                                    variant="outlined"
                                                                    size="small"
                                                                    className='clearable-select'
                                                                    fullWidth
                                                                    value={formData.advancedSearch?.baggage || ''}
                                                                    onChange={e => handleChangeAdvancedSearchField('baggage', e.target.value)}
                                                                    endAdornment={
                                                                        <InputAdornment position="end">
                                                                            {formData.advancedSearch?.baggage && (
                                                                                <IconButton
                                                                                    edge="end"
                                                                                    size='small'
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        handleChangeAdvancedSearchField('baggage', '');
                                                                                    }}
                                                                                >
                                                                                    <Clear fontSize='small' />
                                                                                </IconButton>
                                                                            )}
                                                                        </InputAdornment>
                                                                    }
                                                                >
                                                                    <MenuItem value='true'>{t('multiSearch.flights.included')}</MenuItem>
                                                                    <MenuItem value='false'>{t('multiSearch.flights.notIncluded')}</MenuItem>
                                                                </Select>
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        </>
                                    )}

                                    <Hidden xlUp>
                                        <Grid item xs={12} sm={6} display='flex' justifyContent='flex-end'>
                                            {getSubmitButton()}
                                        </Grid>
                                    </Hidden>
                                </Grid>
                            </Grid>
                        </form>
                    </Container>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

const mapStateToProps = reducers => {
    return reducers.checkoutReducer;
};

const mapDispatchToProps = dispatch => {
    return {
        getPaymentMethodsByCurrencies: (access, tokenCheckout, product) => dispatch(getPaymentMethodsByCurrenciesAction(access, tokenCheckout, product))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MultibuscadorFlights);
