import React, { useEffect, useState } from 'react';
import { useClientData } from '../../../context/ClientContext';
import {
  Alert,
  AppBar,
  Button,
  Card,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';
import Image from '../../common/Image';
import { useParams } from 'react-router-dom';
import { useBannersLandingsData } from '../../../context/BannersLandingsContext';
import { useTranslation } from 'react-i18next';
import InputWrapper from '../../common/InputWrapper';
import { LoadingButton } from '@mui/lab';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Clear, DateRange } from '@mui/icons-material';
import dayjs from 'dayjs';

const DATE_FORMAT = 'YYYY-MM-DD';

const FIELDS = [
  { name: 'reason', field: 'entry.1442357549' },
  { name: 'name', field: 'entry.248672452' },
  { name: 'surname', field: 'entry.503259617' },
  { name: 'email', field: 'emailAddress' },
  { name: 'phone', field: 'entry.1212441179' },
  { name: 'schedule', field: 'entry.2018917934' },
  { name: 'operationNumber', field: 'entry.1405260663' },
  { name: 'purchaseDate', field: 'entry.64477972' },
  { name: 'consultation', field: 'entry.1080051528' }
];

const OPTIONAL_FIELDS = {
  'customerService': [
    'operationNumber',
    'purchaseDate'
  ]
};

const STEPS = [
  {
    name: 'reason',
    message: 'reasonMessage'
  },
  {
    name: 'titularData',
    message: 'titularDataMessage'
  },
  {
    name: 'confirmation',
    message: 'confirmationMessage'
  }
];

const REASONS = [
  'changes',
  'cancelation',
  'purchaseSeatsOrBaggage',
  'billingQuestions',
  'customerService'
];

const SCHEDULES = ['9 a 14', '14 a 18'];

const ContactPage = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { clientData } = useClientData();
  const { bannersData } = useBannersLandingsData();
  const [activeStep, setActiveStep] = useState(params.reason ? 1 : 0);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [messagesSnackbar, setMessagesSnackbar] = useState();
  const [openPurchaseDateField, setOpenPurchaseDateField] = useState(false);

  useEffect(() => {
    if (params.reason === 'cancelar') {
      setFormData({ reason: t('contactPage.reasons.cancelation') })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getLogo = () => {
    const businessUnit = JSON.parse(localStorage.getItem('businessUnit') || '{}');
    const workUnit = JSON.parse(localStorage.getItem('workUnit') || '{}');
    const bannerLogo = bannersData?.data?.modules?.['otros']?.flows?.header?.banners?.Logo?.slice(-1)?.[0]?.image_url
      || bannersData?.data?.modules?.['otros']?.flows?.login?.banners?.Logo?.slice(-1)?.[0]?.image_url;
    const logo = bannerLogo || businessUnit?.logo?.src || workUnit?.logo?.src;
    if (logo) {
      const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
      const link = iframePath + (clientData?.modules?.[0]?.path || '/');
      return <Link href={link}>
        <Image img={logo} alt='logo-cliente' isFullPath />
      </Link>
    }
  }

  const handleChangeField = (event) => {
    const { name, value } = event.target;
    setFormData(current => ({
      ...current,
      [name]: value
    }));
    setErrors(current => ({
      ...current,
      [name]: false
    }));

    if (value && name === 'reason') {
      setActiveStep(current => current + 1);
    }
  }

  const handleClickBack = () => {
    setActiveStep(current => current - 1);
    setFormData({});
    setErrors({});
  }

  const fieldIsOptional = (fieldName) => {
    const reason = REASONS.find(elem => t(`contactPage.reasons.${elem}`) === formData.reason);
    if (reason) {
      return OPTIONAL_FIELDS[reason]?.includes(fieldName);
    }
  }

  const handleClickNext = async () => {
    const errorsAux = {};
    const dataToSend = {};

    FIELDS.forEach(field => {
      if (formData[field.name]) {
        dataToSend[field.field] = formData[field.name] || '';
      } else if (!fieldIsOptional(field.name)) {
        errorsAux[field.name] = true;
      }
    });

    setErrors(errorsAux);

    if (Object.keys(errorsAux).length === 0) {
      setLoading(true);

      try {
        await fetch(
          'https://docs.google.com/forms/d/e/1FAIpQLSfGIe1TJE3GPxcGqwNmK87_VpwUiI0YKAOLlSNsH4zrgVzElw/formResponse?' +
            new URLSearchParams(dataToSend),
          {
            mode: 'no-cors',
          }
        );
        setActiveStep(current => current + 1);
      } catch (e) {
        setMessagesSnackbar(t('contactPage.error'));
        setLoading(false);
      }
    }
  }

  return (
    <div className='contact-page'>
      <AppBar position='static' className='site-header'>
        <Toolbar>
          {getLogo()}
        </Toolbar>
      </AppBar>

      <Container className='image-steps-container'>
        {bannersData?.data?.modules?.otros?.flows?.contact_form?.banners?.FullWidth?.[0]?.image_url && (
          <div className='image-container'>
            <Image
              img={bannersData?.data?.modules?.otros?.flows?.contact_form?.banners?.FullWidth?.[0]?.image_url}
              isFullPath
              hideBrokenImage
            />
          </div>
        )}

        <Stepper className='stepper-component'>
          {STEPS.map((step, i) => (
            <Step key={i} active={activeStep === i} completed={activeStep > i}>
              <StepLabel>{t(`contactPage.steps.${step.name}`)}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Container>

      <Container>
        <Typography className='title' component='h1'>
          {t(`contactPage.${activeStep !== 2 ? 'title' : 'steps.confirmationTitle'}`)}
        </Typography>

        <Typography className='subtitle' component='h2'>
          {t(`contactPage.steps.${STEPS[activeStep].message}`)}
        </Typography>

        {activeStep !== 2 && (
          <>
            <Typography className='step-title' component='h3'>
              {activeStep + 1}. {t(`contactPage.steps.${STEPS[activeStep].name}`)}
            </Typography>

            <Card className='card-content'>
              {activeStep === 0 && (
                <InputWrapper>
                  <Select
                    name='reason'
                    variant='outlined'
                    size='small'
                    value={formData.reason || ''}
                    onChange={handleChangeField}
                  >
                    {REASONS.map((reason, i) => (
                      <MenuItem key={i} value={t(`contactPage.reasons.${reason}`)}>{t(`contactPage.reasons.${reason}`)}</MenuItem>
                    ))}
                  </Select>
                </InputWrapper>
              )}
              {activeStep === 1 && (
                <Grid container rowSpacing={3} columnSpacing={5}>
                  <Grid item xs={12} sm={6}>
                    <InputWrapper
                      label={t('contactPage.fields.name')}
                      required={!fieldIsOptional('name')}
                    >
                      <TextField
                        name='name'
                        size='small'
                        disabled={loading}
                        error={errors?.['name'] || false}
                        value={formData?.['name'] || ''}
                        onChange={handleChangeField}
                      />
                    </InputWrapper>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <InputWrapper
                      label={t('contactPage.fields.surname')}
                      required={!fieldIsOptional('surname')}
                    >
                      <TextField
                        name='surname'
                        size='small'
                        disabled={loading}
                        error={errors?.['surname'] || false}
                        value={formData?.['surname'] || ''}
                        onChange={handleChangeField}
                      />
                    </InputWrapper>
                  </Grid>

                  <Grid item xs={12} sm={12} md={4}>
                    <InputWrapper
                      label={t('contactPage.fields.email')}
                      required={!fieldIsOptional('email')}
                    >
                      <TextField
                        name='email'
                        size='small'
                        disabled={loading}
                        error={errors?.['email'] || false}
                        value={formData?.['email'] || ''}
                        onChange={handleChangeField}
                      />
                    </InputWrapper>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <InputWrapper
                      label={t('contactPage.fields.phone')}
                      required={!fieldIsOptional('phone')}
                    >
                      <TextField
                        name='phone'
                        size='small'
                        disabled={loading}
                        error={errors?.['phone'] || false}
                        inputProps={{ maxLength: 13 }}
                        value={formData?.['phone'] || ''}
                        onChange={handleChangeField}
                      />
                    </InputWrapper>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <InputWrapper
                      label={t('contactPage.fields.schedule')}
                      required={!fieldIsOptional('schedule')}
                    >
                      <RadioGroup
                        name='schedule'
                        row
                        value={formData?.['schedule'] || ''}
                        onChange={handleChangeField}
                      >
                        {SCHEDULES.map((schedule, i) => (
                          <FormControlLabel
                            key={i}
                            className={errors?.['schedule'] ? 'error' : ''}
                            value={schedule}
                            control={<Radio />}
                            label={schedule}
                            disabled={loading}
                          />
                        ))}
                      </RadioGroup>
                    </InputWrapper>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <InputWrapper
                      label={t('contactPage.fields.operationNumber')}
                      required={!fieldIsOptional('operationNumber')}
                    >
                      <TextField
                        name='operationNumber'
                        size='small'
                        placeholder='VLTIJ1234'
                        disabled={loading}
                        error={errors?.['operationNumber'] || false}
                        value={formData?.['operationNumber'] || ''}
                        onChange={handleChangeField}
                      />
                    </InputWrapper>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <InputWrapper
                      label={t('contactPage.fields.purchaseDate')}
                      required={!fieldIsOptional('purchaseDate')}
                    >
                      <DesktopDatePicker
                        name='purchaseDate'
                        disabled={loading}
                        open={openPurchaseDateField}
                        onClose={() => setOpenPurchaseDateField(false)}
                        onChange={(e) => handleChangeField({ target: { name: 'purchaseDate', value: e.format(DATE_FORMAT) } })}
                        showDaysOutsideCurrentMonth
                        disableFuture
                        value={formData?.['purchaseDate']
                          ? dayjs(formData?.['purchaseDate'], DATE_FORMAT)
                          : ''
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={'DD/MM/AAAA'}
                            value={formData?.['purchaseDate'] ? dayjs(formData?.['purchaseDate'], DATE_FORMAT).format('DD/MM/YYYY') : ''}
                            inputProps={{ readOnly: true }}
                            size='small'
                            fullWidth
                            error={errors?.['purchaseDate'] || false}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <DateRange />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  {formData?.['purchaseDate'] && (
                                    <IconButton
                                      edge="end"
                                      size='small'
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleChangeField({ target: { name: 'purchaseDate', value: '' } });
                                      }}
                                    >
                                      <Clear fontSize='small' />
                                    </IconButton>
                                  )}
                                </InputAdornment>
                              )
                            }}
                            onClick={() => setOpenPurchaseDateField(true)}
                          />
                        )}
                    />
                    </InputWrapper>
                  </Grid>

                  <Grid item xs={12}>
                    <InputWrapper
                      label={t('contactPage.fields.consultation')}
                      required={!fieldIsOptional('consultation')}
                    >
                      <TextField
                        name='consultation'
                        size='small'
                        multiline
                        minRows={4}
                        disabled={loading}
                        error={errors?.['consultation'] || false}
                        value={formData?.['consultation'] || ''}
                        onChange={handleChangeField}
                      />
                    </InputWrapper>
                  </Grid>
                </Grid>
              )}
            </Card>

            {activeStep > 0 && (
              <Grid container columnSpacing={2} className='buttons-container'>
                <Grid item xs={6} className='text-right'>
                  <Button variant='contained' color='secondary' disabled={loading} onClick={handleClickBack}>
                    {t('contactPage.back')}
                  </Button>
                </Grid>

                <Grid item xs={6}>
                  <LoadingButton
                    variant='contained'
                    color='secondary'
                    loading={loading}
                    onClick={handleClickNext}
                  >
                    <span>{t('contactPage.next')}</span>
                  </LoadingButton>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Container>

      <Snackbar
        open={Boolean(messagesSnackbar)}
        autoHideDuration={4000}
        onClose={() => setMessagesSnackbar(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setMessagesSnackbar(null)}
          severity='error'
          sx={{ width: '100%', padding: '10px' }}
        >
          <Typography style={{ fontSize: '1.2rem' }}>
            {messagesSnackbar}
          </Typography>
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ContactPage;
