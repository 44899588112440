import React, { useEffect, useState }  from 'react';
import Header from './Header';
import Footer from './Footer';
import { connect } from 'react-redux';
import { startCheckoutAction } from '../../store/actions';
import { getClientIp } from '../../store/services/IpServices';
import { useNavigate, useParams } from 'react-router-dom';
import { useClientData } from '../../context/ClientContext';
import { getDataStartCheckout } from './utils';
import PreloadMetabuscador from './preloadMetabuscador';
import { isMobile } from 'react-device-detect';

function LayoutMetabuscador({
  children,
  client,
  reservations,
  selectedReservation,
  isIframe,
  setReservations,
  handleSelectReservation,
  apiCheckout,
  errorApiCheckout,
  ...props
}) {
  const params = useParams();
  const navigate = useNavigate();
  const { clientData, currentProduct } = useClientData();
  const [ip, setIp] = useState('');
  const [user, setUser] = useState();
  const [loadingCheckout, setLoadingCheckout] = useState(false);

  useEffect(() => {
    const userAux = localStorage.getItem('user');
    setUser(userAux ? JSON.parse(userAux) : null);

    (async () => {
      const clientIp = await getClientIp();
      setIp(clientIp);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loadingCheckout && apiCheckout && Object.keys(apiCheckout).length !== 0) {
      if (!errorApiCheckout) {
        const iframePath = params.tokenIframe ? `${params.tokenIframe}/` : '';
        if (apiCheckout.baseDomain) {
          if (window.location.hostname === 'localhost' || (`${window.location.origin}/` === apiCheckout.baseDomain)) {
            navigate(apiCheckout.urlRedirect.replace(apiCheckout.baseDomain, '/' + iframePath));
          } else {
            window.location.href = apiCheckout.urlRedirect.replace(apiCheckout.baseDomain, apiCheckout.baseDomain + iframePath)
          }
        } else {
          navigate(`/${iframePath}${apiCheckout.urlRedirect}`);
        }
      } else {
        setLoadingCheckout(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorApiCheckout, apiCheckout]);

  const startCheckout = () => {
    const accessToken = localStorage.getItem('jwt');
    const tokenCheckout = currentProduct?.config?.api_checkout_token || currentProduct?.config_work_unit?.api_checkout_token;
    const userProducts = JSON.parse(localStorage.getItem('userProducts') || '{}');
    const userProduct = {
      work_unit_relation: {
        token: userProducts?.work_unit_relation?.[0]?.token || null,
        long_name: userProducts?.work_unit_relation?.[0]?.long_name || null
      },
      username: userProducts?.username,
      hash_client: userProducts?.hash_client,
      products: [
        {
          product_type_id: currentProduct?.product_type_id || null,
          ref_table_detail: currentProduct?.ref_table_detail || null,
          parameters: currentProduct?.parameters || null,
          work_unit_id: currentProduct?.work_unit_id || null,
          token_id: currentProduct?.token_id || null,
          work_unit_token: currentProduct?.work_unit_token || null,
          business_unit_token: currentProduct?.business_unit_token || null
        }
      ]
    }
    let dataToSend = getDataStartCheckout(clientData?.client, params, ip, currentProduct);
    dataToSend = {
      ...dataToSend,
      username: user?.username,
      user_product: userProduct,
      deviceType: isMobile ? 'mobile' : 'desktop'
    }
    props.startCheckout(accessToken, tokenCheckout, dataToSend);
    setLoadingCheckout(true);
  }

  return (
    <div className={`wrapper checkout site-${client.client.name}`}>
      <Header
        client={client}
        user={user}
        reservations={reservations}
        selectedReservation={selectedReservation}
        isIframe={isIframe}
        setReservations={setReservations}
        handleSelectReservation={handleSelectReservation}
      />

      <PreloadMetabuscador
        client={client}
        ip={ip}
        user={user}
        module={params.module}
        startCheckout={startCheckout}
      />

      <Footer client={client} />
    </div>
  );
}

const mapStateToProps = reducers => {
  return reducers.checkoutReducer;
};

const mapDispatchToProps = dispatch => {
  return {
    startCheckout: (access, tokenCheckout, data) => dispatch(startCheckoutAction(access, tokenCheckout, data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutMetabuscador);
