import React from 'react';
import { Button, Container, Grid, Hidden, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AnnouncementOutlined } from '@mui/icons-material';

const RegretContainer = ({ title, content, link }) => {
  const [t] = useTranslation();

  return (
    <Container className='regret-container'>
      <Grid container columns={24} spacing={2}>
        <Hidden mdDown>
          <Grid item md={2} className='column' justifyContent='center'>
            <AnnouncementOutlined fontSize='large' />
          </Grid>
        </Hidden>

        <Grid item xs={true}>
          {(title || title === 'blank') && (
            <Typography variant='h4' className='title row'>
              {title !== 'blank' ? (
                <>
                  <Hidden mdUp>
                    <AnnouncementOutlined fontSize='large' className='mr-05' />
                  </Hidden>
                  {title}
                </>
              ) : (
                <>&nbsp;</>
              )}
            </Typography>
          )}
          {content && content.map((text, textIndex) => (
            <Typography key={textIndex}>
              {text}
            </Typography>
          ))}
        </Grid>

        <Grid item xs={24} md={8} className='column' justifyContent='center'>
          <Button variant='contained' href='/formularioContacto/cancelar' target='_blank'>
            {t('common.regretButton')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default RegretContainer;
