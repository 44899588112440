import React, { useState, useEffect } from 'react';
 import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
 import { useTranslation } from 'react-i18next';
 import { useNavigate } from 'react-router-dom';
 import SearchIcon from '@mui/icons-material/Search';
 
 const PaymentUnavailableModal = () => {
     const { t } = useTranslation();
     const navigate = useNavigate();
     const [open, setOpen] = useState(false);
 
     useEffect(() => {
         // Verificamos si la bandera está en localStorage
         if (localStorage.getItem("showPaymentUnavailableModal") === "true") {
             setOpen(true);
             localStorage.removeItem("showPaymentUnavailableModal");
         }
     }, []);
 
     const handleClose = () => {
         setOpen(false);
     };
 
     return (
         <Dialog
             open={open}
             maxWidth="sm"
             fullWidth
         >
             <DialogTitle sx={{
                 textAlign: 'left',
                 fontWeight: 'semibold',
                 fontSize: '1.25rem'
             }}
                 color='primary'
             >
                 {t('checkout.common.noCurrencyBrandedModalTitle')}
             </DialogTitle>
             <DialogContent>
                 <Typography
                     sx={{
                         textAlign: 'center',
                         fontWeight: 'semibold',
                         fontSize: '1.15rem',
                         mb: 1
                     }}
                     color='primary'>
                     {t('checkout.common.noCurrencyBrandedModalSubtitle')}
                 </Typography>
                 <Typography sx={{
                     textAlign: 'center',
                     fontSize: '0.7rem',
                 }}>
                     {t('checkout.common.noCurrencyBrandedModalText')}
                 </Typography>
             </DialogContent>
             <DialogActions>
                 <Button
                     onClick={() => { handleClose() }}
                     variant="contained"
                     color="secondary"
                     style={{
                         margin: 'auto',
                         fontSize: '0.8rem',
                         borderRadius: '25px'
                     }}
                     startIcon={<SearchIcon />}
                 >
                     {t('checkout.common.noCurrencyBrandedModalButton')}
                 </Button>
             </DialogActions>
         </Dialog>
     );
 };
 
 export default PaymentUnavailableModal;