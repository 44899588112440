import React, { useMemo } from 'react'
import CardOneCol from './CardOneCol'
import CardOneColV2 from './CardOneColV2'
import CardTwoCols from './CardTwoCols'
import CardTwoColsV2 from './CardTwoColsV2'
import CardOneColV3 from './CardOneColV3'
import CardBrandedFares from './CardBrandedFares'

export default function DetailItem({
  item,
  template,
  showDetailSearch,
  showDetailProductFull,
  dialogCrossell = null,
  detailButton = null,
  isModal = false
}) {

  const commonProps = useMemo(() => ({
    template,
    item,
    showDetailSearch,
    showDetailProductFull,
    detailButton
  }), [template, item, showDetailSearch, showDetailProductFull, detailButton]);

  const templateComponents = {
    CardOneCol: <CardOneCol template={template} item={item} />,
    CardOneColV2: <CardOneColV2 {...commonProps} />,
    CardOneColV2brandedFares: (
      <CardBrandedFares
        template={template}
        item={item}
        isModal={isModal}
        dialogCrossell={dialogCrossell}
      />
    ),
    CardTwoCols: <CardTwoCols {...commonProps} />,
    CardTwoColsV2: (
      <CardTwoColsV2
        template={template}
        item={item}
        detailProduct={item.details?.detailProduct}
      />
    ),
    CardOneColV3: <CardOneColV3 {...commonProps} />
  };

  return (
    <div className='detail-item'>
      {templateComponents[template] || null}
    </div>
  );
}
