import React, { memo, useMemo } from 'react';
import { Typography } from '@mui/material';
// import IconC from './../_IconC';

const GetDetailSearch = memo(({ detailSearch }) => (
  <Typography component='p' className='detail-item-list mt-2'>
    {detailSearch.map((item, i) => (
      <div key={i} className='item-detail-search'>
        <strong>{item.title}</strong> {item.description}
      </div>
    ))}
  </Typography>
), []);

const GetDetailProductSelection = memo(({ detailProduct }) => {
  const detailProductSelection = useMemo(() => (
    detailProduct.filter((item) => item.className.includes('featured'))
  ), [detailProduct]);

  return(
    <ul className='detail-item-list products'>
      {detailProductSelection.map((item, i) =>
        <li key={i} className={`${item.className} list-item`}>
          <Typography component='p' className={item.icon ? 'has-icon' : ''}>
            {/* {item.icon && <IconC icon={item.icon} className='custom-icon' />}  {item.title && <span className='title'>{item.title}</span> } */}
            {item.description && (
              <span className={item.className.includes('rate-type') ? 'ellipsisx3 description' : 'description'}>
                {item.description}
              </span>
            )}
          </Typography>
        </li>
      )}
    </ul>
  )
});

const GetDetailProduct = memo(({ detailProduct }) => (
  <ul className='detail-item-list products'>
    {detailProduct.map((item, i) => (
      <li key={i} className={`${item.className} list-item`}>
        <Typography component='p' className={item.icon ? 'has-icon' : ''}>
          {/* {item.icon && <IconC icon={item.icon} className='custom-icon' />}  {item.title && <span className='title'>{item.title}</span> } */}
          {item.description && <span className='description'>{item.description}</span>}
        </Typography>
      </li>
    ))}
  </ul>
));

export default function CardOneColV2({
  template,
  item,
  showDetailSearch,
  showDetailProductFull,
  detailButton
}) {
  const detailProduct = item.detais?.detailProduct;
  const detailSearch = item.details?.detailSearch;

  return (
    <div className={template}>
      {detailProduct && (
        showDetailProductFull
          ? <GetDetailProduct detailProduct={detailProduct} />
          : <GetDetailProductSelection detailProduct={detailProduct} />
      )}

      {showDetailSearch && detailSearch && (
        <GetDetailSearch detailSearch={detailSearch} />
      )}

      {detailButton}
    </div>
  )
}
