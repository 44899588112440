import React, { useMemo }  from 'react'
import { Grid, Tooltip, Typography } from '@mui/material';
import { AirlineSeatReclineNormal, ChangeCircleOutlined, CurrencyExchange } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SmallBaggage } from '../../../../assets/images/baggage/smallBaggage.svg';
import { ReactComponent as MediumBaggage } from '../../../../assets/images/baggage/mediumBaggage.svg';
import { ReactComponent as LargeBaggage } from '../../../../assets/images/baggage/largeBaggage.svg';

export default function CardBrandedFares({ template, item, dialogCrossell, isModal = false }) {
  const { t } = useTranslation();

  const detailProduct = useMemo(() => item?.details?.detailProduct || [], [item]);

  const baggageTypes = useMemo(() => ({
    smallBaggage: detailProduct.find(product => product.title === 'smallBaggage'),
    mediumBaggage: detailProduct.find(product => product.title === 'mediumBaggage'),
    largeBaggage: detailProduct.find(product => product.title === 'largeBaggage'),
  }), [detailProduct]);

  const baggages = useMemo(() => {
    const baggageInfo = {
      small: {
        icon: <SmallBaggage className={`small ${baggageTypes.smallBaggage?.className}`} />,
        title: t('results.flights.smallBaggageTitle'),
        description: t('results.flights.smallBaggageDescription'),
      },
      medium: {
        icon: <MediumBaggage className={`medium ${baggageTypes.mediumBaggage?.className}`} />,
        title: baggageTypes.mediumBaggage?.className === 'included'
          ? t('results.flights.mediumBaggageTitle')
          : t('results.flights.mediumBaggageNotIncluded'),
        description: baggageTypes.mediumBaggage?.className === 'included'
          ? t('results.flights.mediumBaggageDescription')
          : null,
      },
      large: {
        icon: <LargeBaggage className={`large ${baggageTypes.largeBaggage?.className}`} />,
        title: baggageTypes.largeBaggage?.className === 'included'
          ? t('results.flights.largeBaggageIncluded')
          : t('results.flights.largeBaggageNotIncluded'),
        description: baggageTypes.largeBaggage?.className === 'included'
          ? `${baggageTypes.largeBaggage.description}. ${t('results.flights.largeBaggageDescription')}`
          : null,
      },
    };

    return <Grid container className='baggage-container' spacing={1}>
      {Object.values(baggageInfo).map((elem, i) => (
        <Grid item xs={12} md={isModal ? 12 : 4} key={i}>
          <Grid container className='row' spacing={1} columns={24}>
            <Grid item xs={3} className='icon-container'>{elem.icon}</Grid>
            <Grid item xs={21} className='column baggage-description'>
              <b>{elem.title}</b>
              {elem.description && <span>{elem.description}</span>}
            </Grid>
          </Grid>
        </ Grid>
      ))}
    </Grid>
  }, [baggageTypes, isModal, t]);

  const baggageIcons = (
    <Tooltip title={baggages}>
      <SmallBaggage className={`small ${baggageTypes.smallBaggage?.className}`} />
      <MediumBaggage className={`medium ${baggageTypes.mediumBaggage?.className}`} />
      <LargeBaggage className={`large ${baggageTypes.largeBaggage?.className}`} />
    </Tooltip>
  );

  const getFeatureIcon = (feature) => {
    switch (feature.icon) {
      case 'seat-selection':
        return <AirlineSeatReclineNormal className={feature.className} />;
      case 'changeable':
        return <ChangeCircleOutlined className={feature.className} />;
      case 'refundable':
        return <CurrencyExchange className={feature.className} />;
      default:
        return null;
    }
  };

  const fareFeatures = useMemo(() => {
    const features = detailProduct.filter(product => !product.title.includes('Baggage'));

    if (isModal) {
      return features.map((feature) => (
        <div className='row' key={feature.title}>
          {getFeatureIcon(feature)}
          <Typography>
            <b>{t(`results.flights.${feature.title}`)}:</b> {feature.description}
          </Typography>
        </div>
      ));
    }

    return <Grid container spacing={2}>
      {features.map((feature, i) => (
        <Grid item xs={12} md={4} key={i}>
          <Grid container className='row' spacing={1} columns={24}>
            <Grid item xs={3} className='icon-container'>
              {getFeatureIcon(feature)}
            </Grid>
            <Grid item xs={21} className='column fare-feature-description'>
              <b>{t(`results.flights.${feature.title}`)}:</b>
              {feature.description}
            </Grid>
          </Grid>
        </ Grid>
      ))}
    </Grid>
  }, [detailProduct, isModal, t]);

  return (
    <Grid container className={template} spacing={1}>
      {!isModal && (
        <Grid item xs={12} className='subtitle'>
          <Typography>{t('checkout.flights.baggageDetail')}</Typography>
        </Grid>
      )}

      <Grid item xs={12} className='baggage-container-icons'>
        {isModal ? baggageIcons : baggages}
      </Grid>

      {!isModal && (
        <Grid item xs={12} className='subtitle'>
          <Typography>{t('checkout.flights.services')}</Typography>
        </Grid>
      )}

      <Grid item xs={12} md={9} className='fare-features-container'>
        {fareFeatures}
      </Grid>

      {!isModal && Boolean(dialogCrossell) && (
        <Grid item xs={12} md={3}>
          {dialogCrossell}
        </Grid>
      )}
    </Grid>
  )
}
