import React, { useEffect } from 'react';
import Navbar from '../../layout/Navbar';
import { checkToken } from '../../../store/services/Login';
import { useNavigate } from 'react-router';
import { useBannersLandingsData } from '../../../context/BannersLandingsContext';
import AllBanners from '../../common/Banners/AllBanners';
import { useClientData } from '../../../context/ClientContext';

function HomePage({ selectedReservation }) {
  const navigate = useNavigate();
  const { bannersData } = useBannersLandingsData();
  const { updateProductToken } = useClientData();

  useEffect(() => {
    ;(async () => {
      try {
        const token = localStorage.getItem('jwt');
        await checkToken(token);
        updateProductToken(['hotel_detail']);
      } catch (error) {
        navigate('/login', { state: { expiredSession: true } });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="gimmonix-home homepage">
      <Navbar selectedReservation={selectedReservation} />

      <AllBanners
        banners={bannersData?.data?.modules?.['HOTEL']?.flows?.home?.banners || {}}
        titlePromotions='Sumá más productos a tu reserva de hotel'
        titleOffers='Las mejores ofertas hoteleras por el mundo'
        titleFeatured='Las mejores ofertas hoteleras por el mundo'
      />
    </div>
  );
}

export default HomePage;
