const getFlightsData = (client, params, ip, product) => {
  const token = product?.config?.['API-Token']?.[params.currency];

  return {
    module: client?.isB2C ? 'flights' : 'airNarrative',
    dataProduct: {
      recommendationID: params.recommendationID,
      fareId: params.fareID,
      optionId: params.optionID.split(','),
      brandedFare: null,
      brandedFareIndex: null,
      adults: params.adults,
      children: params.children,
      infants: params.infants,
      dateFrom: params.dateFrom,
      dateTo: params.dateTo,
      urlRedirect: '/vuelos',
      supplier: params.supplier,
      ip,
      utm: '',
      loginData: null,
      cabin: params.cabin,
      urlMeta: '',
      token,
      currency: params.currency
    }
  }
}

export const getDataStartCheckout = (client, params, ip, product) => {
  switch (params.module) {
    case 'vuelos':
      return getFlightsData(client, params, ip, product);
    default:
      return {};
  }
}