import allCountries from './allCountries.json';
import allStates from './allStates.json';

export const getCountries = (language = 'es') => {
  return allCountries
    .map(elem => ({
      ...elem,
      description: elem.name[language]
    }))
    .sort((a, b) => a.name[language].toLowerCase().localeCompare(b.name[language].toLowerCase()));
}

export const getStatesByCountry = (country, language = 'es') => {
  if (country) {
    let states = allStates.filter(elem => elem.id === country || elem.country_code === country)?.states;
    if (states.length === 0) {
      const countryAux = allCountries.find(elem => elem.id === country || elem.code_2 === country);
      states = [{
        id: countryAux.id,
        name: countryAux.name[language]
      }]
    }

    return states.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
  }

  return [];
}

export const getSituation = () =>{
  const response = [
    { code: '04', description: 'Consumidor Final' },
    { code: '03', description: 'Exento' },
    { code: '05', description: 'Monotributo' },
    { code: '06', description: 'No responsables' }
  ];

  return response;
}

export const getDocType = (country = null) =>{
  let response = [];

  if (country === 'BR') {
    response = [
      { code: 'CPF', description: 'CPF' },
      { code: 'CNPJ', description: 'CNPJ' }
    ];
  } else {
    response = [
      { code: 'Dni', description: 'DNI' },
      { code: 'Lc', description: 'LC' },
      { code: 'PP', description: 'PAS' }
    ];
  }

  return response;
}