import React, { Fragment } from 'react';
import { Avatar, Badge, Card, CardContent, CardHeader, Grid, Icon, Typography } from '@mui/material';
import DetailDialog from './DetailDialog';
import * as HotelsDetail from './utils/HotelsDetail';
import * as TrainsDetail from './utils/TrainsDetail';
import * as FlightsDetail from './utils/FlightsDetail';
import { useTranslation } from 'react-i18next';
import { useClientData } from '../../../context/ClientContext';
import { usePurchaseDetailContext } from '../../../context/PurchaseDetailContext';
import { connect } from 'react-redux';
import InfoPopover from './InfoPopover';
import { useParams } from 'react-router-dom';
import { ShoppingCart } from '@mui/icons-material';
import { formatNumber, getCurrency } from '../../../utils/price';

const PurchaseDetail = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { clientData, currencies } = useClientData();
  const { purchaseDetail, products, includeComission, interest } = usePurchaseDetailContext();

  const getProductsDetailsB2B = () => {
    if (params.module === 'hotels') {
      return HotelsDetail.getProductsDetails(purchaseDetail);
    } else if (params.module === 'trains') {
      return TrainsDetail.getProductsDetails(purchaseDetail);
    } else if (params.module === 'flights') {
      return FlightsDetail.getProductsDetails(purchaseDetail);
    }
  }

  const getQuotaText = (currency) => {
    const amount = formatNumber(interest.amountPerQuota, 2);
    const text = interest.quota === 1
      ? t('checkout.common.paymentText', { currency, amount })
      : t('checkout.common.paymentsText', { quotas: interest.quota, currency, amount })

    return <span>{text}</span>
  }

  const getProductsDetailsB2C = () => {
    const currency = {
      symbol: getCurrency(clientData?.client, currencies, purchaseDetail?.currency),
      text: purchaseDetail?.currency
    };

    const cart = JSON.parse(JSON.stringify(products?.pivot || [])).filter(elem => elem.priceDetail?.addToCart);

    if (interest) {
      if (interest.chargeAmount && !interest.interestAmount) {
        const charges = cart?.[0]?.priceDetail?.charge || [];
        const chargeIndex = charges.findIndex(elem => elem.title === t('checkout.common.charges'));
        if (chargeIndex > -1) {
          const valorReal = charges[chargeIndex].valorReal + interest.chargeAmount;
          charges[chargeIndex] = {
            ...charges[chargeIndex],
            valor: formatNumber(valorReal, 2),
            valorReal,
          };
        } else {
          charges.push({
            title: t('checkout.common.charges'),
            valor: formatNumber(interest.chargeAmount, 2),
            valorReal: Number(interest.chargeAmount)
          });
        }

        cart[0].priceDetail.charge = charges;
      } else {
        cart.push({
          priceDetail: {
            title: interest.interestDisplay || t('checkout.common.interest'),
            amountReal: interest.interestAmount
          },
        });
      }
    }

    return cart.flatMap((item, i) => {
      if (item?.cardDetail?.icon === 'flight' || item?.cardDetail?.fareFeatures) {
        return FlightsDetail.getProductsDetailsB2C(item, currency, t);
      } else {
        return <Grid
          key={i}
          container
          className='item-resume'
        >
          <Grid item xs={8}>
            <div className='container-item-resume row'>
              <span className='row'>
                {item?.cardDetail?.icon && <Icon className='product-icon'>{item?.cardDetail?.icon}</Icon>}
                {item?.priceDetail?.title}
              </span>
              {item?.priceDetail?.charge && item?.priceDetail?.charge.length > 0 && (
                <InfoPopover
                  className='info-charge'
                  charge={item?.priceDetail?.charge}
                  icon={item?.priceDetail?.icon}
                  currency={currency.symbol}
                />
              )}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={`text-right`}>
              <span>
                {currency.symbol}&nbsp;
                {formatNumber(item?.priceDetail?.priceDiffReal !== undefined ? item?.priceDetail?.priceDiffReal : item?.priceDetail?.amountReal || 0, 2)}
              </span>
            </div>
          </Grid>
        </Grid>
      }
    });
  }

  const getProductsDetails = () => {
    if (clientData.client.isB2C) {
      return getProductsDetailsB2C();
    } else {
      return getProductsDetailsB2B();
    }
  }

  const getPriceDetailsB2B = () => {
    if (params.module === 'trains') {
      return TrainsDetail.getPriceDetails(purchaseDetail, includeComission, false);
    } else if (params.module === 'flights') {
      return FlightsDetail.getPriceDetails(clientData?.client, purchaseDetail);
    }

    return [
      {
        title: 'total',
        value: purchaseDetail?.totalAmount,
        classname: 'strong amount',
        showTax: true
      }
    ];
  }

  const getPriceDetailsB2C = () => {
    let totalAmount = 0;

    if (purchaseDetail?.totalAmountRealFinal) {
      totalAmount = purchaseDetail.totalAmountRealFinal;
    } else {
      const cart = products?.pivot?.map(elem => elem.priceDetail)?.filter(elem => elem.addToCart) || [];

      if (interest) {
        cart.push({ amountReal: interest.interestAmount || interest.chargeAmount });
      }

      totalAmount = cart.reduce((acc, current) => (
        acc + current.amountReal
      ), 0);
    }

    const currency = {
      symbol: getCurrency(clientData?.client, currencies, purchaseDetail?.currency),
      text: purchaseDetail?.currency
    };

    const charge = interest && interest.amountPerQuota
      ? [{  title: getQuotaText(currency?.symbol) }]
      : null;

    return [{
      title: 'total',
      value: formatNumber(totalAmount, 2),
      classname: 'strong amount',
      charge,
      currency
    }];
  }

  const getPriceDetails = () => {
    if (clientData.client.isB2C) {
      return getPriceDetailsB2C();
    } else {
      return getPriceDetailsB2B();
    }
  }

  const getBadgeLength = () => {
    return products?.pivot?.filter(elem => elem.priceDetail?.addToCart && elem.module !== 'brandedFares').length || 0;
  }

  return (
    <Card className='purchase-card'>
      <CardHeader
        avatar={
          <Avatar className='avatar-purchase-detail'>
            <Badge
              badgeContent={getBadgeLength()}
              color='secondary'
              overlap='circular'
            >
              <ShoppingCart />
            </Badge>
          </Avatar>
        }
        action={products &&
          <DetailDialog icon='expand_more'/>
        }
        title={
          <Typography className='title-card-header'>
            {purchaseDetail?.title}
          </Typography>
        }
        subheader={
          <Typography noWrap className='title-card-subheader' >
						{purchaseDetail?.subtitle}
          </Typography>
        }
      />

      <CardContent>
        {getProductsDetails()}
      </CardContent>

      <Grid container className='price-details-container row'>
        {getPriceDetails().map((elem, i) => (
          <Fragment key={i}>
            {elem.value && parseInt(elem.value.replaceAll('.', '')) > 0 && (
              <>
                <Grid item xs={6} sm={8} md={6} xl={7} className='left'>
                  <Grid className='amount-container'>
                    <Typography className={elem.classname}>{elem.noTranslate ? elem.title : t(`checkout.common.${elem.title}`)}</Typography>
                    {elem?.charge && elem?.charge.length > 0 && (
                      <InfoPopover
                        className='info-charge-total'
                        charge={elem?.charge}
                        icon={elem?.icon}
                        currency={elem?.currency.symbol}
                      />
                    )}
                  </Grid>
                  {elem.showTax && Boolean(purchaseDetail?.taxAmount) && purchaseDetail?.taxAmount > 0 && (
                    <Typography className='taxes'>
                      {t('checkout.common.taxesIncluded')} {purchaseDetail?.currency} {purchaseDetail?.taxAmount}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6} sm={4} md={6} xl={5} className='right'>
                  <Typography className={`text-right ${elem.classname}`}>
                    {elem.parentehsis && '('}
                    {getCurrency(clientData?.client, currencies, purchaseDetail?.currency)} {elem.value}
                    {elem.parentehsis && ')'}
                  </Typography>
                </Grid>
              </>
            )}
          </Fragment>
        ))}
      </Grid>
    </Card>
  )
}

const mapStateToProps = reducers => {
  return reducers.checkoutReducer;
};

export default connect(mapStateToProps, null)(PurchaseDetail);
