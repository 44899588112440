import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  Fragment,
  useState,
  useEffect,
  useCallback,
  useMemo,
  createRef
} from 'react';
import { Card, CardHeader, Grid, Hidden, Typography } from '@mui/material';
import Carousel from 'react-multi-carousel';
import CardProduct from '../crossell/CardProduct';
import DialogCrossell from '../crossell/DialogCrossell';
import { AddCircleOutline, ChevronRight } from '@mui/icons-material';
import { ReactComponent as BaggageImage } from '../../../../assets/images/baggage/baggage.svg';
import CrossellResume from '../crossell/CrossellResume';
import DefaultForm from '../form/crossell/DefaultForm';
import { usePurchaseDetailContext } from '../../../../context/PurchaseDetailContext';
import { useTranslation } from 'react-i18next';
import MainTitle from '../MainTitle';
import { useParams } from 'react-router-dom';

const Crossell = ({ data, generalData }, ref) => {
  const { t } = useTranslation();
  const params = useParams();
  const componentRef = useRef([]);
  const { products, handleAddCrosselToCart, handleRemoveCrosselToCart } = usePurchaseDetailContext();
  const [crossellData, setCrossellData] = useState({});
  const [defaultOpenedCrossell, setDefaultOpenedCrossell] = useState();

  useImperativeHandle(ref, () => ({
    handleSubmit: handleSubmit,
  }));

  const carouselResponsive = useMemo(() => ({
    breakpoint3: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3,
    },
    breakpoint2: {
      breakpoint: { max: 1023, min: 640 },
      items: 2,
    },
    breakpoint1: {
      breakpoint: { max: 639, min: 0 },
      items: 1,
    },
  }), []);

  const brandedFareCrossell = useMemo(() => {
    const brandedFaresData = JSON.parse(
      JSON.stringify(data.find(elem => elem.name === 'search_crossell')?.tasks?.get_branded_fares?.data || {})
    );

    if (!Object.keys(brandedFaresData).length) {
      return null
    };

    const selectedBranded = brandedFaresData.cardProducts?.find(elem => elem.rateId === generalData.fareId || elem.priceDetail.title === generalData.fareCode);
    const initialPrice = parseInt(selectedBranded?.priceDetail?.amountReal || 0);

    const cardProducts = brandedFaresData.cardProducts
      ?.map((cardProduct, index) => ({
        ...cardProduct,
        index,
      }))
      ?.filter(cardProduct => parseInt(cardProduct?.priceDetail?.amountReal || 0) >= initialPrice) || [];

    const firstOption = cardProducts[0];
    const updatedCardProducts = cardProducts.map((cardProduct, i) => ({
      ...cardProduct,
      priceDetail: {
        ...cardProduct.priceDetail,
        priceDiff: (cardProduct.priceDetail.priceDiffReal - firstOption.priceDetail.priceDiffReal).toString(),
        priceDiffReal: cardProduct.priceDetail.priceDiffReal - firstOption.priceDetail.priceDiffReal,
        priceDiffPerPerson: (cardProduct.priceDetail.priceDiffPerPersonReal - firstOption.priceDetail.priceDiffPerPersonReal).toString(),
        priceDiffPerPersonReal: cardProduct.priceDetail.priceDiffPerPersonReal - firstOption.priceDetail.priceDiffPerPersonReal
      },
    }));

    return { ...brandedFaresData, cardProducts: updatedCardProducts };
  }, [data, products]);

  const crossellItems = useMemo(() => {
    const crossellData = JSON.parse(
      JSON.stringify(data.find(elem => elem.name === 'search_crossell')?.tasks?.search_crossell?.data || {})
    );

    return Object.entries(crossellData).map(([key, value]) => {
      value.module = key;
      return value
    });
  }, [data]);

  useEffect(() => {
    if (!crossellItems?.length && !brandedFareCrossell) return;

    const initializeCrossellData = () => {
      const initialData = crossellItems.reduce((acc, item) => {
        acc[item.module] = null;
        return acc;
      }, {});

      setCrossellData(initialData);

      Object.entries(initialData).forEach(([key, value]) => {
        if (value?.idOffer) {
          handleAddCrosselToCart(value);
        }
      });
    }

    const setupComponentRefs = () => {
      [...crossellItems, brandedFareCrossell].forEach(elem => {
        if (elem.includeForm) {
          componentRef.current[elem.module] = createRef();
        }
      });
    }

    initializeCrossellData();
    setupComponentRefs();
    if (brandedFareCrossell?.cardProducts?.[0]) {
      const selectedOption = brandedFareCrossell.cardProducts?.find(
        elem => elem.rateId === generalData.fareId || elem.priceDetail.title === generalData.fareCode
      );

      setCrossellData(prev => ({
        ...prev,
        ['brandedFares']: {
          ...prev['brandedFares'],
          ...selectedOption
        }
      }));

      handleAddCrosselToCart(selectedOption);

      setTimeout(() => {
        const moreResults = brandedFareCrossell.cardProducts.length > 1;
        if (moreResults) {
          setDefaultOpenedCrossell('brandedFares');
        }
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getModalCrosselIcon = useCallback((module) => {
    switch (module) {
      case 'brandedFares':
        return <BaggageImage className='baggage-image' />;
      default:
        return null;
    }
  }, []);

  const handleSubmit = async () => {
    const dataToSend = [];
    let responseStatus = true;

    if (crossellData?.brandedFares) {
      dataToSend.push({
        event_name: "form_branded_fares",
        event_data: {
          fare_id: crossellData.brandedFares?.rateId,
          source: "checkout"
        }
      });
    }

    const crossell = {};
    const entries = Object.entries(crossellData);
    for (let i = 0; i < entries.length; i++) {
      const [key, value] = entries[i];
      if (key !== 'brandedFares' && value?.rateId) {
        crossell[key] = {
          idOffer: value.idOffer,
          rateId: value.rateId
        };

        const component = componentRef.current?.[key];
        if (component?.current) {
          const responseChildComponent = await component.current.handleSubmit();
          responseStatus = responseStatus && (responseChildComponent?.status || false);

          crossell[key] = {
            ...crossell[key],
            ...responseChildComponent.data
          }
        }
      }
    };

    if (Object.keys(crossell).length > 0) {
      dataToSend.push({
        event_name: "form_crossell",
        event_data: crossell
      });
    }

    const data = {
      status: responseStatus,
      data: dataToSend
    };

    return data;
  }

  const onSelectCrossell = (crossellName, product) => {
    setDefaultOpenedCrossell(false);
    setCrossellData(prev => ({
      ...prev,
      [crossellName]: {
        ...prev[crossellName],
        ...product
      }
    }));
    handleAddCrosselToCart(product);
  }

  const onRemoveCrossell = (crossellName) => {
    setCrossellData(prev => ({
      ...prev,
      [crossellName]: {
        ...prev[crossellName],
        idOffer: null,
        index: null
      }
    }));
    handleRemoveCrosselToCart(crossellName);
  }

  const getCrosellResume = (item, crossellName) => {
    return <Grid className='selected-crossell'>
      <CrossellResume
        initOpen={crossellName === defaultOpenedCrossell}
        item={item}
        crossellData={crossellData}
        resumenCrossell={crossellData[crossellName]}
        onSelectCrossell={(product) => onSelectCrossell(crossellName, product)}
        onRemoveCrossell={() => onRemoveCrossell(crossellName)}
      />

      {item.includeForm &&
        <DefaultForm
          ref={componentRef.current[crossellName]}
          form={Object.keys(item.form || {})?.[0]}
        />
      }
    </Grid>
  }

  const renderCardProducts = useCallback((cardProducts, crosselName) => (
    cardProducts.map((cardProduct, index) => (
      <div key={index} className='carousel-item-crossell'>
        <CardProduct
          cardProduct={cardProduct}
          crossellData={crossellData[crosselName]}
          isModal={true}
          onSelectCrossell={(idOffer) => onSelectCrossell(crosselName, idOffer, index)}
          onRemoveCrossell={() => onRemoveCrossell(crosselName)}
        />
      </div>
    ))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [crossellData]);

  const getBrandedFareCrossell = () => {
    if (brandedFareCrossell) {
      return <>
        <Typography className="row carousel-crossell-title" gutterBottom variant='h1' component='h2'>
          {brandedFareCrossell.title}
        </Typography>

        {Boolean(crossellData['brandedFares']?.idOffer) ? (
          getCrosellResume(brandedFareCrossell, 'brandedFares')
        ) : (
          <DialogCrossell
            fullWidth
            maxWidth='md'
            className='crossell-branded-modal'
            crossellData={crossellData}
            crosselFormName={'brandedFares'}
            products={brandedFareCrossell.cardProducts}
            header={<div className='modal-title-container row'>
              <Hidden smDown>
                {getModalCrosselIcon(brandedFareCrossell.module)}
              </Hidden>
              <div>
                <Typography className='modal-title'>{brandedFareCrossell.titleModal || brandedFareCrossell.title}</Typography>
                <Typography className='modal-subtitle'>{brandedFareCrossell.subtitleModal}</Typography>
              </div>
            </div>}
            onSelectCrossell={product => onSelectCrossell('brandedFares', product)}
          >
            <Card>
              <CardHeader
                avatar={<Hidden smDown>{getModalCrosselIcon(brandedFareCrossell.module)}</Hidden>}
                title={<Typography className='title-card-header' variant='h3'>{brandedFareCrossell.messages.cardTitle}</Typography>}
                subheader={<Typography className='title-card-subheader' variant='body2'>{brandedFareCrossell.messages.cardSubtitle}</Typography>}
                action={<ChevronRight size={1} color='#ababab' />}
              />
            </Card>
          </DialogCrossell>
        )}
      </>
    }
  }

  return (
    <section className='step-crossell'>
      <MainTitle module={params.module} />
      <div className='crossell-products'>
        {getBrandedFareCrossell()}

        {crossellItems.length > 0 && (
          <Typography component='h2' className='paper-title row mt-1'>
            <AddCircleOutline />
            {t('checkout.crossell.addMoreProducts')}
          </Typography>
        )}

        {crossellItems?.map((item, i) => {
          const crosselName = item.module || item.cardProducts?.[0]?.module;

          return <Fragment key={i}>
            <Typography className="row carousel-crossell-title" gutterBottom variant='h1' component='h2'>
              {item.title}
            </Typography>
            {!Boolean(crossellData[crosselName]?.idOffer) ? (
              <Grid>
                {item.template === 'slider' && (
                  <Carousel className='carousel-crossell' responsive={carouselResponsive}>
                    {renderCardProducts(item.cardProducts, crosselName)}
                  </Carousel>
                )}
              </Grid>
            ) : (
              getCrosellResume(item, crosselName)
            )}
          </Fragment>
        })}
      </div>
    </section>
  );
}

export default forwardRef(Crossell);
